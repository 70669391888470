import './index.css';
import React from 'react';
import { Col, notification, Row, Spin, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utilities/time-utils';
import { useDisposeBatch } from '../../services/batch';
import { Formik } from 'formik';
import { EditBatchForm } from '../../components';
import { useSensorInputData } from '../../hooks';
import { LANGUAGE_CODES } from '../../utilities/constants';

const { Text } = Typography;

const buildInitialValues = (dataSource) => {
  return {
    batch: dataSource.batch,
    batchItemList: dataSource.batch.batchItemList,
    remark: dataSource.batch.remark,
  };
};

export const BatchInfo = ({
  header,
  columns = [],
  dataSource = [],
  pagination = false,
  isLoading = false,
  isPagination,
  isShowCount,
  rowKey = (item, index) => index,
  onPageChange,
  currentPage,
  totalCount,
  pageSize,
  onRow,
}) => {
  const { t, i18n } = useTranslation();
  // const { mutate: DatainputAndExchange, status: editStatus } = useDatainputAndExchange();
  const { mutate: disposeBatch, status: disposeStatus } = useDisposeBatch();
  const getHeader = () => {
    if (header) {
      return { title: header };
    }
  };
  const { setImageDateNull, forceSetImageDate } = useSensorInputData();
  const isDataExist = !isEmpty(dataSource);
  // console.log("dataSource",dataSource)
  const { notificationData, batch } = dataSource || {};
  const initialValues = buildInitialValues(dataSource);
  // console.log("initialValues",initialValues)
  return (
    <Spin spinning={!isDataExist && isLoading}>
      <Row>
        <Col span={24} className='text_lable'>
          {'baitchange' == notificationData.eventType
            ? t('baitchange')
            : t('fill_poison_bait')}{' '}
          {batch && formatDate(batch.createTime)}
        </Col>
        <Col span={8} className='text_lable'>
          {t('location')}
        </Col>
        <Col span={16} className='text_content'>
          {batch && i18n.language === LANGUAGE_CODES.ENGLISH
            ? batch.floorName + ' ' + batch.layerName + ' ' + batch.zoneName
            : batch.floorNameZh +
              ' ' +
              batch.layerNameZh +
              ' ' +
              batch.zoneNameZh}
        </Col>
        <Col span={8} className='text_lable'>
          {t('total')}
        </Col>
        <Col span={16} className='text_content'>
          {batch.batchItemList && batch.batchItemList.length}
        </Col>
        <Col span={24} className='text_content'>
          <Formik
            // initialValues={{originalId: sensor.id,location: sensor.siteName +' '+ sensor.floorName,falseAlarm:true}}
            initialValues={initialValues}
            /* validationSchema={DataInputSchema} */
            /* onSubmit={() => {console.log('tj')}} */
            // render={EditBatchForm}
            onSubmit={async (formValues, actions) => {
              try {
                formValues.falseAlarm = formValues.falseAlarm
                  ? formValues.falseAlarm
                  : false;
                formValues.notificationId = notificationData.id;
                await disposeBatch(formValues);
                // await actions.resetForm();
                notification.success({ message: t('editedSuccess') });
                window.location.reload();
              } catch ({ message }) {
                notification.error({ message });
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({
              values,
              submitCount,
              resetForm,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Spin spinning={disposeStatus === 'pending'}>
                  <EditBatchForm
                    values={initialValues}
                    submitCount={submitCount}
                    resetForm={(e) => {
                      resetForm();
                      forceSetImageDate('1');
                      setFieldValue('photo', '2');
                      // window.location.reload()
                    }}
                    // setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                  />
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Spin>
  );
};
