import classes from './index.module.css';
import React from 'react';
import { Col, Row, Spin } from 'antd';
import { Formik } from 'formik';
import { HomeFilterForm } from '../../components';
import { useFetchSites } from '../../services/sites';
import moment from 'moment';

export const Home = () => {
  const { data: { defaultLocation } = {}, isLoading } = useFetchSites();
  const baseInitialValues = {
    // startTime: moment().subtract(30, 'd'),
    // endTime: moment(),
    dateRange: [moment().subtract(7, 'd'), moment()],
    ...defaultLocation,
    types: ['detection_sensor', 'trap_sensor'],
    timeRange: '1',
  };

  return (
    <Spin spinning={isLoading}>
      <div className={classes.content}>
        <Row>
          <Col span={24}>
            <Formik
              initialValues={{
                ...baseInitialValues,
              }}
              onSubmit={() => {}}
            >
              <HomeFilterForm />
            </Formik>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Home;
