import React from 'react';
import {
  AccountForm,
  Breadcrumbs,
  Loader,
  PageError,
  PageHeader,
} from '../../../components';
import { Formik } from 'formik';
import { EditAccountInformationSchema } from '../../../utilities/schemas';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { notification, Spin } from 'antd';
import {
  useDeleteUser,
  useEditUser,
  useFetchUsers,
} from '../../../services/users';
import { useTranslation } from 'react-i18next';

const buildInitialValues = (user) => {
  let { roleId, name, email, title, state, overviewData } = user || {};
  overviewData = JSON.parse(overviewData);
  return {
    roleId,
    name,
    email,
    title,
    state,
    siteId: overviewData?.siteId,
    floorId: overviewData?.floorId,
    layerId: overviewData?.layerId,
  };
};
export const EditAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading, data: { users: data } = {} } = useFetchUsers({
    userId: id,
  });
  const { mutate: EditUser, status: editStatus } = useEditUser();
  const { mutate: deleteUser, status: deleteStatus } = useDeleteUser();
  if (!data && isLoading) {
    return <Loader />;
  }

  const user = data[0];
  if (!isLoading && isEmpty(user)) {
    return (
      <div style={{ padding: 10 }}>
        <PageError description='Data not found' />;
      </div>
    );
  }
  const initialValues = buildInitialValues(user);
  const handleDeleteUser = async () => {
    try {
      await deleteUser({ userId: parseInt(id) });
      notification.success({ message: t('deletedSuccess') });
      navigate('/account-management');
    } catch (e) {}
  };
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('accountManagement'),
            link: '/account-management',
          },
          {
            name: t('edit'),
            link: `/account-management/${id}/edit`,
          },
        ]}
      />
      <PageHeader title={t('editAccount')} />
      <Formik
        initialValues={initialValues}
        validationSchema={EditAccountInformationSchema}
        enableReinitialize={false}
        onSubmit={async (values, actions) => {
          try {
            const castValues = EditAccountInformationSchema.cast(values);
            const {
              siteId,
              email,
              name,
              floorId,
              layerId,
              roleId,
              title,
              state,
            } = castValues;
            const payload = {
              id,
              roleId,
              title,
              state,
              email,
              name,
              overviewData: JSON.stringify({ siteId, floorId, layerId }),
            };
            actions.setSubmitting(true);
            const { data } = await EditUser(payload);
            const updatedUser = data?.data?.user;
            const updatedData = buildInitialValues(updatedUser);
            await actions.resetForm({ values: updatedData });
            notification.success({ message: t('editedSuccess') });
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, values, submitCount, resetForm, setFieldValue }) => (
          <Spin
            spinning={deleteStatus === 'pending' || editStatus === 'pending'}
          >
            <AccountForm
              id={id}
              title='editAccount'
              handleSubmit={handleSubmit}
              values={values}
              submitCount={submitCount}
              resetForm={resetForm}
              handleDeleteUser={handleDeleteUser}
              setFieldValue={setFieldValue}
            />
          </Spin>
        )}
      </Formik>
    </div>
  );
};

export default EditAccount;
