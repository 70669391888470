import * as React from 'react';

export const ActiveTrapSvg = (props) => (
  <svg
    width={30}
    height={30}
    viewBox='0 0 50 50'
    fill='#fff'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='m50 39.14-4.395-4.395-2.93 2.93-2.929-2.93-2.93 2.93-2.93-2.93-2.929 2.93-2.93-2.93-2.93 2.93-2.929-2.93-3.537 3.536H13.18a7.39 7.39 0 0 0-1.461-1.46v-5.452l3.536-3.537-2.93-2.93 2.93-2.93-2.93-2.929 2.93-2.93-2.93-2.93 2.93-2.93-2.93-2.929 2.93-2.93L10.861 0H2.93v36.82A7.317 7.317 0 0 0 0 42.676C0 46.714 3.286 50 7.324 50a7.317 7.317 0 0 0 5.856-2.93H50v-7.93ZM5.86 2.93h3.787l1.465 1.465-2.93 2.93 2.93 2.929-2.93 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 2.93 2.93 2.929-2.323 2.323v5.344a7.32 7.32 0 0 0-2.93 0V2.929Zm1.464 44.14a4.4 4.4 0 0 1-4.394-4.394 4.4 4.4 0 0 1 4.394-4.395 4.4 4.4 0 0 1 4.395 4.395 4.4 4.4 0 0 1-4.395 4.394Zm39.746-2.93H14.501a7.328 7.328 0 0 0 0-2.93h5.344l2.323-2.322 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 1.464 1.465v3.788Z' />
    <path d='M5.86 41.21h2.93v2.93H5.86v-2.93Z' />
  </svg>
);

export default ActiveTrapSvg;
