import React from 'react';
import { Field } from 'formik';
import { Col, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { AntCheckbox } from '../custom-fields';

export const SecondSection = ({ submitCount }) => {
  const { t } = useTranslation();
  const dataSource = [
    {
      key: 1,
      viewKey: 'detectionSensor',
      section: t('rodentDetectionSensor'),
      edit: false,
    },
    {
      key: 3,
      viewKey: 'trapSensor',
      section: t('rodentTrapSensor'),
      edit: false,
    },
  ];
  return (
    <Col span={24}>
      <Table
        pagination={false}
        rowKey='key'
        dataSource={dataSource}
        columns={[
          {
            title: t('section'),
            key: 'section',
            dataIndex: 'section',
          },
          {
            title: t('view'),
            key: 'permission',
            dataIndex: 'permission',
            render: (text, record, i) => {
              return (
                <Field
                  hasFeedback
                  type='checkbox'
                  component={AntCheckbox}
                  submitCount={submitCount}
                  name={`secondSection.${i}.${record.viewKey}`}
                />
              );
            },
          },
        ]}
      />
    </Col>
  );
};
