import i18next from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Image,
  Modal,
  Space,
  Tooltip,
  notification,
  Select,
} from 'antd';
import { useFetchBatch } from '../../../services/batch';
import { formatDate } from '../../../utilities/time-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import {
  Breadcrumbs,
  CustomTable,
  PageError,
  PageHeader,
} from '../../../components';
import {
  LANGUAGE_CODES,
  Manual_BATCH_OPTIONS,
  EVENT_TYPES,
} from '../../../utilities/constants';
import { searchTableFields, sorter } from '../../../utilities/transform';
import {
  FORM_STORAGE_KEYS,
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../../utilities/storage';

const { Option } = Select;

const buildTableColumns = ({ setPreviewImagePath, setVisible }) => {
  return [
    {
      key: 'id',
      title: i18next.t('id'),
      dataIndex: 'id',
      sorter: (a, b) => sorter(a.id, b.id),
      render: (text, record) => {
        return <p>{record.id}</p>;
      },
    },
    {
      key: 'id',
      title: i18next.t('site'),
      dataIndex: 'siteId',
      sorter: (a, b) => sorter(a.siteId, b.baitId),
      render: (text, record) => {
        return (
          <p>
            {i18next.language === LANGUAGE_CODES.ENGLISH
              ? record.siteName
              : record.siteNameZh}
          </p>
        );
      },
    },
    {
      key: 'id',
      title: i18next.t('floor'),
      dataIndex: 'floorId',
      sorter: (a, b) => sorter(a.floorId, b.floorId),
      render: (text, record) => {
        return (
          <p>
            {i18next.language === LANGUAGE_CODES.ENGLISH
              ? record.floorName
              : record.floorNameZh}
          </p>
        );
      },
    },
    {
      key: 'id',
      title: i18next.t('layer'),
      dataIndex: 'layerId',
      sorter: (a, b) => sorter(a.layerId, b.layerId),
      render: (text, record) => {
        return (
          <p>
            {i18next.language === LANGUAGE_CODES.ENGLISH
              ? record.layerName
              : record.layerNameZh}
          </p>
        );
      },
    },
    {
      key: 'id',
      title: i18next.t('date'),
      dataIndex: 'createTime',
      sorter: (a, b) => sorter(a.createTime, b.createTime),
      render: (text, record) => {
        return <p>{formatDate(record.createTime)}</p>;
      },
    },
    {
      title: i18next.t('action'),
      key: 'action',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <Tooltip title='move to edit'>
              <Link to={`${record?.id}/edit`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

export const ListLocationChange = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search: savedSearch } = getSessionStorageItem(
    FORM_STORAGE_KEYS.BAIT_LIST
  );
  const [search, setSearch] = useState(savedSearch || '');
  const [visible, setVisible] = useState(false);
  const [isBatchModal, setIsBatchModal] = useState(false);
  const [eventType, setEventType] = useState('');
  const [previewImagePath, setPreviewImagePath] = useState(null);
  const {
    data: { batch, totalCount } = {},
    isLoading,
    error,
  } = useFetchBatch({});

  const columns = useMemo(() => {
    return buildTableColumns({
      setPreviewImagePath,
      setVisible,
    });
    // eslint-disable-next-line
  }, [t, setPreviewImagePath, setVisible]);

  const filteredData = useMemo(() => {
    return searchTableFields(search, columns, batch);
  }, [search, batch, columns]);

  const onChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);
  useEffect(() => {
    return () => {
      setSessionStorageItem(FORM_STORAGE_KEYS.BAIT_LIST, { search });
    };
  }, [search]);
  if (error) {
    return (
      <div className='p-10'>
        <PageHeader title={t('batchManagement')} />
        <PageError description='Error is found' />
      </div>
    );
  }

  const gotoCreatePage = () => {
    if (!eventType) {
      notification.error({ message: t('pleaseselecteventtype') });
      return;
    }

    if (eventType === EVENT_TYPES.BAIT_REPLACE) {
      navigate('/batch-management/add', {
        state: { eventType: eventType, isFromEvent: false },
      });
    } else if (eventType === EVENT_TYPES.FILL_POISON_BAIT) {
      navigate('/batch-management/add', {
        state: { eventType: eventType, isFromEvent: false },
      });
    }
  };

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('batchManagement'),
            link: '/batch-management',
          },
        ]}
      />
      <PageHeader
        onChange={onChange}
        title={t('batchManagement')}
        isSearch
        isCreateBatch
        search={search}
        showBatchModel={() => setIsBatchModal(true)}
      />
      <CustomTable
        rowKey='id'
        columns={columns}
        dataSource={filteredData}
        isLoading={isLoading}
        isShowCount
        totalCount={totalCount}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={previewImagePath}
        onError={() => null}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
            setPreviewImagePath(null);
          },
        }}
      />
      <Modal
        title={t('selectType')}
        visible={isBatchModal}
        onCancel={() => setIsBatchModal(false)}
        footer={[
          <Button type={'primary'} onClick={gotoCreatePage}>
            {t('create')}
          </Button>,
          <Button onClick={() => setIsBatchModal(false)}>{t('cancel')}</Button>,
        ]}
      >
        <div>
          <Select
            value={eventType}
            onChange={(e) => setEventType(e)}
            style={{ width: '100%' }}
            placeholder={t('selectType')}
          >
            {Manual_BATCH_OPTIONS.map((e) => (
              <Option value={e.value} key={e.key}>
                {t(e.label)}
              </Option>
            ))}
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default ListLocationChange;
