import './index.css';
import React from 'react';
import { Col, notification, Row, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDisposeEventBatteryReplace } from '../../../../../services/eventBatteryReplace';
import { formatDate } from '../../../../../utilities/time-utils';
import {
  LANGUAGE_CODES,
  SENSOR_TYPES,
} from '../../../../../utilities/constants';
import { EditBatteryReplaceSensorsForm } from './edit-battery-replace-sensors-form';
import { Formik } from 'formik';

const buildInitialValues = (dataSource, notificationData) => {
  const { eventBatteryChangeItemList, remark } = dataSource;
  const sensors = eventBatteryChangeItemList?.map((sensor) => ({
    label: sensor?.sensorName,
    value: sensor?.id,
  }));
  return {
    sensors: sensors,
    checkedList: sensors?.map((sensor) => sensor.value),
    remark: remark,
    notificationId: notificationData.id,
    eventBatteryChange: dataSource,
  };
};

export const BatteryReplace = ({ dataSource = [], isLoading = false }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === LANGUAGE_CODES.ENGLISH;
  const { mutate: disposeEventBatteryReplace, status: disposeStatus } =
    useDisposeEventBatteryReplace();
  const isDataExist = !isEmpty(dataSource);
  const { notificationData, eventBatteryChange, sensors } = dataSource || {};
  const initialValues = buildInitialValues(
    eventBatteryChange,
    notificationData
  );
  return (
    <Spin spinning={!isDataExist && isLoading}>
      <Row>
        <Col span={24} className='text_lable'>
          <span style={{ marginRight: '10px' }}>{t('batteryReplacement')}</span>
          {notificationData && formatDate(notificationData?.eventStart)}
        </Col>
        <Col span={8} className='text_lable'>
          {t('location')}
        </Col>
        <Col span={16} className='text_content'>
          {isEnglish
            ? eventBatteryChange?.floorName
            : eventBatteryChange?.floorNameZh}
        </Col>
        <Col span={8} className='text_lable'>
          {t('sensorType')}
        </Col>
        <Col span={16} className='text_content'>
          {t(
            SENSOR_TYPES.find(
              (sensor) => sensor.value === eventBatteryChange?.sensorType
            ).label
          )}
        </Col>
        <Col span={8} className='text_lable'>
          {t('total')}
        </Col>
        <Col span={16} className='text_content'>
          {sensors?.length}
        </Col>
        <Col span={24} className='text_content'>
          <Formik
            initialValues={initialValues}
            onSubmit={async (formValues, actions) => {
              try {
                await disposeEventBatteryReplace(formValues);
                notification.success({ message: t('editedSuccess') });
                window.location.reload();
              } catch ({ message }) {
                notification.error({ message });
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ submitCount, resetForm, handleSubmit }) => {
              return (
                <Spin spinning={disposeStatus === 'pending'}>
                  <EditBatteryReplaceSensorsForm
                    submitCount={submitCount}
                    resetForm={(e) => {
                      resetForm();
                    }}
                    handleSubmit={handleSubmit}
                  />
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Spin>
  );
};
