import moment from 'moment';
import { useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import chunk from 'lodash/chunk';
import sum from 'lodash/sum';
import isEmpty from 'lodash/isEmpty';
import './ms-report.css';
import { Loader } from '../../../components';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const BoxItem = ({ value, subtitle, style, valueStyle }) => {
  return (
    <div className='report-box' style={style}>
      <div style={valueStyle}>{value}</div>
      <div className='subtitle'>{subtitle || ''}</div>
    </div>
  );
};

const Row = ({ total, dates, byDateMap }) => {
  return (
    <div className='report-row'>
      <BoxItem value='' />
      {dates.map((item) => {
        const sensorInfo = byDateMap[item];
        const { dataIndex, isHoliday, subtitle } = sensorInfo ?? {};
        return (
          <BoxItem
            className='report-box'
            value={dataIndex ? `${dataIndex}` : ''}
            subtitle={subtitle}
            valueStyle={isHoliday ? { color: 'red' } : {}}
          />
        );
      })}
      <BoxItem value={total} />
    </div>
  );
};

const HEADER_DATA = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'total'];
const Header = () => {
  const { t } = useTranslation();
  return (
    <div className='report-row'>
      <BoxItem value='' />
      {HEADER_DATA.map((item) => (
        <BoxItem value={t(item)} />
      ))}
    </div>
  );
};

const Footer = ({ columnsTotalList, totalSum }) => {
  const { t } = useTranslation();
  return (
    <div className='report-row'>
      <BoxItem value={t('total')} />
      {columnsTotalList.map((item) => (
        <BoxItem value={item} />
      ))}
      <BoxItem value={totalSum} />
    </div>
  );
};

export const M2Report = ({
  selectedDate,
  fetchSensorDataLoading,
  sensorData = [],
}) => {
  const { byDateMap, datesListWithTotal, columnsTotalList, totalSum } =
    useMemo(() => {
      const sensorDataWithIndex = sensorData.map((item, index) => {
        const { lCount, mCount, sCount } = item || {};
        const updatedItem = {
          ...item,
          dataIndex: moment(item.date).format('D'),
        };
        const dayNumber = moment(item.date).day();
        if (dayNumber === 0 || dayNumber === 6) {
          updatedItem.isHoliday = true;
        }
        const subtitleList = [];
        if (sCount) {
          subtitleList.push(`S${sCount}`);
        }
        if (mCount) {
          subtitleList.push(`M${mCount}`);
        }
        if (lCount) {
          subtitleList.push(`L${lCount}`);
        }
        updatedItem.subtitle = subtitleList.join(' ');
        return updatedItem;
      });
      const byDateMap = keyBy(sensorDataWithIndex, 'date');
      const start = moment(selectedDate).startOf('month').startOf('week');
      const end = moment(selectedDate).endOf('month').endOf('week');
      const datesList = [];
      const diff = end.diff(start, 'days') + 1;
      for (let i = 0; i < diff; i++) {
        datesList.push(moment(start).add(i, 'days').format('YYYY-MM-DD'));
      }

      const chunksList = chunk(datesList, 7);
      const columnsTotalList = [];
      const datesListWithTotal = chunksList.map((item) => {
        const total = item.reduce((oldTotal, date, index) => {
          const { lCount = 0, sCount = 0, mCount = 0 } = byDateMap[date] ?? {};
          const total = lCount + mCount + sCount;
          columnsTotalList[index] = (columnsTotalList[index] ?? 0) + total;
          return oldTotal + total;
        }, 0);
        return { total, dates: item };
      });
      return {
        datesListWithTotal,
        byDateMap,
        columnsTotalList,
        totalSum: sum(columnsTotalList),
      };
    }, [selectedDate, sensorData]);

  if (fetchSensorDataLoading) {
    return <Loader />;
  }

  if (isEmpty(sensorData)) {
    return null;
  }

  return (
    <div className='report-grid'>
      <Typography.Title level={4}>
        {moment(selectedDate).format('MMM YYYY')}
      </Typography.Title>
      <Header />
      {datesListWithTotal.map((item, index) => (
        <Row
          key={index}
          {...item}
          byDateMap={byDateMap}
          isLast={index === datesListWithTotal.length - 1}
        />
      ))}
      <Footer columnsTotalList={columnsTotalList} totalSum={totalSum} />
    </div>
  );
};
