import React, { useEffect, useMemo, useState } from 'react';
import { CustomLineChart, Loader } from '../../../../components';
import { COLORS } from '../../../../utilities/colors';
import orderBy from 'lodash/orderBy';
import { formatDate } from '../../../../utilities/time-utils';

const formatChartData = (sensorData) => {
  return (
    orderBy(sensorData, 'eventTime', 'asc').map((item) => {
      return {
        name: formatDate(item.eventTime, 'MMM DD'),
        dataPoint: item.value,
      };
    }) || []
  );
};

export const SensorLineChart = ({ title, data, isLoading, filters }) => {
  const [reRenderLoading, setRerenderLoading] = useState(false);
  useEffect(() => {
    setRerenderLoading(true);
    setTimeout(() => {
      setRerenderLoading(false);
    }, 500);
  }, [filters.startTime]);

  const { sensorData = [] } = data || {};
  const formattedData = useMemo(
    () => formatChartData(sensorData),
    [sensorData]
  );
  if (isLoading || reRenderLoading) {
    return (
      <div
        style={{
          height: 250,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <CustomLineChart
        isTitle
        height={250}
        title={title}
        data={formattedData}
        titleStyles={{ color: COLORS.lightGreen }}
      />
    </>
  );
};
