import * as React from 'react';

export const BatterSvg = (props) => {
  return (
    <svg
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='#fff'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4.6875 9.00001H2.8125C2.502 9.00001 2.25 8.74801 2.25 8.43751V2.8125C2.25 2.502 2.502 2.25 2.8125 2.25H4.6875C4.998 2.25 5.25 2.502 5.25 2.8125V8.43751C5.25 8.74801 4.998 9.00001 4.6875 9.00001ZM3.375 7.87501H4.125V3.375H3.375V7.87501Z'></path>
      <path d='M9.1875 9.00001H7.3125C7.002 9.00001 6.75 8.74801 6.75 8.43751V2.8125C6.75 2.502 7.002 2.25 7.3125 2.25H9.1875C9.498 2.25 9.75 2.502 9.75 2.8125V8.43751C9.75 8.74801 9.498 9.00001 9.1875 9.00001ZM7.875 7.87501H8.625V3.375H7.875V7.87501Z'></path>
      <path d='M13.6875 9.00001H11.8125C11.502 9.00001 11.25 8.74801 11.25 8.43751V2.8125C11.25 2.502 11.502 2.25 11.8125 2.25H13.6875C13.998 2.25 14.25 2.502 14.25 2.8125V8.43751C14.25 8.74801 13.998 9.00001 13.6875 9.00001ZM12.375 7.87501H13.125V3.375H12.375V7.87501Z'></path>
      <path d='M14.4375 11.25H2.0625C0.92475 11.25 0 10.3253 0 9.18751V2.0625C0 0.924751 0.92475 0 2.0625 0H14.4375C15.5752 0 16.5 0.924751 16.5 2.0625V2.25H16.6807C17.4045 2.25 17.9932 2.83875 17.9932 3.5625V7.68751C17.9932 8.41126 17.4045 9.00001 16.6807 9.00001H16.5V9.18751C16.5 10.3253 15.5752 11.25 14.4375 11.25ZM2.0625 1.125C1.54575 1.125 1.125 1.54575 1.125 2.0625V9.18751C1.125 9.70426 1.54575 10.125 2.0625 10.125H14.4375C14.9543 10.125 15.375 9.70426 15.375 9.18751V8.43751C15.375 8.12701 15.627 7.87501 15.9375 7.87501H16.6807C16.7843 7.87501 16.8682 7.79101 16.8682 7.68751V3.5625C16.8682 3.459 16.7843 3.375 16.6807 3.375H15.9375C15.627 3.375 15.375 3.123 15.375 2.8125V2.0625C15.375 1.54575 14.9543 1.125 14.4375 1.125H2.0625Z'></path>
    </svg>
  );
};

export default BatterSvg;
