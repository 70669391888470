import React, { useEffect, useMemo, useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  notification,
  Row,
  Spin,
} from 'antd';
import { useListByLocation } from '../../services/sensors';
import { AntSelect } from '../custom-fields';
import { useForceUpdate, useZoomFloorPlan } from '../../hooks';
import { useSyncFormState } from '../../hooks/use-sync-form-state';
import {
  getFloorLayerAndZoneFromSite,
  getFormattedSites,
} from '../../utilities/transform';
import { useFetchBaits } from '../../services/baits';
import { useTranslation } from 'react-i18next';
import { useFetchSites } from '../../services/sites';
import {
  useAddEventTrapClean,
  useDeleteEventTrapClean,
  useEditEventTrapClean,
} from '../../services/eventTrapClean';
import { BAIT_TYPES, SENSOR_TYPE_KEYS } from '../../utilities/constants';
import xor from 'lodash/xor';
import { useActiveSiteProvider } from '../../context/site';
import { isEmpty } from 'lodash';

const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return <Col>{children}</Col>;
};

export const TrapCleanForm = ({
  handleSubmit2,
  values2,
  defaultLocation,
  submitCount2,
  baitTypes,
  isFromEvent,
}) => {
  const navigate = useNavigate();
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t, i18n } = useTranslation();
  const { activeSite: activeSiteId } = useActiveSiteProvider();
  const { values, handleReset, setFieldValue } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const [types, setTypes] = useState([SENSOR_TYPE_KEYS.TRAP_SENSOR]);
  const { data: { baits, totalCount } = {} } = useFetchBaits({
    types: baitTypes,
  });
  // if (isEmpty(baits) ) {
  //   return <Loader />;
  // }
  baits &&
    baits.forEach((n) => {
      n.key = n.id;
      n.value = n.id;
      n.label = n.name;
      n.type = n.type;
    });
  const { submitCount, validateForm, setFieldTouched, setTouched } =
    useFormikContext();
  const [activeSite, setActiveSite] = useState({});
  const [activeLocation, setActiveLocation] = useState({});
  const [sensors, setSensors] = useState([]);
  const [dateRange, setDateRange] = useState([
    values.eventStart,
    values.eventEnd,
  ]);
  const { activeScaleInfo } = useZoomFloorPlan();
  const [checkedList, setCheckedList] = useState([]);
  const [checkedBatchIdList, setCcheckedBatchIdList] = useState([]);
  const [checkedListErrorMessage, setCheckedListErrorMessage] = useState('');

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < sensors.length);
    setCheckAll(list.length === sensors.length);
  };
  const onCheckAllChange = (e) => {
    const tempMap = sensors.map((item) => item.id);
    setCheckedList(e.target.checked ? tempMap : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const initCheckChange = (eventTrapCleanItemList) => {
    const tempMap = eventTrapCleanItemList.map((item) => item.sensorId);
    setCheckedList(tempMap ? tempMap : []);
  };
  const { data: { sites } = {}, isLoading: fetchSiteLoading } = useFetchSites();

  const { mutate: editEventTrapClean, status: editStatus } =
    useEditEventTrapClean();
  const { mutate: addEventTrapClean, status: addStatus } =
    useAddEventTrapClean();
  const { mutate: deleteEventTrapClean, status: deleteStatus } =
    useDeleteEventTrapClean();
  const { mutate: listByLocation, status: listStatus } = useListByLocation();

  useEffect(() => {
    locationReset();
  }, [sites, i18n.language]);
  const locationReset = async () => {
    if (sites && sites.length > 0) {
      if (defaultLocation && activeSiteId.value) {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        activeSite1.id = selectedSite?.id;
        setActiveSite(activeSite1);
        // handleSiteChange(22);
        // await setFieldValue('siteId', values.siteId);
      } else {
        // var activeSite1 = getFloorLayerAndZoneFromSite(sites[0], sites[0].id);
        // activeSite1.id = sites[0].id;
        // setActiveSite(activeSite1);
        // handleSiteChange(activeSite1.id);
      }
    }
    //
    if (defaultLocation && activeSiteId.value) {
      await setFieldValue('siteId', activeSiteId.value);
      await setFieldValue('layerId', defaultLocation.layerId);
      await setFieldValue('floorId', defaultLocation.floorId);
      await setFieldValue('zoneId', defaultLocation.zoneId);
      await setFieldValue('baitId', defaultLocation.baitId);
      setActiveLocation({
        ...activeLocation,
        siteId: activeSiteId.value,
        layerId: defaultLocation.layerId,
        floorId: defaultLocation.floorId,
        zoneId: defaultLocation.zoneId,
      });
      if (defaultLocation.eventTrapCleanItemList) {
        initCheckChange(defaultLocation.eventTrapCleanItemList);
      }
    } else {
      // handleSiteChange(activeSite.id);
      await setFieldValue('siteId', null);
      await setFieldValue('layerId', null);
      await setFieldValue('floorId', null);
      await setFieldValue('zoneId', []);
      setActiveLocation({
        // ...activeLocation,
        siteId: null,
        layerId: null,
        floorId: null,
        zoneId: [],
      });
    }
    forceUpdate();
    //
  };

  useEffect(async () => {
    if (activeSite && activeSite.layers && activeSite.layers.length > 0) {
      await setFieldValue('layerId', activeSite.layers[0].value);
    } else {
      await setFieldValue('layerId', []);
    }

    if (activeSite.floors && activeSite.floors.length > 0) {
      await setFieldValue('floorId', activeSite.floors[0].value);
    } else {
      await setFieldValue('floorId', '');
    }

    if (activeSite.zones && activeSite.zones.length > 0) {
      await setFieldValue('zoneId', [activeSite.zones[0].value]);
    } else {
      await setFieldValue('zoneId', []);
    }
    await setFieldValue('siteIds', activeSite.id);

    // forceUpdate();
  }, [activeSite]);

  useEffect(async () => {
    // if (values.siteId) {
    //   await setFieldValue('siteId', values.siteId);
    //   await setFieldValue('layerId', values.layerId);
    //   await setFieldValue('floorId', values.floorId);
    //   await setFieldValue('zoneId', values.zoneId);
    //   await setFieldValue('baitId', values.baitId);
    // } else {
    //   handleSiteChange(activeSite.id);
    // }
    // setActiveLocation({
    //   ...activeLocation,
    //   siteId: values.siteId,
    //   layerId: values.layerId,
    //   floorId: values.floorId,
    //   zoneId: values.zoneId,
    // });
    // if (values.eventTrapCleanItemList) {
    //   initCheckChange(values.eventTrapCleanItemList);
    // }
    // if(baitTypes.length>0){
    //   var sTypes = [];
    //   if(baitTypes.includes(BAIT_TYPES.NORMAL)){
    //     sTypes.push(SENSOR_TYPE_KEYS.TRAP_SENSOR);
    //   }
    //   if(baitTypes.includes(BAIT_TYPES.POISON)){
    //     sTypes.push(SENSOR_TYPE_KEYS.BAIT_SENSOR);
    //   }
    //   setTypes(sTypes);
    // }else{
    //   setTypes([SENSOR_TYPE_KEYS.TRAP_SENSOR, SENSOR_TYPE_KEYS.BAIT_SENSOR]);
    // }
    // forceUpdate();
  }, []);

  const getBaitIdBySensorId = (id) => {
    if (!values.eventTrapCleanItemList) {
      return baits[0].id;
    }
    const item = values.eventTrapCleanItemList.find((n) => n.sensorId === id);
    if (null != item) {
      return item.baitId;
    } else {
      return baits[0].id;
    }
  };

  useEffect(async () => {
    if (null == activeLocation.layerId && null == activeLocation.floorId) {
      return;
    }
    const { data: { data: { sensors: sensorsData } } = {} } =
      await listByLocation({ ...activeLocation, types: types });
    sensorsData &&
      sensorsData.forEach((n) => {
        n.label = n.name + '---' + n.id;
        n.value = n.id;
      });
    setSensors(sensorsData);
  }, [activeLocation]);

  const siteOptions = useMemo(() => getFormattedSites(sites), [sites]);

  const handleSiteChange = async (siteId) => {
    const selectedSite = sites.find((site) => site.id === siteId);
    var activeSite1 = getFloorLayerAndZoneFromSite(selectedSite, siteId);
    activeSite1.id = siteId;
    const locationDate = activeLocation;
    locationDate.siteId = siteId;
    if (activeSite1.layers.length > 0) {
      await setFieldValue('layerId', activeSite1.layers[0].value);
      locationDate.layerId = activeSite1.layers[0].value;
    } else {
      await setFieldValue('layerId', []);
      locationDate.layerId = null;
    }

    if (activeSite1.floors.length > 0) {
      await setFieldValue('floorId', activeSite1.floors[0].value);
      locationDate.floorId = activeSite1.floors[0].value;
    } else {
      await setFieldValue('floorId', '');
      locationDate.floorId = null;
    }

    if (activeSite1.zones.length > 0) {
      await setFieldValue('zoneId', activeSite1.zones[0].value);
      locationDate.zoneId = activeSite1.zones[0].value;
    } else {
      await setFieldValue('zoneId', []);
      locationDate.zoneId = null;
    }
    setSensors([]);
    await setFieldValue('siteId', siteId);
    // setActiveLocation({...activeLocation,siteId:siteId})
    setActiveLocation({ ...locationDate });
    setActiveSite(activeSite1);
  };
  useEffect(() => {
    if (checkedList.length > 0) {
      setCheckedListErrorMessage('');
    }
  }, [checkedList]);

  const isValidating = false;

  const onChangeBaitId = async (e) => {
    values.baitId = e;
    if (activeLocation.siteId) {
      const bait = baits.filter((b) => b.id === e);
      if (bait.length > 0) {
        var btypes =
          bait[0].type === BAIT_TYPES.NORMAL
            ? [SENSOR_TYPE_KEYS.TRAP_SENSOR]
            : [SENSOR_TYPE_KEYS.BAIT_SENSOR];
        setTypes(btypes);
        const { data: { data: { sensors: sensorsData } } = {} } =
          await listByLocation({ ...activeLocation, types: btypes });
        sensorsData &&
          sensorsData.forEach((n) => {
            n.label = n.name + '---' + n.id;
            n.value = n.id;
          });
        setSensors(sensorsData);
      }
    }
  };
  useSyncFormState({ callback: forceUpdate });

  const handleSubmit = async () => {
    try {
      values.checkedList = checkedList;

      const errors = await validateForm();
      if (!isEmpty(errors)) {
        setTouched({
          siteIds: true,
          baitId: true,
          floorId: true,
          layerId: true,
          zoneId: true,
        });
      }
      if (checkedList.length === 0) {
        // message.error(t('pleaseSelectAtleastoneSensor'));
        setCheckedListErrorMessage('pleaseSelectAtleastoneSensor');
        return;
      }
      if (null != values.id) {
        const editData = {
          ...activeLocation,
          startTime: dateRange[0].startOf('day').valueOf(),
          endTime: dateRange[1].endOf('day').valueOf(),
          zoneId: JSON.stringify(activeLocation.zoneId),
          id: values.id,
          checkedList: checkedList,
          sensorList: sensors.map((item) => item.id),
          baitId: values.baitId,
          sensors: sensors,
        };
        await editEventTrapClean(editData);
        notification.success({ message: t('editedSuccess') });
      } else {
        const addData = {
          ...values,
          startTime: dateRange[0].startOf('day').valueOf(),
          endTime: dateRange[1].endOf('day').valueOf(),
          // sensors: sensors,
        };
        await addEventTrapClean(addData);
        notification.success({ message: t('createdSuccess') });
      }
      navigate(isFromEvent ? '/events' : '/batch-management');
    } catch ({ message }) {
      notification.error({ message });
    } finally {
      // actions.setSubmitting(false);
    }
    forceUpdate();
  };

  const handleDeleteEventTrapClean = async () => {
    try {
      await deleteEventTrapClean({ eventTrapCleanId: values.id });
      notification.success({ message: t('deletedSuccess') });
      navigate(isFromEvent ? '/events' : '/batch-management');
    } catch (message) {
      notification.error({ message });
    }
  };

  const handleReset1 = async () => {
    await handleReset();
    locationReset();
    forceUpdate();
  };
  const onChangeCheckedBaitId = (e, id) => {
    let sensorsTemp = sensors;
    sensorsTemp.map((item) => {
      if (id == item.id) {
        item.baitId = e;
      }
      return item;
    });
    let temp = JSON.parse(JSON.stringify(sensorsTemp));
    setSensors(temp);
  };
  const sensorsOption = () => {
    return sensors.map((n) => (
      <Row>
        <Col span={24}>
          <Checkbox value={n.id} key={n.id}>
            {n.name}
          </Checkbox>
        </Col>
      </Row>
    ));
  };
  const onChangeCheckedZoneId = (e) => {
    onChangeZoneId(e);
    setActiveLocation({
      ...activeLocation,
      zoneId: e,
    });
  };
  const onChangeZoneId = async (e) => {
    // activeSite.zones
    if (e.includes('all')) {
      const options = activeSite.zones
        .map((item) => item.value)
        .filter((item) => !values.zoneId.includes(item));
      await setFieldValue(
        'zoneId',
        xor(
          values.zoneId,
          options.length === 0
            ? activeSite.zones.map((item) => item.value)
            : options
        )
      );
      setActiveLocation({
        ...activeLocation,
        zoneId: activeSite.zones.map((item) => item.value),
      });
    } else {
      await setFieldValue('zoneId', e);
      setActiveLocation({
        ...activeLocation,
        zoneId: e,
      });
    }
  };
  return (
    <Spin spinning={isValidating}>
      <Form>
        <div style={{ marginTop: 10 }} />
        <Row gutter={[10, 10]} align='middle'>
          <>
            <ItemWrapper>
              <Field
                component={AntSelect}
                name='siteId'
                label={t('site')}
                placeholder={t('pleaseSelectSite')}
                submitCount={submitCount}
                selectOptions={siteOptions}
                disabled
                required
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntSelect}
                name='floorId'
                label={t('floor')}
                placeholder={t('pleaseSelectFloor')}
                submitCount={submitCount}
                selectOptions={activeSite.floors}
                onSelect={(label, option) =>
                  setActiveLocation({
                    ...activeLocation,
                    floorId: option.value,
                  })
                }
                required
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntSelect}
                name='layerId'
                label={t('layer')}
                placeholder={t('pleaseSelectLayer')}
                submitCount={submitCount}
                selectOptions={activeSite.layers}
                onSelect={(label, option) =>
                  setActiveLocation({
                    ...activeLocation,
                    layerId: option.value,
                  })
                }
                required
              />
            </ItemWrapper>

            <ItemWrapper>
              <Field
                component={AntSelect}
                name='zoneId'
                label={t('zone')}
                placeholder={t('pleaseSelectZone')}
                mode={'multiple'}
                submitCount={submitCount}
                selectOptions={activeSite.zones}
                onChange={(e) => onChangeCheckedZoneId(e)}
                required
              />
            </ItemWrapper>
            <ItemWrapper>
              <span style={{ fontWeight: 'bold' }}>{t('date')}</span>
              <RangePicker
                style={{ width: '100%' }}
                value={dateRange}
                onChange={(val) => setDateRange(val)}
                allowClear={false}
              />
            </ItemWrapper>
            <ItemWrapper></ItemWrapper>
            <ItemWrapper>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                style={{ width: '100%' }}
              >
                {t('checkall')}
              </Checkbox>
              <Divider />
              <CheckboxGroup
                style={{ width: '100%' }}
                value={checkedList}
                onChange={onChange}
              >
                {sensors && sensorsOption()}
              </CheckboxGroup>
              <div role='alert' className='ant-form-item-explain-error'>
                {checkedListErrorMessage && t(checkedListErrorMessage)}
              </div>
            </ItemWrapper>
            <ItemWrapper></ItemWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} type='secondary'>
                <Link to={isFromEvent ? '/events' : '/batch-management'}>
                  {t('back')}
                </Link>
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
                {t('reset')}
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                block
                type='primary'
                style={{ marginTop: 22 }}
                onClick={handleSubmit}
              >
                {t('save')}
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                block
                type='primary'
                style={{ marginTop: 22 }}
                onClick={handleDeleteEventTrapClean}
                danger
              >
                {t('delete')}
              </Button>
            </ActionButtonWrapper>
          </>
          <Col
            xs={{ span: 24 }}
            md={{ span: 4 }}
            sm={{ span: 24 }}
            style={{ marginTop: 20 }}
          ></Col>
        </Row>
        <Row></Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }}></Col>
        </Row>
      </Form>
    </Spin>
  );
};
