import './index.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';
import { Formik } from 'formik';
import { Breadcrumbs, InputDataForm, PageHeader } from '../../../components';
import { DataInputSchema } from '../../../utilities/schemas';

const { Title } = Typography;
export const InputData = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('deviceList'),
            link: '/trap-sensor/list',
          },
          {
            name: t('dataInput'),
            link: `/trap-sensor/input-data`,
          },
        ]}
      />
      <PageHeader title={t('dataInput')} />
      <Row>
        <Col span={24}>
          <Formik
            initialValues={{}}
            validationSchema={DataInputSchema}
            onSubmit={() => {}}
            render={InputDataForm}
          />
        </Col>
      </Row>
    </div>
  );
};
export default InputData;
