import './floor-plan.css';
import { Row, Spin, Typography } from 'antd';
import {
  DateFilterForm,
  Heatmap,
  SiteFloorLayerZoneSelectionForm,
} from '../../components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import { fetchSensorAndSite, fetchSensorHeatmap } from './service';
import {
  getImageDimensions,
  getScaledSensorFloorPlanInfo,
} from '../../utilities/image-utils';
import { sensorScaleDownTransform } from './transform';
import {
  getFloorImageUrl,
  getFloorLayerAndZoneFromSite,
} from '../../utilities/transform';
import {
  useForceUpdate,
  useFormStateBackup,
  useZoomFloorPlan,
} from '../../hooks';
import moment from 'moment';
import { useActiveSiteProvider } from '../../context/site';
import { useFetchSites } from '../../services/sites';
import { isEmpty } from 'lodash';

export const FloorPlanForm = ({ routeKey, formKey }) => {
  const { t } = useTranslation();
  const { forceUpdate } = useForceUpdate();
  const [floorPlanMeta, setFloorPlanMeta] = useState({});
  const [heatmapData, setHeatmapData] = useState({});
  const { activeScaleInfo } = useZoomFloorPlan();
  const {
    resetForm,
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    validateForm,
    setTouched,
  } = useFormikContext();
  const { data: { sites, defaultLocation } = {} } = useFetchSites();
  const { activeSite: activeSiteId } = useActiveSiteProvider();

  useFormStateBackup({
    formKey,
    callback: forceUpdate,
    isMultiLayer: true,
    isMultiZone: true,
  });

  const { sensors = [] } = values || {};
  const {
    floorPlanImageUrl,
    scaledFloorPlanImageWidth,
    scaledFloorPlanImageHeight,
  } = floorPlanMeta || {};
  useEffect(() => {
    setFieldValue('sensors', []);
    setFloorPlanMeta({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.siteId]);

  useEffect(() => {
    if (defaultLocation) {
      locationReset();
    }
  }, [defaultLocation]);

  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value == defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('test', '');
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue('floorId', activeSite1.floors[0].value);
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }
      forceUpdate();
    }
  };
  const onSubmit = async () => {
    try {
      const { siteId, floorId, layerId, zoneId, startTime, endTime } = values;
      const errors = await validateForm();
      if (!isEmpty(errors)) {
        setTouched({
          layerId: true,
          zoneId: true,
          startTime: true,
          endTime: true,
        });
        return;
      }
      await setSubmitting(true);
      const params = {
        siteId,
        floorId,
        layerId,
        zoneId,
        startTime: moment(startTime).valueOf(),
        endTime: moment(endTime).valueOf(),
        routeKey,
      };
      const { floor, sensors } = await fetchSensorAndSite(params);
      const { detectionData, trapCaughtData, trapMissedData } =
        await fetchSensorHeatmap(params);
      setHeatmapData({ detectionData, trapCaughtData, trapMissedData });
      const floorPlanImageUrl = getFloorImageUrl(floor.floorPath);
      const { naturalWidth, naturalHeight } = await getImageDimensions(
        floorPlanImageUrl
      );
      const {
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      } = getScaledSensorFloorPlanInfo(
        naturalWidth,
        naturalHeight,
        activeScaleInfo.imageWidth
      );
      setFloorPlanMeta({
        naturalWidth,
        naturalHeight,
        floorPlanImageUrl,
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      });
      const formattedSensors = sensorScaleDownTransform(
        sensors,
        widthScale,
        heightScale,
        naturalWidth,
        naturalHeight
      );
      const updatedValues = {
        sensors: formattedSensors,
        siteId,
        floorId,
        layerId,
        zoneId,
        startTime,
        endTime,
      };
      await resetForm({ values: updatedValues });
    } catch ({ message }) {
    } finally {
      await setSubmitting(false);
    }
  };
  const handleReset = async () => {
    await resetForm({
      values: {
        siteId: activeSiteId.value,
        startTime: moment().subtract(7, 'd'),
        endTime: moment(),
      },
    });
    locationReset();
  };
  return (
    <Spin spinning={isSubmitting}>
      <Form>
        <Row gutter={[10, 10]} align='middle'>
          <SiteFloorLayerZoneSelectionForm
            isZone={true}
            isMultiLayer={true}
            isMultiZone={true}
          />
          <DateFilterForm
            handleSubmit={onSubmit}
            handleReset={handleReset}
            showTime={true}
            format='YYYY-MM-DD HH:mm'
            required={true}
          />
        </Row>
        {floorPlanImageUrl && (
          <Heatmap
            data={heatmapData}
            floorPlan={floorPlanImageUrl}
            floorPlanMeta={floorPlanMeta}
            scaledFloorPlanImageHeight={scaledFloorPlanImageHeight}
            scaledFloorPlanImageWidth={scaledFloorPlanImageWidth}
          />
        )}
        {!floorPlanImageUrl && sensors.length > 0 && (
          <Typography.Text>{t('noFloorPlanFound')}</Typography.Text>
        )}
      </Form>
    </Spin>
  );
};
