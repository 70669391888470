import moment from 'moment';
import * as _ from 'lodash';
import i18next from 'i18next';
import { sorter } from '../../../utilities/transform';
import { Typography } from 'antd';
import { SensorAttributeIcon } from '../../../components';
import { formatTime } from '../../../utilities/time-utils';
import React from 'react';

export const getGraphData = (sensorData) => {
  if (sensorData?.length > 0) {
    const transformed = sensorData.map(({ eventTime, ...rest }) => ({
      eventTime: moment(eventTime).format('YYYY-MM-DD'),
      ...rest,
    }));
    var grouped = _.groupBy(transformed, 'eventTime');
    let dataPoints = [];
    Object.keys(grouped).forEach((key) => {
      dataPoints.push({ name: key, dataPoint: grouped[key].length });
    });
    return dataPoints;
  } else return [];
};
export const buildTableColumns = () => {
  return [
    {
      title: i18next.t('deviceNameLowercase'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sorter(a.name, b.name),
    },
    {
      title: i18next.t('action'),
      key: 'type',
      sorter: (a, b) => sorter(a.type, b.type),
      render: (text, record) => {
        return (
          <Typography.Text className='d-flex'>
            <SensorAttributeIcon type={record?.type} />
            &nbsp;&nbsp;{i18next.t(record?.type)}
          </Typography.Text>
        );
      },
    },
    {
      title: i18next.t('status'),
      key: 'value',
      sorter: (a, b) => sorter(a.value, b.value),
      render: (text, record) => {
        return (
          <>
            {record?.value ? i18next.t('connected') : i18next.t('disconnected')}
          </>
        );
      },
    },
    {
      title: i18next.t('date'),
      dataIndex: 'eventTime',
      key: 'eventTime',
      render: (text, record) => {
        return <>{formatTime(record?.eventTime)}</>;
      },
      sorter: (a, b) => {
        return sorter(a.eventTime, b.eventTime);
      },
    },
    {
      title: i18next.t('testingOrProduction'),
      dataIndex: 'stage',
      key: 'stage',
      sorter: (a, b) => sorter(a.stage, b.stage),
    },
    {
      title: i18next.t('id'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => sorter(a.id, b.id),
    },
  ];
};
export const getCSVHeaders = () => {
  return [
    { label: 'Device Name', key: 'name' },
    { label: 'Action', key: 'type' },
    { label: 'Testing or Production', key: 'stage' },
    { label: 'Date', key: 'eventTime' },
    { label: 'ID', key: 'id' },
  ];
};
export const getCSVData = (sensorData) => {
  return sensorData.map(({ eventTime, ...rest }) => ({
    eventTime: formatTime(eventTime),
    ...rest,
  }));
};
