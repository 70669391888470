import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  CustomTable,
  PageError,
  PageHeader,
} from '../../../components';
import { buildTableColumns } from './transform';
import { useFetchUsers } from '../../../services/users';
import { searchTableFields } from '../../../utilities/transform';
import {
  FORM_STORAGE_KEYS,
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../../utilities/storage';

export const ListAccounts = () => {
  const { t } = useTranslation();
  const { search: savedSearch } = getSessionStorageItem(
    FORM_STORAGE_KEYS.ACCOUNT_LIST
  );
  const [search, setSearch] = useState(savedSearch || '');
  const { data: { users: data } = {}, isLoading, error } = useFetchUsers({});

  const columns = useMemo(() => {
    return buildTableColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const filteredData = useMemo(() => {
    return searchTableFields(search, columns, data);
  }, [search, data, columns]);

  const onChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);
  useEffect(() => {
    return () => {
      setSessionStorageItem(FORM_STORAGE_KEYS.ACCOUNT_LIST, { search });
    };
  }, [search]);
  if (error) {
    return (
      <div className='p-10'>
        <PageHeader title={t('accountManagement')} />
        <PageError description='Error is found' />
      </div>
    );
  }

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('accountManagement'),
            link: '/account-management',
          },
        ]}
      />
      <PageHeader
        onChange={onChange}
        title={t('accountManagement')}
        isSearch
        isCreate
        to='add'
        search={search}
      />
      <CustomTable
        rowKey='id'
        columns={columns}
        dataSource={filteredData}
        isLoading={isLoading}
        pagination={{
          locale: { items_per_page: `/ ${t('page')}` },
          showLessItems: true,
          showSizeChanger: true,
          showTotal: (total) => (
            <div className='table-pagination'>
              {t('total')}: {total}
            </div>
          ),
        }}
      />
    </div>
  );
};
export default ListAccounts;
