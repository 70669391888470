import {
  Button,
  Col,
  Empty,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EVENT_TYPES,
  EVENT_TYPES_OPTIONS,
  Manual_EVENT_TYPES_OPTIONS1,
} from '../../../utilities/constants';
import {
  ShowHideWrapper,
  SiteFloorLayerZoneSelectionForm,
} from '../../../components';
import { isEmpty } from 'lodash';
import { FORM_STORAGE_KEYS } from '../../../utilities/storage';
import { useFetchEvents } from '../../../services/notifications';
import { DayEvents } from './components';
import { useFormikContext } from 'formik';
import { useForceUpdate, useFormStateBackup } from '../../../hooks';
import { getFloorLayerAndZoneFromSite } from '../../../utilities/transform';
import { useFetchSites } from '../../../services/sites';
import { useActiveSiteProvider } from '../../../context/site';
import moment from 'moment';
import './index.css';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { useNavigate } from 'react-router-dom';
import { Option } from 'antd/lib/mentions';

export const EventFilterForm = ({
  isShowFilters,
  showEventModel,
  setShowEventModel,
  ROLEID,
}) => {
  let initialValues = {};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sensorType, setSensorType] = useState('');
  const [selectedEventType, setSelectedEventType] = useState('');
  const [eventType, setEventType] = useState(null);
  const [currentMonthDate, setCurrentMonthDate] = useState(moment());
  const { setFieldValue, values } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();

  const { data: { sites, defaultLocation } = {} } = useFetchSites();
  const { activeSite: activeSiteId } = useActiveSiteProvider();
  const { handleReset } = useFormStateBackup({
    formKey: FORM_STORAGE_KEYS.EVENT_LIST,
    callback: forceUpdate,
    initialValues,
  });
  useEffect(() => {
    if (defaultLocation) {
      locationReset();
    }
  }, [defaultLocation]);

  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value == defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('floorId', activeSite1.floors[0].value);
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }
      forceUpdate();
    }
  };

  const filters = useMemo(() => {
    const { siteId, floorId, layerId, zoneId } = values || {};
    const filters = {};

    if (sensorType !== '') {
      filters.type = sensorType;
    }

    filters.startTime = currentMonthDate.startOf('month').valueOf();
    filters.endTime = currentMonthDate.endOf('month').valueOf();

    if (siteId) {
      filters.siteId = siteId;
      filters.floorId = floorId;

      if (!isEmpty(layerId)) {
        filters.layerId = layerId;
      }
      if (!isEmpty(zoneId)) {
        filters.zoneId = zoneId;
      }
    }
    return filters;
  }, [forceUpdateKey, sensorType]);

  const { data, isValidating } = useFetchEvents(filters);

  const { events = [] } = data || {};

  const handleSubmit = async () => {
    if (!values.siteId) {
      return notification.error({
        message: t('siteIsRequired'),
      });
    }
    if (!values.floorId) {
      return notification.error({
        message: t('floorIsRequired'),
      });
    }
    forceUpdate();
  };

  const handleReset1 = async () => {
    await handleReset();
    locationReset();
    forceUpdate();
  };

  const onMonthChange = (d) => {
    setCurrentMonthDate(d);
    forceUpdate();
  };

  const gotoCreatePage = () => {
    if (!eventType) {
      notification.error({ message: t('pleaseselecteventtype') });
      return;
    }

    if (
      eventType === EVENT_TYPES.BAIT_REPLACE ||
      eventType === EVENT_TYPES.FILL_POISON_BAIT
    ) {
      navigate('/batch-management/add', {
        state: { eventType: eventType, isFromEvent: true },
      });
    } else if (
      eventType === EVENT_TYPES.SITE_VISIT ||
      eventType === EVENT_TYPES.MEETING
    ) {
      navigate('/events/add', { state: { eventType: eventType } });
    } else if (eventType === EVENT_TYPES.LOCATION_UPDATE) {
      navigate('/location-change-management/add', {
        state: {
          eventType: eventType,
          isFromEvent: true,
        },
      });
    } else if (eventType === EVENT_TYPES.TRAP_CLEAN) {
      navigate('/event-trap-clean-management/add', {
        state: {
          eventType: eventType,
          isFromEvent: true,
        },
      });
    } else if (eventType === EVENT_TYPES.BATTERY_REPLACE) {
      navigate('/events/battery-replacement/add', {
        state: {
          eventType: eventType,
          isFromEvent: true,
        },
      });
    }
  };

  const onChangeEventType = (e) => {
    setSelectedEventType(e);
  };

  return (
    <Spin spinning={isValidating}>
      <Form>
        <ShowHideWrapper isShow={isShowFilters}>
          <Row gutter={[10, 10]} align='middle'>
            <SiteFloorLayerZoneSelectionForm
              isZone={true}
              isMultiLayer={true}
              isMultiZone={true}
            />
            <Col xs={{ span: 24 }} md={{ span: 2 }}>
              <Button
                style={{ marginTop: 22 }}
                type='secondary'
                block
                onClick={handleReset1}
              >
                {t('reset')}
              </Button>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 2 }}>
              <Button
                style={{ marginTop: 22 }}
                type='primary'
                block
                onClick={handleSubmit}
              >
                {t('submit')}
              </Button>
            </Col>
          </Row>
        </ShowHideWrapper>
        <Row justify='center' style={{ marginTop: '30px' }}>
          <Col lg={20} xs={24} sm={24} md={20} style={{ marginTop: 10 }}>
            <Row justify='center'>
              <Col>
                <Button
                  type='primary'
                  icon={<LeftOutlined />}
                  onClick={() =>
                    onMonthChange(currentMonthDate.subtract(1, 'month'))
                  }
                />
              </Col>
              <Col>
                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 5,
                  }}
                >
                  <span className={'date'}>
                    {' '}
                    {currentMonthDate.format('MMM YYYY')}
                  </span>
                </div>
              </Col>
              <Col>
                <Button
                  type='primary'
                  icon={<RightOutlined />}
                  onClick={() =>
                    onMonthChange(currentMonthDate.add(1, 'month'))
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xs={24} sm={24} md={4} style={{ marginTop: 10 }}>
            <Select
              placeholder={t('pleaseSelectType')}
              style={{ float: 'right', width: '100%' }}
              value={selectedEventType}
              onChange={(e) => onChangeEventType(e)}
            >
              {EVENT_TYPES_OPTIONS.map((et) => (
                <Option key={et.key} value={et.value}>
                  {t(et.label)}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Form>
      <div className='event-cards-container'>
        {events?.map(({ day, eventList, eventNames }, index) => {
          if (
            selectedEventType === ''
              ? eventNames.length
              : eventNames.filter((e) => e === selectedEventType).length
          ) {
            return (
              <DayEvents
                day={day}
                eventList={
                  selectedEventType === ''
                    ? eventNames
                    : eventNames.filter((e) => e === selectedEventType)
                }
                events={eventList}
                ROLEID={ROLEID}
              />
            );
          }
        })}
      </div>
      {isEmpty(events) && <Empty description={<span>{t('noData')}</span>} />}
      <Modal
        title={t('selectEvent')}
        visible={showEventModel}
        onCancel={() => setShowEventModel(false)}
        footer={[
          <Button type={'primary'} onClick={gotoCreatePage}>
            {t('create')}
          </Button>,
          <Button onClick={() => setShowEventModel(false)}>
            {t('cancel')}
          </Button>,
        ]}
      >
        <div>
          <Select
            value={eventType}
            onChange={(e) => setEventType(e)}
            style={{ width: '100%' }}
            placeholder={t('pleaseselecteventtype')}
          >
            {Manual_EVENT_TYPES_OPTIONS1.map((e) => (
              <Option value={e.value} key={e.key}>
                {t(e.label)}
              </Option>
            ))}
          </Select>
        </div>
      </Modal>
    </Spin>
  );
};
