import React from 'react';
import './index.css';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

const getSections = () => {
  return [
    { label: i18next.t('siteManagement'), to: '/site-management' },
    {
      label: i18next.t('assignSensorLocation'),
      to: '/site-management/assign-trap-sensor-location',
    },
    { label: i18next.t('baitManagement'), to: '/device-management' },
    { label: i18next.t('trapManagement'), to: '/trap-management' },
    {
      label: i18next.t('roleManagement'),
      to: '/role-management',
    },
    {
      label: i18next.t('accountManagement'),
      to: '/account-management',
    },
    {
      label: i18next.t('profile'),
      to: '/profile',
    },
    {
      label: i18next.t('changePassword'),
      to: '/change-password',
    },
  ];
};

const Section = ({ title, dataList }) => {
  return (
    <Card title={title} bordered={true} className='mb-10'>
      <div className='setting-section-list'>
        {dataList.map(({ to, label }) => {
          return (
            <Link to={to} key={to}>
              <div className='setting-section-list-item'>
                <Button key={to} type='link'>
                  {label}
                </Button>
              </div>
            </Link>
          );
        })}
      </div>
    </Card>
  );
};

export const Settings = () => {
  const { t } = useTranslation();
  const sections = getSections();
  return (
    <div className='settings-page'>
      <Section title={t('siteManagement')} dataList={sections.slice(0, 2)} />
      <Section title={t('deviceManagement')} dataList={sections.slice(2, 4)} />
      <Section
        title={t('webPortalManagement')}
        dataList={sections.slice(4, 6)}
      />
      <Section title={t('userManagement')} dataList={sections.slice(6, 8)} />
    </div>
  );
};
export default Settings;
