import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { Breadcrumbs, TrapCleanForm } from '../../../components';

import { useFetchEventTrapCleanDetails } from '../../../services/eventTrapClean';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { getBaitImageUrl } from '../../../utilities/transform';
import { AddBatchSchema } from '../../../utilities/schemas';

const buildInitialValues = (eventTrapClean) => {
  const { name, expiryDate, path } = eventTrapClean || {};
  return {
    ...eventTrapClean,
    name,
    expiryDate: moment(expiryDate),
    imageData: null,
    path: getBaitImageUrl(path),
    eventStart: moment(),
    eventEnd: moment(),
  };
};

const { Title } = Typography;
export const AddEventTrapClean = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { isFromEvent = false } = state || { isFromEvent: false };

  const { data: { eventTrapClean, eventTrapCleanItemList } = {}, isLoading } =
    useFetchEventTrapCleanDetails({ eventTrapCleanId: 2 });

  const initialValues = buildInitialValues(eventTrapClean);

  const handleDeleteBait = async () => {
    try {
    } catch (e) {}
  };

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          !isFromEvent
            ? {
                name: t('trap_clean'),
                link: '/event-trap-clean-management',
              }
            : {
                name: t('event1'),
                link: '/events',
              },
          {
            name: t('add'),
            link: `/event-trap-clean-management/add`,
          },
        ]}
      />
      <Title level={3}>{t('trap_clean')}</Title>

      <Formik
        enableReinitialize={false}
        validationSchema={AddBatchSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            // await actions.resetForm({ values: updatedData });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin spinning={false}>
              <TrapCleanForm
                dirty={dirty}
                values={initialValues}
                defaultLocation={initialValues}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                handleDeleteBait={handleDeleteBait}
                isFromEvent={isFromEvent}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddEventTrapClean;
