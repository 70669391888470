import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { DeviceListFilterForm } from './device-list-filter-form';
import { PageHeader } from '../../../components';
import { SENSOR_PAGE_KEYS } from '../../../utilities/constants';
import { useNavigate } from 'react-router-dom';
import { useToggleFilters } from '../../../hooks';
import { useFetchSites } from '../../../services/sites';

// const INITIAL_VALUES = {
//   pageNumber: 1,
//   pageSize: 10,
//   siteId: null,
//   floorId: null,
//   layerId: null,
//   zoneId: null,
//   isProduction: null,
//   status: null,
//   name: '',
// };
export const SensorDeviceListPage = ({ routeKey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isShowFilters, toggleFilters } = useToggleFilters();
  const [showFloorPlan, setShowFloorPlan] = useState(false);

  const { data: { sites, defaultLocation } = {}, isLoading: fetchSiteLoading } =
    useFetchSites();

  const handleSensorType = (routeKey) => {
    navigate(
      routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
        ? '/trap-sensor/list'
        : routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR
        ? '/detection-sensor/list'
        : routeKey === SENSOR_PAGE_KEYS.BAIT_SENSOR
        ? '/bait-sensor/list'
        : ''
    );
  };
  if (sites && defaultLocation) {
    const selectedSite =
      sites && sites.find((site) => site.id === defaultLocation.siteId);
    defaultLocation.zoneId = selectedSite && selectedSite?.zones?.[0]?.id;
    // defaultLocation.zoneId=33;
  }

  const INITIAL_VALUES = {
    // pageNumber: 1,
    // pageSize: 10,
    // siteId: null,
    // floorId: null,
    // layerId: null,
    // zoneId: 1,
    ...defaultLocation,
    isProduction: 1,
    status: 'A',
    name: '',
  };
  return (
    <div className='p-10'>
      <PageHeader
        to={`/${routeKey}/add`}
        title={t('deviceList')}
        isCreate
        sensorType={routeKey}
        handleSensorType={handleSensorType}
        isFilterDropDownAllowed
        isShowFilters={isShowFilters}
        toggleFilters={toggleFilters}
        isFloorPlanPin={true}
        setShowFloorPlan={setShowFloorPlan}
      />
      <Formik initialValues={INITIAL_VALUES} onSubmit={() => {}}>
        <DeviceListFilterForm
          routeKey={routeKey}
          initialValues={INITIAL_VALUES}
          isShowFilters={isShowFilters}
          showFloorPlan={showFloorPlan}
          setShowFloorPlan={setShowFloorPlan}
        />
      </Formik>
    </div>
  );
};

export default SensorDeviceListPage;
