import * as _ from 'lodash';
import React from 'react';
// Javascript program to check if a point
// lies inside a circle or not
export const isInside = (circle_x, circle_y, rad, x, y) => {
  // Compare radius of circle with
  // distance of its center from
  // given point

  return (
    (x - circle_x) * (x - circle_x) + (y - circle_y) * (y - circle_y) <=
    rad * rad
  );
};

export const checkEmptiness = (data) => {
  return _.every(data || [], { count: 0 });
};
const RADIAN = Math.PI / 180;
export const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (!percent) return;
  return (
    <text
      x={x}
      y={y}
      fill='black'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
