import './index.css';
import { Radio, Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ZoomFloorActionButtons = ({
  activeScaleInfo,
  optionsList,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const loadingHandler = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <div className='zoom-buttons-wrapper'>
      <Radio.Group
        optionType='button'
        buttonStyle='solid'
        value={activeScaleInfo.value}
      >
        <Radio.Button
          disabled={activeScaleInfo.index === 0 || isLoading}
          onChange={(event) => {
            loadingHandler();
            if (activeScaleInfo.index === 0) {
              return;
            }
            onChange(optionsList[activeScaleInfo.index - 1]);
          }}
        >
          -
        </Radio.Button>
        <Radio.Button
          disabled={
            activeScaleInfo.index === optionsList.length - 1 || isLoading
          }
          onChange={() => {
            loadingHandler();
            if (activeScaleInfo.index === optionsList.length - 1) {
              return;
            }
            onChange(optionsList[activeScaleInfo.index + 1]);
          }}
        >
          +
        </Radio.Button>
        <Radio.Button
          disabled={activeScaleInfo.index === 0 || isLoading}
          onChange={() => {
            loadingHandler();
            onChange(optionsList[0]);
          }}
        >
          {t('default')}
        </Radio.Button>
      </Radio.Group>
      <Spin size='small' spinning={isLoading} style={{ marginLeft: 4 }} />
    </div>
  );
};
