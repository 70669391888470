import { fetchSensorsAsync } from '../../../services/sensors';
import { ROUTE_KEY_TO_SENSOR_TYPE_MAP } from '../constants';
import { fetchSitesAsync } from '../../../services/sites';

export const fetchSensorAndSite = async ({
  siteId,
  floorId,
  layerId,
  zoneId,
  routeKey,
}) => {
  const { sensors } = await fetchSensorsAsync({
    siteId,
    floorId,
    layerId,
    zoneId,
    type: ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey],
    pageNumber: 1,
    count: 1000,
  });
  const { sites } = await fetchSitesAsync([siteId]);
  const floor = sites[0].floors.filter((floor) => floor.id === floorId).pop();

  return {
    sensors,
    floor,
  };
};
