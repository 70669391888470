import './index.css';
import React from 'react';
import { Image, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const CustomCard = ({
  isLeftIcon = false,
  iconConatainer,
  icon = null,
  iconStyle = null,
  title = '',
  value = '',
  preview = true,
  tooltip = null,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className='container' onClick={onClick}>
      <div className='card-parent'>
        {isLeftIcon && (
          <div
            className='card-body-left-container'
            style={{ ...iconConatainer }}
          >
            <Image
              width={40}
              height='auto'
              src={icon}
              style={iconStyle}
              preview={preview}
            />
          </div>
        )}
        <div className='card-body-right-container'>
          <div className='card-title'>
            <span>
              {t(title)}{' '}
              {tooltip && (
                <span style={{ marginLeft: '5px' }}>
                  <Tooltip title={tooltip}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              )}
            </span>
          </div>
          <div className='card-value'>{value}</div>
          {onClick && <div className='card-action'>{t('view')}</div>}
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
// <Statistic
//     style={{fontWeight: 500}}
//     title={<span>{t(title)} {tooltip && (
//         <span style={{marginLeft: '5px'}}>
//                 <Tooltip title={tooltip}>
//                   <QuestionCircleOutlined/>
//                 </Tooltip>
//               </span>
//     )}</span>
//     }
//     value={value}
// />
