import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { LANGUAGE_CODES } from '../../../utilities/constants';
import { useTranslation } from 'react-i18next';
import '../dashboard-body.css';
import { every, isEmpty } from 'lodash';
import { Typography } from 'antd';
import { checkEmptiness } from '../../../utilities/functions';

const checkCountByDate = (data) => {
  return (
    !isEmpty(data) &&
    !every(data || [], { '100%': 0, '75-50%': 0, '25%': 0, '0%': 0 })
  );
};
export const BaitDashboardBody = ({ dashboardData }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === LANGUAGE_CODES.ENGLISH;
  const {
    countByBaitLvl = [],
    countByLayers = [],
    countByZones = [],
    countByDate = [],
    baitLvl = [],
  } = dashboardData || {};

  const getRandomColorCode = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return '#' + randomColor;
  };
  return (
    <div>
      <div className='graphs-container1'>
        <div style={{ border: '1px solid #ccc', padding: '5px' }}>
          <p className='graph-title'>{t('allCountByBaitLvl')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {!checkEmptiness(countByBaitLvl) && !isEmpty(countByBaitLvl) ? (
              <BarChart
                data={countByBaitLvl}
                margin={{
                  top: 5,
                  right: 25,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey={'lvl'}
                  name={t('baitLvl')}
                  tick={{
                    width: 'fit-content',
                    height: 'auto',
                    wordWrap: 'break-word',
                  }}
                  interval={0}
                >
                  <Label
                    value={t('baitLvl')}
                    offset={-5}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
                <Bar dataKey={'count'} name={t('totalCount')} fill='#8884d8' />
              </BarChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
        <div style={{ border: '1px solid #ccc', padding: '5px' }}>
          <p className='graph-title'>{t('allLayerByDate')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {checkCountByDate(countByDate) ? (
              <LineChart
                data={countByDate}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 5,
                  right: 25,
                }}
              >
                {baitLvl.map((bl, i) => (
                  <Line
                    key={i}
                    type='monotone'
                    dataKey={bl}
                    name={bl}
                    stroke={getRandomColorCode()}
                  />
                ))}
                <CartesianGrid stroke='#ccc' />
                <XAxis dataKey='date'>
                  <Label
                    value={t('date')}
                    offset={-5}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis allowDecimals={false}>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
              </LineChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
        {countByLayers.map((gd, i) => {
          return (
            <div key={i} style={{ border: '1px solid #ccc', padding: '5px' }}>
              <p className='graph-title'>{isEnglish ? gd.name : gd.nameZh}</p>
              <ResponsiveContainer width='100%' height={300}>
                {!checkEmptiness(gd.data) && !isEmpty(gd.data) ? (
                  <BarChart
                    layout={'vertical'}
                    data={gd.data}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis type='number'>
                      <Label
                        value={t('totalCount')}
                        offset={-5}
                        position='insideBottom'
                      />
                    </XAxis>
                    <YAxis
                      dataKey='lvl'
                      name={t('baitLvl')}
                      type='category'
                      tick={{ padding: '10px', margin: '10px' }}
                    >
                      <Label
                        angle={-90}
                        value={t('baitLvl')}
                        offset={-12}
                        position='insideLeft'
                      />
                    </YAxis>
                    <Tooltip />
                    <Legend verticalAlign='top' />
                    <Bar
                      dataKey={'count'}
                      name={t('totalCount')}
                      barSize={20}
                      fill='#8884d8'
                    />
                  </BarChart>
                ) : (
                  <div style={{ width: '100%' }}>
                    <Typography.Text>{t('noData')}</Typography.Text>
                  </div>
                )}
              </ResponsiveContainer>
            </div>
          );
        })}
        {countByZones.map((gd, i) => (
          <div key={i} style={{ border: '1px solid #ccc', padding: '5px' }}>
            <p className='graph-title'>{isEnglish ? gd.name : gd.nameZh}</p>
            <ResponsiveContainer width='100%' height={250}>
              {gd.sensors.length > 0 && !isEmpty(gd.data) ? (
                <LineChart
                  data={gd.data}
                  margin={{
                    top: 5,
                    bottom: 5,
                    left: 5,
                    right: 25,
                  }}
                >
                  {gd.sensors.map((s, i) => (
                    <Line
                      key={i}
                      type='monotone'
                      dataKey={s.name}
                      name={s.name}
                      stroke={getRandomColorCode()}
                    />
                  ))}
                  <CartesianGrid stroke='#ccc' />
                  <XAxis dataKey='date'>
                    <Label
                      value={t('date')}
                      offset={-5}
                      position='insideBottom'
                    />
                  </XAxis>
                  <YAxis allowDecimals={false}>
                    <Label
                      angle={-90}
                      value={t('baitLvl')}
                      position='insideLeft'
                    />
                  </YAxis>
                  <Tooltip />
                </LineChart>
              ) : (
                <div style={{ width: '100%' }}>
                  <Typography.Text>{t('noData')}</Typography.Text>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    </div>
  );
};
