import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './files/english.json';
import chinese from './files/chinese.json';
import { LANGUAGE_CODES } from '../utilities/constants';

i18next.use(initReactI18next).init({
  resources: {
    [LANGUAGE_CODES.CHINESE]: { translation: chinese },
    [LANGUAGE_CODES.ENGLISH]: { translation: english },
  },
  lng: LANGUAGE_CODES.CHINESE,
  fallbackLng: LANGUAGE_CODES.CHINESE,
});
export default i18next;
