import './index.css';
import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import { DEFAULT_PAGE_SIZE } from '../../utilities/constants';

export const Pagination = ({
  currentPage,
  totalCount,
  pageSize = DEFAULT_PAGE_SIZE,
  onChange,
}) => {
  return (
    <div className='pagination'>
      <AntdPagination
        defaultCurrent={1}
        current={currentPage}
        total={totalCount}
        pageSize={pageSize || 0}
        onChange={onChange}
        showSizeChanger={false}
      />
    </div>
  );
};
export default Pagination;
