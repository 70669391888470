import React from 'react';
import { Select } from 'antd';

const { Option } = Select;
export const SelectField = ({
  value,
  placeholder,
  onChange,
  options,
  bordered = false,
  allowClear = false,
  className,
  ...props
}) => {
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      bordered={bordered}
      allowClear={allowClear}
      className={className}
      {...props}
    >
      {options.map((option, index) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectField;
