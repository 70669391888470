import './header-right.scss';
import React, { useContext, useMemo, useState } from 'react';
import { Menu } from 'antd';
import {
  LANGUAGE,
  LANGUAGE_CODES,
  LANGUAGES,
  STORAGE_KEYS,
  STYLE_SHEETS,
} from '../../../utilities/constants';
import { changeLanguage } from '../../../language/languageUtils';
import { FontContext, LanguageContext } from '../../../index';
import { setStorageItem } from '../../../utilities/storage';
import SelectSite from './select-site';
import { useFetchSites } from '../../../services/sites';
import { useNavigate } from 'react-router-dom';
import { useActiveSiteProvider } from '../../../context/site';
import { useTranslation } from 'react-i18next';

const CustomMenu = ({
  formattedSites,
  updateFontSize,
  highlightFontSize,
  updateLanguage,
  updateWebsite,
  selectedMenuItems,
  onSiteSelection,
}) => {
  return (
    <>
      <div className='desktop-header-right-container'>
        <Menu
          theme='light'
          mode='horizontal'
          selectedKeys={[
            selectedMenuItems?.fontSize,
            selectedMenuItems?.language,
          ]}
        >
          <Menu.Item key={'site'}>
            <SelectSite onSiteSelection={onSiteSelection} />
          </Menu.Item>
          <Menu.Item
            key={LANGUAGE_CODES.ENGLISH}
            onClick={({ key }) => updateLanguage(LANGUAGES[0]?.value, key)}
          >
            ENG
          </Menu.Item>
          <Menu.Item
            key={LANGUAGE_CODES.CHINESE}
            onClick={({ key }) => updateLanguage(LANGUAGES[1]?.value, key)}
          >
            中文
          </Menu.Item>
          <Menu.Item
            key={STYLE_SHEETS.SMALL}
            onClick={({ key }) => {
              updateFontSize(STYLE_SHEETS.SMALL);
              highlightFontSize(key);
            }}
          >
            <div style={{ display: 'inline' }}>
              <span className='font-small'>A</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key={STYLE_SHEETS.MEDIUM}
            onClick={({ key }) => {
              updateFontSize(STYLE_SHEETS.MEDIUM);
              highlightFontSize(key);
            }}
          >
            <div style={{ display: 'inline' }}>
              <span className='font-medium'>A</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key={STYLE_SHEETS.LARGE}
            onClick={({ key }) => {
              updateFontSize(STYLE_SHEETS.LARGE);
              highlightFontSize(key);
            }}
          >
            <div style={{ display: 'inline' }}>
              <span className='font-large'>A</span>
            </div>
          </Menu.Item>
        </Menu>
      </div>
      <div className='mobile-header-right-container'>
        <Menu
          theme='light'
          mode='horizontal'
          selectedKeys={[
            selectedMenuItems?.activeSite?.toString(),
            selectedMenuItems?.fontSize,
            selectedMenuItems?.language,
          ]}
        >
          {formattedSites?.map((option) => {
            return (
              <Menu.Item
                key={option.value}
                onClick={({ key }) => updateWebsite(key, option.value)}
              >
                {option.label}
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={LANGUAGE_CODES.ENGLISH}
            onClick={({ key }) => updateLanguage(LANGUAGES[0]?.value, key)}
          >
            ENG
          </Menu.Item>
          <Menu.Item
            key={LANGUAGE_CODES.CHINESE}
            onClick={({ key }) => updateLanguage(LANGUAGES[1]?.value, key)}
          >
            中文
          </Menu.Item>
          <Menu.Item
            key={STYLE_SHEETS.SMALL}
            onClick={({ key }) => {
              updateFontSize(STYLE_SHEETS.SMALL);
              highlightFontSize(key);
            }}
          >
            <div style={{ display: 'inline' }}>
              <span className='font-small'>A</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key={STYLE_SHEETS.MEDIUM}
            onClick={({ key }) => {
              updateFontSize(STYLE_SHEETS.MEDIUM);
              highlightFontSize(key);
            }}
          >
            <div style={{ display: 'inline' }}>
              <span className='font-medium'>A</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key={STYLE_SHEETS.LARGE}
            onClick={({ key }) => {
              updateFontSize(STYLE_SHEETS.LARGE);
              highlightFontSize(key);
            }}
          >
            <div style={{ display: 'inline' }}>
              <span className='font-large'>A</span>
            </div>
          </Menu.Item>
        </Menu>
      </div>
    </>
  );
};
export const HeaderRight = () => {
  const { i18n } = useTranslation();
  const { data } = useFetchSites();
  const { sites = [] } = data || {};
  const navigate = useNavigate();
  const { activeSite, setActiveSite } = useActiveSiteProvider();

  const formattedSites = useMemo(() => {
    return sites.map((site) => {
      const { id, name, nameZh } = site || {};
      return {
        value: id,
        label: i18n.language === LANGUAGE_CODES.ENGLISH ? name : nameZh,
      };
    });
  }, [sites, i18n.language]);
  const { fontSize, setFontSize } = useContext(FontContext);
  const { language, setLanguage } = useContext(LanguageContext);

  const [selectedMenuItems, setSelectedMenuItems] = useState({
    fontSize,
    language,
    activeSite: activeSite?.value,
  });

  const updateFontSize = (size) => {
    setStorageItem(STORAGE_KEYS.FONT_SIZE, size);
    setFontSize(size);
  };
  const updateLanguage = (value, key) => {
    changeLanguage(value);
    setStorageItem(LANGUAGE, value);
    setLanguage(value);
    setSelectedMenuItems((state) => ({ ...state, language: key }));
  };

  const highlightFontSize = (key) => {
    setSelectedMenuItems((state) => ({ ...state, fontSize: key }));
  };
  const updateWebsite = (key, value) => {
    sessionStorage.clear();
    setActiveSite({ value });
    setSelectedMenuItems((state) => ({ ...state, activeSite: key }));
    navigate('/');
  };
  const onSiteSelection = (key) => {
    setSelectedMenuItems((state) => ({ ...state, activeSite: key }));
  };
  if (!activeSite?.value) {
    return null;
  }

  return (
    <CustomMenu
      formattedSites={formattedSites}
      selectedMenuItems={{
        ...selectedMenuItems,
        activeSite: activeSite?.value,
      }}
      highlightFontSize={highlightFontSize}
      onSiteSelection={onSiteSelection}
      updateFontSize={updateFontSize}
      updateLanguage={updateLanguage}
      updateWebsite={updateWebsite}
    />
  );
};

export default HeaderRight;
