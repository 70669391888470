import { scaleLinear } from 'd3-scale';

export const SVG_SENSOR_ICON_ORIGINAL_WIDTH = 26;
export const SVG_SENSOR_ICON_ORIGINAL_HEIGHT = 43;
export const readBase64Image = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getBase64String = (base64) => {
  return base64.split(',').pop();
};

export const getImageDimensions = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      resolve({
        naturalWidth: this.naturalWidth,
        naturalHeight: this.naturalHeight,
      });
    };
    image.onerror = function () {
      reject();
    };
    image.src = url;
  });

export const getScaledSensorFloorPlanInfo = (
  fromImageWidth,
  fromImageHeight,
  toImageWidth
) => {
  const IMAGE_WIDTH = fromImageWidth;
  const IMAGE_HEIGHT = fromImageHeight;
  const ASPECT_RATIO = IMAGE_WIDTH / IMAGE_HEIGHT;

  const IMAGE_WIDTH_1 = toImageWidth;
  const IMAGE_HEIGHT_1 = IMAGE_WIDTH_1 / ASPECT_RATIO;
  const widthScale = scaleLinear()
    .domain([0, IMAGE_WIDTH])
    .range([0, IMAGE_WIDTH_1]);

  const heightScale = scaleLinear()
    .domain([0, IMAGE_HEIGHT])
    .range([0, IMAGE_HEIGHT_1]);

  const SCALED_ICON_WIDTH =
    (SVG_SENSOR_ICON_ORIGINAL_WIDTH * IMAGE_WIDTH_1) / IMAGE_WIDTH;
  const SCALED_ICON_HEIGHT =
    (SVG_SENSOR_ICON_ORIGINAL_HEIGHT * IMAGE_HEIGHT_1) / IMAGE_HEIGHT;

  return {
    widthScale,
    heightScale,
    scaledFloorPlanImageWidth: IMAGE_WIDTH_1,
    scaledFloorPlanImageHeight: IMAGE_HEIGHT_1,
    sensorIconWidth: SCALED_ICON_WIDTH,
    sensorIconHeight: SCALED_ICON_HEIGHT,
  };
};
