import { Col } from 'antd';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AntSelect } from '../custom-fields';
import { useActiveSiteFloorLayerZone } from '../../hooks';

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }}>
      {children}
    </Col>
  );
};

export const SiteFloorLayerZoneSelectionForm = ({
  ItemWrapper = DefaultItemWrapper,
  isZone = true,
  isMultiLayer = false,
  isMultiZone = false,
}) => {
  const { activeSite } = useActiveSiteFloorLayerZone();
  const { t } = useTranslation();
  const { submitCount } = useFormikContext();
  return (
    <>
      <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 2 }}>
        <Field
          component={AntSelect}
          name='floorId'
          label={t('floor')}
          placeholder={t('pleaseSelectFloor')}
          submitCount={submitCount}
          selectOptions={activeSite.floors}
          required
        />
      </Col>
      <ItemWrapper>
        <Field
          mode={isMultiLayer ? 'multiple' : ''}
          component={AntSelect}
          name='layerId'
          label={t('layer')}
          placeholder={t('pleaseSelectLayer')}
          submitCount={submitCount}
          selectOptions={activeSite.layers}
          required
        />
      </ItemWrapper>
      {isZone && (
        <ItemWrapper>
          <Field
            mode={isMultiZone ? 'multiple' : ''}
            component={AntSelect}
            name='zoneId'
            label={t('zone')}
            placeholder={t('pleaseSelectZone')}
            submitCount={submitCount}
            selectOptions={activeSite.zones}
            required
          />
        </ItemWrapper>
      )}
    </>
  );
};
