import React from 'react';
import { Field, Form } from 'formik';
import { AntInput, AntUpload } from '../index';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { GUTTER } from '../../utilities/constants';
import { Link } from 'react-router-dom';
import { readBase64Image } from '../../utilities/image-utils';

export const AddFloorPlan = ({
  handleSubmit,
  values,
  submitCount,
  resetForm,
  setFieldValue,
  siteId,
}) => {
  const { t } = useTranslation();
  const { floorPath, imageData } = values || {};
  const isEditMode = !!floorPath;
  const handleChange = ({ file } = {}) => {
    if (file.status === 'done') {
      readBase64Image(file.originFileObj, (imageUrl) => {
        setFieldValue('imageData', imageUrl);
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name='siteName'
            type='text'
            label={t('siteName')}
            placeholder={''}
            submitCount={submitCount}
            disabled
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name='floorName'
            type='text'
            required
            label={t('floorName')}
            placeholder={t('pleaseInputName')}
            submitCount={submitCount}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }}>
          <Field
            component={AntUpload}
            name='imageData'
            label={t('photo')}
            placeholder={t('uploadPhoto')}
            submitCount={submitCount}
            hasFeedback={false}
            maxCount={1}
            onChange={handleChange}
            imageWidth={300}
            imageHeight={200}
            extraData={{
              path: floorPath,
              uploadType: 'floor',
              imageData,
            }}
          />
        </Col>
      </Row>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col>
          <Link to={`/site-management/${siteId}/edit`}>
            <Button type='secondary'>{t('back')}</Button>
          </Link>
        </Col>
        <Col>
          <Button type='secondary' onClick={resetForm}>
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button type='primary' onClick={handleSubmit}>
            {t(isEditMode ? 'save' : 'create')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
