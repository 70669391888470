import { Button, Col, Form, notification, Row, Spin } from 'antd';
import {
  CustomLineChart,
  CustomTable,
  DateFilterForm,
  ExportCSV,
  SiteFloorLayerZoneSelectionForm,
} from '../../../components';
import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFetchDetectionSensorReport } from '../../../services/sensors';
import { useForceUpdate, useFormStateBackup } from '../../../hooks';
import { COLORS } from '../../../utilities/colors';
import {
  buildTableColumns,
  getCSVData,
  getCSVHeaders,
  getGraphData,
} from './transform';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { FORM_STORAGE_KEYS } from '../../../utilities/storage';
import { useActiveSiteProvider } from '../../../context/site';
import { useFetchSites } from '../../../services/sites';
import { getFloorLayerAndZoneFromSite } from '../../../utilities/transform';

export const FilterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setFieldValue, resetForm, values } = useFormikContext();
  const { pageSize, pageNumber } = values || {};
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const { data: { sites, defaultLocation } = {}, isLoading: fetchSiteLoading } =
    useFetchSites();
  const { activeSite: activeSiteId } = useActiveSiteProvider();
  const filters = useMemo(() => {
    const {
      siteId,
      floorId,
      layerId,
      zoneId,
      startTime,
      endTime,
      isPdfPage = 0,
      pageNumber,
      count = 10,
    } = values || {};
    const filters = {
      isPdfPage,
      pageNumber,
      count,
    };

    if (startTime) {
      filters.startDate = startTime.valueOf();
    }
    if (endTime) {
      filters.endDate = endTime.valueOf();
    }

    if (siteId) {
      filters.siteId = siteId;
      filters.floorId = floorId;

      if (!isEmpty(layerId)) {
        filters.layerId = layerId;
      }
      if (!isEmpty(zoneId)) {
        filters.zoneId = zoneId;
      }
    }
    return filters;
    // eslint-disable-next-line
  }, [forceUpdateKey]);

  useFormStateBackup({
    formKey: FORM_STORAGE_KEYS.SENSOR_REPORT,
    callback: forceUpdate,
  });

  useEffect(async () => {
    if (defaultLocation) {
      locationReset();
    }
  }, [defaultLocation]);

  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value == defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('test', '');
        await setFieldValue('floorId', activeSite1.floors[0].value);
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }
      forceUpdate();
    }
  };
  const { data, isValidating } = useFetchDetectionSensorReport(filters);
  const { sensorData, totalCount } = data || {};
  const dataPoints = getGraphData(sensorData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = buildTableColumns();
  const navigateToPdf = () => {
    navigate('pdf', { state: { filters: JSON.stringify(filters) } });
  };

  const handleSubmit = async () => {
    if (!values.siteId) {
      return notification.error({
        message: t('siteIsRequired'),
      });
    }
    if (!values.floorId) {
      return notification.error({
        message: t('floorIsRequired'),
      });
    }

    await setFieldValue('pageNumber', 1);
    forceUpdate();
  };
  const handleReset = async () => {
    // await resetForm({ values: { siteId: activeSiteId.value } });
    locationReset();
    // forceUpdate();
  };
  return (
    <Spin spinning={isValidating}>
      <Form>
        <Row gutter={[10, 10]} align='middle'>
          <SiteFloorLayerZoneSelectionForm
            isZone={true}
            isMultiLayer={true}
            isMultiZone={true}
          />
          <DateFilterForm
            handleSubmit={handleSubmit}
            actionButtonStyle={{
              marginTop: 18,
            }}
            handleReset={handleReset}
          />
          {!isEmpty(sensorData) && (
            <>
              <Col style={{ marginTop: 18 }} md={{ span: 3 }}>
                <ExportCSV
                  data={getCSVData(sensorData)}
                  columns={getCSVHeaders()}
                />
              </Col>
              <Col style={{ marginTop: 18 }} md={{ span: 3 }}>
                <Button block onClick={navigateToPdf}>
                  {t('exportToPdf')}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {dataPoints?.length > 0 && (
        <CustomLineChart
          isTitle
          height={250}
          title={''}
          data={dataPoints}
          titleStyles={{ color: COLORS.lightGreen }}
        />
      )}
      {useMemo(() => {
        return (
          <CustomTable
            rowKey='id'
            columns={columns}
            dataSource={sensorData}
            pagination={{
              locale: { items_per_page: `/ ${t('page')}` },
              showLessItems: true,
              showSizeChanger: true,
              showTotal: (total) => (
                <div className='table-pagination'>
                  {t('total')}: {total}
                </div>
              ),
            }}
          />
        );
      }, [
        columns,
        sensorData,
        pageNumber,
        totalCount,
        pageSize,
        setFieldValue,
        forceUpdate,
      ])}
    </Spin>
  );
};
