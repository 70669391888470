import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { TrapForm, Breadcrumbs, Loader } from '../../../components';
import { useDeleteBait, useEditBait } from '../../../services/baits';

import { useFetchDeviceDetails } from '../../../services/device';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  generateFilePath,
  getBaitImageUrl,
} from '../../../utilities/transform';
import { getBase64String } from '../../../utilities/image-utils';
import { AddBatchSchema } from '../../../utilities/schemas';
import isEmpty from 'lodash/isEmpty';

const buildInitialValues = (device) => {
  const { name, expiryDate, path } = device || {};
  return {
    ...device,
  };
};

const { Title } = Typography;
export const EditTrap = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: { device } = {}, isLoading } = useFetchDeviceDetails({
    deviceId: id,
  });
  const { mutate: editBait, status: editStatus } = useEditBait();
  const { mutate: deleteBait, status: deleteStatus } = useDeleteBait();

  if (isEmpty(device) || isLoading) {
    return <Loader />;
  }
  // const bait = baits[0];
  // if (!isLoading && isEmpty(batch)) {
  //   return (
  //     <div style={{ padding: 10 }}>
  //       <PageError description='Data not found' />;
  //     </div>
  //   );
  // }
  const initialValues = buildInitialValues(device);

  const handleDeleteBait = async () => {
    try {
    } catch (e) {}
  };
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('trapManagement'),
            link: '/trap-management',
          },
          {
            name: t('edit'),
            link: `/device-management/${id}/edit`,
          },
        ]}
      />
      <Title level={3}>{t('createbatch')}trap</Title>

      <Formik
        enableReinitialize={false}
        validationSchema={AddBatchSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            const { name, path, imageData } = values || {};
            const params = { name: name.trim(), path, imageData };
            if (values.imageData) {
              params.imageData = getBase64String(imageData);
              params.path = generateFilePath();
            }
            params.expiryDate = moment(values.expiryDate).valueOf();
            actions.setSubmitting(true);
            const payload = { ...params };
            const { data } = await editBait(payload);
            notification.success({ message: t('editedSuccess') });
            const updatedSite = data?.data?.batch;
            const updatedData = buildInitialValues(updatedSite);
            await actions.resetForm({ values: updatedData });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin spinning={false}>
              <TrapForm
                dirty={dirty}
                values={initialValues}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                handleDeleteBait={handleDeleteBait}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default EditTrap;
