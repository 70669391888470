import { createContext, useContext } from 'react';
import { storageGet, useLocalStorageState } from '../storage';

const UserContext = createContext({ user: {} });

export const UserProvider = ({ children }) => {
  const value = useLocalStorageState('user', storageGet('user'));
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserProvider = () => {
  const context = useContext(UserContext);
  if (!context)
    throw new Error('useEditOrder must be used inside UserProvider!');
  return context;
};
