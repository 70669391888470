import React, { useEffect, useMemo, useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Button, Col, DatePicker, Row, Spin, Tag } from 'antd';
import { useFetchHomeData } from '../../services/sensors';
import { AntSelect } from '../custom-fields';
import { useForceUpdate } from '../../hooks';
import moment from 'moment';
import { sensorSiteFloorLayerFilterValidation } from '../../utilities/validation-utils';
import { CustomCard, HeatmapStatic } from '../../components';
import Title from 'antd/es/typography/Title';
import { Images } from '../../images';
import {
  getFloorImageUrl,
  getFloorLayerAndZoneFromSite,
} from '../../utilities/transform';
import { useTranslation } from 'react-i18next';
import { useFetchSites } from '../../services/sites';
import { useActiveSiteProvider } from '../../context/site';
import { GUTTER } from '../../utilities/constants';
import CustomModal from '../custom-modal';

const { RangePicker } = DatePicker;
const getSensorNames = (sensors) => {
  return sensors?.map(({ name }) => name);
};
const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 2 }}>
      {children}
    </Col>
  );
};
export const HomeFilterForm = () => {
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t, i18n } = useTranslation();
  const { values, setFieldValue, resetForm } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const [types, setTypes] = useState(['detection_sensor', 'trap_sensor']);
  const { submitCount } = useFormikContext();
  const [activeSite, setActiveSite] = useState({});
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, 'd'),
    moment(),
  ]);
  const [isPageLoaded, setIsPageLoaded] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSensorStats, setSelectedSensorStats] = useState([]);
  const [selectedSensorStats1, setSelectedSensorStats1] = useState([]);
  const { activeSite: activeSiteId } = useActiveSiteProvider();

  const { data: { sites, defaultLocation } = {} } = useFetchSites();

  const TIME_RANGE_OPTION = [
    { key: '1', label: t('allDay'), value: '1' },
    { key: '2', label: t('night'), value: '2' },
  ];

  useEffect(() => {
    if (sites && sites.length > 0) {
      const selectedSite = sites.find(
        (site) => site?.id === activeSiteId.value
      );
      var activeSite1 = getFloorLayerAndZoneFromSite(
        selectedSite,
        selectedSite?.id
      );
      setActiveSite(activeSite1);
    }
  }, [sites, i18n.language, activeSiteId]);

  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value === defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        // setActiveSite(activeSite1);
        await setFieldValue('test', '');
        await setFieldValue('siteId', defaultLocation.siteId);
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('test', '');
        await setFieldValue('siteId', activeSiteId.value);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue('floorId', activeSite1.floors[0].value);
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }
      forceUpdate();
    }
  };

  useEffect(() => {
    if (defaultLocation) {
      locationReset();
    }
  }, [activeSiteId, defaultLocation]);

  const filters = useMemo(() => {
    const { siteId, floorId, layerId, zoneId, timeRange } = values;
    const filters = {
      startTime: dateRange[0].startOf('day').valueOf(),
      endTime: dateRange[1].endOf('day').valueOf(),
    };
    if (siteId) filters.siteId = siteId;
    if (floorId) filters.floorId = floorId;
    if (layerId) filters.layerId = layerId;
    if (zoneId) filters.zoneId = zoneId;
    filters.types = types;
    filters.timeRange = timeRange;
    return filters;
  }, [forceUpdateKey]);

  // let dashboardData={};
  // let isValidating=false;
  // if(values.zoneId){
  // const { data: dashboardData, isValidating } = useFetchHomeData(filters);
  // }
  // const { data: dashboardData, isValidating } = values.zoneId?useFetchHomeData(filters):{dashboardData:{},isValidating:false};
  const { data: dashboardData, isValidating } = useFetchHomeData(filters);
  const {
    detectionBatteryCount = 0,
    detectionHeartBeatCount = 0,
    detectionRatHitCount = 0,
    trapActiveCount = 0,
    trapBatteryCount = 0,
    trapCaughtCount = 0,
    trapMissedCount = 0,
    heatMapDate = null,
    naturalWidth = 0,
    naturalHeight = 0,
    BaitReplacement = 0,
    BatteryReplacement = 0,
    CatchTrapReplacement = 0,
    MissedTrapReset = 0,
    TotalAlertAcknowledged = 0,
    TotalAlertToday = 0,
    bait100 = 0,
    bait50 = 0,
    bait25 = 0,
    bait0 = 0,
    baitLowBattery = 0,
    baitActive = 0,
    floor = {},
  } = dashboardData || {};
  const {
    detectionActive,
    detectionBattery,
    detectionRatHit,
    trapActive,
    trapBattery,
    trapCaught,
    trapMissed,
    tShapedBaitAt,
    tShapedLowBattery,
    tShapedActive,
    disconnectedTrapSensor,
    disconnectedDetectionSensor,
    disconnectedBaitSensor,
  } = heatMapDate || {};
  // useSyncFormState({ callback: forceUpdate });
  const handleSubmit = async () => {
    await setFieldValue('pageNumber', 1);
    if (sensorSiteFloorLayerFilterValidation(values)) {
      forceUpdate();
    }
  };
  const handleReset1 = async () => {
    locationReset();
    setDateRange([moment().subtract(7, 'd'), moment()]);
    resetForm();
    // forceUpdate();
  };
  const floorPlanImageUrl = getFloorImageUrl(floor.floorPath);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoaded(true);
    }, 2000);
  }, [heatMapDate]);
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > moment().endOf('day');
  };
  const handleCardClick = (sensors, title, redSensor) => {
    setIsModalVisible((state) => !state);
    setSelectedSensorStats({ sensors: getSensorNames(sensors), title });
    setSelectedSensorStats1({ sensors: getSensorNames(redSensor), title });
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  return (
    <Spin spinning={isValidating}>
      <Form>
        <div style={{ marginTop: 10 }} />
        <Row gutter={[10, 10]} align='middle'>
          <>
            <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 2 }}>
              <Field
                component={AntSelect}
                name='floorId'
                label={t('floor')}
                placeholder={t('pleaseSelectFloor')}
                submitCount={submitCount}
                selectOptions={activeSite.floors}
              />
            </Col>
            <ItemWrapper>
              <Field
                component={AntSelect}
                name='layerId'
                label={t('layer')}
                mode={'multiple'}
                placeholder={t('pleaseSelectLayer')}
                submitCount={submitCount}
                selectOptions={activeSite.layers}
              />
            </ItemWrapper>

            <ItemWrapper>
              <Field
                component={AntSelect}
                name='zoneId'
                label={t('zone')}
                mode={'multiple'}
                placeholder={t('pleaseSelectZone')}
                submitCount={submitCount}
                selectOptions={activeSite.zones}
              />
            </ItemWrapper>
            <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 2 }}>
              <Field
                component={AntSelect}
                name='timeRange'
                label={t('timeRange')}
                placeholder={t('pleaseSelecttimeRange')}
                submitCount={submitCount}
                selectOptions={TIME_RANGE_OPTION}
                tooltip={
                  <span>
                    {t('dayTimeRangeTooltip')} <br />{' '}
                    {t('nightTimeRangeTooltip')}
                  </span>
                }
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 4 }}
              sm={{ span: 24 }}
              style={{ marginTop: 0 }}
            >
              <span style={{ fontWeight: 'bold' }}>{t('date')}</span>
              <RangePicker
                style={{ width: '100%' }}
                value={dateRange}
                onChange={(val) => setDateRange(val)}
                disabledDate={disabledDate}
              />
            </Col>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
                {t('reset')}
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                block
                type='primary'
                style={{ marginTop: 22 }}
                onClick={handleSubmit}
              >
                {t('submit')}
              </Button>
            </ActionButtonWrapper>
          </>
        </Row>
        <div className='cards-container'>
          <div className='custom-container'>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Title level={5} style={{ marginBottom: 0, marginTop: 8 }}>
                  {t('rodentDetectionSensor')}
                </Title>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.activeimg}
                  iconConatainer={{ backgroundColor: '#88c250' }}
                  title={t('active')}
                  value={detectionHeartBeatCount}
                  preview={false}
                  onClick={() =>
                    handleCardClick(
                      detectionActive,
                      'active',
                      disconnectedDetectionSensor
                    )
                  }
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.hitimg}
                  iconConatainer={{ backgroundColor: '#2100ff' }}
                  title={t('hit')}
                  value={detectionRatHitCount}
                  preview={false}
                  onClick={() => handleCardClick(detectionRatHit, 'hit')}
                />
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.batteryimg}
                  iconConatainer={{ backgroundColor: '#D3293D' }}
                  title={t('lowBattery')}
                  tooltip={<span>{t('lowbatterytooltip')}</span>}
                  value={detectionBatteryCount}
                  preview={false}
                  onClick={() =>
                    handleCardClick(detectionBattery, 'lowBattery')
                  }
                />
              </Col>
            </Row>
          </div>
          <div className='custom-container'>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Title level={5} style={{ marginBottom: 0, marginTop: 8 }}>
                  {t('rodentTrapSensor')}
                </Title>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.activeimg}
                  iconConatainer={{ backgroundColor: '#88c250' }}
                  title={t('active')}
                  value={trapActiveCount}
                  preview={false}
                  onClick={() =>
                    handleCardClick(trapActive, 'total', disconnectedTrapSensor)
                  }
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.caughtimg}
                  iconConatainer={{ backgroundColor: '#2100ff' }}
                  title={t('caught')}
                  value={trapCaughtCount}
                  preview={false}
                  onClick={() => handleCardClick(trapCaught, 'caught')}
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.batteryimg}
                  iconConatainer={{ backgroundColor: '#D3293D' }}
                  title={t('lowBattery')}
                  tooltip={<span>{t('lowbatterytooltip')}</span>}
                  value={trapBatteryCount}
                  preview={false}
                  onClick={() => handleCardClick(trapBattery, 'lowBattery')}
                />
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.missedimg}
                  iconConatainer={{ backgroundColor: '#f9c958' }}
                  iconStyle={{ fontSize: 30, color: 'white', display: 'block' }}
                  title={t('missed')}
                  value={trapMissedCount}
                  preview={false}
                  onClick={() => handleCardClick(trapMissed, 'missed')}
                />
              </Col>
            </Row>
          </div>
          <div className='custom-container'>
            <Row gutter={[GUTTER, GUTTER]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Title level={5} style={{ marginBottom: 10, marginTop: 8 }}>
                  {t('tShapedBaitBox')}
                </Title>
              </Col>
            </Row>
            <Row gutter={[GUTTER, GUTTER]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Row gutter={[GUTTER, GUTTER]}>
                  <Col xs={{ span: 24 }}>
                    <CustomCard
                      isLeftIcon
                      icon={Images.activeimg}
                      iconConatainer={{ backgroundColor: '#88c250' }}
                      title={t('active')}
                      value={baitActive}
                      preview={false}
                      onClick={() =>
                        handleCardClick(
                          tShapedActive,
                          'active',
                          disconnectedBaitSensor
                        )
                      }
                    />
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <CustomCard
                      isLeftIcon
                      icon={Images.batteryimg}
                      iconConatainer={{ backgroundColor: '#D3293D' }}
                      title={t('lowBattery')}
                      tooltip={<span>{t('lowbatterytooltip')}</span>}
                      value={baitLowBattery}
                      preview={false}
                      onClick={() =>
                        handleCardClick(tShapedLowBattery, 'lowBattery')
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Row gutter={[GUTTER, GUTTER]}>
                  <Col xs={{ span: 24 }}>
                    <CustomCard
                      isLeftIcon
                      icon={Images.bait100}
                      iconConatainer={{ backgroundColor: '#FFBEB2' }}
                      title={`${t('baitat')} 100%`}
                      value={bait100}
                      preview={false}
                      onClick={() =>
                        handleCardClick(
                          tShapedBaitAt.filter(
                            ({ baitValue }) => baitValue === '100'
                          ),
                          `${t('baitat')} 100%`
                        )
                      }
                    />
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <CustomCard
                      isLeftIcon
                      icon={Images.bait50}
                      iconConatainer={{ backgroundColor: '#FA8F79' }}
                      title={`${t('baitat')} 50-75%`}
                      value={bait50}
                      preview={false}
                      onClick={() =>
                        handleCardClick(
                          tShapedBaitAt.filter(
                            ({ baitValue }) =>
                              baitValue === '50' || baitValue === '75'
                          ),
                          `${t('baitat')} 50-75%`
                        )
                      }
                    />
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <CustomCard
                      isLeftIcon
                      icon={Images.bait25}
                      iconConatainer={{ backgroundColor: '#E05C4D' }}
                      title={`${t('baitat')} 25%`}
                      value={bait25}
                      preview={false}
                      onClick={() =>
                        handleCardClick(
                          tShapedBaitAt.filter(
                            ({ baitValue }) => baitValue === '25'
                          ),
                          `${t('baitat')} 25%`
                        )
                      }
                    />
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <CustomCard
                      isLeftIcon
                      icon={Images.bait0}
                      iconConatainer={{ backgroundColor: '#D3293D' }}
                      title={`${t('baitat')} 0%`}
                      value={bait0}
                      preview={false}
                      onClick={() =>
                        handleCardClick(
                          tShapedBaitAt.filter(
                            ({ baitValue }) => baitValue === '0'
                          ),
                          `${t('baitat')} 0%`
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className='custom-container'>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Title level={5} style={{ marginBottom: 0, marginTop: 8 }}>
                  {t('specialSupportTeam')}
                </Title>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.totalAlert}
                  iconConatainer={{ backgroundColor: '#D3423D' }}
                  title={t('totalAlert')}
                  value={TotalAlertToday}
                  preview={false}
                />
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.catchTrapReplacement}
                  iconConatainer={{ backgroundColor: '#2100ff' }}
                  title={t('catchTrapreplacement')}
                  value={CatchTrapReplacement}
                  preview={false}
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.totalAlertAcknowledged}
                  iconConatainer={{ backgroundColor: '#289001' }}
                  title={t('totalAlertAcknowledged')}
                  value={TotalAlertAcknowledged}
                  preview={false}
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.missedTrapReset}
                  iconConatainer={{ backgroundColor: '#F9C958' }}
                  title={t('missedTrapReset')}
                  value={MissedTrapReset}
                  preview={false}
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.baitReplacement}
                  iconConatainer={{ backgroundColor: '#D729AA' }}
                  title={t('baitReplacement')}
                  value={BaitReplacement}
                  preview={false}
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomCard
                  isLeftIcon
                  icon={Images.batteryReplacement}
                  iconConatainer={{ backgroundColor: '#5D3FD3' }}
                  title={t('batteryReplacement')}
                  value={BatteryReplacement}
                  preview={false}
                />
              </Col>
            </Row>
          </div>
        </div>
        <Spin spinning={!isPageLoaded && !isValidating}>
          {heatMapDate?.detectionRatHit != null && isPageLoaded ? (
            <HeatmapStatic data={heatMapDate} floorPlan={floorPlanImageUrl} />
          ) : null}
        </Spin>
      </Form>
      <CustomModal
        title={selectedSensorStats.title}
        isVisible={isModalVisible}
        onCancel={handleCloseModal}
        cancelText={'back'}
        isFooter={true}
      >
        {selectedSensorStats.sensors?.length > 0 ||
        selectedSensorStats1.sensors?.length > 0
          ? selectedSensorStats.sensors?.map((name, index) => (
              <Tag color='green' key={index}>
                {name}
              </Tag>
            ))
          : t('noData')}
        {selectedSensorStats1.sensors?.map((name, index) => (
          <Tag color='red' key={index}>
            {name}
          </Tag>
        ))}
      </CustomModal>
    </Spin>
  );
};
