import React, { useEffect, useMemo, useRef, useState } from 'react';
import h337 from 'heatmapjs';
import './index.css';
import { useTranslation } from 'react-i18next';
import { Checkbox, Spin } from 'antd';
import { isInside } from '../../utilities/functions';
import { usePreviewHeatmapWithZoom } from '../../hooks/use-preview-heatmap-with-zoom';
import { ZoomFloorActionButtons } from '../zoom-floor-action-buttons';
import { isEmpty } from 'lodash';

export const HeatmapStatic = ({ data, floorPlan }) => {
  const {
    floorPlanMeta,
    sensors,
    floorPlan: scaledFloorPLan,
    activeScaleInfo,
    optionsList,
    onChangeZoomLevel,
    forceUpdateKey,
  } = usePreviewHeatmapWithZoom({ floorPlan, sensors: data });
  const { scaledFloorPlanImageWidth, scaledFloorPlanImageHeight } =
    floorPlanMeta || {};
  const formattedSensors = useMemo(() => {
    return {
      detectionRatHit: sensors?.detectionRatHit?.map((item) => {
        item.radius = 10 + 50 * (1 - Math.exp(-0.2 * item.value));
        if (
          item.x > scaledFloorPlanImageWidth ||
          item.x < 0 ||
          item.y > scaledFloorPlanImageHeight ||
          item.y < 0
        ) {
          return {
            ...item,
            x: 0,
            y: 0,
          };
        }
        return item;
      }),
      trapCaught: sensors?.trapCaught?.map((item) => {
        item.radius = 10 + 50 * (1 - Math.exp(-0.2 * item.value));
        item.value = 10 + item.value;
        if (
          item.x > scaledFloorPlanImageWidth ||
          item.x < 0 ||
          item.y > scaledFloorPlanImageHeight ||
          item.y < 0
        ) {
          return {
            ...item,
            x: 0,
            y: 0,
          };
        }
        return item;
      }),
      trapMissed: sensors?.trapMissed?.map((item) => {
        item.radius = 10 + 50 * (1 - Math.exp(-0.2 * item.value));
        item.value = 10 + item.value;
        if (
          item.x > scaledFloorPlanImageWidth ||
          item.x < 0 ||
          item.y > scaledFloorPlanImageHeight ||
          item.y < 0
        ) {
          return {
            ...item,
            x: 0,
            y: 0,
          };
        }
        return item;
      }),
      disconnectedTrapSensor: sensors?.disconnectedTrapSensor?.map((item) => {
        item.radius = 10 + 50 * (1 - Math.exp(-0.2 * item.value));
        if (
          item.x > scaledFloorPlanImageWidth ||
          item.x < 0 ||
          item.y > scaledFloorPlanImageHeight ||
          item.y < 0
        ) {
          return {
            ...item,
            x: 0,
            y: 0,
          };
        }
        return item;
      }),
    };
  }, [sensors, scaledFloorPlanImageWidth, scaledFloorPlanImageHeight]);

  const { t } = useTranslation();
  const ratHitHeatmap = useRef();
  const trapMissedHeatmap = useRef();
  const trapCaughtHeatmap = useRef();
  const trapDisconnectedHeatmap = useRef();
  const [isRatHitHeatmap, setIsRatHitHeatmap] = useState(false);
  const [isTrapCaughtHeatmap, setIsTrapCaughtHeatmap] = useState(true);
  const [isTrapMissedHeatmap, setIsTrapMissedHeatmap] = useState(true);
  const [isTrapDisconnectedHeatmap, setIsTrapDisconnectedHeatmap] =
    useState(false);

  useEffect(() => {
    if (!isEmpty(floorPlanMeta)) {
      // Remove Rat Hit Heatmap
      ratHitHeatmap.current?._renderer.canvas.remove();
      ratHitHeatmap.current = null;

      // Remove Trap Missed Heatmap
      trapMissedHeatmap.current?._renderer.canvas.remove();
      trapMissedHeatmap.current = null;

      // Remove Trap Caught Heatmap
      trapCaughtHeatmap.current?._renderer.canvas.remove();
      trapCaughtHeatmap.current = null;

      trapDisconnectedHeatmap.current?._renderer.canvas.remove();
      trapDisconnectedHeatmap.current = null;

      updateRatHitHeatmap();
      updateTrapCaughtHeatmap();
      updateTrapMissedHeatmap();
      updateTrapDisconnectedHeatmap();

      /* tooltip code start */
      const demoWrapper = document.querySelector('.demo-wrapper');
      const tooltip = document.querySelector('.tooltip');

      function updateTooltip(x, y, name) {
        // + 15 for distance to cursor
        tooltip.style.webkitTransform =
          'translate(' + (x + 15) + 'px, ' + (y + 15) + 'px)';

        tooltip.innerHTML = `<span>
${name} <br/>
</span>`;
      }

      demoWrapper.onmousemove = function (ev) {
        const x = ev.layerX;
        const y = ev.layerY;

        if (ratHitHeatmap.current?.getValueAt({ x, y })) {
          const value = ratHitHeatmap.current?.getValueAt({ x, y });
          const sensors = formattedSensors?.detectionRatHit;
          // Find Name of Sensor
          const { name } =
            sensors
              .filter((sensor) => {
                if (isInside(sensor?.x, sensor?.y, sensor?.radius, x, y))
                  return sensor;
              })
              .pop() || {};

          if (value > 0 && ratHitHeatmap.current) {
            tooltip.style.display = 'block';
            updateTooltip(x, y, name);
          } else {
            tooltip.style.display = 'none';
          }
        } else if (
          trapCaughtHeatmap.current?.getValueAt({
            x,
            y,
          })
        ) {
          const value = trapCaughtHeatmap.current?.getValueAt({ x, y });

          const sensors = formattedSensors?.trapCaught;
          // Find Name of Sensor
          const { name } =
            sensors
              .filter((sensor) => {
                if (isInside(sensor?.x, sensor?.y, sensor?.radius, x, y))
                  return sensor;
              })
              .pop() || {};

          if (value > 0 && trapCaughtHeatmap.current) {
            tooltip.style.display = 'block';
            updateTooltip(x, y, name);
          } else {
            tooltip.style.display = 'none';
          }
        } else if (trapMissedHeatmap.current?.getValueAt({ x, y })) {
          const value = trapMissedHeatmap.current?.getValueAt({ x, y });

          const sensors = formattedSensors?.trapMissed;
          // Find Name of Sensor
          const { name } =
            sensors
              .filter((sensor) => {
                if (isInside(sensor?.x, sensor?.y, sensor?.radius, x, y))
                  return sensor;
              })
              .pop() || {};

          if (value > 0 && trapMissedHeatmap.current) {
            tooltip.style.display = 'block';
            updateTooltip(x, y, name);
          } else {
            tooltip.style.display = 'none';
          }
        } else if (trapDisconnectedHeatmap.current?.getValueAt({ x, y })) {
          const value = trapDisconnectedHeatmap.current?.getValueAt({ x, y });

          const sensors = formattedSensors?.disconnectedTrapSensor;
          // Find Name of Sensor
          const { name } =
            sensors
              .filter((sensor) => {
                if (isInside(sensor?.x, sensor?.y, sensor?.radius, x, y))
                  return sensor;
              })
              .pop() || {};

          if (value > 0 && trapDisconnectedHeatmap.current) {
            tooltip.style.display = 'block';
            updateTooltip(x, y, name);
          } else {
            tooltip.style.display = 'none';
          }
        }
      };
      // hide tooltip on mouseout
      demoWrapper.onmouseout = function () {
        tooltip.style.display = 'none';
      };
      /* tooltip code end */
    }
  }, [
    formattedSensors,
    forceUpdateKey,
    floorPlanMeta,
    scaledFloorPlanImageWidth,
    scaledFloorPlanImageHeight,
  ]);
  const handleToggleRatHitHeatmap = (value) => {
    setIsRatHitHeatmap((value) => !value);
  };
  const updateRatHitHeatmap = () => {
    if (isRatHitHeatmap) {
      const ratHitHeatmapInstance = h337.create({
        container: document.querySelector('.heatmap'),
        gradient: { 0.5: 'rgb(0,0,255)', 1.0: 'rgb(0,0,255)' },
      });
      ratHitHeatmapInstance.setData({
        min: 0,
        max: 40,
        data: formattedSensors?.detectionRatHit || [],
      });
      ratHitHeatmap.current = ratHitHeatmapInstance;
    } else {
      ratHitHeatmap.current?._renderer.canvas.remove();
      ratHitHeatmap.current = null;
    }
  };
  const handleToggleTrapMissedHeatmap = (value) => {
    setIsTrapMissedHeatmap((value) => !value);
  };
  const updateTrapMissedHeatmap = () => {
    if (isTrapMissedHeatmap && document.querySelector('.heatmap')) {
      const trapMissedHeatmapInstance = h337.create({
        container: document.querySelector('.heatmap'),
        gradient: { 0.5: 'rgb(255,255,0)', 1.0: 'rgb(255,255,0)' },
      });
      trapMissedHeatmapInstance.setData({
        min: 0,
        max: 40,
        data: formattedSensors?.trapMissed || [],
      });
      trapMissedHeatmap.current = trapMissedHeatmapInstance;
    } else {
      trapMissedHeatmap.current?._renderer.canvas.remove();
      trapMissedHeatmap.current = null;
    }
  };
  const handleToggleTrapCaughtHeatmap = (value) => {
    setIsTrapCaughtHeatmap((value) => !value);
  };
  const updateTrapCaughtHeatmap = () => {
    if (isTrapCaughtHeatmap && document.querySelector('.heatmap')) {
      const trapCaughtHeatmapInstance = h337.create({
        container: document.querySelector('.heatmap'),
        gradient: { 0.5: 'rgb(255,0,255)', 1.0: 'rgb(255,0,255)' },
      });
      trapCaughtHeatmapInstance.setData({
        min: 0,
        max: 40,
        data: formattedSensors?.trapCaught || [],
      });
      trapCaughtHeatmap.current = trapCaughtHeatmapInstance;
    } else {
      trapCaughtHeatmap.current?._renderer.canvas.remove();
      trapCaughtHeatmap.current = null;
    }
  };
  const handleToggleTrapDisconnectedHeatmap = (value) => {
    setIsTrapDisconnectedHeatmap((value) => !value);
  };
  const updateTrapDisconnectedHeatmap = () => {
    if (isTrapDisconnectedHeatmap) {
      const trapDisconnectedHeatmapInstance = h337.create({
        container: document.querySelector('.heatmap'),
        gradient: { 0.5: 'rgb(255,0,0)', 1.0: 'rgb(255,0,0)' },
      });
      trapDisconnectedHeatmapInstance.setData({
        min: 0,
        max: 40,
        data: formattedSensors?.disconnectedTrapSensor || [],
      });
      trapDisconnectedHeatmap.current = trapDisconnectedHeatmapInstance;
    } else {
      trapDisconnectedHeatmap.current?._renderer.canvas.remove();
      trapDisconnectedHeatmap.current = null;
    }
  };

  useEffect(() => {
    // setTimeout(updateRatHitHeatmap(), 1000);
    updateRatHitHeatmap();
  }, [isRatHitHeatmap]);
  useEffect(() => {
    // setTimeout(updateTrapMissedHeatmap(), 1000);
    updateTrapMissedHeatmap();
  }, [isTrapMissedHeatmap]);
  useEffect(() => {
    // setTimeout(updateTrapCaughtHeatmap(), 1000);
    updateTrapCaughtHeatmap();
  }, [isTrapCaughtHeatmap]);
  useEffect(() => {
    setTimeout(updateTrapDisconnectedHeatmap(), 1000);
  }, [isTrapDisconnectedHeatmap]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsTrapMissedHeatmap(true);
  //     setIsTrapCaughtHeatmap(true);
  //   }, 1000);
  // }, []);
  if (isEmpty(floorPlanMeta)) {
    return <Spin />;
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <ZoomFloorActionButtons
        activeScaleInfo={activeScaleInfo}
        optionsList={optionsList}
        onChange={(item) => {
          onChangeZoomLevel(item.value);
        }}
      />
      <div className='legend-container'>
        <div className='legend'>
          <span className='disconnected'>{t('disconnected')}</span>
          <Checkbox
            checked={isTrapDisconnectedHeatmap}
            onChange={(e) =>
              handleToggleTrapDisconnectedHeatmap(e.target.checked)
            }
          />
        </div>
        <div className='legend'>
          <span className='rat-hit'>{t('ratHit')}</span>
          <Checkbox
            checked={isRatHitHeatmap}
            onChange={(e) => handleToggleRatHitHeatmap(e.target.checked)}
          />
        </div>
        <div className='legend'>
          <span className='trap-caught'>{t('trapCaught')}</span>
          <Checkbox
            checked={isTrapCaughtHeatmap}
            onChange={(e) => handleToggleTrapCaughtHeatmap(e.target.checked)}
          />
        </div>
        <div className='legend'>
          <span className='trap-missed'>{t('trapMissed')}</span>
          <Checkbox
            checked={isTrapMissedHeatmap}
            onChange={(e) => handleToggleTrapMissedHeatmap(e.target.checked)}
          />
        </div>
      </div>

      <div
        className='demo-wrapper'
        style={{
          height: scaledFloorPlanImageHeight + 30,
          overflowX: 'scroll',
        }}
        key={forceUpdateKey}
      >
        <div
          className='heatmap'
          style={{
            width: `${scaledFloorPlanImageWidth}px`,
            height: `${scaledFloorPlanImageHeight}px`,
          }}
        >
          <span className='tooltip' />
          <canvas
            style={{
              backgroundImage: `url(${scaledFloorPLan})`,
              backgroundSize: `${scaledFloorPlanImageWidth}px ${scaledFloorPlanImageHeight}px`,
              width: `${scaledFloorPlanImageWidth}px`,
              height: `${scaledFloorPlanImageHeight}px`,
              backgroundRepeat: 'no-repeat',
            }}
            key={forceUpdateKey}
          />
        </div>
      </div>
    </div>
  );
};

export default HeatmapStatic;
