import './index.css';
import React from 'react';
import { Col, notification, Row, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../../utilities/time-utils';
import { useDatainputAndExchange } from '../../services/sensors';
import { Formik } from 'formik';
import { SensorInputDataForm } from '../../components';
import { useForceUpdate } from '../../hooks';
import { getNotificationImageUrl } from '../../utilities/transform';
import { useNavigate } from 'react-router-dom';
import { SensorInputDataSchema } from '../../utilities/schemas';

const buildInitialValues = (dataSource) => {
  const isAcked = 'acked' === dataSource?.notificationData?.state;
  return {
    isAcked: isAcked,
    originalId: dataSource?.sensor?.sensorId,
    newId: isAcked ? dataSource?.exchangeHistory?.toDeviceCode : null,
    location:
      dataSource?.sensor?.siteName + ' ' + dataSource?.sensor?.floorName,
    falseAlarm: isAcked ? dataSource?.dataInput?.isFalseAlert : false,
    photo: isAcked
      ? getNotificationImageUrl(dataSource?.dataInput?.imageName)
      : null,
    ratSize: isAcked ? dataSource?.dataInput?.ratSize : null,
    bait: isAcked ? dataSource?.dataInput?.baitId : null,
    baitUsage: isAcked ? dataSource?.dataInput?.baitUsage : null,
    remark: isAcked ? dataSource?.dataInput?.remark : null,
  };
};

export const CustomInfo = ({
  dataSource = [],
  isLoading = false,
  notificationId,
}) => {
  const { forceUpdate } = useForceUpdate();
  const { t } = useTranslation();
  const { mutate: DatainputAndExchange, status: editStatus } =
    useDatainputAndExchange(notificationId);
  const isDataExist = !isEmpty(dataSource);
  const { notificationData, sensor } = dataSource || {};
  const initialValues = buildInitialValues(dataSource);
  const navigate = useNavigate();
  return (
    <Spin spinning={!isDataExist && isLoading}>
      <Row>
        <Col span={12} className='text_lable'>
          {t('deviceNameLowercase')}
        </Col>
        <Col span={12}>
          <span className='text_content'>{sensor && sensor.name}</span>
        </Col>
      </Row>
      <Row>
        <Col span={12} className='text_lable'>
          {t('eventdate')}
        </Col>
        <Col span={12}>
          <span className='text_content'>
            {notificationData && formatTime(notificationData.createTime)}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={12} className='text_lable'>
          {t('ackDate')}
        </Col>
        <Col span={12}>
          <span className='text_content'>
            {notificationData && formatTime(notificationData.updateTime)}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={12} className='text_lable'>
          {t('location')}
        </Col>
        <Col span={12}>
          <span className='text_content'>
            {sensor && sensor.zoneName} {sensor && sensor.layerName}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={24} className='text_content'>
          <Formik
            // initialValues={{originalId: sensor.id,location: sensor.siteName +' '+ sensor.floorName,falseAlarm:true}}
            initialValues={initialValues}
            validationSchema={SensorInputDataSchema}
            /* onSubmit={() => {console.log('tj')}} */
            // render={SensorInputDataForm}
            onSubmit={async (formValues, actions) => {
              /* useDataInputSensors(formValues); */
              try {
                formValues.falseAlarm = formValues.falseAlarm || false;
                formValues.notificationId = notificationData.id;
                if (formValues.falseAlarm) {
                  formValues.ratSize = '';
                  formValues.bait = '';
                  formValues.baitUsage = '';
                }
                await DatainputAndExchange(formValues);
                await actions.resetForm();
                notification.success({ message: t('editedSuccess') });
                navigate('/notifications');
              } catch ({ message }) {
                notification.error({ message });
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ values, submitCount, resetForm, handleSubmit }) => {
              return (
                <Spin spinning={editStatus === 'pending'}>
                  <SensorInputDataForm
                    values={values}
                    submitCount={submitCount}
                    resetForm={(e) => {
                      resetForm({ values: initialValues });
                      forceUpdate();
                    }}
                    handleSubmit={handleSubmit}
                    isAcked={initialValues?.isAcked}
                  />
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Spin>
  );
};
