import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { BatteryReplaceForm, Breadcrumbs } from '../../../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddEventBatteryReplaceSchema } from '../../../../../utilities/schemas';
import moment from 'moment';
import {
  BATTERY_LEVEL_KEYS,
  SENSOR_TYPE_KEYS,
} from '../../../../../utilities/constants';
import { useAddEventBatteryReplace } from '../../../../../services/eventBatteryReplace';

const { Title } = Typography;
export const AddEventBatteryReplace = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { isFromEvent = false } = state || { isFromEvent: false };
  const { mutate: addEventBatteryReplace } = useAddEventBatteryReplace();
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          !isFromEvent
            ? {
                name: t('trap_clean'),
                link: '/event-trap-clean-management',
              }
            : {
                name: t('event1'),
                link: '/events',
              },
          {
            name: t('add'),
            link: `/event-trap-clean-management/add`,
          },
        ]}
      />
      <Title level={3}>{t('batteryReplacement')}</Title>

      <Formik
        validationSchema={AddEventBatteryReplaceSchema}
        initialValues={{
          siteId: '',
          layerId: [],
          floorId: '',
          zoneId: [],
          sensorType: SENSOR_TYPE_KEYS.TRAP_SENSOR,
          batteryLevel: BATTERY_LEVEL_KEYS.LOW_BATTERY,
          checkedList: [],
          eventStart: moment(),
          eventEnd: moment(),
          startTime: moment().startOf('day').valueOf(),
          endTime: moment().startOf('day').valueOf(),
        }}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true);
            await addEventBatteryReplace(values);
            notification.success({ message: t('createdSuccess') });
            navigate('/events');
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Spin spinning={isSubmitting}>
              <BatteryReplaceForm handleSubmit={handleSubmit} />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddEventBatteryReplace;
