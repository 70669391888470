import { useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const TRAP_CLEAN = 'eventTrapClean';
const ALL = 'all';

const fetchEventTrapClean = (api, filters) => async () => {
  const { data } = await api.fetchEventTrapClean(filters);
  return {
    eventTrapClean: data?.data?.eventTrapClean || [],
    totalCount: data?.data?.totalCount || [],
  };
};

export const useFetchEventTrapClean = (filters) => {
  const api = useApi();
  const response = useSWR(
    `${TRAP_CLEAN}/${filters.eventTrapCleanId || ALL}`,
    fetchEventTrapClean(api, filters)
  );
  return withLoading(response);
};

const fetchEventTrapCleanDetails = (api, filters) => async () => {
  const { data } = await api.fetchEventTrapCleanDetails(filters);
  return data?.data || {};
};

export const useFetchEventTrapCleanDetails = ({ notificationId }) => {
  const api = useApi();
  const response = useSWR(
    `TRAP_CLEAN/${notificationId}`,
    fetchEventTrapCleanDetails(api, { notificationId })
  );
  return withLoading(response);
};

const addEventTrapClean = (api) => async (body) => {
  return await api.addEventTrapClean(body);
};

export const useAddEventTrapClean = () => {
  const api = useApi();
  const mutate = useMatchMutate(TRAP_CLEAN);
  return useMutation(addEventTrapClean(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const editEventTrapClean = (api) => async (body) => {
  return await api.editEventTrapClean(body);
};

export const useEditEventTrapClean = () => {
  const api = useApi();
  const mutate = useMatchMutate(TRAP_CLEAN);
  return useMutation(editEventTrapClean(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteEventTrapClean =
  (api) =>
  async ({ eventTrapCleanId }) => {
    return await api.deleteEventTrapClean({ eventTrapCleanId });
  };

export const useDeleteEventTrapClean = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${TRAP_CLEAN}/${ALL}`);
  return useMutation(deleteEventTrapClean(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
