import React from 'react';
import {
  Breadcrumbs,
  Loader,
  PageError,
  PageHeader,
  RoleForm,
} from '../../../components';
import { Formik } from 'formik';
import { notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoleSchema } from '../../../utilities/schemas';
import { generatePermissionSchema } from '../../../utilities/transform';
import {
  useDeleteRole,
  useEditRole,
  useFetchRoles,
} from '../../../services/roles';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

const buildInitialValues = (role) => {
  const { name, siteId, permissionSchema: stringifySchema } = role || {};
  const permissionSchema = JSON.parse(stringifySchema);
  return {
    name: name,
    siteId: siteId,
    secondSection: [
      { detectionSensor: permissionSchema?.detectionSensor },
      { trapSensor: permissionSchema?.trapSensor },
    ],
    thirdSection: [
      { dashboardView: permissionSchema?.dashboardView },
      {
        sensorView: permissionSchema?.sensorView,
        sensorEdit: permissionSchema?.sensorEdit,
      },
      { siteManagementView: permissionSchema?.siteManagementView },
      { reportView: permissionSchema?.reportView },
      {
        roleManagementView: permissionSchema?.roleManagementView,
        roleManagementEdit: permissionSchema?.roleManagementEdit,
      },
      {
        accountManagementView: permissionSchema?.accountManagementView,
        accountManagementEdit: permissionSchema?.accountManagementEdit,
      },
      {
        baitManagementView: permissionSchema?.baitManagementView,
        baitManagementEdit: permissionSchema?.baitManagementEdit,
      },
      {
        batchManagementView: permissionSchema?.batchManagementView,
        batchManagementEdit: permissionSchema?.batchManagementEdit,
      },
    ],
  };
};
export const EditRole = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, name } = useParams();
  const { isLoading, data: { roles: data } = {} } = useFetchRoles({ name });
  const { mutate: EditRole, status: editStatus } = useEditRole();
  const { mutate: deleteRole, status: deleteStatus } = useDeleteRole();
  if (!data && isLoading) {
    return <Loader />;
  }

  const role = data[0];
  if (!isLoading && isEmpty(role)) {
    return (
      <div style={{ padding: 10 }}>
        <PageError description='Data not found' />;
      </div>
    );
  }
  const initialValues = buildInitialValues(role);
  const handleDeleteRole = async () => {
    try {
      await deleteRole({ roleId: id });
      notification.success({ message: t('deletedSuccess') });
      navigate('/role-management');
    } catch (e) {}
  };

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('roleManagement'),
            link: '/role-management',
          },
          {
            name: t('edit'),
            link: `/role-management/${id}/${name}/edit`,
          },
        ]}
      />
      <PageHeader title={t('editRole')} />
      <Formik
        initialValues={initialValues}
        validationSchema={RoleSchema}
        enableReinitialize={false}
        onSubmit={async (values, actions) => {
          try {
            const castValues = RoleSchema.cast(values);
            const permissionSchema = generatePermissionSchema(
              castValues.secondSection,
              castValues.thirdSection
            );
            actions.setSubmitting(true);
            const payload = {
              name: castValues.name,
              siteId: castValues.siteId,
              permissionSchema: JSON.stringify(permissionSchema),
              id,
            };
            const { data } = await EditRole(payload);
            const updatedRole = data?.data?.Role;
            const updatedData = buildInitialValues(updatedRole);
            await actions.resetForm({ values: updatedData });
            notification.success({ message: t('editedSuccess') });
          } catch ({ message }) {
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, values, submitCount, resetForm }) => (
          <Spin
            spinning={deleteStatus === 'pending' || editStatus === 'pending'}
          >
            <RoleForm
              handleSubmit={handleSubmit}
              values={values}
              submitCount={submitCount}
              resetForm={resetForm}
              handleDeleteRole={handleDeleteRole}
            />
          </Spin>
        )}
      </Formik>
    </div>
  );
};

export default EditRole;
