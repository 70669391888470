import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import {
  Breadcrumbs,
  Loader,
  LocationChangeForm,
  PageError,
} from '../../../components';
import { useDeleteBait, useEditBait } from '../../../services/baits';
import {
  generateFilePath,
  getLocationImageUrl,
} from '../../../utilities/transform';

import { useFetchEventLocationChangeDetails } from '../../../services/eventLocationChange';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { getBase64String } from '../../../utilities/image-utils';
import { EventLocationChangeSchema } from '../../../utilities/schemas';

const buildInitialValues = (eventLocationChange, notificationData) => {
  const { name, expiryDate, path } = eventLocationChange || {};
  return {
    ...eventLocationChange,
    imageAfter: eventLocationChange
      ? getLocationImageUrl(eventLocationChange?.imageAfter)
      : null,
    imageBefore: eventLocationChange
      ? getLocationImageUrl(eventLocationChange?.imageBefore)
      : null,
    name,
    expiryDate: moment(expiryDate),
    imageData: null,
    eventStart: moment(notificationData?.eventStart),
    eventEnd: moment(notificationData?.eventEnd),
  };
};

const { Title } = Typography;
export const EditLocationChange = () => {
  const { state } = useLocation();
  const { isFromEvent = false } = state || { isFromEvent: false };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    data: { eventLocationChange, notification: notificationData } = {},
    isLoading,
  } = useFetchEventLocationChangeDetails({ notificationId: id });

  const { mutate: editBait, status: editStatus } = useEditBait();
  const { mutate: deleteBait, status: deleteStatus } = useDeleteBait();

  if (isEmpty(eventLocationChange) || isLoading) {
    return <Loader />;
  }
  // const bait = baits[0];
  if (!isLoading && isEmpty(eventLocationChange)) {
    return (
      <div style={{ padding: 10 }}>
        <PageError description='Data not found' />;
      </div>
    );
  }

  const handleDeleteBait = async () => {
    try {
      await deleteBait({ eventLocationChangeId: id });
      notification.success({ message: t('deletedSuccess') });
      navigate('/device-management');
    } catch (e) {}
  };

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          !isFromEvent
            ? {
                name: t('batchManagement'),
                link: '/batch-management',
              }
            : {
                name: t('event1'),
                link: '/events',
              },
          {
            name: t('edit'),
            link: `/device-management/${id}/edit`,
          },
        ]}
      />
      <Title level={3}>{t('editBatch')}</Title>

      <Formik
        enableReinitialize={true}
        validationSchema={EventLocationChangeSchema}
        initialValues={buildInitialValues(
          eventLocationChange,
          notificationData
        )}
        onSubmit={async (values, actions) => {
          try {
            const { name, path, imageData } = values || {};
            const params = { name: name.trim(), path, imageData };
            if (values.imageData) {
              params.imageData = getBase64String(imageData);
              params.path = generateFilePath();
            }
            params.expiryDate = moment(values.expiryDate).valueOf();
            actions.setSubmitting(true);
            const payload = { ...params, id };
            const { data } = await editBait(payload);
            notification.success({ message: t('editedSuccess') });
            const updatedSite = data?.data?.eventLocationChange;
            const updatedData = buildInitialValues(updatedSite);
            await actions.resetForm({ values: updatedData });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin
              spinning={deleteStatus === 'pending' || editStatus === 'pending'}
            >
              <LocationChangeForm
                dirty={dirty}
                values={buildInitialValues(eventLocationChange)}
                defaultLocation={buildInitialValues(eventLocationChange)}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                handleDeleteBait={handleDeleteBait}
                isFromEvent={isFromEvent}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default EditLocationChange;
