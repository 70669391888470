import React from 'react';
import {
  AccountManagement,
  AddAccount,
  AddBait,
  AddBaitSensor,
  AddBatch,
  AddDetectionSensor,
  AddEvent,
  AddEventBatteryReplace,
  AddEventTrapClean,
  AddLocationChange,
  AddNotification,
  AddRole,
  AddSite,
  AddTrap,
  AddTrapSensor,
  AssignBaitSensorLocation,
  AssignDetectionSensorLocation,
  AssignTrapSensorLocation,
  BaitManagement,
  BaitSensorDeviceList,
  BaitSensorHistory,
  BatchManagement,
  ChangePassword,
  Dashboard,
  DetectionSensorDeviceList,
  DetectionSensorHistory,
  DetectionSensorReport,
  DetectionSensorReportPdf,
  DeviceManagement,
  EditAccount,
  EditBait,
  EditBaitSensor,
  EditBatch,
  EditDetectionSensor,
  EditEvent,
  EditEventBatteryReplace,
  EditEventTrapClean,
  EditLocationChange,
  EditProfile,
  EditRole,
  EditSite,
  EditTrap,
  EditTrapSensor,
  Events,
  EventsList,
  ExchangeDevice,
  FloorManagement,
  FloorPlan,
  FloorPlanHeatmap,
  Home,
  InputData,
  ListAccounts,
  ListBatch,
  ListEventTrapClean,
  ListEventTrapManagement,
  ListLocationChange,
  ListRoles,
  ListTrap,
  LocationChangeManagement,
  Login,
  Maintenance,
  NotificationDetails,
  NotificationList,
  Notifications,
  Report,
  RodentBaitSensor,
  RodentDetectionSensor,
  RodentTrapSensor,
  RoleManagement,
  Settings,
  SiteManagement,
  TrapManagement,
  TrapSensorDeviceList,
  TrapSensorHistory,
  TrapSensorReport,
  TrapSensorReportPdf,
  ViewProfile,
  ViewReport,
} from '../routes';
import { ProtectedLayout } from '../components';
import { Route, Routes } from 'react-router-dom';
import { UserProvider } from '../context/session';

export const AppRouter = () => {
  return (
    <UserProvider>
      <Routes>
        <Route path='/' element={<ProtectedLayout />}>
          <Route index element={<Home />} />
          <Route exact path='profile' element={<ViewProfile />} />
          <Route exact path='profile/edit' element={<EditProfile />} />
          <Route exact path='change-password' element={<ChangePassword />} />
          <Route exact path='site-management'>
            <Route exact index element={<SiteManagement />} />
            <Route path='add' element={<AddSite />} />
            <Route path=':id/edit' element={<EditSite />} />
            <Route
              path=':id/floor-management/:floorId'
              element={<FloorManagement />}
            />
            <Route
              path='assign-trap-sensor-location'
              element={<AssignTrapSensorLocation />}
            />
            <Route
              path='assign-detection-sensor-location'
              element={<AssignDetectionSensorLocation />}
            />
            <Route
              path='assign-bait-sensor-location'
              element={<AssignBaitSensorLocation />}
            />
          </Route>

          <Route
            exact
            path='account-management'
            element={<AccountManagement />}
          >
            <Route exact index element={<ListAccounts />} />
            <Route exact path='add' element={<AddAccount />} />
            <Route path=':id/edit' element={<EditAccount />} />
            <Route path=':id/change-password' element={<ChangePassword />} />
          </Route>
          <Route exact path='notifications' element={<Notifications />}>
            <Route exact index element={<NotificationList />} />
            <Route path='add' element={<AddNotification />} />
            <Route path=':id/view' element={<NotificationDetails />} />
          </Route>
          <Route exact path='reports' element={<Report />}>
            <Route exact index element={<ViewReport />} />
          </Route>
          <Route index exact element={<Maintenance />} path='maintenance' />
          <Route
            exact
            path='detection-sensor'
            element={<RodentDetectionSensor />}
          >
            {/* <Route exact index element={<Dashboard />} /> */}
            <Route
              exact
              index
              path='list'
              element={<DetectionSensorDeviceList />}
            />
            <Route exact path='add' element={<AddDetectionSensor />} />
            <Route path=':id/edit' element={<EditDetectionSensor />} />
            <Route exact path='floor-plan' element={<FloorPlan />} />
            <Route exact path='report' element={<DetectionSensorReport />} />
            <Route
              exact
              path='report/pdf'
              element={<DetectionSensorReportPdf />}
            />
            <Route
              exact
              path=':sensorId/history'
              element={<DetectionSensorHistory />}
            />
          </Route>

          <Route exact path='bait-sensor' element={<RodentBaitSensor />}>
            {/* <Route exact index element={<Dashboard />} /> */}
            <Route exact index path='list' element={<BaitSensorDeviceList />} />
            <Route exact path='add' element={<AddBaitSensor />} />
            <Route path=':id/edit' element={<EditBaitSensor />} />
            <Route exact path='floor-plan' element={<FloorPlan />} />
            <Route exact path='report' element={<DetectionSensorReport />} />
            <Route
              exact
              path='report/pdf'
              element={<DetectionSensorReportPdf />}
            />
            <Route
              exact
              path=':sensorId/history'
              element={<BaitSensorHistory />}
            />
          </Route>

          <Route exact path='trap-sensor' element={<RodentTrapSensor />}>
            {/* <Route exact index element={<TrapSensorDashboard />} /> */}
            <Route exact index path='list' element={<TrapSensorDeviceList />} />
            <Route exact path='add' element={<AddTrapSensor />} />
            <Route path=':id/edit' element={<EditTrapSensor />} />
            <Route exact path='exchange/:id' element={<ExchangeDevice />} />
            <Route exact path='input-data' element={<InputData />} />
            <Route
              exact
              path=':sensorId/history'
              element={<TrapSensorHistory />}
            />
            <Route exact path='report' element={<TrapSensorReport />} />
            <Route exact path='report/pdf' element={<TrapSensorReportPdf />} />
          </Route>
          <Route exact path='role-management' element={<RoleManagement />}>
            <Route exact index element={<ListRoles />} />
            <Route exact path='add' element={<AddRole />} />
            <Route path=':id/:name/edit' element={<EditRole />} />
          </Route>
          <Route exact path='device-management' element={<DeviceManagement />}>
            <Route exact index element={<BaitManagement />} />
            <Route exact path='add' element={<AddBait />} />
            <Route exact path=':id/edit' element={<EditBait />} />
          </Route>
          <Route exact path='batch-management' element={<BatchManagement />}>
            <Route exact index element={<ListBatch />} />
            <Route exact path='add' element={<AddBatch />} />
            <Route exact path=':id/edit' element={<EditBatch />} />
          </Route>
          <Route
            exact
            path='location-change-management'
            element={<LocationChangeManagement />}
          >
            <Route exact index element={<ListLocationChange />} />
            <Route exact path='add' element={<AddLocationChange />} />
            <Route exact path=':id/edit' element={<EditLocationChange />} />
          </Route>
          <Route
            exact
            path='event-trap-clean-management'
            element={<ListEventTrapManagement />}
          >
            <Route exact index element={<ListEventTrapClean />} />
            <Route exact path='add' element={<AddEventTrapClean />} />
            <Route exact path=':id/edit' element={<EditEventTrapClean />} />
          </Route>
          <Route exact path='trap-management' element={<TrapManagement />}>
            <Route exact index element={<ListTrap />} />
            <Route exact path='add' element={<AddTrap />} />
            <Route exact path=':id/edit' element={<EditTrap />} />
          </Route>
          <Route path='/settings' element={<Settings />} />
          <Route path='/floor-plan' element={<FloorPlanHeatmap />} />
          <Route exact path='events' element={<Events />}>
            <Route exact index element={<EventsList />} />
            <Route exact path='add' element={<AddEvent />} />
            <Route exact path=':id/edit' element={<EditEvent />} />
            <Route
              exact
              path='battery-replacement/add'
              element={<AddEventBatteryReplace />}
            />
            <Route
              exact
              path='battery-replacement/:id/edit'
              element={<EditEventBatteryReplace />}
            />
          </Route>
          <Route exact path='dashboard' element={<Dashboard />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<Login />} />
      </Routes>
    </UserProvider>
  );
};
