import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODES } from '../../../utilities/constants';
import { Button, Space, Tooltip } from 'antd';
import {
  Breadcrumbs,
  CustomTable,
  PageError,
  PageHeader,
} from '../../../components';
import { EditOutlined } from '@ant-design/icons';
import { useFetchRoles } from '../../../services/roles';
import { searchTableFields, sorter } from '../../../utilities/transform';
import i18next from 'i18next';
import {
  FORM_STORAGE_KEYS,
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../../utilities/storage';

const buildTableColumns = () => {
  return [
    {
      title: i18next.t('role'),
      dataIndex: 'name',
      key: 'role',
      sorter: (a, b) => sorter(a.name, b.name),
      isSearchable: true,
    },
    {
      title: i18next.t('site'),
      dataIndex:
        i18next.language === LANGUAGE_CODES.ENGLISH ? 'siteName' : 'siteNameZh',
      key: 'siteName',
      sorter: (a, b) => sorter(a.siteName, b.siteName),
      isSearchable: true,
    },
    {
      title: i18next.t('action'),
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title='move to edit'>
            <Link to={`${record.id}/${record.name}/edit`}>
              <Button icon={<EditOutlined />} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];
};

export const ListRoles = () => {
  const { t } = useTranslation();
  const { search: savedSearch } = getSessionStorageItem(
    FORM_STORAGE_KEYS.ROLE_LIST
  );
  const [search, setSearch] = useState(savedSearch || '');
  const { data: { roles: data } = {}, isLoading, error } = useFetchRoles({});

  const columns = useMemo(() => {
    return buildTableColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const onChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const filteredData = useMemo(() => {
    return searchTableFields(search, columns, data);
  }, [search, data, columns]);
  useEffect(() => {
    return () => {
      setSessionStorageItem(FORM_STORAGE_KEYS.ROLE_LIST, { search });
    };
  }, [search]);
  if (error) {
    return (
      <div className='p-10'>
        <PageHeader title={t('roleManagement')} />
        <PageError description='Error is found' />
      </div>
    );
  }

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('roleManagement'),
            link: '/role-management',
          },
        ]}
      />
      <PageHeader
        onChange={onChange}
        title={t('roleManagement')}
        isSearch
        isCreate
        to='add'
        search={search}
      />
      <CustomTable
        columns={columns}
        dataSource={filteredData}
        isLoading={isLoading}
        pagination={{
          locale: { items_per_page: `/ ${t('page')}` },
          showLessItems: true,
          showSizeChanger: true,
          showTotal: (total) => (
            <div className='table-pagination'>
              {t('total')}: {total}
            </div>
          ),
        }}
      />
    </div>
  );
};
export default ListRoles;
