import React from 'react';
import { Formik } from 'formik';
import { AssignSensorLocationForm } from './assign-sensor-location-form';
import { Breadcrumbs, PageHeader } from '../../../components';
import { useTranslation } from 'react-i18next';
import { FilterFloorPlanSchema } from './schema';
import { SENSOR_PAGE_KEYS } from '../../../utilities/constants';
import { FORM_STORAGE_KEYS } from '../../../utilities/storage';
import { useNavigate } from 'react-router-dom';

export const AssignSensorLocationPage = ({ routeKey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formKey = FORM_STORAGE_KEYS.ASSIGN_SENSOR;

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name:
              routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR
                ? t('assignDetectionSensorLocation')
                : routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
                ? t('assignTrapSensor')
                : routeKey === SENSOR_PAGE_KEYS.BAIT_SENSOR
                ? t('assignBaitSensor')
                : '',
            link:
              routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR
                ? '/site-management/assign-detection-sensor-location'
                : routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
                ? '/site-management/assign-trap-sensor-location'
                : routeKey === SENSOR_PAGE_KEYS.BAIT_SENSOR
                ? '/site-management/assign-bait-sensor-location'
                : '',
          },
        ]}
      />
      <PageHeader
        title={t('setup')}
        sensorType={routeKey}
        sensorTypeOptionsList={[
          {
            labelKey: 'trapSensor',
            value: SENSOR_PAGE_KEYS.TRAP_SENSOR,
          },
          {
            labelKey: 'detectionSensor',
            value: SENSOR_PAGE_KEYS.DETECTION_SENSOR,
          },
          {
            labelKey: 'bait',
            value: SENSOR_PAGE_KEYS.BAIT_SENSOR,
          },
        ]}
        handleSensorType={(value) => {
          if (value === SENSOR_PAGE_KEYS.TRAP_SENSOR) {
            navigate('/site-management/assign-trap-sensor-location');
          } else if (value === SENSOR_PAGE_KEYS.DETECTION_SENSOR) {
            navigate('/site-management/assign-detection-sensor-location');
          } else if (value === SENSOR_PAGE_KEYS.BAIT_SENSOR) {
            navigate('/site-management/assign-bait-sensor-location');
          }
        }}
      />

      <Formik
        initialValues={{
          siteId: null,
          floorId: null,
          layerId: null,
          zoneId: [],
          sensors: [],
        }}
        validationSchema={FilterFloorPlanSchema}
        onSubmit={() => {}}
      >
        <AssignSensorLocationForm routeKey={routeKey} formKey={formKey} />
      </Formik>
    </div>
  );
};

export default AssignSensorLocationPage;
