import { useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';
import { useUserProvider } from '../context/session';
import { parseJson } from '../utilities/transform';

const USERS_CACHE_KEY = 'users';
const CURRENT_USER_CACHE_KEY = 'current-user';
const ALL = 'all';
const fetchUsers = (api, filters) => async () => {
  const { data } = await api.fetchUsers(filters);
  return {
    users: data?.data?.users || [],
    totalCount: data?.data?.totalCount || 0,
  };
};

export const useFetchUsers = (filters) => {
  const api = useApi();
  const response = useSWR(
    `${USERS_CACHE_KEY}/${filters.userId || ALL}`,
    fetchUsers(api, filters)
  );
  return withLoading(response);
};

const fetchCurrentUser = (api, filters) => async () => {
  const { data } = await api.fetchUsers(filters);
  const user = data?.data?.users?.[0];
  user.overviewData = parseJson(user.overviewData);
  return { user };
};

export const useFetchCurrentUser = () => {
  const [user] = useUserProvider();
  const currentUserId = user?.user.id;
  const api = useApi();
  const response = useSWR(
    `${CURRENT_USER_CACHE_KEY}/${currentUserId}`,
    fetchCurrentUser(api, { userId: currentUserId })
  );
  return withLoading(response);
};

const addUser = (api) => async (body) => {
  return await api.addUser(body);
};

export const useAddUser = () => {
  const api = useApi();
  const mutate = useMatchMutate(USERS_CACHE_KEY);
  return useMutation(addUser(api), {
    isShowErrorMessage: true,
    async onSuccess() {
      await mutate();
    },
  });
};

const editUser = (api) => async (body) => {
  return await api.editUser(body);
};

export const useEditUser = () => {
  const api = useApi();
  const mutate = useMatchMutate(USERS_CACHE_KEY);
  return useMutation(editUser(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteUser =
  (api) =>
  async ({ userId }) => {
    return await api.deleteUser({ userId });
  };

export const useDeleteUser = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${USERS_CACHE_KEY}/${ALL}`);
  return useMutation(deleteUser(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const editPassword = (api) => async (body) => {
  return await api.editPassword(body);
};

export const useEditPassword = () => {
  const api = useApi();
  const mutate = useMatchMutate(USERS_CACHE_KEY);
  return useMutation(editPassword(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
