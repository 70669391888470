import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import Button from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const getFooterActions = (onConfirm, confirmText, onCancel, cancelText) => {
  const actions = [];
  if (onConfirm)
    actions.push(
      <Button key='confirm' type='primary' onClick={onConfirm}>
        {i18next.t(confirmText)}
      </Button>
    );

  if (onCancel)
    actions.push(
      <Button key='cancel' onClick={onCancel}>
        {i18next.t(cancelText)}
      </Button>
    );

  return actions;
};
const CustomModal = (props) => {
  const { t } = useTranslation();
  const {
    title,
    isVisible,
    onConfirm,
    confirmText = 'confirm',
    onCancel,
    cancelText = 'cancel',
  } = props;

  const footerActions = getFooterActions(
    onConfirm,
    confirmText,
    onCancel,
    cancelText
  );
  return (
    <Modal
      centered={true}
      title={t(title)}
      visible={isVisible}
      onOk={onConfirm}
      okText={confirmText}
      onCancel={onCancel}
      cancelText={cancelText}
      footer={footerActions}
    >
      {props.children}
    </Modal>
  );
};

export default CustomModal;
