import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../components';
import { FilterForm } from './filter-form';
import { Formik } from 'formik';
import moment from 'moment';
import { SENSOR_PAGE_KEYS } from '../../../utilities/constants';
import { useNavigate } from 'react-router-dom';

const INITIAL_VALUES = {
  startTime: moment().startOf('day').subtract(1, 'month'),
  endTime: moment().endOf('day'),
};

export const DetectionSensorReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSensorType = (routeKey) => {
    navigate(
      routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
        ? '/trap-sensor/report'
        : '/detection-sensor/report'
    );
  };
  return (
    <div className='p-10'>
      <PageHeader
        title={t('report')}
        sensorType={SENSOR_PAGE_KEYS.DETECTION_SENSOR}
        handleSensorType={handleSensorType}
      />
      <Formik initialValues={INITIAL_VALUES} onSubmit={() => {}}>
        <FilterForm />
      </Formik>
    </div>
  );
};

export default DetectionSensorReport;
