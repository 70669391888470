import * as React from 'react';

export const MissedTrapSvg = (props) => (
  <svg
    width={30}
    height={30}
    viewBox='0 0 50 50'
    fill='#fff'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M5.86 35.151h2.93v2.93H5.86v-2.93Z' />
    <path d='m45.605 28.685-2.93 2.93-2.929-2.93-2.93 2.93-2.93-2.93-2.929 2.93-2.93-2.93-2.93 2.93-2.929-2.93-3.536 3.536h-5.448a7.398 7.398 0 0 0-1.465-1.465v-3.634h4.04l3.538 3.537 2.93-2.93 2.93 2.93 2.929-2.93 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 2.93 2.93 4.394-4.395v-7.93h-44.2v12.422A7.325 7.325 0 0 0 0 36.598V36.767a7.332 7.332 0 0 0 7.173 7.173H7.342a7.324 7.324 0 0 0 5.842-2.93H50v-7.93l-4.395-4.395ZM5.86 21.263H44.2v3.788l-1.465 1.465-2.93-2.93-2.929 2.93-2.93-2.93-2.93 2.93-2.93-2.93-2.929 2.93-2.93-2.93-2.93 2.93-2.322-2.323H8.789v5.246a7.35 7.35 0 0 0-2.93 0v-8.176ZM7.324 41.01a4.394 4.394 0 1 1 0-8.789 4.394 4.394 0 0 1 0 8.79Zm39.746-2.93H14.501a7.35 7.35 0 0 0 0-2.93h5.344l2.323-2.322 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 1.464 1.464v3.788Z' />
  </svg>
);

export default MissedTrapSvg;
