export const hitTrendChartData = [
  {
    name: '2021-01-21',
    dataPoint: 4000,
  },
  {
    name: '2021-01-22',
    dataPoint: 3000,
  },
  {
    name: '2021-01-23',
    dataPoint: 2000,
  },
  {
    name: '2021-01-24',
    dataPoint: 2780,
  },
  {
    name: '2021-01-25',
    dataPoint: 1890,
  },
  {
    name: '2021-01-26',
    dataPoint: 2390,
  },
  {
    name: '2021-01-27',
    dataPoint: 3490,
  },
];
export const FLOORS = [
  {
    key: 0,
    label: 'floor1',
    value: 1,
  },
  {
    key: 1,
    label: 'floor2',
    value: 2,
  },
];
export const LAYERS = [
  {
    key: 0,
    label: 'layer1',
    value: 1,
  },
  {
    key: 1,
    label: 'layer2',
    value: 2,
  },
];
export const ZONES = [
  {
    key: 0,
    label: 'zone1',
    value: 1,
  },
  {
    key: 1,
    label: 'zone2',
    value: 2,
  },
];

export const REPORT = [
  {
    action: 'Sensor Signal',
    deviceName: 'FD_6f_D3001',
    status: 'Connected',
    date: '2021-01-27',
    id: 0,
    environment: 'Production',
    key: '827279395684',
  },
  {
    action: 'Sensor Signal',
    deviceName: 'FD_6f_D3001',
    status: 'Disconnected',
    date: '2021-01-27',
    id: 0,
    environment: 'Production',
    key: '356995005447',
  },
  {
    action: 'Sensor Signal',
    deviceName: 'FD_6f_D3002',
    status: 'Connected',
    date: '2021-01-27',
    id: 0,
    environment: 'Production',
    key: '827270395684',
  },
  {
    action: 'Sensor Signal',
    deviceName: 'FD_6f_D3003',
    status: 'Connected',
    date: '2021-01-27',
    id: 0,
    environment: 'Production',
    key: '821279395684',
  },
  {
    action: 'Sensor Signal',
    deviceName: 'FD_6f_D3004',
    status: 'Connected',
    date: '2021-01-27',
    id: 0,
    environment: 'Production',
    key: '829279395684',
  },
  {
    action: 'Sensor Signal',
    deviceName: 'FD_6f_D3005',
    status: 'Connected',
    date: '2021-01-27',
    id: 0,
    environment: 'Production',
    key: '827219395684',
  },
];
