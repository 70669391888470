import React, { useMemo, useState } from 'react';
import { buildTableColumns } from './transform';
import { CustomTable } from '../../../../components';
import { useFetchBaitSensorData } from '../../../../services/sensors';
import { DEFAULT_PAGE_SIZE } from '../../../../utilities/constants';
import { useTranslation } from 'react-i18next';

export const BaitSensorFirstTab = ({ routeKey, sensorId }) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading } = useFetchBaitSensorData({
    sensorId,
    startTime: null,
    endTime: null,
    count: DEFAULT_PAGE_SIZE,
    types: ['high'],
    pageNumber,
  });

  const { sensorData } = data || {};
  const columns = useMemo(() => buildTableColumns(routeKey), [routeKey]);
  return (
    <CustomTable
      isLoading={isLoading}
      columns={columns}
      dataSource={sensorData}
      pagination={{
        locale: { items_per_page: `/ ${t('page')}` },
        showLessItems: true,
        showSizeChanger: true,
        showTotal: (total) => (
          <div className='table-pagination'>
            {t('total')}: {total}
          </div>
        ),
      }}
    />
  );
};
