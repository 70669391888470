import classes from './index.module.css';
import React from 'react';
import { Breadcrumbs, Loader, PageError, SiteForm } from '../../../components';
import { Formik } from 'formik';
import { EditSiteInformationSchema } from '../../../utilities/schemas';
import {
  useDeleteSite,
  useEditSite,
  useFetchSites,
  useTestSms,
} from '../../../services/sites';
import { notification, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

const buildInitialValues = (site) => {
  return {
    siteKey: site?.siteKey,
    name: site?.name,
    nameZh: site?.nameZh,
    address: site?.address,
    contactEmail: site?.contactEmail,
    contactPerson: site?.contactPerson,
    contactPhone: site?.contactPhone,
    remark: site?.remark,
    floors: site?.floors || [],
    layers: site?.layers || [],
    zones: site?.zones || [],
    triggerMedium: site?.triggerMedium,
    emailList: site?.emailList,
    smsAlertList: site?.smsAlertList,
    emailAlertList: site?.emailAlertList,
    accountAlertList: site?.accountAlertList,
  };
};

export const EditSite = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, data: { sites: data } = {} } = useFetchSites([id]);
  const { mutate: editSite } = useEditSite();
  const { mutate: testSms } = useTestSms();
  const { mutate, status: deletePending } = useDeleteSite();
  if (!data && isLoading) {
    return <Loader />;
  }

  const site = data[0];

  if (isEmpty(site)) {
    return (
      <div className={classes.content}>
        <PageError description='Error not found' />
      </div>
    );
  }
  const initialValues = buildInitialValues(site);
  const handleDelete = async () => {
    try {
      await mutate({ siteId: id });
      navigate('/site-management');
    } catch (e) {
    } finally {
    }
  };

  const testSms1 = async (values) => {
    const { data } = await testSms({ phoneNos: values.smsAlertList });
    if (data.code === '1') {
      const result = data.data.result;
      var resultstr = '';
      for (const r of result) {
        if (r.code === '1') {
          resultstr += r.phone + ' success, ';
        } else {
          resultstr += r.phone + ' failed, ';
        }
      }
      notification.success({ message: <div>{resultstr}</div> });
    } else {
      notification.error({ message: data.error });
    }
  };

  return (
    <div className={classes.content}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('siteManagement'),
            link: '/site-management',
          },
          {
            name: t('edit'),
            link: `/site-management/${id}/edit`,
          },
        ]}
      />
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        validationSchema={EditSiteInformationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true);
            const castValues = EditSiteInformationSchema.cast(values);
            const payload = { ...castValues, id };
            const { data } = await editSite(payload);
            const updatedSite = data?.data?.site;
            const updatedData = buildInitialValues(updatedSite);
            await actions.resetForm({ values: updatedData });
            notification.success({ message: t('editedSuccess') });
          } catch ({ message }) {
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, values, submitCount, resetForm, isSubmitting }) => {
          return (
            <Spin spinning={isSubmitting || deletePending === 'pending'}>
              <SiteForm
                siteId={id}
                handleSubmit={handleSubmit}
                values={values}
                submitCount={submitCount}
                resetForm={resetForm}
                handleDelete={handleDelete}
                testSms={() => testSms1(values)}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditSite;
