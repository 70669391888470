import React from 'react';
import AssignSensorLocationPage from '../../../pages/sensor/sensor-location-page';
import { SENSOR_PAGE_KEYS } from '../../../utilities/constants';

export const AssignDetectionSensorLocation = () => {
  return (
    <AssignSensorLocationPage routeKey={SENSOR_PAGE_KEYS.DETECTION_SENSOR} />
  );
};

export default AssignDetectionSensorLocation;
