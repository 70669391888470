import './index.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal, notification, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { buildTableColumns } from './transform';
import { useFetchBaits } from '../../../services/baits';
import { EnvironmentFilled } from '@ant-design/icons';
import {
  BatchInfo,
  Breadcrumbs,
  CustomInfo,
  Loader,
  PageError,
  PageHeader,
  ZoomAbleFloorPlan,
} from '../../../components';
import {
  useAcknowledgeNotification,
  useFetchNotificationDetails,
} from '../../../services/notifications';
import { isEmpty } from 'lodash';
import { getFloorImageUrl } from '../../../utilities/transform';
import { BatteryReplace } from './components/battery-replace';
import { EVENT_TYPES } from '../../../utilities/constants';

const PageBody = ({ isModalVisible, handleOk, handleCancel }) => {
  const { id } = useParams();
  const { data: { baits, totalCount } = {} } = useFetchBaits({});
  const { t } = useTranslation();
  const { mutate } = useAcknowledgeNotification({ notificationId: id });
  const { data, isLoading, error } = useFetchNotificationDetails({
    notificationId: parseInt(id),
  });
  const {
    eventBatteryChange,
    notification: notificationData,
    sensor,
    dataInput,
    exchangeHistory,
    batch,
    sensors,
  } = data || {};
  if (error || (!notificationData && !isLoading)) {
    return <PageError description='Error is found' />;
  }

  const handleAcknowledge = async (notificationId) => {
    try {
      await mutate({ notificationId });
      notification.success({ message: t('ackDone') });
    } catch ({ message }) {
      notification.error({ message });
    }
  };
  const cols = buildTableColumns(handleAcknowledge);
  const tableData = {
    sensor: sensor,
    sensors: sensors,
    notificationData: notificationData,
    baits: baits,
    dataInput: dataInput,
    exchangeHistory: exchangeHistory,
    batch: batch,
  };

  if (isLoading && isEmpty(sensor)) {
    return <Loader />;
  }
  const getHeaderTitle = () => {
    if (notificationData?.eventType === EVENT_TYPES.BAIT_REPLACE) {
      return t('batchManagement');
    } else if (notificationData?.eventType === EVENT_TYPES.BATTERY_REPLACE) {
      return t('batteryReplacement');
    } else if (sensor?.typeName === 'Rodent Trap Sensor') {
      return t('rodentTrapSensor');
    } else {
      return t('rodentDetectionSensor');
    }
  };
  const renderDetails = () => {
    if (batch) {
      return <BatchInfo rowKey='id' dataSource={tableData} columns={cols} />;
    } else if (eventBatteryChange) {
      return (
        <BatteryReplace
          dataSource={{ eventBatteryChange, sensors, notificationData }}
        />
      );
    } else {
      return (
        <CustomInfo
          rowKey='id'
          dataSource={tableData}
          columns={cols}
          notificationId={parseInt(id)}
        />
      );
    }
  };
  return (
    <Spin spinning={isLoading}>
      <Row className='table-header'>
        <Col span={24}>{getHeaderTitle()}</Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
          {renderDetails()}
        </Col>
        <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 18 }}>
          <ZoomAbleFloorPlan
            sensors={batch || eventBatteryChange ? sensors : [sensor]}
            floorPlan={getFloorImageUrl(
              batch || eventBatteryChange
                ? sensors.length > 0
                  ? sensors[0].floorPath
                  : ''
                : sensor?.floorPath
            )}
            pincolor={
              notificationData?.eventType === EVENT_TYPES.BAIT_REPLACE
                ? '#EA232B'
                : '#EA232B'
            }
            offset={1000}
          />
        </Col>
      </Row>
      <Modal
        width={'100%'}
        title=''
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ZoomAbleFloorPlan
          sensors={batch || eventBatteryChange ? sensors : [sensor]}
          floorPlan={getFloorImageUrl(
            batch || eventBatteryChange
              ? sensors.length > 0
                ? sensors[0].floorPath
                : ''
              : sensor?.floorPath
          )}
          pincolor={
            notificationData?.eventType === EVENT_TYPES.BAIT_REPLACE
              ? '#EA232B'
              : '#EA232B'
          }
        />
      </Modal>
    </Spin>
  );
};

export const NotificationDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('notification'),
            link: '/notifications',
            state: { fromNotificationDetails: true },
          },
          {
            name: t('detail'),
            link: `/notifications/${id}/view`,
          },
        ]}
      />
      <Row>
        <Col xs={{ span: 22 }} md={{ span: 22 }} lg={{ span: 24 }}>
          <PageHeader title={t('detail')} />
        </Col>
        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 0 }} style={{}}>
          <EnvironmentFilled
            style={{ fontSize: '36px', color: '#EA232B' }}
            onClick={showModal}
          />
        </Col>
      </Row>
      <div>
        <PageBody
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  );
};

export default NotificationDetails;
