import { Button, Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import React from 'react';
import i18next from 'i18next';
import { formatTime } from '../../../utilities/time-utils';

export const getTableData = (sensor, notification) => {
  return [
    {
      id: notification?.id,
      deviceName: sensor?.name,
      zone: sensor?.zoneName,
      layer: sensor?.layerName,
      event: notification?.sensorType,
      time: notification?.createTime,
      acknowledge: !!notification?.ackBy,
    },
  ];
};
export const buildTableColumns = (handleAcknowledge) => {
  return [
    {
      title: i18next.t('deviceName'),
      dataIndex: 'deviceName',
      key: 'deviceName',
    },
    {
      title: i18next.t('islandGroup'),
      dataIndex: 'zone',
      key: 'zone',
    },
    {
      title: i18next.t('layerUppercase'),
      dataIndex: 'layer',
      key: 'layer',
    },
    {
      title: i18next.t('event'),
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: i18next.t('time'),
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => <>{formatTime(record?.time)}</>,
    },
    {
      title: i18next.t('duration'),
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: i18next.t('detailUpperCase'),
      dataIndex: 'detail',
      key: 'detail',
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title='move to detail'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to='#'>
              <Button icon={<ArrowRightOutlined />} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: i18next.t('acknowledge'),
      dataIndex: 'acknowledge',
      key: 'acknowledge',
      render: (text, record) => (
        <Space size='middle'>
          {record?.acknowledge ? (
            <CheckOutlined style={{ color: 'green' }} />
          ) : (
            <Button onClick={() => handleAcknowledge(record?.id)}>
              {i18next.t('ackNow')}
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
