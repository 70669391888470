import i18next from 'i18next';
import * as _ from 'lodash';
import { sorter } from '../../../../utilities/transform';

export const buildTableColumns = () => {
  return [
    {
      title: i18next.t('sensorID'),
      dataIndex: 'sensorId',
      key: 'sensorId',
      sorter: (a, b) => sorter(a.sensorId, b.sensorId),
    },
    {
      title: i18next.t('mpID'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sorter(a.name, b.name),
    },
    {
      title: i18next.t('hits'),
      dataIndex: 'ratHit',
      key: 'ratHit',
      sorter: (a, b) => {
        return sorter(a.ratHit, b.ratHit);
      },
    },
  ];
};
const COLORS = [
  '#FCE8E8',
  '#F8BCBC',
  '#F38F8F',
  '#EE6363',
  '#F7FCE8',
  '#E4F6AD',
  '#D0EF72',
  '#BCE937',
  '#E5FFF7',
  '#86FFDB',
  '#27FFBF',
  '#00C78C',
  '#EFF5F5',
  '#A4C9C9',
  '#5C9A9A',
  '#2F4F4F',
  '#ECF5F8',
  '#B8DBE6',
  '#84C0D4',
  '#50A6C2',
  '#E5F9FF',
  '#93E4FF',
  '#41CFFF',
  '#00B2EE',
  '#EBEDFA',
  '#C8CDF0',
  '#A6AEE7',
  '#838EDE',
  '#F5E5FF',
  '#E3B9FF',
  '#D18CFF',
  '#BF5FFF',
  '#FFE5F3',
  '#FFA0D3',
  '#FF5AB3',
  '#FF1493',
  '#FBE9E9',
  '#F3B6B7',
  '#EB8484',
  '#E35152',
];
export const getSensorColor = (eventCount, sensorLayerId, groups) => {
  const sensorGroup = groups
    .filter(
      (group) => eventCount >= group.minHits && eventCount <= group.maxHits
    )
    .pop();
  return sensorGroup.color;
};
export const getSensorData = (sensors) => {
  return sensors?.map((sensor) => {
    if (sensor?.sigfoxSensorData?.length === 0) {
      return {
        name: sensor?.name,
        sensorId: sensor?.sensorId,
        layerId: sensor?.layerId,
        latitude: parseInt(sensor?.latitude),
        longitude: parseInt(sensor?.longitude),
        ratHit: 0,
      };
    } else {
      const grouped = _.groupBy(sensor?.sigfoxSensorData, 'type');
      const item = {
        name: sensor?.name,
        sensorId: sensor?.sensorId,
        layerId: sensor?.layerId,
        latitude: parseInt(sensor?.latitude),
        longitude: parseInt(sensor?.longitude),
      };
      Object.keys(grouped).forEach((key) => {
        item[key] = grouped[key].length;
      });
      item.ratHit = item?.ratHit || 0;
      return item;
    }
  });
};

export const getMatchedLayers = (ids, layers) => {
  let final = [];
  ids?.forEach((id) => {
    const match = _.filter(layers, ['id', id]);
    final.push({ id: match[0].id, name: match[0].name });
  });
  return final;
};
export const getColorGroups = () => {
  return [
    {
      color: COLORS[0],
      minHits: 0,
      maxHits: 4,
    },
    {
      color: COLORS[1],
      minHits: 5,
      maxHits: 9,
    },
    {
      color: COLORS[2],
      minHits: 10,
      maxHits: 14,
    },
    {
      color: COLORS[3],
      minHits: 15,
      maxHits: 90000000,
    },
  ];
};
