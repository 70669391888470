import React from 'react';
import { notification, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  Breadcrumbs,
  EditProfileForm,
  Loader,
  PageError,
} from '../../../components';
import { Formik } from 'formik';
import { EditProfileInformationSchema } from '../../../utilities/schemas';
import { useUserProvider } from '../../../context/session';
import { useEditUser, useFetchUsers } from '../../../services/users';
import isEmpty from 'lodash/isEmpty';
import classes from '../view/index.module.css';

const { Title } = Typography;

const buildInitialValues = (user) => {
  return {
    roleId: user?.roleId,
    name: user?.name,
    title: user?.title,
    email: user?.email,
  };
};
const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title level={3}>{t('accountInformation')}</Title>
      <Bar />
    </>
  );
};

export const EditProfile = () => {
  const { t } = useTranslation();
  const [user] = useUserProvider();
  const { user: profile } = user || {};
  const { id: userId } = profile || {};
  const {
    data: { users: data } = {},
    isLoading,
    error,
  } = useFetchUsers({ userId });
  const { mutate: EditUser, status: editStatus } = useEditUser();
  if (error) {
    return (
      <div className={classes.content}>
        <Header />
        <PageError description='Error is found' />
      </div>
    );
  }
  if (!data && isLoading) {
    return <Loader />;
  }

  const userDetails = data[0];
  if (!isLoading && isEmpty(userDetails)) {
    return (
      <div style={{ padding: 10 }}>
        <PageError description='Data not found' />;
      </div>
    );
  }
  const initialValues = buildInitialValues(userDetails);
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('profile'),
            link: '/profile',
          },
          {
            name: t('edit'),
            link: '/profile/edit',
          },
        ]}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={EditProfileInformationSchema}
        enableReinitialize={false}
        onSubmit={async (values, actions) => {
          try {
            const castValues = EditProfileInformationSchema.cast(values);
            const { name, title } = castValues;
            const payload = {
              id: userId,
              title,
              name,
            };
            actions.setSubmitting(true);
            const { data } = await EditUser(payload);
            const updatedUser = data?.data?.user;
            const updatedData = buildInitialValues(updatedUser);
            await actions.resetForm({ values: updatedData });
            notification.success({ message: t('editedSuccess') });
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, submitCount, handleSubmit, resetForm }) => (
          <Spin spinning={editStatus === 'pending'}>
            <EditProfileForm
              handleSubmit={handleSubmit}
              values={values}
              submitCount={submitCount}
              resetForm={resetForm}
            />
          </Spin>
        )}
      </Formik>
    </div>
  );
};

export default EditProfile;
