import React, { useEffect, useMemo, useRef, useState } from 'react';
import h337 from 'heatmapjs';
import './index.css';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import { getAnimationData } from './transform';

function $(selector) {
  return document.querySelectorAll(selector);
}

function AnimationPlayer(options) {
  this.heatmap = options.heatmap;
  this.data = options.data;
  this.heatmapTrapCaught = options.heatmapTrapCaught;
  this.dataTrapCaught = options.dataTrapCaught;
  this.heatmapTrapMissed = options.heatmapTrapMissed;
  this.dataTrapMissed = options.dataTrapMissed;
  this.interval = null;
  this.animationSpeed = options.animationSpeed || 300;
  this.wrapperEl = options.wrapperEl;
  this.isPlaying = false;
  this.currentInterval = options.currentInterval;
  this.init();
}

AnimationPlayer.prototype = {
  init: function () {
    let dataLen = Math.max(
      this.data?.length,
      this.dataTrapCaught?.length,
      this.dataTrapMissed?.length
    );
    this.wrapperEl.innerHTML = '';
    let playButton = (this.playButton = document.createElement('button'));
    playButton.setAttribute('type', 'button');
    playButton.onclick = function (e) {
      if (this.isPlaying) {
        this.stop();
      } else {
        this.play();
      }
      this.isPlaying = !this.isPlaying;
    }.bind(this);
    playButton.innerText = 'play';

    this.wrapperEl.appendChild(playButton);

    let events = document.createElement('div');
    events.className = 'heatmap-timeline';
    events.innerHTML = '<div class="line"></div>';

    for (let i = 0; i < dataLen; i++) {
      let xOffset = (100 / (dataLen - 1)) * i;

      let ev = document.createElement('div');
      ev.className = 'time-point';
      ev.style.left = xOffset + '%';

      ev.onclick = function (i) {
        return function () {
          this.isPlaying = false;
          this.stop();
          this.setFrame(i);
        }.bind(this);
      }.bind(this)(i);

      events.appendChild(ev);
    }
    this.wrapperEl.appendChild(events);
    this.setFrame(0);
  },
  play: function () {
    let dataLen = Math.max(
      this.data?.length,
      this.dataTrapCaught?.length,
      this.dataTrapMissed?.length
    );

    if (dataLen > 0) {
      this.playButton.innerText = i18next.t('pause');
      this.interval = setInterval(
        function () {
          this.setFrame(++this.currentFrame); //% dataLen
        }.bind(this),
        this.animationSpeed
      );
    }
    const animationFrameTimestamp = document.getElementById('time');
    animationFrameTimestamp.style.display = 'block';
  },
  stop: function () {
    clearInterval(this.interval);
    this.playButton.innerText = i18next.t('play');
    const animationFrameTimestamp = document.getElementById('time');
    // animationFrameTimestamp.style.display = 'none';
  },
  setFrame: function (frame) {
    this.currentFrame = frame;
    let snapshot = this.data[frame % this.data.length];
    if (snapshot && this.heatmap) {
      this.setCurrentInterval(snapshot?.date);
      this.heatmap.setData(snapshot);
      let timePoints = $('.heatmap-timeline .time-point');
      for (let i = 0; i < timePoints.length; i++) {
        timePoints[i].classList.remove('active');
      }
      timePoints[frame % this.data.length]?.classList.add('active');
    }
    let snapshotTrapCaught =
      this.dataTrapCaught[frame % this.dataTrapCaught.length];
    if (snapshotTrapCaught && this.heatmapTrapCaught) {
      this.setCurrentInterval(snapshotTrapCaught?.date);
      this.heatmapTrapCaught.setData(snapshotTrapCaught);
      let timePoints = $('.heatmap-timeline .time-point');
      for (let i = 0; i < timePoints.length; i++) {
        timePoints[i].classList.remove('active');
      }
      timePoints[frame % this.dataTrapCaught.length]?.classList.add('active');
    }
    let snapshotTrapMissed =
      this.dataTrapMissed[frame % this.dataTrapMissed.length];
    if (snapshotTrapMissed && this.heatmapTrapMissed) {
      this.setCurrentInterval(snapshotTrapMissed?.date);
      this.heatmapTrapMissed.setData(snapshotTrapMissed);
      let timePoints = $('.heatmap-timeline .time-point');
      for (let i = 0; i < timePoints.length; i++) {
        timePoints[i].classList.remove('active');
      }
      timePoints[frame % this.dataTrapMissed.length]?.classList.add('active');
    }
  },
  setAnimationData: function (data) {
    this.isPlaying = false;
    this.stop();
    this.data = data;
    this.init();
  },
  setAnimationSpeed: function (speed) {
    this.isPlaying = false;
    this.stop();
    this.animationSpeed = speed;
  },
  setCurrentInterval: function (date) {
    this.currentInterval = date;
    const el = document.getElementById('time');
    el.innerText = moment
      .unix(this.currentInterval)
      .format('YYYY-MM-DD HH:mm a');
  },
};
export const Heatmap = ({
  data,
  floorPlan,
  floorPlanMeta,
  scaledFloorPlanImageWidth,
  scaledFloorPlanImageHeight,
}) => {
  const { t } = useTranslation();
  const ratHitHeatmap = useRef();
  const trapMissedHeatmap = useRef();
  const trapCaughtHeatmap = useRef();
  const [isRatHitHeatmap, setIsRatHitHeatmap] = useState(false);
  const [isTrapCaughtHeatmap, setIsTrapCaughtHeatmap] = useState(true);
  const [isTrapMissedHeatmap, setIsTrapMissedHeatmap] = useState(true);

  const {
    animationDataDetectionData,
    animationDataTrapCaught,
    animationDataTrapMissed,
  } = useMemo(() => {
    const animationDataDetectionData = getAnimationData(
      data.detectionData,
      isRatHitHeatmap,
      floorPlanMeta
    );
    const animationDataTrapCaught = getAnimationData(
      data.trapCaughtData,
      isTrapCaughtHeatmap,
      floorPlanMeta
    );
    const animationDataTrapMissed = getAnimationData(
      data.trapMissedData,
      isTrapMissedHeatmap,
      floorPlanMeta
    );
    return {
      animationDataDetectionData,
      animationDataTrapCaught,
      animationDataTrapMissed,
    };
  }, [
    data.detectionData,
    data.trapCaughtData,
    data.trapMissedData,
    isRatHitHeatmap,
    isTrapCaughtHeatmap,
    isTrapMissedHeatmap,
  ]);
  const updateRatHitHeatmap = () => {
    if (isRatHitHeatmap) {
      ratHitHeatmap.current = h337.create({
        container: document.querySelector('.heatmap'),
        gradient: { 0.5: 'rgb(0,0,255)', 1.0: 'rgb(0,0,255)' },
      });
    } else {
      ratHitHeatmap.current?._renderer.canvas.remove();
      ratHitHeatmap.current = null;
    }
  };
  const updateTrapMissedHeatmap = () => {
    if (isTrapMissedHeatmap && document.querySelector('.heatmap')) {
      trapMissedHeatmap.current = h337.create({
        container: document.querySelector('.heatmap'),
        gradient: { 0.5: 'rgb(255,255,0)', 1.0: 'rgb(255,255,0)' },
      });
    } else {
      trapMissedHeatmap.current?._renderer.canvas.remove();
      trapMissedHeatmap.current = null;
    }
  };
  const updateTrapCaughtHeatmap = () => {
    if (isTrapCaughtHeatmap && document.querySelector('.heatmap')) {
      trapCaughtHeatmap.current = h337.create({
        container: document.querySelector('.heatmap'),
        gradient: { 0.5: 'rgb(255,0,255)', 1.0: 'rgb(255,0,255)' },
      });
    } else {
      trapCaughtHeatmap.current?._renderer.canvas.remove();
      trapCaughtHeatmap.current = null;
    }
  };

  useEffect(() => {
    // Remove Rat Hit Heatmap
    ratHitHeatmap.current?._renderer.canvas.remove();
    ratHitHeatmap.current = null;

    // Remove Trap Missed Heatmap
    trapMissedHeatmap.current?._renderer.canvas.remove();
    trapMissedHeatmap.current = null;

    // Remove Trap Caught Heatmap
    trapCaughtHeatmap.current?._renderer.canvas.remove();
    trapCaughtHeatmap.current = null;

    updateRatHitHeatmap();
    updateTrapCaughtHeatmap();
    updateTrapMissedHeatmap();
    const player = new AnimationPlayer({
      heatmap: ratHitHeatmap.current,
      data: animationDataDetectionData,
      heatmapTrapCaught: trapCaughtHeatmap.current,
      dataTrapCaught: animationDataTrapCaught,
      heatmapTrapMissed: trapMissedHeatmap.current,
      dataTrapMissed: animationDataTrapMissed,
      wrapperEl: document.querySelector('.timeline-wrapper'),
      animationSpeed: 500,
      currentInterval: '',
    });

    const controlButtons = $('.trigger-refresh');
    for (let i = 0; i < controlButtons.length; i++) {
      controlButtons[i].onclick = function () {
        let fps = this.dataset.fps;
        player.setAnimationSpeed((1 / +fps) * 1000);
      };
    }
    return () => {
      player.stop();
    };
  }, [
    animationDataDetectionData,
    animationDataTrapCaught,
    animationDataTrapMissed,
  ]);
  const handleToggleRatHitHeatmap = (value) => {
    setIsRatHitHeatmap((value) => !value);
  };
  const handleToggleTrapMissedHeatmap = (value) => {
    setIsTrapMissedHeatmap((value) => !value);
  };
  const handleToggleTrapCaughtHeatmap = (value) => {
    setIsTrapCaughtHeatmap((value) => !value);
  };

  return (
    <>
      <div>
        <div className='heat-map-actions-row'>
          <Button id='b1' className='trigger-refresh' data-fps='1'>
            {t('1XSpeed')}
          </Button>
          <Button id='b5' className='trigger-refresh' data-fps='5'>
            {t('5XSpeed')}
          </Button>
          <Button id='b10' className='trigger-refresh' data-fps='10'>
            {t('10XSpeed')}
          </Button>
          <div>
            <div className='time-wrapper' id='time' />
          </div>
          <div />
          <div>
            <span
              className='rat-hit'
              style={{ display: 'inline-block', marginTop: '7px' }}
            >
              {t('ratHit')}
            </span>
            <Checkbox
              checked={isRatHitHeatmap}
              onChange={(e) => handleToggleRatHitHeatmap(e.target.checked)}
            />
          </div>
          <div>
            <span
              className='trap-caught'
              style={{ display: 'inline-block', marginTop: '7px' }}
            >
              {t('trapCaught')}
            </span>
            <Checkbox
              checked={isTrapCaughtHeatmap}
              onChange={(e) => handleToggleTrapCaughtHeatmap(e.target.checked)}
            />
          </div>
          <div>
            <span
              className='trap-missed'
              style={{ display: 'inline-block', marginTop: '7px' }}
            >
              {t('trapMissed')}
            </span>
            <Checkbox
              checked={isTrapMissedHeatmap}
              onChange={(e) => handleToggleTrapMissedHeatmap(e.target.checked)}
            />
          </div>
        </div>
        <div
          className='demo-wrapper'
          style={{
            height: scaledFloorPlanImageHeight + 30,
            overflowX: 'scroll',
          }}
        >
          <div className='heatmap'>
            <canvas
              style={{
                backgroundImage: `url(${floorPlan})`,
                backgroundSize: `${scaledFloorPlanImageWidth}px ${scaledFloorPlanImageHeight}px`,
                width: `${scaledFloorPlanImageWidth}px`,
                height: `${scaledFloorPlanImageHeight}px`,
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
          <div className='timeline-wrapper'>
            <Button>{t('play')}</Button>
            <div className='heatmap-timeline'>
              <div className='line' />
              <div className='time-point' style={{ left: '0%' }} />
              <div className='time-point' style={{ left: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heatmap;
