import { useApi } from './api';
import { useMutation } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { useUserProvider } from '../context/session';
import { useSWRConfig } from 'swr';

const logIn =
  (api) =>
  async ({ username, password }) => {
    return await api.signIn({ username, password });
  };

export const useLogin = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [, setUser] = useUserProvider();
  return useMutation(logIn(api), {
    onSuccess(response) {
      const { data } = response || {};
      const userinfo = data?.data;
      setUser(userinfo);

      if (userinfo.user.roleId !== 3) {
        navigate('/', { replace: true });
      } else {
        navigate('/notifications', { replace: true });
      }
    },
  });
};

export const useLogout = () => {
  const navigate = useNavigate();
  const { cache } = useSWRConfig();
  const [, setUser] = useUserProvider();
  return () => {
    setUser();
    navigate('/login', { replace: true });
    cache.clear();
  };
};
