import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useActiveSiteProvider } from '../context/site';

export const useSyncFormState = ({ callback = () => {} } = {}) => {
  const { setValues, values } = useFormikContext();
  const { activeSite: globalActiveSite } = useActiveSiteProvider();
  useEffect(() => {
    const initializeForm = async () => {
      const state = { ...values };
      if (state.siteId !== globalActiveSite.value) {
        state.siteId = globalActiveSite.value;
        state.floorId = null;
        state.layerId = null;
        state.zoneId = null;
        await setValues(state);
        callback();
      }
    };
    initializeForm();
    // eslint-disable-next-line
  }, [globalActiveSite]);
};
