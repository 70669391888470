import i18next from 'i18next';

export const EXPORT_BUTTON_HEADERS = [
  {
    label: i18next.t('id'),
    key: 'id',
  },
  {
    label: i18next.t('nibiotId'),
    key: 'nibiotId',
  },
  {
    label: i18next.t('value'),
    key: 'value',
  },
  {
    label: i18next.t('action'),
    key: 'action',
  },

  {
    label: i18next.t('eventTime'),
    key: 'eventTime',
  },
  {
    label: i18next.t('rssi'),
    key: 'rssi',
  },
  {
    label: i18next.t('status'),
    key: 'status',
  },
];
