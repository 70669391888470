import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { LANGUAGE_CODES } from '../../../utilities/constants';
import { useTranslation } from 'react-i18next';
import '../dashboard-body.css';

export const DeviceStatusBody = ({ dashboardData }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === LANGUAGE_CODES.ENGLISH;
  const {
    activeDetectionSensors = [],
    activeTrapSensors = [],
    activeBaitSensors = [],
    deactiveDetectionSensors = [],
    deactiveTrapSensors = [],
    deactiveBaitSensors = [],
    lowBatteryDetectionSensors = [],
    lowBatteryTrapSensors = [],
    lowBatteryBaitSensors = [],
  } = dashboardData || {};

  const columns = [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('deviceId'),
      key: 'sensorId',
      dataIndex: 'sensorId',
    },
  ];

  return (
    <div>
      <div className='graphs-container1'>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('activeDetectionSensors')}</p>
          <Table
            pagination={false}
            dataSource={activeDetectionSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('deactiveDetectionSensors')}</p>
          <Table
            pagination={false}
            dataSource={deactiveDetectionSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('lowBatteryDetectionSensors')}</p>
          <Table
            pagination={false}
            dataSource={lowBatteryDetectionSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>

        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('activeTrapSensors')}</p>
          <Table
            pagination={false}
            dataSource={activeTrapSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('deactiveTrapSensors')}</p>
          <Table
            pagination={false}
            dataSource={deactiveTrapSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('lowBatteryTrapSensors')}</p>
          <Table
            pagination={false}
            dataSource={lowBatteryTrapSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>

        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('activeBaitSensors')}</p>
          <Table
            pagination={false}
            dataSource={activeBaitSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('deactiveBaitSensors')}</p>
          <Table
            pagination={false}
            dataSource={deactiveBaitSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            background: 'white',
          }}
        >
          <p className='graph-title'>{t('lowBatteryBaitSensors')}</p>
          <Table
            pagination={false}
            dataSource={lowBatteryBaitSensors}
            columns={columns}
            scroll={{ y: 200 }}
          />
        </div>
      </div>
    </div>
  );
};
