import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Image, Space, Tooltip } from 'antd';
import { useFetchBaits } from '../../../services/baits';
import { formatDate } from '../../../utilities/time-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Breadcrumbs,
  CustomTable,
  PageError,
  PageHeader,
} from '../../../components';
import {
  getBaitImageUrl,
  searchTableFields,
  sorter,
} from '../../../utilities/transform';
import {
  FORM_STORAGE_KEYS,
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../../utilities/storage';
import { BAIT_TYPES } from '../../../utilities/constants';

const buildTableColumns = ({ setPreviewImagePath, setVisible }) => {
  return [
    {
      key: 'id',
      title: i18next.t('bait'),
      dataIndex: 'name',
      sorter: (a, b) => sorter(a.name, b.name),
      isSearchable: true,
    },
    {
      key: 'type',
      title: i18next.t('baitType'),
      dataIndex: 'type',
      sorter: (a, b) => sorter(a.type, b.type),
      render: (text, record) =>
        text === BAIT_TYPES.NORMAL ? i18next.t('normal') : i18next.t('poison'),
    },
    {
      key: 'id',
      title: i18next.t('date'),
      dataIndex: 'expiryDate',
      sorter: (a, b) => sorter(a.expiryDate, b.expiryDate),
      render: (text, record) => {
        return <p>{formatDate(record.expiryDate)}</p>;
      },
    },
    {
      key: 'id',
      title: i18next.t('photo'),
      dataIndex: 'imageData',
      render: (text, record) => {
        const isImage = record.path;
        return (
          <Space size='middle'>
            <Tooltip title={isImage ? 'view image' : 'No image'}>
              <Button
                icon={<EyeOutlined />}
                onClick={() => {
                  if (!isImage) {
                    return;
                  }
                  setPreviewImagePath(getBaitImageUrl(record.path));
                  setVisible(true);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: i18next.t('action'),
      key: 'action',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <Tooltip title='move to edit'>
              <Link to={`${record?.id}/edit`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

export const BaitManagement = () => {
  const { t } = useTranslation();
  const { search: savedSearch } = getSessionStorageItem(
    FORM_STORAGE_KEYS.BAIT_LIST
  );
  const [search, setSearch] = useState(savedSearch || '');
  const [visible, setVisible] = useState(false);
  const [previewImagePath, setPreviewImagePath] = useState(null);
  const {
    data: { baits, totalCount } = {},
    isLoading,
    error,
  } = useFetchBaits({});

  const columns = useMemo(() => {
    return buildTableColumns({
      setPreviewImagePath,
      setVisible,
    });
    // eslint-disable-next-line
  }, [t, setPreviewImagePath, setVisible]);

  const filteredData = useMemo(() => {
    return searchTableFields(search, columns, baits);
  }, [search, baits, columns]);

  const onChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);
  useEffect(() => {
    return () => {
      setSessionStorageItem(FORM_STORAGE_KEYS.BAIT_LIST, { search });
    };
  }, [search]);
  if (error) {
    return (
      <div className='p-10'>
        <PageHeader title={t('baitManagement')} />
        <PageError description='Error is found' />
      </div>
    );
  }

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('baitManagement'),
            link: '/device-management',
          },
        ]}
      />
      <PageHeader
        onChange={onChange}
        title={t('baitManagement')}
        isSearch
        isCreate
        to='add'
        search={search}
      />
      <CustomTable
        rowKey='id'
        columns={columns}
        dataSource={filteredData}
        isLoading={isLoading}
        isShowCount
        totalCount={totalCount}
        pagination={false}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={previewImagePath}
        onError={() => null}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
            setPreviewImagePath(null);
          },
        }}
      />
    </div>
  );
};

export default BaitManagement;
