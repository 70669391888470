import React, { useEffect, useMemo, useRef, useState } from 'react';
import NewWindow from 'react-new-window';
import { Field, Form, useFormikContext } from 'formik';
import {
  AntInput,
  AntSelect,
  CustomTable,
  ShowHideWrapper,
  SiteFloorLayerZoneSelectionForm,
} from '../../../components';
import { Button, Col, notification, Row, Spin } from 'antd';
import {
  buildCSVColumns,
  buildTableColumns,
  getLocaleCSVData,
} from './transform';
import { useTranslation } from 'react-i18next';
import { ENVIRONMENTS, STATUSES } from '../../../utilities/constants';
import { fetchSensorsAsync, useFetchSensors } from '../../../services/sensors';
import { useFetchSites } from '../../../services/sites';
import { useActiveSiteProvider } from '../../../context/site';
import {
  useActiveSiteFloorLayerZone,
  useForceUpdate,
  useFormStateBackup,
  useZoomFloorPlan,
} from '../../../hooks';
import { ROUTE_KEY_TO_SENSOR_TYPE_MAP } from '../constants';
import { FORM_STORAGE_KEYS } from '../../../utilities/storage';
import { FloorPlan } from './floor-plan';
import {
  getFloorImageUrl,
  getFloorLayerAndZoneFromSite,
} from '../../../utilities/transform';
import { CSVLink } from 'react-csv';
import {
  getImageDimensions,
  getScaledSensorFloorPlanInfo,
} from '../../../utilities/image-utils';
import { fetchSensorAndSite } from './service';

const ItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 5 }}>
      {children}
    </Col>
  );
};

export const DeviceListFilterForm = ({
  routeKey,
  initialValues,
  isShowFilters,
  showFloorPlan,
  setShowFloorPlan,
}) => {
  const [activeItems, setActiveItems] = useState([]);
  const CSVLinkRef = useRef();
  const [CSVData, setCSVData] = useState([[]]);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { submitCount, setFieldValue, values } = useFormikContext();
  const { activeSite } = useActiveSiteFloorLayerZone();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const { pageSize, pageNumber } = values || {};
  const { t, i18n } = useTranslation();
  const { data: { sites, defaultLocation } = {} } = useFetchSites();
  const { activeSite: activeSiteId } = useActiveSiteProvider();

  const filters = useMemo(() => {
    const {
      pageNumber,
      pageSize,
      siteId,
      floorId,
      layerId,
      zoneId,
      isProduction,
      status,
      sensorId,
      name,
    } = values || {};
    const type = ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey];
    const filters = {
      pageNumber: pageNumber || 1,
      count: 1000,
      type,
    };
    if (siteId) {
      filters.siteId = siteId;
      filters.floorId = floorId;
      filters.layerId = layerId;
      filters.zoneId = zoneId;
    }
    if (sensorId) filters.sensorId = sensorId;
    if (name) filters.name = name;
    if (isProduction !== null) filters.isProduction = isProduction;
    if (status !== null) filters.status = status;
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdateKey, routeKey]);

  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value == defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('test', '');
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('test', '');
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue('floorId', activeSite1.floors[0].value);
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }

      await setFieldValue('isProduction', 1);
      await setFieldValue('status', 'A');
      await setFieldValue('name', '');
      await setFieldValue('sensorId', '');
      values.isProduction = 1;
      values.status = 'A';
      values.name = '';
      values.sensorId = '';
      forceUpdate();
    }
  };

  useEffect(async () => {
    if (defaultLocation) {
      locationReset();
    }
  }, [defaultLocation]);

  const { handleReset } = useFormStateBackup({
    formKey: FORM_STORAGE_KEYS.DEVICE_LIST,
    callback: forceUpdate,
    initialValues,
  });
  const { data: { sensors, totalCount } = {}, isLoading } =
    useFetchSensors(filters);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => buildTableColumns(routeKey),
    [t, routeKey, i18n.language]
  );
  // const CSVData = useMemo(
  //   () => getLocaleCSVData(sensors),
  //   [sensors, i18n.language]
  // );
  const getSensorsCSVData = async () => {
    setIsCSVLoading(true);
    try {
      const { sensors } = await fetchSensorsAsync({
        ...filters,
        count: 1000,
      });
      setCSVData(getLocaleCSVData(sensors));
      setIsCSVLoading(false);
      setTimeout(() => {
        CSVLinkRef.current?.link.click();
      }, 1000);
    } catch ({ message }) {
      setIsCSVLoading(false);
      notification.error({ message });
    }
  };
  const activeFloor = useMemo(() => {
    const { floors = [] } = activeSite || {};
    return floors.find((item) => item.value === filters.floorId);
  }, [activeSite, filters.floorId]);
  const { activeScaleInfo } = useZoomFloorPlan();
  const [windowDimensions, setWindowDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(async () => {
    const { floor } = await fetchSensorAndSite(filters);
    const floorPlanImageUrl = getFloorImageUrl(floor.floorPath);
    const { naturalWidth, naturalHeight } = await getImageDimensions(
      floorPlanImageUrl
    );
    const { scaledFloorPlanImageWidth, scaledFloorPlanImageHeight } =
      getScaledSensorFloorPlanInfo(
        naturalWidth,
        naturalHeight,
        activeScaleInfo.imageWidth
      );
    setWindowDimensions({
      height: scaledFloorPlanImageHeight,
      width: scaledFloorPlanImageWidth,
    });
  }, [filters]);
  return (
    <Spin spinning={isCSVLoading}>
      <Form>
        <ShowHideWrapper isShow={isShowFilters}>
          <Row gutter={[10, 10]} align='middle'>
            <SiteFloorLayerZoneSelectionForm
              ItemWrapper={ItemWrapper}
              isMultiLayer={true}
              isMultiZone={true}
            />
            <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 3 }}>
              <Field
                name='isProduction'
                component={AntSelect}
                selectOptions={ENVIRONMENTS}
                label={t('testingOrProduction')}
                placeholder={t('pleaseSelectEnvironment')}
                submitCount={submitCount}
              />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 3 }}>
              <Field
                name='status'
                component={AntSelect}
                selectOptions={STATUSES}
                label={t('status')}
                placeholder={t('pleaseSelectStatus')}
                submitCount={submitCount}
              />
            </Col>
            <ItemWrapper>
              <Field
                component={AntInput}
                name='name'
                type='text'
                label={t('name')}
                placeholder={t('pleaseInputName')}
                submitCount={submitCount}
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntInput}
                name='sensorId'
                type='text'
                label={t('id')}
                placeholder={t('pleaseInputId')}
                submitCount={submitCount}
              />
            </ItemWrapper>
            <Col
              xs={{ span: 24 }}
              md={{ span: 2 }}
              style={{ paddingBottom: 0, marginTop: 22 }}
            >
              <Button
                block
                type='secondary'
                onClick={async () => {
                  locationReset();
                }}
              >
                {t('reset')}
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 2 }}
              style={{ paddingBottom: 0, marginTop: 22 }}
            >
              <Button
                type='primary'
                block
                onClick={async () => {
                  await setFieldValue('pageNumber', 1);
                  forceUpdate();
                }}
              >
                {t('submit')}
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 3 }}
              style={{ paddingBottom: 0, marginTop: 22 }}
            >
              <Button
                type='secondary'
                block
                onClick={async () => {
                  await getSensorsCSVData();
                }}
              >
                {t('exportToCSV')}
              </Button>
              <CSVLink
                style={{ display: 'none' }}
                headers={buildCSVColumns()}
                data={CSVData || []}
                ref={CSVLinkRef}
              >
                {t('exportToCSV')}
              </CSVLink>
            </Col>
          </Row>
        </ShowHideWrapper>
      </Form>
      {showFloorPlan && windowDimensions && (
        <NewWindow
          title='Floor Plan'
          features={{
            url: '',
            target: '',
            width: windowDimensions.width,
            height: windowDimensions.height,
            scrollbars: 'yes',
            resizable: 'yes',
            toolbar: 'yes',
          }}
          name={'Floor Plan'}
          center='screen'
          onUnload={() => setShowFloorPlan(false)}
        >
          <div
            style={{ height: '100%', overflowY: 'scroll', overflowX: 'scroll' }}
          >
            {activeFloor?.floorPath && (
              <FloorPlan
                draggableSensors={[]}
                filters={filters}
                activeItems={activeItems}
              />
            )}
          </div>
        </NewWindow>
      )}
      {useMemo(() => {
        return (
          <CustomTable
            rowKey='id'
            columns={columns}
            dataSource={sensors}
            isLoading={isLoading}
            pagination={{
              locale: { items_per_page: `/ ${t('page')}` },
              showLessItems: true,
              showSizeChanger: true,
              showTotal: (total) => (
                <div className='table-pagination'>
                  {t('total')}: {total}
                </div>
              ),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setActiveItems([record.id]);
                },
              };
            }}
          />
        );
      }, [
        columns,
        sensors,
        isLoading,
        pageNumber,
        totalCount,
        pageSize,
        setFieldValue,
        forceUpdate,
      ])}
    </Spin>
  );
};
