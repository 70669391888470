import { sorter } from '../../../../utilities/transform';
import { Typography } from 'antd';
import React from 'react';
import i18next from 'i18next';
import { formatTime } from '../../../../utilities/time-utils';

export const buildTableColumns = (values) => {
  const { isRssi, isStatus } = values || {};
  const dataColumns = [
    {
      title: i18next.t('id'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => sorter(a.id, b.id),
    },
    {
      title: i18next.t('nibiotId'),
      dataIndex: 'nbiotId',
      key: 'nbiotId',
      sorter: (a, b) => sorter(a.nbiotId, b.nbiotId),
    },
    {
      title: i18next.t('value'),
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => sorter(a.value, b.value),
    },
    {
      title: i18next.t('action'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => sorter(a.type, b.type),
      render: (text) => {
        return <Typography.Text>{i18next.t(text)}</Typography.Text>;
      },
    },

    {
      title: i18next.t('eventTime'),
      dataIndex: 'eventTime',
      key: 'eventTime',
      sorter: (a, b) => sorter(a.eventTime, b.eventTime),
      render: (text) => {
        return <Typography.Text>{formatTime(text)}</Typography.Text>;
      },
    },
  ];
  if (isRssi) {
    dataColumns.push({
      title: i18next.t('rssi'),
      dataIndex: 'rssi',
      key: 'rssi',
      sorter: (a, b) => sorter(a.rssi, b.rssi),
      render: (text) => {
        return <Typography.Text>50</Typography.Text>;
      },
    });
  }
  if (isStatus) {
    dataColumns.push({
      title: i18next.t('status'),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => sorter(a.status, b.status),
      render: () => {
        return <Typography.Text>Active</Typography.Text>;
      },
    });
  }

  return dataColumns;
};
