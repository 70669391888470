import React from 'react';
import { Field, Form } from 'formik';
import { AntDatePicker, AntSelect } from '../index';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { WEBSITES } from '../../utilities/constants';
import { FLOORS, LAYERS, ZONES } from '../../utilities/mock';

export const FloorPlanFilterForm = ({ handleSubmit, values, submitCount }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]} align='middle'>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Field
            component={AntSelect}
            name='sites'
            label={t('site')}
            placeholder={t('pleaseSelectSite')}
            submitCount={submitCount}
            selectOptions={WEBSITES}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Field
            component={AntSelect}
            name='floors'
            label={t('floor')}
            placeholder={t('pleaseSelectFloor')}
            submitCount={submitCount}
            selectOptions={FLOORS}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Field
            component={AntSelect}
            name='layers'
            label={t('layer')}
            placeholder={t('pleaseSelectLayer')}
            submitCount={submitCount}
            selectOptions={LAYERS}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Field
            component={AntSelect}
            name='zones'
            label={t('zone')}
            placeholder={t('pleaseSelectZone')}
            submitCount={submitCount}
            selectOptions={ZONES}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Field
            component={AntDatePicker}
            name='fromDate'
            label={t('fromDate')}
            placeholder={t('fromDate')}
            defaultValue={values.fromDate}
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            submitCount={submitCount}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Field
            component={AntDatePicker}
            name='toDate'
            label={t('toDate')}
            placeholder={t('toDate')}
            defaultValue={values.toDate}
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            submitCount={submitCount}
            style={{ width: '100%' }}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 2 }}
          style={{ paddingBottom: 0, paddingTop: 14 }}
        >
          <Button type='primary' block>
            {t('submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
