import * as Yup from 'yup';

export const EditSensorSchema = Yup.object().shape({
  siteId: Yup.number('siteIsRequired').required('siteIsRequired'),
  floorId: Yup.number('floorIsRequired').required('floorIsRequired'),
  layerId: Yup.number('layerIsRequired').required('layerIsRequired'),
  name: Yup.string().required('nameIsRequired'),
  type: Yup.string().required('deviceTypeIsRequired'),
  isProduction: Yup.string().required('testingOrProductionIsRequired'),
  zoneId: Yup.number('zoneIsRequired').required('zoneIsRequired'),
  state: Yup.string().required('statusIsRequired'),
  sensorId: Yup.string().required('idIsRequired'),
});
