import React from 'react';
import { Field, Form } from 'formik';
import { AntCheckbox, AntInput, AntSelect, AntUpload } from '../index';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { GUTTER } from '../../utilities/constants';
import { Link } from 'react-router-dom';

export const InputDataForm = ({
  handleSubmit,
  values,
  submitCount,
  resetForm,
}) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }}>
          <Field
            component={AntUpload}
            name='photo'
            listType='picture'
            label={t('photo')}
            placeholder={t('uploadPhoto')}
            submitCount={submitCount}
            hasFeedback
            required
            extraData={{
              uploadType: 'input',
            }}
          />
        </Col>
      </Row>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name='ratSize'
            type='text'
            label={t('ratSize')}
            placeholder={t('pleaseInputRatSize')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntSelect}
            name='bait'
            label={t('bait')}
            placeholder={t('pleaseSelectBait')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
      </Row>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntSelect}
            name='baitUsage'
            label={t('baitUsage')}
            placeholder={t('pleaseSelectBaitUsage')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntCheckbox}
            name='falseAlarm'
            label={t('falseAlarm')}
            submitCount={submitCount}
            hasFeedback
          />
        </Col>
      </Row>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Row gutter={[8, 8]}>
            <Col>
              <Link to='/trap-sensor/list'>
                <Button type='secondary' onClick={resetForm}>
                  {t('cancel')}
                </Button>
              </Link>
            </Col>
            <Col>
              <Button type='secondary' onClick={resetForm}>
                {t('reset')}
              </Button>
            </Col>
            <Col>
              <Button type='primary' onClick={handleSubmit}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
