import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { Typography } from 'antd';

export const CustomLineChart = ({
  isTitle,
  title,
  titleStyles,
  data,
  height,
  isToolTip = true,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isTitle && (
        <Typography.Title style={{ ...titleStyles }} level={5}>
          {t(title)}
        </Typography.Title>
      )}
      {!isEmpty(data) ? (
        <div style={{ width: '100%', height }}>
          <ResponsiveContainer>
            <LineChart
              data={data}
              margin={{
                top: 5,
                bottom: 5,
                left: 5,
                right: 25,
              }}
            >
              <Line type='monotone' dataKey='dataPoint' stroke='#8884d8' />
              <CartesianGrid stroke='#ccc' />
              <XAxis dataKey='name' />
              <YAxis allowDecimals={false} />
              {isToolTip && <Tooltip />}
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div style={{ width: '100%', height }}>
          <Typography.Text>{t('noData')}</Typography.Text>
        </div>
      )}
    </>
  );
};
