import './layout-header.css';
import HeaderLeft from './header-left';
import React from 'react';
import { Layout } from 'antd';
import HeaderRight from './header-right';
import { Images } from '../../../images';

const { Header } = Layout;

export const LayoutHeader = ({ collapsed, setCollapsed }) => {
  return (
    <Header className='header' style={{ padding: 0 }}>
      <HeaderLeft collapsed={collapsed} setCollapsed={setCollapsed} />
      <div className='project-name-wrapper'>
        <img
          alt='project name'
          className='project-name-image'
          src={Images.projectName}
        />
      </div>
      <HeaderRight />
    </Header>
  );
};

export default LayoutHeader;
