import { isNumber } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { IMAGE_URL } from '../services/api';
import i18next from 'i18next';
import { LANGUAGE_CODES } from './constants';
import {
  SVG_SENSOR_ICON_ORIGINAL_HEIGHT,
  SVG_SENSOR_ICON_ORIGINAL_WIDTH,
} from './image-utils';

export const getBaitImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }
  return `${IMAGE_URL}/rcp_files/bait/${imageUrl}`;
};

export const getFloorImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }
  return `${IMAGE_URL}/rcp_files/floor/${imageUrl}`;
};

export const getNotificationImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }
  return `${IMAGE_URL}/rcp_files/input/${imageUrl}`;
};
export const getLocationImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }
  return `${IMAGE_URL}/rcp_files/location/${imageUrl}`;
};

export const generateFilePath = () => {
  return `${Date.now().toString()}.png`;
};

export const getFormattedRoles = (roles) => {
  if (isEmpty(roles)) {
    return [];
  }
  return roles.map((role = {}) => ({
    key: role?.id,
    localiseLabel: role?.name,
    value: role?.id,
  }));
};

export const getFormattedSites = (sites) => {
  if (isEmpty(sites)) {
    return [];
  }
  return sites.map((site) => ({
    key: site.id,
    localiseLabel:
      i18next.language === LANGUAGE_CODES.ENGLISH ? site?.name : site?.nameZh,
    value: site.id,
  }));
};

export const getFormattedFloors = (floors) => {
  if (isEmpty(floors)) {
    return [];
  }
  return floors
    .map((floor) => ({
      key: floor.id,
      localiseLabel:
        i18next.language === LANGUAGE_CODES.ENGLISH
          ? floor?.name
          : floor?.nameZh,
      value: floor.id,
      //extra fields
      floorPath: floor.floorPath,
    }))
    .sort((a, b) => a.key - b.key);
};

const getFormattedZones = (zones) => {
  if (isEmpty(zones)) {
    return [];
  }
  return zones
    .map((zone) => ({
      key: zone?.id,
      localiseLabel:
        i18next.language === LANGUAGE_CODES.ENGLISH ? zone?.name : zone?.nameZh,
      value: zone?.id,
    }))
    .sort((a, b) => a.key - b.key);
};

export const getFormattedLayers = (layers) => {
  if (isEmpty(layers)) {
    return [];
  }
  return layers
    .map((layer) => ({
      key: layer?.id,
      localiseLabel:
        i18next.language === LANGUAGE_CODES.ENGLISH
          ? layer?.name
          : layer?.nameZh,
      value: layer?.id,
    }))
    .sort((a, b) => a.key - b.key);
};

export const getFloorLayerAndZoneFromSite = (site) => {
  const { floors, layers, zones } = site || {};
  return {
    floors: getFormattedFloors(floors),
    layers: getFormattedLayers(layers),
    zones: getFormattedZones(zones),
  };
};

export const sorter = (a, b) => {
  if (isNumber(a) && isNumber(b)) {
    return (a || 0) - (b || 0);
  }
  return String(a || '').localeCompare(String(b || ''), undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

export const generatePermissionSchema = (secondSection, thirdSection) => {
  let permissionSchema = {};
  for (let schemaItem of secondSection) {
    permissionSchema = { ...permissionSchema, ...schemaItem };
  }
  for (let schemaItem of thirdSection) {
    permissionSchema = { ...permissionSchema, ...schemaItem };
  }
  return permissionSchema;
};

export const stringifyObject = (value) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return '';
  }
};

export const parseJson = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return {};
  }
};

export const formatValue = (value) => {
  return (value || 0).toFixed(2);
};

export const searchTableFields = (search, columns, data) => {
  if (!search) {
    return data;
  }
  const lowerCaseSearch = search.toLowerCase();
  const searchableColumns = columns.filter((item) => item.isSearchable);
  return (
    data?.filter((item) => {
      return searchableColumns.some(({ dataIndex }) =>
        item[dataIndex]?.toLowerCase().includes(lowerCaseSearch)
      );
    }) || []
  );
};

export const sensorScaleTransform = (sensors = [], widthScale, heightScale) => {
  return sensors.map((sensor) => {
    const { id, latitude, longitude, direction, nameShort, type, sensorId } =
      sensor || {};
    return {
      ...sensor,
      id: parseInt(id),
      sensorId,
      latitude: widthScale(parseInt(latitude || 0)),
      longitude: heightScale(parseInt(longitude || 0)),
      direction,
      name: nameShort,
      type,
    };
  });
};

export const truncate = (str) => {
  return str?.length > 15 ? str?.substring(0, 15 - 1) + '...' : str;
};
export const heatmapScaleTransform = (
  sensors = [],
  widthScale,
  heightScale
) => {
  return sensors.map((sensor) => {
    const { name, x, y, value, radius } = sensor || {};
    const lat = parseInt(x || 0) + parseInt(SVG_SENSOR_ICON_ORIGINAL_WIDTH / 2); // Delta values are added to push circle center to arrow center
    const lng = parseInt(y || 0) + parseInt(SVG_SENSOR_ICON_ORIGINAL_HEIGHT / 2);
    // let radius = 0;
    // if (value >= 10) radius = (value - 10) * 20;
    // else if (value < 10) radius = value * 20;
    return {
      name,
      x: widthScale(lat || 0),
      y: heightScale(lng || 0),
      value,
      radius,
    };
  });
};
