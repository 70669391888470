import i18next from 'i18next';
import * as _ from 'lodash';
import { sorter } from '../../../../utilities/transform';

export const buildTableColumns = () => {
  return [
    {
      title: i18next.t('sensorID'),
      dataIndex: 'sensorId',
      key: 'sensorId',
      sorter: (a, b) => sorter(a.sensorId, b.sensorId),
    },
    {
      title: i18next.t('mpID'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sorter(a.name, b.name),
    },
    {
      title: i18next.t('caught'),
      dataIndex: 'caught',
      key: 'caught',
      sorter: (a, b) => {
        return sorter(a.caught, b.caught);
      },
    },
    {
      title: i18next.t('missed'),
      dataIndex: 'missed',
      key: 'missed',
      sorter: (a, b) => {
        return sorter(a.missed, b.missed);
      },
    },
  ];
};
const COLORS = [
  '#FCE8E8',
  '#F8BCBC',
  '#F38F8F',
  '#EE6363',
  '#F7FCE8',
  '#E4F6AD',
  '#D0EF72',
  '#BCE937',
  '#E5FFF7',
  '#86FFDB',
  '#27FFBF',
  '#00C78C',
  '#EFF5F5',
  '#A4C9C9',
  '#5C9A9A',
  '#2F4F4F',
  '#ECF5F8',
  '#B8DBE6',
  '#84C0D4',
  '#50A6C2',
  '#E5F9FF',
  '#93E4FF',
  '#41CFFF',
  '#00B2EE',
  '#EBEDFA',
  '#C8CDF0',
  '#A6AEE7',
  '#838EDE',
  '#F5E5FF',
  '#E3B9FF',
  '#D18CFF',
  '#BF5FFF',
  '#FFE5F3',
  '#FFA0D3',
  '#FF5AB3',
  '#FF1493',
  '#FBE9E9',
  '#F3B6B7',
  '#EB8484',
  '#E35152',
];
export const getLayers = (data, layerNames) => {
  const grouped = _.groupBy(data, 'layerId');
  const layers = [];
  let start = 0;
  Object.keys(grouped).forEach((key) => {
    const layer = layerNames
      ?.filter((layer) => layer.id === parseInt(key))
      .pop();
    layers.push({
      layerId: parseInt(key),
      layerName: layer?.name,
      strip: COLORS.slice(start, start + 4),
    });
    start += 4;
  });
  return layers;
};
export const getSensorColor = (eventCount, sensorLayerId, layers) => {
  const sensorLayer = layers
    .filter((layer) => layer.layerId === sensorLayerId)
    .pop();
  switch (true) {
    case eventCount >= 0 && eventCount < 5:
      return sensorLayer.strip[0];
    case eventCount >= 5 && eventCount < 10:
      return sensorLayer.strip[1];
    case eventCount >= 10 && eventCount < 15:
      return sensorLayer.strip[2];
    case eventCount >= 15:
      return sensorLayer.strip[3];
    default:
      break;
  }
};
export const getSensorData = (sensors) => {
  return sensors?.map((sensor) => {
    if (sensor?.nbiotSensorData?.length === 0) {
      return {
        id: sensor.id,
        name: sensor?.name,
        sensorId: sensor?.sensorId,
        layerId: sensor?.layerId,
        latitude: parseInt(sensor?.latitude),
        longitude: parseInt(sensor?.longitude),
        caught: 0,
        missed: 0,
      };
    } else {
      const grouped = _.groupBy(sensor?.nbiotSensorData, 'type');
      const item = {
        id: sensor.id,
        name: sensor?.name,
        sensorId: sensor?.sensorId,
        layerId: sensor?.layerId,
        latitude: parseInt(sensor?.latitude),
        longitude: parseInt(sensor?.longitude),
      };
      Object.keys(grouped).forEach((key) => {
        item[key] = grouped[key].length;
      });
      item.caught = item.caught || 0;
      item.missed = item.missed || 0;
      return item;
    }
  });
};

export const getMatchedLayers = (ids, layers) => {
  let final = [];
  ids?.forEach((id) => {
    const match = _.filter(layers, ['id', id]);
    final.push({ id: match[0].id, name: match[0].name });
  });
  return final;
};
