import { api, useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const NOTIFICATIONS_CACHE_KEY = 'notifications';
const fetchNotifications = (api, filters) => async () => {
  const { data } = await api.fetchNotifications(filters);
  return {
    notifications: data?.data?.notifications || [],
    totalCount: data?.data?.totalCount || 0,
    floorPath: data?.data?.floorPath || '',
    sensors: data?.data?.SensorList || [],
  };
};

export const useFetchNotifications = (filters) => {
  const api = useApi();
  const response = useSWR(
    {
      url: NOTIFICATIONS_CACHE_KEY,
      filters,
    },
    fetchNotifications(api, filters)
  );
  return withLoading(response);
};

const fetchEvents = (api, filters) => async () => {
  const { data } = await api.fetchEvents(filters);
  return {
    events: data?.data?.events || [],
  };
};

export const useFetchEvents = (filters) => {
  const api = useApi();
  const response = useSWR(
    {
      url: NOTIFICATIONS_CACHE_KEY,
      filters,
    },
    fetchEvents(api, filters)
  );
  return withLoading(response);
};

const fetchNotificationDetails = (api, filters) => async () => {
  const { data } = await api.fetchNotificationDetails(filters);
  return data?.data || {};
};

export const useFetchNotificationDetails = ({ notificationId }) => {
  const api = useApi();
  const response = useSWR(
    `NOTIFICATION_DETAILS_CACHE_KEY/${notificationId}`,
    fetchNotificationDetails(api, { notificationId })
  );
  return withLoading(response);
};
export const acknowledgeNotificationAsync = async (body) => {
  return await api.acknowledgeNotification(body);
};
const acknowledgeNotification = (api) => async (body) => {
  return await api.acknowledgeNotification(body);
};

export const useAcknowledgeNotification = ({ notificationId }) => {
  const api = useApi();
  const mutate = useMatchMutate(
    `NOTIFICATION_DETAILS_CACHE_KEY/${notificationId}`
  );
  return useMutation(acknowledgeNotification(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const addNotification = (api) => async (body) => {
  return await api.addNotification(body);
};

export const useAddNotification = () => {
  const api = useApi();
  const mutate = useMatchMutate(NOTIFICATIONS_CACHE_KEY);
  return useMutation(addNotification(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const editNotification = (api) => async (body) => {
  return await api.editNotification(body);
};

export const useEditNotification = () => {
  const api = useApi();
  const mutate = useMatchMutate(NOTIFICATIONS_CACHE_KEY);
  return useMutation(editNotification(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const removeNotification =
  (api) =>
  async ({ id }) => {
    return await api.removeNotification({ id });
  };

export const useDeleteNotification = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${NOTIFICATIONS_CACHE_KEY}`);
  return useMutation(removeNotification(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
