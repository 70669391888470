import './assign-sensor-location-form.css';
import { Button, Col, notification, Row, Spin, Typography } from 'antd';
import {
  SiteFloorLayerZoneSelectionForm,
  ZoomFloorActionButtons,
} from '../../../components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import { saveSensorLocationAsync } from '../../../services/sensors';
import { fetchSensorAndSite } from './service';
import {
  getImageDimensions,
  getScaledSensorFloorPlanInfo,
} from '../../../utilities/image-utils';
import { sensorScaleDownTransform } from './transform';
import {
  getFloorImageUrl,
  sensorScaleTransform,
} from '../../../utilities/transform';
import { ROUTE_KEY_TO_SENSOR_TYPE_MAP } from '../constants';
import { GUTTER } from '../../../utilities/constants';
import { SensorsList } from '../../../components/sensors-list';
import useDraggableScroll from 'use-draggable-scroll';
import {
  useForceUpdate,
  useFormStateBackup,
  useZoomFloorPlan,
} from '../../../hooks';
import { isEmpty } from 'lodash';

export const AssignSensorLocationForm = ({ routeKey, formKey }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const { onMouseDown } = useDraggableScroll(ref);
  const { forceUpdate, forceUpdateKey } = useForceUpdate();
  const sensorType = ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey];
  const [floorPlanMeta, setFloorPlanMeta] = useState({});
  const { optionsList, activeScaleInfo, onChangeZoomLevel } =
    useZoomFloorPlan();
  const {
    initialValues,
    resetForm,
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    setTouched,
    validateForm,
  } = useFormikContext();

  const { handleReset } = useFormStateBackup({
    formKey,
    callback: forceUpdate,
    isMultiLayer: false,
    isMultiZone: true,
    initialValues: { ...initialValues },
  });

  const { sensors = [] } = values || {};

  const {
    floorPlanImageUrl,
    scaledFloorPlanImageWidth,
    scaledFloorPlanImageHeight,
    sensorIconWidth,
    sensorIconHeight,
    naturalWidth,
    naturalHeight,
  } = floorPlanMeta || {};

  const handleResetSensorInfo = async () => {
    await resetForm();
    forceUpdate();
  };

  useEffect(() => {
    setFieldValue('sensors', []);
    setFloorPlanMeta({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.siteId]);

  const onSubmit = async () => {
    try {
      const { siteId, floorId, layerId, zoneId } = values;
      await setTouched({ floorId: true, layerId: true, zoneId: true });
      const errors = await validateForm();
      if (!isEmpty(errors)) return;
      await setSubmitting(true);
      const params = {
        siteId,
        floorId,
        layerId,
        zoneId,
        routeKey,
      };
      const { floor, sensors } = await fetchSensorAndSite(params);
      const floorPlanImageUrl = getFloorImageUrl(floor.floorPath);
      const { naturalWidth, naturalHeight } = await getImageDimensions(
        floorPlanImageUrl
      );
      const {
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      } = getScaledSensorFloorPlanInfo(
        naturalWidth,
        naturalHeight,
        activeScaleInfo.imageWidth
      );
      setFloorPlanMeta({
        naturalWidth,
        naturalHeight,
        floorPlanImageUrl,
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      });
      const formattedSensors = sensorScaleDownTransform(
        sensors,
        widthScale,
        heightScale,
        naturalWidth,
        naturalHeight
      );
      const updatedValues = {
        sensors: formattedSensors,
        siteId,
        floorId,
        layerId,
        zoneId,
      };
      await resetForm({ values: updatedValues });
      forceUpdate();
    } catch ({ message }) {
    } finally {
      await setSubmitting(false);
    }
  };

  const handleSave = async () => {
    try {
      const { siteId, floorId, layerId, zoneId } = values;
      setSubmitting(true);
      const { widthScale, heightScale } = getScaledSensorFloorPlanInfo(
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        naturalWidth
      );
      const updatedSensors = sensorScaleTransform(
        sensors,
        widthScale,
        heightScale
      );
      await saveSensorLocationAsync(updatedSensors);
      const sensorsDefaultColor = sensors.map((sensor) => ({
        ...sensor,
        color: '#2db34a',
      }));
      const updatedValues = {
        sensors: sensorsDefaultColor,
        siteId,
        floorId,
        layerId,
        zoneId,
      };
      await resetForm({ values: updatedValues });
      notification.success({ message: t('editedSuccess') });
    } catch (message) {
    } finally {
      setSubmitting(false);
    }
  };

  const handleZoomLevelChange = async (imageWidth) => {
    const { siteId, floorId, layerId, zoneId } = values;
    const originalScaleInfo = getScaledSensorFloorPlanInfo(
      scaledFloorPlanImageWidth,
      scaledFloorPlanImageHeight,
      naturalWidth
    );
    const originalScaledSensors = sensorScaleTransform(
      sensors,
      originalScaleInfo.widthScale,
      originalScaleInfo.heightScale
    );

    const zoomedScaleInfo = getScaledSensorFloorPlanInfo(
      naturalWidth,
      naturalHeight,
      imageWidth
    );
    const zoomedSensors = sensorScaleTransform(
      originalScaledSensors,
      zoomedScaleInfo.widthScale,
      zoomedScaleInfo.heightScale
    );
    setFloorPlanMeta((old) => ({
      ...old,
      ...zoomedScaleInfo,
      naturalWidth,
      naturalHeight,
    }));
    const updatedValues = {
      sensors: zoomedSensors,
      siteId,
      floorId,
      layerId,
      zoneId,
    };
    await resetForm({ values: updatedValues });
    forceUpdate();
  };

  return (
    <Spin spinning={isSubmitting}>
      <Form>
        <Row gutter={[10, 10]} align='middle'>
          <SiteFloorLayerZoneSelectionForm
            isMultiLayer={false}
            isZone={true}
            isMultiZone={true}
          />
          <Col>
            <Button
              style={{ marginTop: 22 }}
              type='secondary'
              onClick={handleReset}
            >
              {t('reset')}
            </Button>
          </Col>
          <Col>
            <Button style={{ marginTop: 22 }} type='primary' onClick={onSubmit}>
              {t('submit')}
            </Button>
          </Col>
        </Row>
        {floorPlanImageUrl && (
          <>
            <ZoomFloorActionButtons
              activeScaleInfo={activeScaleInfo}
              optionsList={optionsList}
              onChange={(item) => {
                onChangeZoomLevel(item.value);
                handleZoomLevelChange(item.imageWidth);
              }}
            />
            <div className='floor-plan-center'>
              <div
                className='floor-plan-wrapper'
                ref={ref}
                onMouseDown={onMouseDown}
              >
                <img
                  draggable='false'
                  alt='Sensor Area'
                  className='floor-plan-image'
                  src={floorPlanImageUrl}
                  width={scaledFloorPlanImageWidth}
                  height={scaledFloorPlanImageHeight}
                  ref={ref}
                  onMouseDown={onMouseDown}
                />
                <SensorsList
                  isPopover
                  key={forceUpdateKey}
                  sensors={sensors}
                  sensorType={sensorType}
                  sensorIconWidth={sensorIconWidth}
                  sensorIconHeight={sensorIconHeight}
                  scaledFloorPlanImageWidth={scaledFloorPlanImageWidth}
                  scaledFloorPlanImageHeight={scaledFloorPlanImageHeight}
                  onUpdate={async (item, index, updatedLatLng) => {
                    await setFieldValue(`sensors[${index}]`, {
                      ...item,
                      ...updatedLatLng,
                      color: 'red',
                    });
                  }}
                />
              </div>
            </div>
            <Row gutter={[GUTTER, GUTTER]} style={{ marginTop: 22 }}>
              <Col>
                <Button type='secondary' onClick={handleResetSensorInfo}>
                  {t('reset')}
                </Button>
              </Col>
              <Col>
                <Button type='primary' onClick={handleSave}>
                  {t('save')}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {!floorPlanImageUrl && sensors.length > 0 && (
          <Typography.Text>{t('noFloorPlanFound')}</Typography.Text>
        )}
      </Form>
    </Spin>
  );
};
