import classes from './index.module.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { FloorPlanFilterForm } from '../../../components';
import { Formik } from 'formik';
import { GUTTER } from '../../../utilities/constants';

export const FloorPlan = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.content}>
      <Row className={classes.headerRow}>
        <Col span={24}>
          <Title level={3}>{t('floorPlan')}</Title>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }} gutter={[GUTTER, GUTTER]}>
        <Col span={24}>
          <Formik
            initialValues={{}}
            onSubmit={() => {}}
            render={FloorPlanFilterForm}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Button type='primary' block>
            {t('playTimeLapse')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default FloorPlan;
