import React, { useEffect, useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Button, Checkbox, Col, Divider, Row, Spin } from 'antd';
import { AntTextAreaInput } from '../custom-fields';
import { useForceUpdate } from '../../hooks';
import { useSyncFormState } from '../../hooks/use-sync-form-state';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CheckboxGroup = Checkbox.Group;

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
      {children}
    </Col>
  );
};

export const EditBatchForm = ({ handleSubmit, values, submitCount2 }) => {
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t } = useTranslation();
  const { handleReset, setFieldValue } = useFormikContext();
  const { batch, batchItemList } = values;
  const { forceUpdate } = useForceUpdate();
  const [setTypes] = useState(['detection_sensor', 'trap_sensor']);
  const { submitCount } = useFormikContext();
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < batchItemList.length);
    setCheckAll(list.length === batchItemList.length);
  };

  const onCheckAllChange = (e) => {
    const tempMap = batchItemList.map((item) => item.id);
    // setCheckedList(e.target.checked ? plainOptions : []);
    setCheckedList(e.target.checked ? tempMap : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  batchItemList &&
    batchItemList.forEach((n) => {
      n.label = n.sensorName;
      n.value = n.id;
    });
  useEffect(() => {
    const tempCheckedList = batchItemList.filter((item) => 'S' == item.state);
    const tempCheckedListId = tempCheckedList.map((item) => item.id);

    setCheckedList(tempCheckedListId ? tempCheckedListId : []);
  }, []);
  useEffect(() => {
    setFieldValue('checkedList', checkedList);
  }, [checkedList]);
  const isValidating = false;
  useSyncFormState({ callback: forceUpdate });
  useEffect(() => {
    setCheckedList(batchItemList.map((item) => item.id));
  }, [batchItemList]);
  const handleReset1 = async () => {
    await handleReset();
    // const tempCheckedList = batchItemList.filter((item) => 'A' == item.state);
    // const tempCheckedListId = batchItemList.map((item) => item.id);
    setCheckedList(batchItemList.map((item) => item.id));
    forceUpdate();
  };
  useEffect(() => {
    const tempCheckedListId = batchItemList.map((item) => item.id);
    setCheckAll(checkedList.length === tempCheckedListId.length);
    setIndeterminate(checkedList.length !== tempCheckedListId.length);
  }, [checkedList]);
  const batchItemOption = () => {
    return batchItemList.map((n) => (
      <Row>
        <Col span={12}>
          <Checkbox value={n.id} key={n.id}>
            {n.sensorName}
          </Checkbox>
        </Col>
        <Col span={12}>{n.baitName}</Col>
      </Row>
    ));
  };
  return (
    <Spin spinning={isValidating}>
      <Form>
        <div style={{ marginTop: 10 }} />
        <Row gutter={[10, 10]} align='middle'>
          <>
            <ItemWrapper>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                disabled={'P' == batch.state ? false : true}
                style={{ width: '100%' }}
              >
                {t('checkall')}
              </Checkbox>
              <Divider />
              <CheckboxGroup
                value={checkedList}
                onChange={onChange}
                disabled={'P' == batch.state ? false : true}
                style={{ width: '100%' }}
              >
                {batchItemOption()}
              </CheckboxGroup>
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntTextAreaInput}
                disabled={'P' == batch.state ? false : true}
                name='remark'
                label={t('remarks')}
                type='text'
                placeholder={t('pleaseInputRemarks')}
                submitCount={submitCount}
                hasFeedback={'P' == batch.state ? true : false}
              />
            </ItemWrapper>
            <ActionButtonWrapper>
              <Link
                to='/notifications'
                state={{ fromNotificationDetails: true }}
              >
                <Button
                  type='secondary'
                  block
                  style={{ marginTop: 22 }}
                  onClick={handleReset1}
                >
                  {t('back')}
                </Button>
              </Link>
            </ActionButtonWrapper>
            {'P' == batch.state ? (
              <ActionButtonWrapper>
                <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
                  {t('reset')}
                </Button>
              </ActionButtonWrapper>
            ) : (
              ''
            )}
            {'P' == batch.state ? (
              <ActionButtonWrapper>
                <Button
                  block
                  type='primary'
                  style={{ marginTop: 22 }}
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </Button>
              </ActionButtonWrapper>
            ) : (
              ''
            )}
          </>
          <Col
            xs={{ span: 24 }}
            md={{ span: 4 }}
            sm={{ span: 24 }}
            style={{ marginTop: 20 }}
          ></Col>
        </Row>
        <Row></Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }}></Col>
        </Row>
      </Form>
    </Spin>
  );
};
