import React, { useState } from 'react';
import classes from './index.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { EVENT_TYPES } from '../../../../../utilities/constants';
import { Button, Col, Modal, Row, Table, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Marker = ({ type }) => {
  return (
    <span
      className={clsx(
        classes.marker,
        type === EVENT_TYPES.BAIT_REPLACE && classes.eventType1,
        type === EVENT_TYPES.FILL_POISON_BAIT && classes.eventType2,
        // type === EVENT_TYPES.TRAP_CLEAN_REPLACE && classes.eventType3,
        type === EVENT_TYPES.TRAP_CLEAN && classes.eventType3,
        type === EVENT_TYPES.LOCATION_UPDATE && classes.eventType4,
        type === EVENT_TYPES.SITE_VISIT && classes.eventType5,
        type === EVENT_TYPES.MEETING && classes.eventType6,
        type === EVENT_TYPES.BATTERY_REPLACE && classes.eventType7
      )}
    />
  );
};
export const DayEvents = ({ day, eventList, events, ROLEID }) => {
  const { t } = useTranslation();
  const [isShowEvents, setIsShowEvents] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const navigate = useNavigate();

  const showEvents = (type) => {
    setFilteredEvents(events.filter((e) => e.eventType === type));
    setIsShowEvents(true);
  };

  const columns = [
    { title: t('id'), dataIndex: 'id', key: 'id' },
    {
      title: t('eventtype'),
      dataIndex: 'eventType',
      key: 'eventType',
      render: (text, record, i) => t(text),
    },
    {
      title: t('eventtime'),
      dataIndex: 'createTime',
      key: 'createTime',
      render: (text, record, i) => (
        <span>
          {moment(record.eventStart).format('YYYY-MM-DD')} -{' '}
          {moment(record.eventEnd).format('YYYY-MM-DD')}
        </span>
      ),
    },
    { title: t('remark'), dataIndex: 'remark', key: 'remark' },
    {
      title: t('action'),
      dataIndex: 'id',
      key: 'id',
      render: (text, record, i) =>
        ROLEID !== 3 &&
        (record.eventType === EVENT_TYPES.SITE_VISIT ||
        record.eventType === EVENT_TYPES.MEETING ? (
          <Tag
            onClick={() =>
              navigate('/events/' + text + '/edit', { state: record })
            }
            color={'#1890ff'}
          >
            {t('edit')}
          </Tag>
        ) : (record.eventType === EVENT_TYPES.BAIT_REPLACE ||
            record.eventType === EVENT_TYPES.FILL_POISON_BAIT) &&
          record.batch !== null &&
          record.state !== 'acked' ? (
          <Tag
            onClick={() =>
              navigate('/batch-management/' + record?.batch?.id + '/edit', {
                state: { isFromEvent: true, eventType: record.eventType },
              })
            }
            color={'#1890ff'}
          >
            {t('edit')}
          </Tag>
        ) : record.eventType === EVENT_TYPES.LOCATION_UPDATE &&
          record.state !== 'acked' ? (
          <Tag
            onClick={() =>
              navigate('/location-change-management/' + record?.id + '/edit', {
                state: { isFromEvent: true },
              })
            }
            color={'#1890ff'}
          >
            {t('edit')}
          </Tag>
        ) : record.eventType === EVENT_TYPES.TRAP_CLEAN &&
          record.state !== 'acked' ? (
          <Tag
            onClick={() =>
              navigate('/event-trap-clean-management/' + record?.id + '/edit', {
                state: { isFromEvent: true },
              })
            }
            color={'#1890ff'}
          >
            {t('edit')}
          </Tag>
        ) : record.eventType === EVENT_TYPES.BATTERY_REPLACE &&
          record.state !== 'acked' ? (
          <Tag
            onClick={() =>
              navigate('/events/battery-replacement/' + record?.id + '/edit', {
                state: { isFromEvent: true },
              })
            }
            color={'#1890ff'}
          >
            {t('edit')}
          </Tag>
        ) : (
          ''
        )),
    },
  ];
  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>{day}</h3>
      <ul>
        {eventList?.map((event) => (
          <Row style={{ marginTop: 5 }}>
            <Col span={24}>
              <Marker type={event} />
              <span
                style={{
                  fontSize: '16px',
                  textAlign: 'left',
                }}
              >
                {t(event)}
              </span>
              {(event === EVENT_TYPES.SITE_VISIT ||
                event === EVENT_TYPES.MEETING ||
                event === EVENT_TYPES.BAIT_REPLACE ||
                event === EVENT_TYPES.LOCATION_UPDATE ||
                event === EVENT_TYPES.TRAP_CLEAN ||
                event === EVENT_TYPES.FILL_POISON_BAIT ||
                event === EVENT_TYPES.BATTERY_REPLACE) && (
                <span
                  onClick={() => showEvents(event)}
                  style={{ marginLeft: 10, color: 'red' }}
                >
                  {t('view')}
                </span>
              )}
            </Col>
          </Row>
        ))}
      </ul>
      <Modal
        visible={isShowEvents}
        title={t('event1')}
        onCancel={() => setIsShowEvents(false)}
        footer={[
          <Button onClick={() => setIsShowEvents(false)}>{t('back')}</Button>,
        ]}
        width={1000}
      >
        <div>
          <Table
            dataSource={filteredEvents}
            columns={columns}
            pagination={{
              pageSize: 10,
              locale: { items_per_page: `/ ${t('page')}` },
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
