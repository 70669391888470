import './sidebar.css';
import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserProvider } from '../../context/session';
import Icon, {
  BarChartOutlined,
  BellOutlined,
  CalendarOutlined,
  EyeOutlined,
  LogoutOutlined,
  SettingOutlined,
  ToolOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { HiDocumentReport } from 'react-icons/hi';
import { RiRoadMapLine } from 'react-icons/ri';

const Item = ({ active, route, title, icon }) => {
  return (
    <Link to={route} style={{ textDecoration: 'none', color: 'white' }}>
      <div className={active ? 'active sidebar-item' : 'sidebar-item'}>
        <div style={{ textAlign: 'center' }}>
          <Icon component={icon} style={{ fontSize: 22 }} />
        </div>
        <div style={{ marginTop: 15, textAlign: 'center' }}>{title}</div>
      </div>
    </Link>
  );
};

function CustomLink({ regexList = [], title, icon, to }) {
  const { pathname } = useLocation();
  const isMatch = regexList?.some((rx) => rx.test(pathname));
  return <Item active={isMatch} route={to} title={title} icon={icon} />;
}

export const SideBar = () => {
  const { t } = useTranslation();
  const [user, setUser] = useUserProvider();
  useNavigate();
  const onLogout = () => {
    setUser(null);
  };

  const ROLEID = useMemo(() => {
    const { user: profile } = user || {};
    const { roleId } = profile || {};
    return roleId;
  }, [user]);

  return (
    <div className='sidebar-container'>
      {ROLEID !== 3 && (
        <Menu
          mode='inline'
          style={{ backgroundColor: '#EA232B ', color: 'white' }}
        >
          <CustomLink
            regexList={[/^(\/){1}$/]}
            to='/'
            title={t('home')}
            icon={EyeOutlined}
          />

          <CustomLink
            regexList={[/^(\/){1}(dashboard)$/]}
            to='dashboard'
            title={t('dashboard')}
            icon={BarChartOutlined}
          />
          <CustomLink
            regexList={[
              /^(\/){1}(detection-sensor|trap-sensor|bait-sensor)(\/){1}list$/,
              /^(\/){1}(detection-sensor|trap-sensor|bait-sensor)(\/){1}add/,
              /^(\/){1}(detection-sensor|trap-sensor|bait-sensor)(\/){1}edit/,
              /^(\/){1}(detection-sensor|trap-sensor|bait-sensor)(\/){1}(\w)+(\/){1}(history|edit)$/,
              /^(\/){1}(trap-sensor)(\/){1}(input-data|exchange)$/,
            ]}
            to='detection-sensor/list'
            title={t('deviceList')}
            icon={UnorderedListOutlined}
          />
          <CustomLink
            regexList={[
              /^(\/)(events)/,
              /^(\/)(batch-management)/,
              /^(\/)(event-trap-clean-management)/,
              /^(\/)(location-change-management)/,
            ]}
            to='events'
            title={t('eventLowercase')}
            icon={CalendarOutlined}
          />
          <CustomLink
            regexList={[
              /^(\/){1}(notifications){1}$/,
              /^(\/){1}(notifications){1}(\/){1}(\w)+(\/){1}view$/,
            ]}
            to='notifications'
            title={t('notification')}
            icon={BellOutlined}
          />
          <CustomLink
            regexList={[/^(\/){1}(floor-plan){1}$/]}
            to='floor-plan'
            title={t('playback')}
            icon={RiRoadMapLine}
          />
          <CustomLink
            regexList={[/^(\/){1}reports$/]}
            to='reports'
            title={t('report')}
            icon={HiDocumentReport}
          />
          <CustomLink
            regexList={[
              /^(\/){1}settings$/,
              /^(\/){1}site-management$/,
              /^(\/){1}site-management(\/){1}(\w)+(\/){1}edit$/,
              /^(\/){1}site-management(\/){1}(add|assign-detection-sensor-location|assign-trap-sensor-location|assign-bait-sensor-location)$/,
              /^(\/){1}site-management(\/){1}(\w)+(\/){1}floor-management(\/){1}(\w)+$/,
              /^(\/){1}device-management$/,
              /^(\/){1}device-management(\/){1}(\w)+(\/){1}edit$/,
              /^(\/){1}device-management(\/){1}add$/,
              /^(\/){1}role-management$/,
              /^(\/){1}role-management(\/){1}add$/,
              /^(\/){1}role-management(\/){1}(\w)+(\/){1}[\w%]+(\/){1}edit$/,
              /^(\/){1}account-management$/,
              /^(\/){1}account-management(\/){1}add$/,
              /^(\/){1}account-management(\/){1}(\w)+(\/){1}edit$/,
              /^(\/){1}profile$/,
              /^(\/){1}profile(\/){1}edit$/,
              /^(\/){1}change-password$/,
              /^(\/){1}trap-management/,
            ]}
            to='settings'
            title={t('settings')}
            icon={SettingOutlined}
          />
          <CustomLink
            regexList={[/^(\/){1}(maintenance){1}$/]}
            to='maintenance'
            title={t('maintenance')}
            icon={ToolOutlined}
          />
          <span onClick={onLogout}>
            <CustomLink to='' title={t('logout')} icon={LogoutOutlined} />
          </span>
        </Menu>
      )}
      {ROLEID === 3 && (
        <Menu
          mode='inline'
          style={{ backgroundColor: '#EA232B ', color: 'white' }}
        >
          <CustomLink
            regexList={[/^(\/){1}(dashboard)$/]}
            to='dashboard'
            title={t('dashboard')}
            icon={BarChartOutlined}
          />
          <CustomLink
            regexList={[
              /^(\/)(events)/,
              /^(\/)(batch-management)/,
              /^(\/)(event-trap-clean-management)/,
              /^(\/)(location-change-management)/,
            ]}
            to='events'
            title={t('eventLowercase')}
            icon={CalendarOutlined}
          />
          <CustomLink
            regexList={[
              /^(\/){1}(notifications){1}$/,
              /^(\/){1}(notifications){1}(\/){1}(\w)+(\/){1}view$/,
            ]}
            to='notifications'
            title={t('notification')}
            icon={BellOutlined}
          />
          <CustomLink
            regexList={[/^(\/){1}(maintenance){1}$/]}
            to='maintenance'
            title={t('maintenance')}
            icon={ToolOutlined}
          />
          <span onClick={onLogout}>
            <CustomLink to='' title={t('logout')} icon={LogoutOutlined} />
          </span>
        </Menu>
      )}
    </div>
  );
};
