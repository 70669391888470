import React, { useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  notification,
  Row,
  Spin,
} from 'antd';
import { useListByLocation } from '../../services/sensors';
import { AntInput } from '../custom-fields';
import { useForceUpdate, useZoomFloorPlan } from '../../hooks';
import moment from 'moment';
import { useSyncFormState } from '../../hooks/use-sync-form-state';
import { useAddBait } from '../../services/baits';
import { useTranslation } from 'react-i18next';

import {
  useAddDevice,
  useDeleteDevice,
  useEditDevice,
} from '../../services/device';
import isEmpty from 'lodash/isEmpty';

const { RangePicker } = DatePicker;

const CheckboxGroup = Checkbox.Group;

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 2 }}>
      {children}
    </Col>
  );
};

const COLORS = ['#8884d8', '#ff0000', '#FFFF00', '#FF00FF'];

export const TrapForm = ({ handleSubmit2, values2, submitCount2 }) => {
  const navigate = useNavigate();
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t } = useTranslation();
  const { values, handleReset, setFieldValue } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const [types, setTypes] = useState(['detection_sensor', 'trap_sensor']);
  const { submitCount, validateForm, setFieldTouched } = useFormikContext();
  const [activeSite, setActiveSite] = useState({});
  const [activeLocation, setActiveLocation] = useState({});
  const [sensors, setSensors] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, 'd'),
    moment(),
  ]);
  const { activeScaleInfo } = useZoomFloorPlan();
  const [checkedList, setCheckedList] = useState([]);
  const [checkedListErrorMessage, setCheckedListErrorMessage] = useState('');

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < sensors.length);
    setCheckAll(list.length === sensors.length);
  };
  const onCheckAllChange = (e) => {
    const tempMap = sensors.map((item) => item.id);
    // setCheckedList(e.target.checked ? plainOptions : []);
    setCheckedList(e.target.checked ? tempMap : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const initCheckChange = (deviceItemList) => {
    const tempMap = deviceItemList.map((item) => item.sensorId);
    // setCheckedList(e.target.checked ? plainOptions : []);
    setCheckedList(tempMap ? tempMap : []);
    // setIndeterminate(false);
    // setCheckAll(e.target.checked);
    // const tempMap=sensors.map(item => item.id)
    // // setCheckedList(e.target.checked ? plainOptions : []);
    // setCheckedList(e.target.checked?tempMap:[]);
    // setIndeterminate(false);
    // setCheckAll(e.target.checked);
  };

  const TYPES = [
    { label: t('trapSensor'), value: 'trap_sensor' },
    { label: t('detectionSensor'), value: 'detection_sensor' },
  ];
  const TIME_RANGE_OPTION = [
    { key: '1', label: t('allDay'), value: '1' },
    { key: '2', label: t('night'), value: '2' },
  ];

  const { mutate } = useAddBait();
  const { mutate: editDevice, status: editStatus } = useEditDevice();
  const { mutate: addDevice, status: addStatus } = useAddDevice();
  const { mutate: deleteDevice, status: deleteStatus } = useDeleteDevice();
  const { mutate: listByLocation, status: listStatus } = useListByLocation();

  // useEffect(async () => {
  //   forceUpdate();
  // }, []);

  const isValidating = false;

  useSyncFormState({ callback: forceUpdate });

  const handleSubmit = async () => {
    try {
      setFieldTouched('code');
      const errors = await validateForm();
      if (!isEmpty(errors)) return;
      if (null != values.id) {
        const editData = {
          id: values.id,
          code: values.code,
        };
        await editDevice(editData);
        notification.success({ message: t('editedSuccess') });
      } else {
        await addDevice(values);
        notification.success({ message: t('createdSuccess') });
      }
    } catch ({ message }) {
      notification.error({ message });
    } finally {
      // actions.setSubmitting(false);
    }
    forceUpdate();
  };
  const handleDeleteDevice = async () => {
    try {
      await deleteDevice({ deviceId: values.id });
      notification.success({ message: t('deletedSuccess') });
      navigate('/trap-management');
    } catch (message) {
      notification.error({ message });
    }
  };

  const handleReset1 = async () => {
    await handleReset();
    forceUpdate();
  };

  return (
    <Spin spinning={isValidating}>
      <Form>
        <div style={{ marginTop: 10 }} />
        <Row gutter={[10, 10]} align='middle'>
          <>
            <ItemWrapper>
              <Field
                component={AntInput}
                name='code'
                type='text'
                label={t('code')}
                placeholder={t('pleaseInputCode')}
                submitCount={submitCount}
                hasFeedback
                required
              />
            </ItemWrapper>
            <ItemWrapper></ItemWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} type='secondary'>
                <Link to='/trap-management'>{t('back')}</Link>
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
                {t('reset')}
              </Button>
            </ActionButtonWrapper>
            {values.id ? (
              ''
            ) : (
              <ActionButtonWrapper>
                <Button
                  block
                  type='primary'
                  style={{ marginTop: 22 }}
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </Button>
              </ActionButtonWrapper>
            )}
            {values.id ? (
              <ActionButtonWrapper>
                <Button
                  block
                  type='primary'
                  style={{ marginTop: 22 }}
                  onClick={handleDeleteDevice}
                  danger
                >
                  {t('delete')}
                </Button>
              </ActionButtonWrapper>
            ) : (
              ''
            )}
          </>
          <Col
            xs={{ span: 24 }}
            md={{ span: 4 }}
            sm={{ span: 24 }}
            style={{ marginTop: 20 }}
          ></Col>
        </Row>
        <Row></Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }}></Col>
        </Row>
      </Form>
    </Spin>
  );
};
