import './index.css';
import React, { useMemo, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { SensorsList } from '../sensors-list';
import { ZoomFloorActionButtons } from '../zoom-floor-action-buttons';
import { usePreviewSensorWithZoom } from '../../hooks';
import useDraggableScroll from 'use-draggable-scroll';

export const ZoomAbleFloorPlan = ({
  draggableSensors = [],
  onUpdate,
  renderItem,
  isPdf = false,
  ...props
}) => {
  const {
    floorPlanMeta,
    sensors,
    floorPlan,
    activeScaleInfo,
    optionsList,
    onChangeZoomLevel,
    forceUpdateKey,
  } = usePreviewSensorWithZoom(props);
  const ref = useRef();
  const { onMouseDown } = useDraggableScroll(ref);
  const {
    scaledFloorPlanImageWidth,
    scaledFloorPlanImageHeight,
    sensorIconWidth,
    sensorIconHeight,
  } = floorPlanMeta || {};

  const formattedSensors = useMemo(() => {
    return sensors.map((item) => {
      if (
        item.latitude > scaledFloorPlanImageWidth ||
        item.latitude < 0 ||
        item.longitude > scaledFloorPlanImageHeight ||
        item.longitude < 0
      ) {
        return {
          ...item,
          latitude: 0,
          longitude: 0,
        };
      }
      return item;
    });
  }, [sensors, scaledFloorPlanImageWidth, scaledFloorPlanImageHeight]);

  if (isEmpty(floorPlanMeta)) {
    return <Spin />;
  }

  return (
    <>
      <ZoomFloorActionButtons
        activeScaleInfo={activeScaleInfo}
        optionsList={optionsList}
        onChange={(item) => {
          onChangeZoomLevel(item.value);
        }}
      />
      <div className='floor-plan-center'>
        <div className='floor-plan-wrapper' ref={ref} onMouseDown={onMouseDown}>
          <img
            className='floor-plan-image'
            draggable='false'
            width={scaledFloorPlanImageWidth}
            height={scaledFloorPlanImageHeight}
            alt='Sensor Area'
            src={floorPlan}
          />
          <SensorsList
            key={forceUpdateKey}
            onUpdate={async (item, index, updatedLatLng) => {
              onUpdate(item, index, updatedLatLng);
            }}
            disabled={true}
            sensors={formattedSensors}
            sensorIconWidth={isPdf ? 26 : sensorIconWidth}
            sensorIconHeight={isPdf ? 26 : sensorIconHeight}
            scaledFloorPlanImageWidth={scaledFloorPlanImageWidth}
            scaledFloorPlanImageHeight={scaledFloorPlanImageHeight}
            renderItem={renderItem}
            pincolor={props.pincolor}
            draggableSensors={draggableSensors}
          />
        </div>
      </div>
    </>
  );
};
