import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getFloorLayerAndZoneFromSite } from '../utilities/transform';
import { useFetchSites } from '../services/sites';
import { useTranslation } from 'react-i18next';

export const useActiveSiteFloorLayerZone = () => {
  const { i18n } = useTranslation();
  const { data } = useFetchSites();
  const { sites = [] } = data || {};
  const { values } = useFormikContext();
  const [activeSite, setActiveSite] = useState({});
  const { siteId } = values || {};
  useEffect(() => {
    if (!siteId || isEmpty(sites)) {
      return;
    }
    const selectedSite = sites.find((site) => site.id === siteId);
    if (isEmpty(selectedSite)) {
      return;
    }
    setActiveSite(getFloorLayerAndZoneFromSite(selectedSite, siteId));
  }, [sites, siteId, i18n.language]);

  return {
    activeSite,
  };
};
