import { SENSOR_ATTRIBUTE_KEYS } from '../../utilities/constants';
import BatterSvg from './battery-svg';
import MissedTrapSvg from './missed-trap-svg';
import ActiveTrapSvg from './active-trap-svg';
import CaughtTrapSvg from './caught-trap-svg';

const style = { width: 20, height: 20 };

export const SensorAttributeIcon = ({ type }) => {
  if (
    type === SENSOR_ATTRIBUTE_KEYS.BATTERY ||
    type === SENSOR_ATTRIBUTE_KEYS.BATTERY_VOLTAGE
  ) {
    return <BatterSvg fill='purple' {...style} />;
  }
  if (type === SENSOR_ATTRIBUTE_KEYS.ACTIVE) {
    return <ActiveTrapSvg fill='#9af172' {...style} />;
  }
  if (type === SENSOR_ATTRIBUTE_KEYS.CAUGHT) {
    return <CaughtTrapSvg fill='#ffb174' {...style} />;
  }
  if (type === SENSOR_ATTRIBUTE_KEYS.MISSED) {
    return <MissedTrapSvg fill='#f6eb5b' {...style} />;
  }
  return null;
};
