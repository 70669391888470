import React from 'react';
import { Field } from 'formik';
import { Col, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { AntCheckbox } from '../custom-fields';

export const ThirdSection = ({ submitCount }) => {
  const { t } = useTranslation();
  const dataSource = [
    {
      key: 1,
      viewKey: 'dashboardView',
      section: t('dashboard'),
      edit: false,
      isEditOptionAllowed: false,
    },
    {
      key: 2,
      viewKey: 'sensorView',
      editKey: 'sensorEdit',
      section: t('device'),
      edit: false,
      isEditOptionAllowed: true,
    },
    {
      key: 3,
      viewKey: 'siteManagementView',
      section: t('siteManagement'),
      edit: false,
      isEditOptionAllowed: false,
    },
    {
      key: 4,
      viewKey: 'reportView',
      section: t('report'),
      edit: false,
      isEditOptionAllowed: false,
    },
    {
      key: 5,
      viewKey: 'roleManagementView',
      editKey: 'roleManagementEdit',
      section: t('roleManagement'),
      edit: false,
      isEditOptionAllowed: true,
    },
    {
      key: 6,
      viewKey: 'accountManagementView',
      editKey: 'accountManagementEdit',
      section: t('accountManagement'),
      edit: false,
      isEditOptionAllowed: true,
    },
    {
      key: 7,
      viewKey: 'baitManagementView',
      editKey: 'baitManagementEdit',
      section: t('baitManagement'),
      edit: false,
      isEditOptionAllowed: true,
    },
    {
      key: 8,
      viewKey: 'batchManagementView',
      editKey: 'batchManagementEdit',
      section: t('batchManagement'),
      edit: false,
      isEditOptionAllowed: true,
    },
  ];
  return (
    <Col span={24}>
      <Table
        name='tableData'
        pagination={false}
        rowKey={'key'}
        dataSource={dataSource}
        columns={[
          {
            title: t('section'),
            key: 'section',
            dataIndex: 'section',
          },
          {
            title: t('edit'),
            key: 'permission',
            dataIndex: 'permission',
            render: (text, record, i) => {
              const { isEditOptionAllowed } = record || {};
              if (!isEditOptionAllowed) {
                return null;
              }
              return (
                <Field
                  hasFeedback
                  type='checkbox'
                  component={AntCheckbox}
                  name={`thirdSection.${i}.${record.editKey}`}
                  submitCount={submitCount}
                />
              );
            },
          },
          {
            title: t('viewOnly'),
            key: 'permission',
            dataIndex: 'permission',
            render: (text, record, i) => {
              return (
                <Field
                  hasFeedback
                  type='checkbox'
                  component={AntCheckbox}
                  name={`thirdSection.${i}.${record.viewKey}`}
                  submitCount={submitCount}
                />
              );
            },
          },
        ]}
      />
    </Col>
  );
};
