import { CSVLink } from 'react-csv';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Row } from 'antd';
import { EXPORT_BUTTON_HEADERS } from './constant';
import { Form, useFormikContext } from 'formik';
import { CustomTable, DateFilterForm } from '../../../../components';
import { useFetchTrapSensorData } from '../../../../services/sensors';
import { useParams } from 'react-router-dom';
import { buildTableColumns } from './transform';
import { useForceUpdate } from '../../../../hooks';
import { endOfDay, startOfDay } from '../../../../utilities/time-utils';

export const CHECKBOX_LIST = [
  {
    labelKey: 'disconnected',
    name: 'isDisconnected',
  },
  { labelKey: 'active', name: 'isActive' },
  {
    labelKey: 'missed',
    name: 'isMissed',
  },
  { labelKey: 'caught', name: 'isCaught' },
  {
    labelKey: 'batteryVoltage',
    name: 'isBatteryVoltage',
  },
  { labelKey: 'rssi', name: 'isRssi' },
  {
    labelKey: 'status',
    name: 'isStatus',
  },
];

export const TrapSensorThirdTabForm = () => {
  const { values, setFieldValue, handleReset, handleSubmit } =
    useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const { t } = useTranslation();
  const { sensorId } = useParams();

  const filters = useMemo(() => {
    const {
      endTime,
      startTime,
      pageNumber,
      pageSize,
      isDisconnected,
      isActive,
      isMissed,
      isCaught,
      isBatteryVoltage,
    } = values || {};
    const types = [];
    if (isDisconnected) types.push('disconnected');
    if (isActive) types.push('active');
    if (isMissed) types.push('missed');
    if (isCaught) types.push('caught');
    if (isBatteryVoltage) types.push('batteryVoltage');
    return {
      startTime: startOfDay(startTime),
      endTime: endOfDay(endTime),
      count: pageSize,
      pageNumber,
      sensorId,
      types,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorId, values.pageNumber, forceUpdateKey]);

  const { data, isLoading } = useFetchTrapSensorData(filters);
  const { sensorData } = data || {};

  const dataColumns = useMemo(() => {
    return buildTableColumns(values);
  }, [values]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: 10 }}>
          {CHECKBOX_LIST.map(({ name, labelKey }) => {
            return (
              <Checkbox
                key={name}
                checked={values[name]}
                onChange={({ target }) => {
                  setFieldValue(name, target.checked);
                }}
              >
                {t(labelKey)}
              </Checkbox>
            );
          })}
        </Row>
        <Row align='middle' gutter={10}>
          <DateFilterForm
            handleReset={async () => {
              await handleReset();
              forceUpdate();
            }}
            handleSubmit={forceUpdate}
          />
        </Row>
      </Form>
      <Button style={{ float: 'right', marginBottom: 10, marginTop: 10 }}>
        <CSVLink data={[]} headers={EXPORT_BUTTON_HEADERS}>
          {t('exportToCSV')}
        </CSVLink>
      </Button>
      <CustomTable
        isLoading={isLoading}
        columns={dataColumns}
        dataSource={sensorData}
        pagination={{
          locale: { items_per_page: `/ ${t('page')}` },
          showLessItems: true,
          showSizeChanger: true,
          showTotal: (total) => (
            <div className='table-pagination'>
              {t('total')}: {total}
            </div>
          ),
        }}
      />
    </>
  );
};
