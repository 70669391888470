import { BarChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { GrMapLocation } from 'react-icons/gr';
import { AiOutlineUser } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { VscGlobe } from 'react-icons/vsc';

export const LANGUAGE_CODES = {
  CHINESE: 'zh',
  ENGLISH: 'en',
};

export const STYLE_SHEETS = {
  SMALL: 'small',
  MEDIUM: 'middle',
  LARGE: 'large',
};

export const STORAGE_KEYS = {
  FONT_SIZE: 'fontSize',
};

export const WEBSITES = [
  { key: 0, label: 'emsd', value: 1 },
  { key: 1, label: 'yeugUkRoadMarket', value: 2 },
];
export const RATSIZE = [
  { key: 0, label: '溝鼠', value: 'L' },
  { key: 1, label: '家鼠', value: 'M' },
  { key: 2, label: '小家鼠', value: 'S' },
];
export const BAITUSAGE = [
  { key: 0, label: '0%', value: '0' },
  { key: 1, label: '25%', value: '25' },
  { key: 3, label: '50%', value: '50' },
  { key: 4, label: '75%', value: '75' },
  { key: 5, label: '100%', value: '100' },
];

export const LANGUAGES = [
  { key: 0, label: 'english', value: LANGUAGE_CODES.ENGLISH },
  { key: 1, label: 'chinese', value: LANGUAGE_CODES.CHINESE },
];

export const PROFILE_MENU_ITEMS = [
  { key: 81, label: 'profile', icon: '', route: 'profile' },
  { key: 82, label: 'changePassword', icon: '', route: 'change-password' },
  { key: 83, label: 'logout', icon: '', route: '' },
];

export const WEBSITE_ITEMS = [
  { key: 100, label: 'EMSD', icon: VscGlobe, route: '' },
  { key: 1001, label: 'Yeug Uk Road Market', icon: VscGlobe, route: '' },
];

export const SYSTEM_ADMIN_ITEMS = [
  { key: 102, label: 'profile', icon: AiOutlineUser, route: 'profile' },
  {
    key: 103,
    label: 'changePassword',
    icon: RiLockPasswordLine,
    route: 'change-password',
  },
];

export const RODENT_DETECTION_SENSOR_MENU_ITEMS = [
  {
    key: 32,
    label: 'floorPlan',
    icon: GrMapLocation,
    route: 'detection-sensor/floor-plan',
  },
];
export const RODENT_TRAP_SENSOR_MENU_ITEMS = [];
export const DEVICE_MANAGEMENT_MENU_ITEMS = [
  {
    key: 54,
    label: 'baitManagement',
    icon: UnorderedListOutlined,
    route: 'device-management',
  },
];
export const SITE_MANAGEMENT_MENU_ITEMS = [
  {
    key: 51,
    label: 'siteManagement',
    icon: BarChartOutlined,
    route: 'site-management',
  },
  {
    key: 52,
    label: 'assignDetectionSensorLocation',
    icon: BarChartOutlined,
    route: 'site-management/assign-detection-sensor-location',
  },
  {
    key: 53,
    label: 'assignTrapSensorLocation',
    icon: BarChartOutlined,
    route: 'site-management/assign-trap-sensor-location',
  },
];
export const STATUS_KEYS = {
  ACTIVE: 'A',
  IN_ACTIVE: 'I',
};
export const STATUSES = [
  { key: 0, label: 'active', value: STATUS_KEYS.ACTIVE },
  { key: 1, label: 'inActive', value: STATUS_KEYS.IN_ACTIVE },
];
export const MEDIUMS = [
  { key: 0, label: 'SMS', value: 'SMS' },
  { key: 1, label: 'Email', value: 'Email' },
];

export const SENSOR_TYPE_KEYS = {
  TRAP_SENSOR: 'trap_sensor',
  DETECTION_SENSOR: 'detection_sensor',
  BAIT_SENSOR: 'bait_sensor',
};

export const SENSOR_TYPES = [
  { key: 0, label: 'trapSensor', value: SENSOR_TYPE_KEYS.TRAP_SENSOR },
  {
    key: 1,
    label: 'detectionSensor',
    value: SENSOR_TYPE_KEYS.DETECTION_SENSOR,
  },
  {
    key: 2,
    label: 'baitSensor',
    value: SENSOR_TYPE_KEYS.BAIT_SENSOR,
  },
];

export const EVENT_TYPES = {
  BAIT_REPLACE: 'baitchange',
  FILL_POISON_BAIT: 'fill_poison_bait',
  // TRAP_CLEAN_REPLACE: 'trap_clean_replace',
  TRAP_CLEAN: 'trap_clean',
  // LOCATION_UPDATE: 'location_update',
  LOCATION_UPDATE: 'location_change',
  SITE_VISIT: 'site_visit',
  MEETING: 'meeting',
  BATTERY_REPLACE: 'battery_change',
};

export const BAIT_TYPES = {
  NORMAL: 'normal',
  POISON: 'poison',
};

export const BAIT_TYPES_OPTIONS = [
  { key: 4, label: 'normal', value: BAIT_TYPES.NORMAL },
  { key: 5, label: 'poison', value: BAIT_TYPES.POISON },
];

export const EVENT_TYPES_OPTIONS = [
  { key: 0, label: 'all', value: '' },
  { key: 7, label: 'bait_replace', value: EVENT_TYPES.BAIT_REPLACE },
  { key: 1, label: 'fill_poison_bait', value: EVENT_TYPES.FILL_POISON_BAIT },
  {
    key: 2,
    label: 'trap_clean_replace',
    value: EVENT_TYPES.TRAP_CLEAN_REPLACE,
  },
  { key: 3, label: 'location_update', value: EVENT_TYPES.LOCATION_UPDATE },
  { key: 4, label: 'site_visit', value: EVENT_TYPES.SITE_VISIT },
  { key: 5, label: 'meeting', value: EVENT_TYPES.MEETING },
  { key: 6, label: 'battery_replace', value: EVENT_TYPES.BATTERY_REPLACE },
];
export const Manual_EVENT_TYPES_OPTIONS = [
  { key: 4, label: 'site_visit', value: EVENT_TYPES.SITE_VISIT },
  { key: 5, label: 'meeting', value: EVENT_TYPES.MEETING },
];

export const REPORT_TYPES_OPTIONS = [
  { key: 0, label: 'd1', value: 'D1' },
  { key: 1, label: 'd2', value: 'D2' },
  { key: 2, label: 'd3', value: 'D3' },
  { key: 3, label: 'm1', value: 'M1' },
  { key: 4, label: 'm2', value: 'M2' },
  { key: 5, label: 'm3', value: 'M3' },
];

export const Manual_EVENT_TYPES_OPTIONS1 = [
  { key: 7, label: 'bait_replace', value: EVENT_TYPES.BAIT_REPLACE },
  { key: 1, label: 'fill_poison_bait', value: EVENT_TYPES.FILL_POISON_BAIT },
  // {
  //   key: 6,
  //   label: 'trap_clean_replace',
  //   value: EVENT_TYPES.TRAP_CLEAN_REPLACE,
  // },
  {
    key: 6,
    label: 'trap_clean',
    value: EVENT_TYPES.TRAP_CLEAN,
  },
  { key: 4, label: 'site_visit', value: EVENT_TYPES.SITE_VISIT },
  { key: 5, label: 'meeting', value: EVENT_TYPES.MEETING },
  { key: 8, label: 'location_change', value: EVENT_TYPES.LOCATION_UPDATE },
  { key: 9, label: 'batteryReplacement', value: EVENT_TYPES.BATTERY_REPLACE },
];

export const Manual_BATCH_OPTIONS = [
  { key: 7, label: 'bait_replace', value: EVENT_TYPES.BAIT_REPLACE },
  { key: 1, label: 'fill_poison_bait', value: EVENT_TYPES.FILL_POISON_BAIT },
];

export const SENSOR_TYPES1 = [
  { key: 0, label: 'trapSensor', value: SENSOR_TYPE_KEYS.TRAP_SENSOR },
  // {
  //   key: 1,
  //   label: 'detectionSensor',
  //   value: SENSOR_TYPE_KEYS.DETECTION_SENSOR,
  // },
  {
    key: 2,
    label: 'batch',
    value: 'baitchange',
  },
  {
    key: 2,
    label: 'fill_poison_bait',
    value: 'fill_poison_bait',
  },
  { key: 0, label: 'batteryReplacement', value: EVENT_TYPES.BATTERY_REPLACE },
];

export const ENVIRONMENTS = [
  { key: 0, label: 'production', value: 1 },
  { key: 1, label: 'testing', value: 0 },
];
export const GUTTER = 10;
export const ICON_SIZE = 15;
export const baseURL = 'http://localhost:8091/rcp/';

export const COLOR_NAMES_LIST = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

export const SENSOR_ATTRIBUTE_KEYS = {
  MISSED: 'missed',
  CAUGHT: 'caught',
  ACTIVE: 'active',
  BATTERY_VOLTAGE: 'batteryVoltage',
  BATTERY: 'battery',
};

export const SENSOR_ATTRIBUTE_TO_SYMBOL_MAP = {
  missed: '',
  caught: '',
  active: '',
  batteryVoltage: 'mV',
  battery: '%',
};

export const SENSOR_PAGE_KEYS = {
  DETECTION_SENSOR: 'detection-sensor',
  TRAP_SENSOR: 'trap-sensor',
  BAIT_SENSOR: 'bait-sensor',
};

export const LANGUAGE = 'language';

export const SENSOR_ATTRIBUTE_TYPES = [
  { key: 0, label: 'missed', value: SENSOR_ATTRIBUTE_KEYS.MISSED },
  { key: 1, label: 'caught', value: SENSOR_ATTRIBUTE_KEYS.CAUGHT },
];
export const NOTIFICATION_STATUSES = [
  { key: 0, label: 'pending', value: 'pending' },
  { key: 1, label: 'acknowledgeLowercase', value: 'acked' },
];
export const YSENO = [
  { key: 1, label: 'yes', value: 1 },
  { key: 0, label: 'no', value: 0 },
];

export const DEFAULT_PAGE_SIZE = 1000;
export const TIME_RANGE_OPTION = [
  { key: 1, label: 'day', value: '1' },
  { key: 2, label: 'night', value: '2' },
];
export const BATTERY_LEVEL_KEYS = {
  ANY: 'Any',
  LOW_BATTERY: 'Low Battery',
};
export const BATTERY_LEVELS = [
  { key: 0, label: 'any', value: BATTERY_LEVEL_KEYS.ANY },
  {
    key: 1,
    label: 'lowBattery',
    value: BATTERY_LEVEL_KEYS.LOW_BATTERY,
  },
];
export const COLORS = [
  '#bf4790',
  '#1435d0',
  '#8f4b1e',
  '#d97035',
  '#dcc104',
  '#62b9a0',
  '#5e6942',
  '#aecbd1',
  '#c59439',
  '#b9243d',
  '#53e106',
  '#5389f8',
];
export const SMS_STATE_KEYS = {
  NOT_APPLICABLE: 'not_appliable',
};
