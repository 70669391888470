import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Typography } from 'antd';
import { Formik } from 'formik';
import { Breadcrumbs } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { useActiveSiteProvider } from '../../../context/site';
import { AddNotificationForm } from './components/add-notification-form';
import { useForceUpdate } from '../../../hooks';
import { useAddCaughtInfo } from '../../../services/sensors';
import { AddNotificationSchema } from '../../../utilities/schemas';

const { Title } = Typography;
export const AddNotification = () => {
  const navigate = useNavigate();
  const { forceUpdate } = useForceUpdate();
  const { t } = useTranslation();
  const { activeSite } = useActiveSiteProvider();
  const initialValues = { siteId: activeSite.value, photo: null };
  const { mutate: addCaughtInfo } = useAddCaughtInfo();

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('notification'),
            link: '/notifications',
          },
          {
            name: t('add'),
            link: `/notifications/add`,
          },
        ]}
      />
      <Title level={3}>{t('addNotification')}</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={AddNotificationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true);
            await addCaughtInfo({
              ...values,
              isAcked: true,
              falseAlarm: false,
            });
            notification.success({ message: t('createdSuccess') });
            navigate('/notifications');
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ submitCount, handleSubmit, resetForm, values, isSubmitting }) => (
          <AddNotificationForm
            values={values}
            submitCount={submitCount}
            handleSubmit={handleSubmit}
            resetForm={(e) => {
              resetForm({ values: initialValues });
              forceUpdate();
            }}
          />
        )}
      </Formik>
    </div>
  );
};
export default AddNotification;
