import { useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const BAITS_CACHE_KEY = 'baits';
const ALL = 'all';
const fetchBaits = (api, filters) => async () => {
  const { data } = await api.fetchBaits(filters);
  return {
    baits: data?.data?.baits || [],
    totalCount: data?.data?.totalCount || [],
  };
};

export const useFetchBaits = (filters) => {
  const api = useApi();
  const response = useSWR(
    `${BAITS_CACHE_KEY}/${filters.baitId || ALL}`,
    fetchBaits(api, filters)
  );
  return withLoading(response);
};

const addBait = (api) => async (body) => {
  return await api.addBait(body);
};

export const useAddBait = () => {
  const api = useApi();
  const mutate = useMatchMutate(BAITS_CACHE_KEY);
  return useMutation(addBait(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const editBait = (api) => async (body) => {
  return await api.editBait(body);
};

export const useEditBait = () => {
  const api = useApi();
  const mutate = useMatchMutate(BAITS_CACHE_KEY);
  return useMutation(editBait(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteBait =
  (api) =>
  async ({ baitId }) => {
    return await api.deleteBait({ baitId });
  };

export const useDeleteBait = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${BAITS_CACHE_KEY}/${ALL}`);
  return useMutation(deleteBait(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
