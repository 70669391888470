import React from 'react';
import { Col, Row } from 'antd';
import Icon from '@ant-design/icons';
import {
  BsArrowDown,
  BsArrowDownLeft,
  BsArrowDownRight,
  BsArrowLeft,
  BsArrowRight,
  BsArrowUp,
  BsArrowUpLeft,
  BsArrowUpRight,
} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { SENSOR_DIRECTIONS } from './constants';

export const DirectionInput = ({
  initialValue: value = '',
  onChange,
  required = false,
}) => {
  const { t } = useTranslation();
  const directions = [
    { id: 1, label: BsArrowDown, value: SENSOR_DIRECTIONS.DOWN },
    { id: 2, label: BsArrowDownLeft, value: SENSOR_DIRECTIONS.DOWN_LEFT },
    { id: 3, label: BsArrowDownRight, value: SENSOR_DIRECTIONS.DOWN_RIGHT },
    { id: 4, label: BsArrowUp, value: SENSOR_DIRECTIONS.UP },
    { id: 5, label: BsArrowUpRight, value: SENSOR_DIRECTIONS.UP_RIGHT },
    { id: 6, label: BsArrowUpLeft, value: SENSOR_DIRECTIONS.UP_LEFT },
    { id: 7, label: BsArrowLeft, value: SENSOR_DIRECTIONS.LEFT },
    { id: 8, label: BsArrowRight, value: SENSOR_DIRECTIONS.RIGHT },
  ];
  return (
    <Row>
      <Col span={24} style={{ marginBottom: 2 }}>
        {required ? <span style={{ color: 'red' }}>*</span> : false}
        {t('directions')}
      </Col>
      {directions.map((direction) => (
        <Col span={2} key={direction.id}>
          <div onClick={() => onChange(direction.value)}>
            <Icon
              component={direction.label}
              style={{
                color: direction.value === value ? 'blue' : 'black',
                fontSize: 20,
                fontWeight: 700,
              }}
            />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default DirectionInput;
