import React from 'react';
import './index.css';
import { Field, Form } from 'formik';
import { AntCheckbox, AntInput, AntSelect, AntUpload } from '../index';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BAITUSAGE, GUTTER, RATSIZE } from '../../utilities/constants';
import { Link } from 'react-router-dom';
import { useFetchBaits } from '../../services/baits';
import { useDeviceEfficient } from '../../services/sensors';

export const SensorInputDataForm = ({
  handleSubmit,
  values,
  submitCount,
  resetForm,
}) => {
  const { t } = useTranslation();
  let { data: { deviceList } = {} } = useDeviceEfficient({});
  const { data: { baits, totalCount } = {} } = useFetchBaits({});
  deviceList &&
    deviceList.forEach((n) => {
      n.key = n.code;
      n.value = n.code;
      n.label = n.code;
    });
  if (deviceList) {
    deviceList = deviceList.sort((a, b) => b.code - a.code);
  }
  baits &&
    baits.forEach((n) => {
      n.key = n.id;
      n.value = n.id;
      n.label = n.name;
    });
  return (
    <Form onSubmit={handleSubmit} style={{ background: 'white' }}>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col span={8} className='text_lable' style={{ marginTop: '-5px' }}>
          {t('falseAlarm')}
        </Col>
        <Col span={16} className='text_content'>
          <Field
            component={AntCheckbox}
            type='checkbox'
            name='falseAlarm'
            label={''}
            submitCount={submitCount}
            hasFeedback
            disabled={values.isAcked}
          />
        </Col>
      </Row>
      {!values.falseAlarm && (
        <div>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 22, offset: 1 }}>
              <Field
                component={AntUpload}
                name='photo'
                listType='picture'
                label={''}
                placeholder={t('uploadPhoto')}
                submitCount={submitCount}
                hasFeedback
                photoRequired
                extraData={{
                  uploadType: 'input',
                }}
                disabled={values.isAcked}
              />
            </Col>
          </Row>
          <Row gutter={[GUTTER, GUTTER]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='ratSize'
                label={t('ratSize')}
                placeholder={t('pleaseInputRatSize')}
                submitCount={submitCount}
                selectOptions={RATSIZE}
                hasFeedback
                required
                disabled={values.isAcked}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='bait'
                label={t('bait')}
                placeholder={t('pleaseSelectBait')}
                submitCount={submitCount}
                selectOptions={baits}
                hasFeedback
                required
                disabled={values.isAcked}
              />
            </Col>
          </Row>
          <Row gutter={[GUTTER, GUTTER]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='baitUsage'
                label={t('baitUsage')}
                placeholder={t('pleaseSelectBaitUsage')}
                submitCount={submitCount}
                selectOptions={BAITUSAGE}
                hasFeedback
                required
                disabled={values.isAcked}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Field
                component={AntInput}
                name='originalId'
                type='text'
                label={t('originalId')}
                submitCount={submitCount}
                disabled
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Field
                component={AntInput}
                name='location'
                type='text'
                label={t('location')}
                submitCount={submitCount}
                disabled
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='newId'
                label={t('newId')}
                placeholder={t('pleaseInputNewId')}
                submitCount={submitCount}
                selectOptions={deviceList}
                hasFeedback
                disabled={values.isAcked}
              />
            </Col>
          </Row>
        </div>
      )}
      {Boolean(values.falseAlarm) && (
        <div>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 22, offset: 1 }}>
              <Field
                component={AntUpload}
                name='photo'
                listType='picture'
                label={''}
                placeholder={t('uploadPhoto')}
                submitCount={submitCount}
                hasFeedback
                extraData={{
                  uploadType: 'input',
                }}
                disabled={values.isAcked}
              />
            </Col>
          </Row>
          <Row gutter={[GUTTER, GUTTER]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntInput}
                name='remark'
                type='text'
                label={t('remark')}
                submitCount={submitCount}
                disabled={values.isAcked}
              />
            </Col>
          </Row>
        </div>
      )}
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <Row gutter={[8, 8]}>
            <Col>
              <Link
                to='/notifications'
                state={{ fromNotificationDetails: true }}
              >
                <Button type='secondary' onClick={resetForm}>
                  {t('back')}
                </Button>
              </Link>
            </Col>
            {values.isAcked ? (
              <></>
            ) : (
              <>
                <Col>
                  <Button type='secondary' onClick={resetForm}>
                    {t('reset')}
                  </Button>
                </Col>
                <Col>
                  <Button type='primary' onClick={handleSubmit}>
                    {t('save')}
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
