import './index.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';
import { Formik } from 'formik';
import { DashboardBody } from './dashboard-body';
import moment from 'moment';
import { useUserProvider } from '../../context/session';
import { useFetchUsers } from '../../services/users';
import { Loader, PageHeader } from '../../components';
import { useFetchSites } from '../../services/sites';
import { SENSOR_TYPE_KEYS } from '../../utilities/constants';
import { useToggleFilters } from '../../hooks';

export const Dashboard = () => {
  const { isShowFilters, toggleFilters } = useToggleFilters();
  const { t } = useTranslation();
  const [user] = useUserProvider();
  const { user: profile } = user || {};
  const { id: userId } = profile || {};
  const { data: { users: userData } = {}, isLoading: fetchUserLoading } =
    useFetchUsers({ userId });
  const { data: { defaultLocation } = {} } = useFetchSites();

  const baseInitialValues = {
    startTime: moment().subtract(30, 'd'),
    endTime: moment(),
    timeRange: '1',
    types: [SENSOR_TYPE_KEYS.DETECTION_SENSOR],
    ...defaultLocation,
  };
  if (fetchUserLoading) {
    return (
      <div className='content'>
        <Title level={3}>{t('dashboard')}</Title>
        <Loader />
      </div>
    );
  }

  return (
    <div className='p-10' style={{ background: '#efefef' }}>
      <PageHeader
        title={t('dashboard')}
        isFilterDropDownAllowed
        isShowFilters={isShowFilters}
        toggleFilters={toggleFilters}
      />
      <Formik
        initialValues={{
          ...baseInitialValues,
        }}
        onSubmit={() => {}}
      >
        <DashboardBody isShowFilters={isShowFilters} />
      </Formik>
    </div>
  );
};

export default Dashboard;
