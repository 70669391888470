import React from 'react';
import './index.css';
import { Spin } from 'antd';

export const Loader = () => (
  <div className='spinner-wrapper'>
    <Spin />
  </div>
);

export default Loader;
