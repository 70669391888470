import { api, useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const SITES_CACHE_KEY = 'sites';
const fetchSites =
  (api) =>
  async ({ siteIds }) => {
    const { data } = await api.fetchSites({ siteIds });
    return {
      sites: data?.data?.sites || [],
      defaultLocation: data?.data?.defaultLocation || {},
      totalCount: data?.data?.totalCount || 0,
    };
  };

export const useFetchSites = (siteIds = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: SITES_CACHE_KEY,
      siteIds,
    },
    fetchSites(api),
    {}
  );
  return withLoading(response);
};

const editSite = (api) => async (body) => {
  return await api.editSite(body);
};

export const useEditSite = () => {
  const api = useApi();
  const mutate = useMatchMutate(SITES_CACHE_KEY);
  return useMutation(editSite(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const testSms = (api) => async (body) => {
  return await api.testSms(body);
};

export const useTestSms = () => {
  const api = useApi();
  const mutate = useMatchMutate(SITES_CACHE_KEY);
  return useMutation(testSms(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const addSite = (api) => async (body) => {
  return await api.addSite(body);
};

export const useAddSite = () => {
  const api = useApi();
  const mutate = useMatchMutate(SITES_CACHE_KEY);
  return useMutation(addSite(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const addSiteFloorPlan = (api) => async (body) => {
  return await api.addFloorPlan(body);
};

export const useAddSiteFloorPlan = () => {
  const api = useApi();
  const mutate = useMatchMutate(SITES_CACHE_KEY);
  return useMutation(addSiteFloorPlan(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteSite = (api) => async (body) => {
  return await api.deleteSite(body);
};

export const useDeleteSite = () => {
  const api = useApi();
  const mutate = useMatchMutate(SITES_CACHE_KEY);
  return useMutation(deleteSite(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
export const fetchSitesAsync = async (ids) => {
  const { data } = await api.fetchSites({ siteIds: ids });
  return {
    sites: data?.data?.sites || [],
    totalCount: data?.data?.totalCount || 0,
  };
};
