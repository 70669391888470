import './index.css';
import React from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Row, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import isEmpty from 'lodash/isEmpty';
import Text from 'antd/es/typography/Text';
import { formatDate, formatTime } from '../../../utilities/time-utils';
import Circle from '../../../components/circle';
import {
  EVENT_TYPES,
  LANGUAGE_CODES,
  SMS_STATE_KEYS,
} from '../../../utilities/constants';

const { Meta } = Card;

export const ListingPage = ({
  data,
  isShowCount,
  totalCount,
  isPagination,
  onPageChange,
  currentPage,
  pageSize,
}) => {
  const { t } = useTranslation();
  const isDataExist = !isEmpty(data);
  const CardTitle = ({ title, id, item }) => (
    <Row>
      <Col span={24}>
        <Row justify='space-between'>
          <Col>
            {id}{' '}
            {item?.smsState === SMS_STATE_KEYS.NOT_APPLICABLE && (
              <span style={{ color: '#ff4d4f' }}>{` [${t(
                'manualInput'
              )}] `}</span>
            )}
            <span style={{ color: '#2DB34A' }}>
              {'acked' === item.state && t('acknowledeged')}
            </span>
          </Col>
          <Col>
            <Tooltip title='move to detail'>
              <Link to={`${id}/view`}>
                <Button icon={<ArrowRightOutlined />} />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {t('date')}: {formatTime(item.eventStart)}
      </Col>
      <Col span={24}>
        {t('location')}:{' '}
        {item.sensor &&
          (i18next.language === LANGUAGE_CODES.ENGLISH
            ? item.sensor.floorName +
              ',' +
              item.sensor.layerName +
              ',' +
              item.sensor.zoneName
            : item.sensor.floorNameZh +
              ',' +
              item.sensor.layerNameZh +
              ',' +
              item.sensor.zoneNameZh)}
      </Col>
      <Col span={24}>
        {t('deviceName')}: {item.sensor && item.sensor.name}
      </Col>
    </Row>
  );
  const BatchBody = ({ title, id, item }) => (
    <Row>
      <Col span={24}>
        <Row justify='space-between'>
          <Col>
            {id}{' '}
            <span style={{ color: '#2DB34A' }}>
              {'acked' == item.state && t('acknowledeged')}
            </span>
          </Col>
          <Col>
            <Tooltip title='move to detail'>
              <Link to={`${id}/view`}>
                <Button icon={<ArrowRightOutlined />} />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {t('baitchange')} {formatDate(item.eventStart)}
      </Col>
      <Col span={24}>
        {t('location')}:{' '}
        {item.batch &&
          (i18next.language === LANGUAGE_CODES.ENGLISH
            ? item.batch.floorName
            : item.batch.floorNameZh)}
      </Col>
      <Col span={24}>
        {t('total')}: {item.batch && item.batch.batchItemList.length}
      </Col>
    </Row>
  );
  const PoisonBaitBody = ({ title, id, item }) => (
    <Row>
      <Col span={24}>
        <Row justify='space-between'>
          <Col>
            {id}{' '}
            <span style={{ color: '#2DB34A' }}>
              {'acked' == item.state && t('acknowledeged')}
            </span>
          </Col>
          <Col>
            <Tooltip title='move to detail'>
              <Link to={`${id}/view`}>
                <Button icon={<ArrowRightOutlined />} />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {t('fill_poison_bait')} {formatDate(item.eventStart)}
      </Col>
      <Col span={24}>
        {t('location')}:{' '}
        {item.batch &&
          (i18next.language === LANGUAGE_CODES.ENGLISH
            ? item.batch.floorName
            : item.batch.floorNameZh)}
      </Col>
      <Col span={24}>
        {t('total')}: {item.batch && item.batch.batchItemList.length}
      </Col>
    </Row>
  );
  const BatteryReplaceBody = ({ title, id, item }) => {
    return (
      <Row>
        <Col span={24}>
          <Row justify='space-between'>
            <Col>
              {id}{' '}
              <span style={{ color: '#2DB34A' }}>
                {'acked' == item.state && t('acknowledeged')}
              </span>
            </Col>
            <Col>
              <Tooltip title='move to detail'>
                <Link to={`${id}/view`}>
                  <Button icon={<ArrowRightOutlined />} />
                </Link>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {t('batteryReplacement')} {formatDate(item?.eventStart)}
        </Col>
        {/*<Col span={24}>*/}
        {/*    {t('location')}:{' '}*/}
        {/*    {item.batch &&*/}
        {/*        (i18next.language === LANGUAGE_CODES.ENGLISH*/}
        {/*            ? item.batch.floorName*/}
        {/*            : item.batch.floorNameZh)}*/}
        {/*</Col>*/}
        {/*<Col span={24}>*/}
        {/*    {t('total')}: {item.batch && item.batch.batchItemList.length}*/}
        {/*</Col>*/}
      </Row>
    );
  };
  const Listing = ({ data }) =>
    data?.map((item, index) =>
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      item.eventType == 'baitchange' ? (
        <Card
          key={index}
          style={{ marginTop: 16, backgroundColor: '#FFE1CE' }}
          hoverable
        >
          <Link to={`${item.id}/view`}>
            <Meta
              avatar={item?.state === 'pending' ? <Circle /> : null}
              title={
                <BatchBody title={item?.title} id={item?.id} item={item} />
              }
              // description={item?.body}
            />
          </Link>
        </Card>
      ) : item.eventType == 'fill_poison_bait' ? (
        <Card
          key={index}
          style={{ marginTop: 16, backgroundColor: '#D2EFD0' }}
          hoverable
        >
          <Link to={`${item.id}/view`}>
            <Meta
              avatar={item?.state === 'pending' ? <Circle /> : null}
              title={
                <PoisonBaitBody title={item?.title} id={item?.id} item={item} />
              }
              // description={item?.body}
            />
          </Link>
        </Card>
      ) : item.eventType === EVENT_TYPES.BATTERY_REPLACE ? (
        <Card
          key={index}
          style={{ marginTop: 16, backgroundColor: '#B8B3CE' }}
          hoverable
        >
          <Link to={`${item.id}/view`}>
            <Meta
              avatar={item?.state === 'pending' ? <Circle /> : null}
              title={
                <BatteryReplaceBody
                  title={item?.title}
                  id={item?.id}
                  item={item}
                />
              }
            />
          </Link>
        </Card>
      ) : (
        <Card
          key={index}
          style={{ marginTop: 16, backgroundColor: '#FFF3BE' }}
          hoverable
        >
          <Link to={`${item.id}/view`}>
            <Meta
              avatar={item?.state === 'pending' ? <Circle /> : null}
              title={
                <CardTitle title={item?.title} id={item?.id} item={item} />
              }
              // description={item?.body}
            />
          </Link>
        </Card>
      )
    );
  return (
    <>
      <Listing data={data} />
      <div className='footer'>
        {isShowCount && isDataExist && (
          <div className='total-count'>
            <Text strong>
              {t('total')}: {totalCount || 0}
            </Text>
          </div>
        )}
        {isPagination && isDataExist && (
          <Pagination
            onChange={onPageChange}
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={totalCount}
          />
        )}
      </div>
    </>
  );
};

export default ListingPage;
