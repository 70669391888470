import { Col, Empty, Form, Modal, notification, Row, Spin } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  NOTIFICATION_STATUSES,
  SENSOR_TYPES1,
  YSENO,
} from '../../../utilities/constants';
import { useForceUpdate, useFormStateBackup } from '../../../hooks';
import ListingPage from './listing-page';
import { useFetchNotifications } from '../../../services/notifications';
import {
  AntInput,
  AntSelect,
  DateFilterForm,
  ShowHideWrapper,
  SiteFloorLayerZoneSelectionForm,
  ZoomAbleFloorPlan,
} from '../../../components';
import { isEmpty } from 'lodash';
import { FORM_STORAGE_KEYS } from '../../../utilities/storage';
import {
  getFloorImageUrl,
  getFloorLayerAndZoneFromSite,
} from '../../../utilities/transform';
import { useFetchSites } from '../../../services/sites';
import { useActiveSiteProvider } from '../../../context/site';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { getColoredSensors } from './transform';

export const NotificationFilterForm = ({
  sensorType,
  initialValues,
  isShowFilters,
  showFloorPlan,
  setShowFloorPlan,
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { setFieldValue, values } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const { pageSize, pageNumber } = values || {};
  const { data: { sites, defaultLocation } = {} } = useFetchSites();
  const { activeSite: activeSiteId } = useActiveSiteProvider();
  const { handleReset } = useFormStateBackup({
    formKey: FORM_STORAGE_KEYS.NOTIFICATION_LIST,
    callback: forceUpdate,
    initialValues,
  });
  useEffect(() => {
    if (!state?.fromNotificationDetails) {
      setFieldValue('startTime', moment().startOf('day').subtract(6, 'day'));
      setFieldValue('endTime', moment().endOf('day'));
      setFieldValue('falseAlarm', [0, 1]);
      setFieldValue('state', ['pending', 'acked']);
      setFieldValue('sensorName', '');
      setFieldValue('sensorId', '');
      setFieldValue('type', ['trap_sensor', 'baitchange', 'fill_poison_bait']);
    }
  }, []);
  useEffect(async () => {
    if (defaultLocation && !state?.fromNotificationDetails) {
      locationReset();
    }
  }, [defaultLocation]);
  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value === defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('floorId', activeSite1.floors[0].value);
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }

      forceUpdate();
    }
  };
  // const locationReset = async () => {
  //   if(defaultLocation){
  //   	await setFieldValue('layerId', [defaultLocation.layerId]);
  //   	await setFieldValue('floorId', defaultLocation.floorId);
  //   	await setFieldValue('zoneId', [defaultLocation.zoneId]);
  //   	const selectedSite = sites.find((site) => site.id === defaultLocation.siteId);
  //   	// var activeSite1 = getFloorLayerAndZoneFromSite(selectedSite, selectedSite?.id);
  //   	// activeSite1.zones.map(item => item.value);
  //   	await setFieldValue('zoneId', selectedSite.zones.map(item => item.id));
  //   	values.layerId=[defaultLocation.layerId];
  //   	values.floorId=[defaultLocation.floorId];
  //   	values.zoneId=selectedSite.zones.map(item => item.id);
  //   	forceUpdate();
  //   }
  // };
  const filters = useMemo(() => {
    const {
      siteId,
      floorId,
      layerId,
      zoneId,
      startTime,
      endTime,
      sensorName,
      sensorId,
      state,
      pageNumber,
      pageSize,
      type,
      falseAlarm,
    } = values || {};
    const filters = {
      type,
      sensorName,
      sensorId,
      state,
      falseAlarm,
      pageNumber,
      count: pageSize,
    };

    if (startTime) {
      filters.startTime = startTime.valueOf();
    }
    if (endTime) {
      filters.endTime = endTime.valueOf();
    }
    if (siteId) {
      filters.siteId = siteId;
      filters.floorId = floorId;

      if (!isEmpty(layerId)) {
        filters.layerId = layerId;
      }
      if (!isEmpty(zoneId)) {
        filters.zoneId = zoneId;
      }
    }
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdateKey, sensorType]);

  const { data, isValidating } = useFetchNotifications(filters);

  const {
    notifications = [],
    totalCount,
    sensors = [],
    floorPath = '',
  } = data || {};
  const coloredSensors = useMemo(() => getColoredSensors(sensors), [sensors]);
  const handleSubmit = async () => {
    if (!values.siteId && !values.sensorName) {
      return notification.error({
        message: t('siteIsRequired'),
      });
    }
    if (!values.floorId && !values.sensorName) {
      return notification.error({
        message: t('floorIsRequired'),
      });
    }
    forceUpdate();
  };
  return (
    <Spin spinning={isValidating}>
      <Form>
        <ShowHideWrapper isShow={isShowFilters}>
          <Row gutter={[10, 10]} align='middle'>
            <SiteFloorLayerZoneSelectionForm
              isZone={true}
              isMultiLayer={true}
              isMultiZone={true}
            />

            <Col md={{ span: 4 }} xs={{ span: 24 }}>
              <Field
                component={AntInput}
                name='sensorName'
                type='text'
                label={t('name')}
                placeholder={t('pleaseInputName')}
              />
            </Col>
            <Col md={{ span: 4 }} xs={{ span: 24 }}>
              <Field
                component={AntInput}
                name='sensorId'
                type='text'
                label={t('code')}
                placeholder={t('pleaseInputCode')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 5 }}>
              <Field
                component={AntSelect}
                style={{ width: '100%' }}
                name='type'
                mode='multiple'
                label={t('type')}
                placeholder={t('pleaseSelectType')}
                selectOptions={SENSOR_TYPES1}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 4 }}>
              <Field
                style={{ width: '100%' }}
                mode='multiple'
                component={AntSelect}
                name='state'
                label={t('state')}
                placeholder={t('pleaseSelectState')}
                selectOptions={NOTIFICATION_STATUSES}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 3 }}>
              <Field
                style={{ width: '100%' }}
                mode='multiple'
                component={AntSelect}
                name='falseAlarm'
                label={t('falseAlarm')}
                placeholder={t('pleaseSelectFalseAlarm')}
                selectOptions={YSENO}
              />
            </Col>
            <DateFilterForm
              handleSubmit={handleSubmit}
              handleReset={async () => {
                await handleReset();
                locationReset();
                forceUpdate();
              }}
            />
          </Row>
        </ShowHideWrapper>
      </Form>
      {useMemo(() => {
        return (
          <ListingPage
            data={notifications}
            isPagination
            isShowCount
            onPageChange={async (pageNumber) => {
              await setFieldValue('pageNumber', pageNumber);
              forceUpdate();
            }}
            currentPage={pageNumber}
            totalCount={totalCount}
            pageSize={pageSize}
          />
        );
      }, [
        notifications,
        pageNumber,
        totalCount,
        pageSize,
        setFieldValue,
        forceUpdate,
      ])}
      {isEmpty(notifications) && (
        <Empty description={<span>{t('noData')}</span>} />
      )}
      <Modal
        width={'100%'}
        title=''
        visible={showFloorPlan}
        onOk={() => setShowFloorPlan(false)}
        onCancel={() => setShowFloorPlan(false)}
      >
        <ZoomAbleFloorPlan
          sensors={coloredSensors}
          floorPlan={getFloorImageUrl(floorPath)}
        />
      </Modal>
    </Spin>
  );
};
