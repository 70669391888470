import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../components';
import { TrapSensorReportForm } from './trap-sensor-report-form';
import { Formik } from 'formik';
import {
  SENSOR_ATTRIBUTE_KEYS,
  SENSOR_PAGE_KEYS,
} from '../../../utilities/constants';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const INITIAL_VALUES = {
  startTime: moment().startOf('day').subtract(1, 'month'),
  endTime: moment().endOf('day'),
  types: [SENSOR_ATTRIBUTE_KEYS.CAUGHT, SENSOR_ATTRIBUTE_KEYS.MISSED],
};

export const TrapSensorReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSensorType = (routeKey) => {
    navigate(
      routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
        ? '/trap-sensor/report'
        : '/detection-sensor/report'
    );
  };
  return (
    <div className='p-10'>
      <PageHeader
        title={t('report')}
        sensorType={SENSOR_PAGE_KEYS.TRAP_SENSOR}
        handleSensorType={handleSensorType}
      />

      <Formik initialValues={INITIAL_VALUES} onSubmit={() => {}}>
        <TrapSensorReportForm />
      </Formik>
    </div>
  );
};

export default TrapSensorReport;
