import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { Breadcrumbs, EventForm } from '../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { addEventSchema } from '../../../utilities/schemas';

const buildInitialValues = (n) => {
  let { createTime, eventType } = n;
  return {
    ...n,
    type: eventType,
    time: moment(n?.eventStart),
    date: moment(n?.eventStart),
  };
};

const { Title } = Typography;
export const EditEvent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const initialValues = buildInitialValues(state);
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('event1'),
            link: '/events',
          },
          {
            name: t('edit'),
            link: `/events/${state.id}/edit`,
          },
        ]}
      />
      <Title level={3}>{t('editevent')}</Title>

      <Formik
        enableReinitialize={false}
        validationSchema={addEventSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {}}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin spinning={false}>
              <EventForm
                dirty={dirty}
                values={initialValues}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default EditEvent;
