import './index.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import moment from 'moment';
import { NotificationFilterForm } from './notification-filter-form';
import { PageHeader } from '../../../components';
import {
  DEFAULT_PAGE_SIZE,
  SENSOR_TYPE_KEYS,
} from '../../../utilities/constants';
import { useToggleFilters } from '../../../hooks';

const INITIAL_VALUES = {
  pageNumber: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  type: ['trap_sensor', 'baitchange', 'fill_poison_bait'],
  sensorName: null,
  sensorId: null,
  layerId: [28],
  state: ['pending', 'acked'],
  startTime: moment().startOf('day').subtract(1, 'day'),
  endTime: moment().endOf('day'),
};
export const NotificationList = () => {
  const [sensorType, setSensorType] = useState(SENSOR_TYPE_KEYS.TRAP_SENSOR);
  // const { data: { sites ,defaultLocation } = {}, isLoading: fetchSiteLoading } = useFetchSites();
  const [showFloorPlan, setShowFloorPlan] = useState(false);
  const { t } = useTranslation();
  const { isShowFilters, toggleFilters, showFilters, hideFilters } =
    useToggleFilters();

  useEffect(() => {
    if (window.innerWidth > 768) {
      showFilters();
    } else {
      hideFilters();
    }
  }, [window.innerWidth]);
  return (
    <div className='p-10'>
      <Formik initialValues={INITIAL_VALUES} onSubmit={() => {}}>
        <div>
          <PageHeader
            title={t('notification')}
            to={`/notifications/add`}
            isCreate={true}
            sensorType={sensorType}
            sensorTypeOptionsList={[]}
            handleSensorType={(value) => {
              setSensorType(value);
            }}
            isFilterDropDownAllowed
            isShowFilters={isShowFilters}
            toggleFilters={toggleFilters}
            isFloorPlanPin={true}
            setShowFloorPlan={setShowFloorPlan}
          />
          <NotificationFilterForm
            sensorType={sensorType}
            initialValues={INITIAL_VALUES}
            isShowFilters={isShowFilters}
            showFloorPlan={showFloorPlan}
            setShowFloorPlan={setShowFloorPlan}
          />
        </div>
      </Formik>
    </div>
  );
};

export default NotificationList;
