import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { BatteryReplaceForm, Breadcrumbs } from '../../../../../components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AddEventBatteryReplaceSchema } from '../../../../../utilities/schemas';
import {
  useDeleteEventBatteryReplace,
  useEditEventBatteryReplace,
  useFetchEventBatteryReplaceDetails,
} from '../../../../../services/eventBatteryReplace';
import moment from 'moment';

const { Title } = Typography;
const buildInitialValues = (
  eventBatteryChange,
  eventBatteryChangeItemList,
  notificationData
) => {
  const { siteId, layerId, floorId, zoneId, batteryLevel, sensorType } =
    eventBatteryChange || {};
  const sensors = eventBatteryChangeItemList?.map((sensor) => sensor?.sensorId);
  return {
    siteId,
    floorId,
    layerId: layerId ? JSON.parse(layerId) : [],
    zoneId: zoneId ? JSON.parse(zoneId) : [],
    sensorType,
    batteryLevel,
    checkedList: sensors,
    eventStart: moment(notificationData?.eventStart),
    eventEnd: moment(notificationData?.eventEnd),
  };
};
export const EditEventBatteryReplace = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const { isFromEvent = false } = state || { isFromEvent: false };
  const {
    data: {
      eventBatteryChange,
      eventBatteryChangeItemList,
      notification: notificationData,
    } = {},
    isLoading,
  } = useFetchEventBatteryReplaceDetails({ notificationId: id });
  const { mutate: editEventBatteryReplace } = useEditEventBatteryReplace();
  const { mutate: deleteEventBatteryReplace } = useDeleteEventBatteryReplace();
  if (isLoading) return null;
  const initialValues = buildInitialValues(
    eventBatteryChange,
    eventBatteryChangeItemList,
    notificationData
  );

  const handleDeleteEventBatteryReplace = async () => {
    try {
      const { id } = eventBatteryChange;
      await deleteEventBatteryReplace({ eventBatteryChangeId: id });
      notification.success({ message: t('deletedSuccess') });
      navigate('/events');
    } catch (message) {
      notification.error({ message });
    }
  };
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          !isFromEvent
            ? {
                name: t('trap_clean'),
                link: '/event-trap-clean-management',
              }
            : {
                name: t('event1'),
                link: '/events',
              },
          {
            name: t('edit'),
            link: `/event-trap-clean-management/add`,
          },
        ]}
      />
      <Title level={3}>{t('batteryReplacement')}</Title>

      <Formik
        validationSchema={AddEventBatteryReplaceSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            const { id, notificationId, state } = eventBatteryChange;
            actions.setSubmitting(true);
            await editEventBatteryReplace({
              ...values,
              id,
              notificationId,
              state,
            });
            notification.success({ message: t('createdSuccess') });
            navigate('/events');
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Spin spinning={isSubmitting}>
              <BatteryReplaceForm
                handleSubmit={handleSubmit}
                handleDelete={handleDeleteEventBatteryReplace}
                editMode={true}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default EditEventBatteryReplace;
