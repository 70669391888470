import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form } from 'formik';
import {
  AntInput,
  AntSelect,
  AntTextAreaInput,
  DirectionInput,
  PageError,
} from '../index';
import { Button, Col, Popconfirm, Row, Spin } from 'antd';
import {
  ENVIRONMENTS,
  SENSOR_PAGE_KEYS,
  SENSOR_TYPES,
  STATUSES,
} from '../../utilities/constants';
import { useFetchSites } from '../../services/sites';
import { Link } from 'react-router-dom';
import {
  getFloorImageUrl,
  getFloorLayerAndZoneFromSite,
  getFormattedSites,
} from '../../utilities/transform';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { ROUTE_KEY_TO_SENSOR_TYPE_MAP } from '../../pages/sensor/constants';
import { SensorFloorPlans } from './sensor-floor-plans';
import { useDeviceEfficient } from '../../services/sensors';

export const AddEditSensorForm = ({
  sensorId = 0,
  isEditMode = true,
  routeKey,
  values,
  submitCount,
  resetForm,
  handleSubmit,
  setFieldValue,
  handleDeleteSensor,
}) => {
  const { siteId, floorId } = values || {};
  const childRef = useRef();
  const [activeSite, setActiveSite] = useState({});
  let { data: { deviceList } = {} } = useDeviceEfficient({});
  const { data: { sites } = {}, isLoading: fetchSiteLoading } = useFetchSites();
  const { t, i18n } = useTranslation();
  deviceList &&
    deviceList.forEach((n) => {
      n.key = n.code;
      n.value = n.code;
      n.label = n.code;
    });
  if (deviceList) {
    deviceList = deviceList.sort((a, b) => b.code - a.code);
  }

  useEffect(() => {
    if (!siteId || isEmpty(sites)) {
      return;
    }
    const selectedSite = sites.find((site) => site.id === siteId);
    if (isEmpty(selectedSite)) {
      return;
    }
    setActiveSite(getFloorLayerAndZoneFromSite(selectedSite, siteId));
  }, [sites, siteId, i18n.language]);

  const siteOptions = useMemo(
    () => getFormattedSites(sites),
    [sites, i18n.language]
  );

  const activeFloorInfo = useMemo(() => {
    const { floors = [] } = activeSite || {};
    return floors.find((item) => item.value === floorId);
  }, [floorId, activeSite]);

  const floorImageUrl = getFloorImageUrl(activeFloorInfo?.floorPath);

  if (fetchSiteLoading) {
    return <Spin spinning />;
  }

  if (isEmpty(sites)) {
    return <PageError description='Unable to fetch the data' />;
  }

  const handleSiteChange = async () => {
    await setFieldValue('layerId', null);
    await setFieldValue('floorId', null);
    await setFieldValue('zoneId', null);
  };

  return (
    <>
      <Form>
        <Row gutter={[10, 10]} align='top'>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            {routeKey !== SENSOR_PAGE_KEYS.TRAP_SENSOR && (
              <Field
                component={AntInput}
                name='sensorId'
                type='text'
                label={t('id')}
                placeholder={t('pleaseInputId')}
                submitCount={submitCount}
                required
              />
            )}
            {routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR && (
              <Field
                component={AntSelect}
                name='sensorId'
                label={t('id')}
                placeholder={t('pleaseInputId')}
                submitCount={submitCount}
                selectOptions={deviceList}
                hasFeedback
                required
              />
            )}
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntInput}
              name='name'
              type='text'
              label={t('name')}
              placeholder={t('pleaseInputName')}
              submitCount={submitCount}
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntInput}
              name='nameShort'
              type='text'
              label={t('shortName')}
              placeholder={t('pleaseInputShortName')}
              submitCount={submitCount}
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntSelect}
              name='type'
              label={t('deviceType')}
              placeholder={t('pleaseSelectDeviceType')}
              submitCount={submitCount}
              selectOptions={SENSOR_TYPES}
              required
              disabled
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              disabled={true}
              component={AntSelect}
              name='siteId'
              label={t('site')}
              placeholder={t('pleaseSelectSite')}
              submitCount={submitCount}
              selectOptions={siteOptions}
              onSelect={(label, option) => handleSiteChange(option.value)}
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntSelect}
              name='floorId'
              label={t('floor')}
              placeholder={t('pleaseSelectFloor')}
              submitCount={submitCount}
              selectOptions={activeSite.floors}
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntSelect}
              name='layerId'
              label={t('layer')}
              placeholder={t('pleaseSelectLayer')}
              submitCount={submitCount}
              selectOptions={activeSite.layers}
              required
            />
          </Col>
          {routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR && (
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <DirectionInput
                required
                initialValue={values?.direction}
                onChange={(value) => {
                  setFieldValue('direction', value);
                }}
              />
            </Col>
          )}
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              name='isProduction'
              component={AntSelect}
              selectOptions={ENVIRONMENTS}
              label={t('testingOrProduction')}
              placeholder={t('pleaseSelectEnvironment')}
              submitCount={submitCount}
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              name='state'
              component={AntSelect}
              selectOptions={STATUSES}
              label={t('status')}
              placeholder={t('pleaseSelectStatus')}
              submitCount={submitCount}
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntSelect}
              name='zoneId'
              label={t('zone')}
              placeholder={t('pleaseSelectZone')}
              submitCount={submitCount}
              selectOptions={activeSite.zones}
              required
            />
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntInput}
              name='manufacturer'
              type='text'
              label={t('manufacturer')}
              placeholder={t('pleaseInputManufacturer')}
              submitCount={submitCount}
              hasFeedback
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntTextAreaInput}
              name='remark'
              label={t('remarks')}
              type='text'
              placeholder={t('pleaseInputRemarks')}
              submitCount={submitCount}
              hasFeedback
            />
          </Col>
          <Col>
            <Link to={`/${routeKey}/list`}>
              <Button type='secondary' block>
                {t('back')}
              </Button>
            </Link>
          </Col>
          <Col>
            <Button
              type='secondary'
              block
              onClick={async () => {
                await resetForm();
                childRef.current?.refetchData();
              }}
            >
              {t('reset')}
            </Button>
          </Col>
          <Col>
            <Button
              type='primary'
              block
              onClick={async () => {
                const updatedItem = childRef.current?.getUpdatedSensorInfo();
                await setFieldValue('updatedItem', updatedItem);
                await handleSubmit();
              }}
            >
              {t('save')}
            </Button>
          </Col>
          {isEditMode && (
            <Col>
              <Popconfirm
                title='Are you sure to delete this sensor?'
                onConfirm={handleDeleteSensor}
                okText='Yes'
                cancelText='No'
              >
                <Button type='danger' block>
                  {t('delete')}
                </Button>
              </Popconfirm>
            </Col>
          )}
        </Row>
        {siteId && floorId && floorImageUrl && (
          <SensorFloorPlans
            ref={childRef}
            routeKey={routeKey}
            sensorType={ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey]}
            floorImageUrl={floorImageUrl}
            editSensorId={sensorId}
            draggableSensors={[sensorId]}
            isEditMode={isEditMode}
          />
        )}
      </Form>
    </>
  );
};
