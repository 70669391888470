import { useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const DEVICE_CACHE_KEY = 'device';
const ALL = 'all';

const fetchDevice = (api, filters) => async () => {
  const { data } = await api.fetchDevice(filters);
  return {
    deviceList: data?.data?.deviceList || [],
    totalCount: data?.data?.totalCount || [],
  };
};

export const useFetchDevice = (filters) => {
  const api = useApi();
  const response = useSWR(
    `${DEVICE_CACHE_KEY}/${filters.deviceId || ALL}`,
    fetchDevice(api, filters)
  );
  return withLoading(response);
};

const fetchDeviceDetails = (api, filters) => async () => {
  const { data } = await api.fetchDeviceDetails(filters);
  return {
    device: data?.data?.device || {},
  };
};

export const useFetchDeviceDetails = ({ deviceId }) => {
  const api = useApi();
  const response = useSWR(
    `DEVICE_CACHE_KEY/${deviceId}`,
    fetchDeviceDetails(api, { deviceId })
  );
  return withLoading(response);
};

const fetchDeviceBySensorId = (api, filters) => async () => {
  const { data } = await api.fetchDeviceBySensorId(filters);
  return {
    device: data?.data?.device || {},
    sensor: data?.data?.sensor || {},
  };
};

export const useFetchDeviceBySensorId = ({ sensorId }) => {
  const api = useApi();
  const response = useSWR(
    `DEVICE_CACHE_KEY/${sensorId}`,
    fetchDeviceBySensorId(api, { sensorId })
  );
  return withLoading(response);
};
const addDevice = (api) => async (body) => {
  return await api.addDevice(body);
};

export const useAddDevice = () => {
  const api = useApi();
  const mutate = useMatchMutate(DEVICE_CACHE_KEY);
  return useMutation(addDevice(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const editDevice = (api) => async (body) => {
  return await api.editDevice(body);
};

export const useEditDevice = () => {
  const api = useApi();
  const mutate = useMatchMutate(DEVICE_CACHE_KEY);
  return useMutation(editDevice(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteDevice =
  (api) =>
  async ({ deviceId }) => {
    return await api.deleteDevice({ deviceId });
  };

export const useDeleteDevice = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${DEVICE_CACHE_KEY}/${ALL}`);
  return useMutation(deleteDevice(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
