import { useSWRConfig } from 'swr';

export const useMatchMutate = (matcher) => {
  const { cache, mutate } = useSWRConfig();
  return () => {
    if (!(cache instanceof Map)) {
      throw new Error(
        'matchMutate requires the cache provider to be a Map instance'
      );
    }

    const keys = [];
    const regexp = typeof matcher === 'string' ? new RegExp(matcher) : matcher;

    for (const key of cache.keys()) {
      if (regexp.test(key)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) => mutate(key));
    return Promise.all(mutations);
  };
};

export const withLoading = (item) => {
  return {
    ...item,
    isLoading: item.data === undefined && item.error === undefined,
  };
};
