import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Image, Space, Tooltip } from 'antd';
import { useFetchDevice } from '../../../services/device';
import { formatDate } from '../../../utilities/time-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import {
  Breadcrumbs,
  CustomTable,
  PageError,
  PageHeader,
} from '../../../components';
import { LANGUAGE_CODES } from '../../../utilities/constants';
import { searchTableFields, sorter } from '../../../utilities/transform';
import {
  FORM_STORAGE_KEYS,
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../../utilities/storage';
import { useActiveSiteProvider } from '../../../context/site';

const buildTableColumns = ({ setPreviewImagePath, setVisible }) => {
  return [
    {
      key: 'id',
      title: i18next.t('id'),
      dataIndex: 'id',
      sorter: (a, b) => sorter(a.id, b.id),
      render: (text, record) => {
        return <p>{record.id}</p>;
      },
    },
    {
      key: 'id',
      title: i18next.t('code'),
      dataIndex: 'code',
      sorter: (a, b) => sorter(a.code, b.code),
      render: (text, record) => {
        return <p>{record.code}</p>;
      },
    },
    {
      key: 'state',
      title: i18next.t('state'),
      dataIndex: 'state',
      sorter: (a, b) => sorter(a.state, b.state),
      render: (text, record) => {
        return <p>{record.state}</p>;
      },
    },
    {
      key: 'id',
      title: i18next.t('site'),
      dataIndex: 'siteId',
      sorter: (a, b) => sorter(a.siteId, b.baitId),
      render: (text, record) => {
        return (
          <p>
            {record.sensor &&
              (i18next.language === LANGUAGE_CODES.ENGLISH
                ? record.sensor.siteName
                : record.sensor.siteNameZh)}
          </p>
        );
      },
    },
    {
      key: 'id',
      title: i18next.t('floor'),
      dataIndex: 'floorId',
      sorter: (a, b) => sorter(a.floorId, b.floorId),
      render: (text, record) => {
        return (
          <p>
            {record.sensor &&
              (i18next.language === LANGUAGE_CODES.ENGLISH
                ? record.sensor.floorName
                : record.sensor.floorNameZh)}
          </p>
        );
      },
    },
    {
      key: 'id',
      title: i18next.t('zone'),
      dataIndex: 'zoneId',
      sorter: (a, b) => sorter(a.zoneId, b.zoneId),
      render: (text, record) => {
        return (
          <p>
            {record.sensor &&
              (i18next.language === LANGUAGE_CODES.ENGLISH
                ? record.sensor.zoneName
                : record.sensor.zoneNameZh)}
          </p>
        );
      },
    },
    {
      key: 'id',
      title: i18next.t('layer'),
      dataIndex: 'layerId',
      sorter: (a, b) => sorter(a.layerId, b.layerId),
      render: (text, record) => {
        return (
          <p>
            {record.sensor &&
              (i18next.language === LANGUAGE_CODES.ENGLISH
                ? record.sensor.layerName
                : record.sensor.layerNameZh)}
          </p>
        );
      },
    },
    {
      key: 'id',
      title: i18next.t('bait'),
      dataIndex: 'baitId',
      sorter: (a, b) => sorter(a.baitId, b.baitId),
      render: (text, record) => {
        return <p>{record.sensor?.baitName}</p>;
      },
    },
    {
      key: 'id',
      title: i18next.t('date'),
      dataIndex: 'createTime',
      sorter: (a, b) => sorter(a.createTime, b.createTime),
      render: (text, record) => {
        return <p>{formatDate(record.createTime)}</p>;
      },
    },
    {
      title: i18next.t('action'),
      key: 'action',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <Tooltip title='move to edit'>
              <Link to={`${record?.id}/edit`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

export const ListTrap = () => {
  const { t } = useTranslation();
  const { search: savedSearch } = getSessionStorageItem(
    FORM_STORAGE_KEYS.BAIT_LIST
  );
  const [search, setSearch] = useState(savedSearch || '');
  const [visible, setVisible] = useState(false);
  const [previewImagePath, setPreviewImagePath] = useState(null);
  const { activeSite: activeSiteId } = useActiveSiteProvider();
  const {
    data: { deviceList, totalCount } = {},
    isLoading,
    error,
  } = useFetchDevice({ siteId: activeSiteId.value });

  const columns = useMemo(() => {
    return buildTableColumns({
      setPreviewImagePath,
      setVisible,
    });
    // eslint-disable-next-line
  }, [t, setPreviewImagePath, setVisible]);

  const filteredData = useMemo(() => {
    return searchTableFields(search, columns, deviceList);
  }, [search, deviceList, columns]);

  const onChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);
  useEffect(() => {
    return () => {
      setSessionStorageItem(FORM_STORAGE_KEYS.BAIT_LIST, { search });
    };
  }, [search]);
  if (error) {
    return (
      <div className='p-10'>
        <PageHeader title={t('baitManagement')} />
        <PageError description='Error is found' />
      </div>
    );
  }
  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('trapManagement'),
            link: '/trap-management',
          },
        ]}
      />
      <PageHeader
        onChange={onChange}
        title={t('trapManagement')}
        isSearch
        isCreate
        to='add'
        search={search}
      />
      <CustomTable
        rowKey='id'
        columns={columns}
        dataSource={filteredData}
        isLoading={isLoading}
        isShowCount
        totalCount={totalCount}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={previewImagePath}
        onError={() => null}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
            setPreviewImagePath(null);
          },
        }}
      />
    </div>
  );
};

export default ListTrap;
