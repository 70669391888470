import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, 'loginNameMinValidation')
    .max(50, 'tooLong')
    .required('loginNameIsRequired'),
  password: Yup.string()
    .min(2, 'passwordMinValidation')
    .max(50, 'tooLong')
    .required('passwordRequireValidation'),
});
export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(5, 'oldPasswordMinValidation')
    .max(50, 'tooLongValidation')
    .required('oldPasswordRequireValidation'),
  newPassword: Yup.string()
    .notOneOf([Yup.ref('oldPassword'), null], 'newPasswordMatchValidation')
    .min(5, 'newPasswordMinValidation')
    .max(50, 'tooLongValidation')
    .required('newPasswordRequireValidation'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'confirmPasswordMatchValidation')
    .min(5, 'confirmPasswordMinValidation')
    .max(50, 'tooLongValidation')
    .required('confirmPasswordRequireValidation'),
});
export const AddAccountInformationSchema = Yup.object().shape({
  roleId: Yup.number().typeError('roleIsRequired').required('roleIsRequired'),
  name: Yup.string().trim().required('nameIsRequired'),
  email: Yup.string().trim().email('emailInvalid').required('emailIsRequired'),
  title: Yup.string().trim().required('titleIsRequired'),
  userPassword: Yup.string()
    .min(5, 'passwordMinValidation')
    .max(50, 'tooLong')
    .required('passwordRequireValidation'),
  siteId: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  floorId: Yup.number()
    .typeError('floorIsRequired')
    .required('floorIsRequired'),
  layerId: Yup.number()
    .typeError('layerIsRequired')
    .required('layerIsRequired'),
});
export const EditAccountInformationSchema = Yup.object().shape({
  email: Yup.string().trim().email('emailInvalid').required('emailIsRequired'),
  name: Yup.string().trim().required('nameIsRequired'),
  roleId: Yup.string().required('roleIsRequired'),
  title: Yup.string().trim().required('titleIsRequired'),
  state: Yup.string().required('statusIsRequired'),
  siteId: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  floorId: Yup.number()
    .typeError('floorIsRequired')
    .required('floorIsRequired'),
  layerId: Yup.array().typeError('layerIsRequired').required('layerIsRequired'),
});

export const EditSiteInformationSchema = Yup.object().shape({
  siteKey: Yup.string().trim().required('keyIsRequired'),
  name: Yup.string().trim().required('siteNameIsRequired'),
  nameZh: Yup.string().trim().required('siteNameIsRequired'),
  address: Yup.string().trim(),
  contactEmail: Yup.string()
    .email('emailInvalid')
    .required('contactEmailIsRequired'),
  contactPerson: Yup.string().trim(),
  contactPhone: Yup.string().trim(),
  remark: Yup.string().trim(),
  floors: Yup.array()
    .of(
      Yup.object()
        .shape({
          floorKey: Yup.number()
            .typeError('idIsRequired')
            .required('idIsRequired'),
          name: Yup.string().trim().required('nameIsRequired'),
          nameZh: Yup.string().trim().required('nameIsRequired'),
        })
        .noUnknown(false)
    )
    .required(),
  layers: Yup.array()
    .of(
      Yup.object()
        .shape({
          layerKey: Yup.number()
            .typeError('idIsRequired')
            .required('idIsRequired'),
          name: Yup.string().trim().required('nameIsRequired'),
          nameZh: Yup.string().trim().required('nameIsRequired'),
        })
        .noUnknown(false)
    )
    .required(),
  zones: Yup.array()
    .of(
      Yup.object()
        .shape({
          zoneKey: Yup.number()
            .typeError('idIsRequired')
            .required('idIsRequired'),
          name: Yup.string().trim().required('nameIsRequired'),
          nameZh: Yup.string().trim().required('nameIsRequired'),
        })
        .noUnknown(false)
    )
    .required(),
  triggerMedium: Yup.string('triggerMediumIsRequired').required(
    'triggerMediumIsRequired'
  ),
  emailList: Yup.string().trim(),
  smsAlertList: Yup.string()
    .trim()
    .matches(/^(852[0-9]{8})*(,852[0-9]{8})*$/, 'smsListFormat'),
  emailAlertList: Yup.string()
    .trim()
    .matches(
      /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*(,(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/,
      'emailListFormat'
    ),
  accountAlertList: Yup.string().trim(),
});
export const DashboardFilterSchema = Yup.object().shape({
  sites: Yup.number().typeError('siteIsRequired'),
  floors: Yup.number().typeError('floorIsRequired'),
  layers: Yup.number().typeError('layerIsRequired'),
  fromDate: Yup.date().max(Yup.ref('toDate'), 'fromDateMaxValidation'),
  toDate: Yup.date().min(Yup.ref('fromDate'), 'toDateMinValidation'),
});
export const EditProfileInformationSchema = Yup.object().shape({
  name: Yup.string().required('nameIsRequired'),
  title: Yup.string().required('titleIsRequired'),
});

export const ExchangeDeviceSchema = Yup.object().shape({
  newId: Yup.string().required('newIdIsRequired'),
});
export const DataInputSchema = Yup.object().shape({
  photo: Yup.string().required('photoIsRequired'),
  // ratSize: Yup.number().required('ratSizeIsRequired'),
  bait: Yup.number().typeError('baitIsRequired').required('baitIsRequired'),
  baitUsage: Yup.string().required('baitUsageIsRequired'),
  falseAlarm: Yup.boolean(),
});
export const EventLocationChangeSchema = Yup.object().shape({
  imageAfter: Yup.string()
    .typeError('imageAfterIsRequired')
    .required('imageAfterIsRequired'),
  imageBefore: Yup.string()
    .typeError('imageBeforeIsRequired')
    .required('imageBeforeIsRequired'),
});
export const RoleSchema = Yup.object().shape({
  name: Yup.string().trim().required('roleIsRequired'),
  siteId: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  // permissionSchema:Yup.string().required()
});

export const DeviceSchema = Yup.object().shape({
  siteId: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  floorId: Yup.number()
    .typeError('floorIsRequired')
    .required('floorIsRequired'),
  layerId: Yup.number()
    .typeError('layerIsRequired')
    .required('layerIsRequired'),
  zoneId: Yup.number().typeError('zoneIsRequired').required('zoneIsRequired'),
  name: Yup.string().required('nameIsRequired'),
  type: Yup.string().required('deviceTypeIsRequired'),
  direction: Yup.string().required('directionIsRequired'),
  isProduction: Yup.string().required('testingOrProductionIsRequired'),
  state: Yup.string().required('statusIsRequired'),
  sensorId: Yup.string().required('idIsRequired'),
});

export const AddBaitSchema = Yup.object().shape({
  name: Yup.string().required('nameIsRequired'),
  type: Yup.string().required('baittypeIsRequired'),
  expiryDate: Yup.date().required('dateIsRequired'),
});

export const addEventSchema = Yup.object().shape({
  siteId: Yup.number().required('siteIsRequired'),
  type: Yup.string().required('typeIsRequired'),
  date: Yup.date().required('dateIsRequired'),
  time: Yup.date().required('timeIsRequired'),
});

export const AddBatchSchema = Yup.object().shape({
  siteIds: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  baitId: Yup.number().typeError('baitIsRequired').required('baitIsRequired'),
  floorId: Yup.number().required('floorIsRequired'),
  layerId: Yup.number()
    .typeError('layerIsRequired')
    .required('layerIsRequired'),
  zoneId: Yup.array().required('zoneIsRequired'),
});
export const FilterDeviceListSchema = Yup.object().shape({
  siteId: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  floorId: Yup.number()
    .typeError('floorIsRequired')
    .required('floorIsRequired'),
  layerId: Yup.number()
    .typeError('layerIsRequired')
    .required('layerIsRequired'),
  zoneId: Yup.number().typeError('zoneIsRequired').required('zoneIsRequired'),
});

export class FilterFloorPlanSchema {}

//
export const SensorInputDataSchema = Yup.object().shape({
  photo: Yup.string()
    .nullable()
    .when('falseAlarm', {
      is: (falseAlarm) => falseAlarm === false,
      then: Yup.string()
        .typeError('photoIsRequired')
        .required('photoIsRequired'),
    }),
  ratSize: Yup.string()
    .nullable()
    .when('falseAlarm', {
      is: (falseAlarm) => falseAlarm === false,
      then: Yup.string()
        .typeError('ratSizeIsRequired')
        .required('ratSizeIsRequired'),
    }),
  bait: Yup.string()
    .nullable()
    .when('falseAlarm', {
      is: (falseAlarm) => falseAlarm === false,
      then: Yup.string().typeError('baitIsRequired').required('baitIsRequired'),
    }),
  baitUsage: Yup.string()
    .nullable()
    .when('falseAlarm', {
      is: (falseAlarm) => falseAlarm === false,
      then: Yup.string()
        .typeError('baitUsageIsRequired')
        .required('baitUsageIsRequired'),
    }),
  // newId: Yup.string()
  //   .nullable()
  //   .when('falseAlarm', {
  //     is: (falseAlarm) => falseAlarm === false,
  //     then: Yup.string().nullable(),
  //   }),
});
export const AddTrapSchema = Yup.object().shape({
  code: Yup.string().required('codeIsRequired'),
});

export const AddEventBatteryReplaceSchema = Yup.object().shape({
  siteId: Yup.number().required('siteIsRequired'),
  floorId: Yup.number().required('floorIsRequired'),
  layerId: Yup.array().required('layerIsRequired'),
  zoneId: Yup.array().required('zoneIsRequired'),
  sensorType: Yup.string().required('sensorTypeIsRequired'),
  batteryLevel: Yup.string().required('batteryLevelIsRequired'),
  checkedList: Yup.array()
    .min(1, 'pleaseSelectAtleastoneSensor')
    .required('pleaseSelectAtleastoneSensor'),
});
export const AddNotificationSchema = Yup.object().shape({
  siteId: Yup.number().required('siteIsRequired'),
  floorId: Yup.number().required('floorIsRequired'),
  layerId: Yup.number().required('layerIsRequired'),
  zoneId: Yup.number().required('zoneIsRequired'),
  originalId: Yup.string().required('originalIdIsRequired'),
  photo: Yup.string().typeError('photoIsRequired').required('photoIsRequired'),
  newId: Yup.string(),
  ratSize: Yup.string().required('ratSizeIsRequired'),
  bait: Yup.number().required('baitIsRequired'),
  baitUsage: Yup.string().required('baitUsageIsRequired'),
});
export const FilterHeatmapSchema = Yup.object().shape({
  siteId: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  floorId: Yup.number()
    .typeError('floorIsRequired')
    .required('floorIsRequired'),
  layerId: Yup.number()
    .typeError('layerIsRequired')
    .required('layerIsRequired'),
  zoneId: Yup.number().typeError('zoneIsRequired').required('zoneIsRequired'),
});
