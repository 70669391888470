import { useCallback, useState } from 'react';
import { notification } from 'antd';
import i18next from 'i18next';
import { LANGUAGE_CODES } from '../utilities/constants';

export const useMutation = (mutation, options, dependencyList) => {
  const { onSuccess, onError, isShowErrorMessage = true } = options || {};
  const [status, setStatus] = useState('initial');
  const [error, setError] = useState();
  const [response, setResponse] = useState();

  const mutate = useCallback(
    async (...args) => {
      setStatus('pending');
      setError(undefined);
      setResponse(undefined);
      try {
        const response = await mutation(...args);
        if (response.data.code === '0' || !!response.data.error) {
          isShowErrorMessage &&
            notification.error({
              message:
                i18next.language === LANGUAGE_CODES.CHINESE &&
                response.data.errorZh
                  ? response.data.errorZh
                  : response.data.error,
            });
          throw {
            message:
              i18next.language === LANGUAGE_CODES.CHINESE &&
              response.data.errorZh
                ? response.data.errorZh
                : response.data.error,
          };
        }
        setResponse(response);
        setStatus('resolved');
        await onSuccess?.(response);
        return response;
      } catch (error) {
        setError(error);
        setStatus('rejected');
        await onError?.(error);
        throw error;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dependencyList]
  );

  return {
    status,
    error,
    response,
    mutate,
  };
};
