import classes from './index.module.css';
import React from 'react';
import { AccountForm, Breadcrumbs, PageHeader } from '../../../components';
import { Formik } from 'formik';
import { AddAccountInformationSchema } from '../../../utilities/schemas';
import { useAddUser } from '../../../services/users';
import { notification, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AddAccount = () => {
  const { t } = useTranslation();
  const { mutate } = useAddUser();
  const navigate = useNavigate();
  return (
    <div className={classes.content}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('accountManagement'),
            link: '/account-management',
          },
          {
            name: t('create'),
            link: '/account-management/add',
          },
        ]}
      />
      <PageHeader title={t('createAccount')} />
      <Formik
        initialValues={{}}
        validationSchema={AddAccountInformationSchema}
        onSubmit={async (values, actions) => {
          try {
            const castValues = AddAccountInformationSchema.cast(values);

            const { siteId, floorId, layerId, ...rest } = castValues;
            const payload = {
              ...rest,
              overviewData: JSON.stringify({ siteId, floorId, layerId }),
            };
            actions.setSubmitting(true);
            await mutate(payload);
            actions.resetForm();
            notification.success({ message: t('createdSuccess') });
            navigate('/account-management');
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleSubmit,
          submitCount,
          resetForm,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <Spin spinning={isSubmitting}>
              <AccountForm
                title='createAccount'
                isEditMode={false}
                values={values}
                submitCount={submitCount}
                resetForm={resetForm}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddAccount;
