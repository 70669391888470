import { Button, Col } from 'antd';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AntDatePicker } from '../custom-fields';
import { disableEndDate, disableStartDate } from '../../utilities/time-utils';

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 2 }}>
      {children}
    </Col>
  );
};

export const DateFilterForm = ({
  handleReset,
  handleSubmit,
  ItemWrapper = DefaultItemWrapper,
  ActionButtonWrapper = DefaultActionButtonWrapper,
  actionButtonStyle = {},
  showTime = false,
  format = 'YYYY-MM-DD',
  required = false,
}) => {
  const { t } = useTranslation();
  const { submitCount, values } = useFormikContext();
  const { startTime, endTime } = values;
  return (
    <>
      <ItemWrapper>
        <Field
          component={AntDatePicker}
          name='startTime'
          label={t('fromDate')}
          placeholder={t('fromDate')}
          format={format}
          submitCount={submitCount}
          allowClear={false}
          style={{ width: '100%' }}
          disabledDate={disableStartDate(startTime, endTime)}
          showTime={showTime}
          required={required}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Field
          component={AntDatePicker}
          name='endTime'
          label={t('toDate')}
          placeholder={t('toDate')}
          format={format}
          submitCount={submitCount}
          allowClear={false}
          style={{ width: '100%' }}
          disabledDate={disableEndDate(values.startTime, values.endTime)}
          showTime={showTime}
          required={required}
        />
      </ItemWrapper>
      <ActionButtonWrapper>
        <Button
          block
          style={{ marginTop: 22, ...actionButtonStyle }}
          onClick={handleReset}
        >
          {t('reset')}
        </Button>
      </ActionButtonWrapper>
      <ActionButtonWrapper>
        <Button
          block
          type='primary'
          style={{ marginTop: 22, ...actionButtonStyle }}
          onClick={handleSubmit}
        >
          {t('submit')}
        </Button>
      </ActionButtonWrapper>
    </>
  );
};
