import React, { useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { GUTTER } from '../../../../../utilities/constants';
import { useForceUpdate } from '../../../../../hooks';
import { AntTextAreaInput } from '../../../../../components';

const CheckboxGroup = Checkbox.Group;

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return <Col>{children}</Col>;
};

export const EditBatteryReplaceSensorsForm = ({
  handleSubmit,
  handleDelete,
}) => {
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t } = useTranslation();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const { values, errors, handleReset, setFieldValue } = useFormikContext();
  const { checkedList, sensors, eventBatteryChange } = values || {};
  const { forceUpdateKey, forceUpdate } = useForceUpdate();

  const { submitCount } = useFormikContext();

  const onChange = (list) => {
    setFieldValue('checkedList', list);
    setIndeterminate(!!list.length && list.length < sensors.length);
    setCheckAll(list.length === sensors.length);
  };
  const onCheckAllChange = (e) => {
    const tempMap = sensors.map((item) => item.id);
    setFieldValue('checkedList', e.target.checked ? tempMap : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleReset1 = async () => {
    await handleReset();
    forceUpdate();
  };
  const sensorsOption = () => {
    return sensors.map((sensor, index) => (
      <Row key={index}>
        <Col span={24}>
          <Checkbox value={sensor.value} key={sensor.value}>
            {sensor.label}
          </Checkbox>
        </Col>
      </Row>
    ));
  };
  return (
    <Form>
      <Row>
        <ItemWrapper>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            style={{ width: '100%' }}
            disabled={'P' == eventBatteryChange.state ? false : true}
          >
            {t('checkall')}
          </Checkbox>
          <Divider />
          <CheckboxGroup
            style={{ width: '100%' }}
            value={checkedList}
            onChange={onChange}
            disabled={'P' == eventBatteryChange.state ? false : true}
          >
            {sensors && sensorsOption()}
          </CheckboxGroup>
          <div role='alert' className='ant-form-item-explain-error'>
            {submitCount > 0 && errors?.checkedList && t(errors?.checkedList)}
          </div>
        </ItemWrapper>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 20 }}>
          <Field
            component={AntTextAreaInput}
            name='remark'
            label={t('remarks')}
            type='text'
            placeholder={t('pleaseInputRemarks')}
            submitCount={submitCount}
            disabled={'P' == eventBatteryChange.state ? false : true}
          />
        </Col>
      </Row>
      <Row gutter={[GUTTER, GUTTER]}>
        <ActionButtonWrapper>
          <Button block style={{ marginTop: 22 }} type='secondary'>
            <Link
              to={'/notifications'}
              state={{ fromNotificationDetails: true }}
            >
              {t('back')}
            </Link>
          </Button>
        </ActionButtonWrapper>
        {'P' == eventBatteryChange.state && (
          <ActionButtonWrapper>
            <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
              {t('reset')}
            </Button>
          </ActionButtonWrapper>
        )}
        {'P' == eventBatteryChange.state && (
          <ActionButtonWrapper>
            <Button
              block
              type='primary'
              style={{ marginTop: 22 }}
              onClick={handleSubmit}
            >
              {t('save')}
            </Button>
          </ActionButtonWrapper>
        )}
        {'P' == eventBatteryChange.state && (
          <ActionButtonWrapper>
            <Button
              block
              type='primary'
              style={{ marginTop: 22 }}
              onClick={handleDelete}
              danger
            >
              {t('delete')}
            </Button>
          </ActionButtonWrapper>
        )}
      </Row>
    </Form>
  );
};
