import './header-left.scss';
import { Images } from '../../../images';
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

export const HeaderLeft = ({ setCollapsed }) => {
  const onCollapse = () => {
    setCollapsed((old) => !old);
  };
  return (
    <div>
      <div className='desktop-side-bar'>
        <img alt='logo' src={Images.emsdLogo2} />
        <img alt='logo' src={Images.emsdLogo} />
      </div>
      <div className='mobile-side-bar'>
        <GiHamburgerMenu className='hamburger' onClick={onCollapse} />
        <img alt='logo' src={Images.emsdLogo2Small} />
        <img alt='logo' src={Images.emsdLogoSmall} />
      </div>
    </div>
  );
};

export default HeaderLeft;
