import { useRef, useState } from 'react';

export const storageGet = (key) => {
  try {
    return JSON.parse(localStorage?.getItem(key) || '');
  } catch {
    return undefined;
  }
};

export const storageSet = (key, value) => {
  try {
    return localStorage?.setItem(key, JSON.stringify(value));
  } catch (e) {
    return undefined;
  }
};

export const useLocalStorageState = (key, initialState) => {
  const [state, setState] = useState(initialState);
  const handleSetState = useRef((updatedValue) => {
    return setState(() => {
      storageSet(key, updatedValue);
      return updatedValue;
    });
  });
  return [state, handleSetState.current];
};
