import { useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const LOCATION_CACHE_KEY = 'eventLocationChange';
const ALL = 'all';

const fetchEventLocationChange = (api, filters) => async () => {
  const { data } = await api.fetchEventLocationChange(filters);
  return {
    eventLocationChange: data?.data?.eventLocationChange || [],
    totalCount: data?.data?.totalCount || [],
  };
};

export const useFetchEventLocationChange = (filters) => {
  const api = useApi();
  const response = useSWR(
    `${LOCATION_CACHE_KEY}/${filters.eventLocationChangeId || ALL}`,
    fetchEventLocationChange(api, filters)
  );
  return withLoading(response);
};

const fetchEventLocationChangeDetails = (api, filters) => async () => {
  const { data } = await api.fetchEventLocationChangeDetails(filters);
  return data?.data || {};
};

export const useFetchEventLocationChangeDetails = ({ notificationId }) => {
  const api = useApi();
  const response = useSWR(
    `LOCATION_CACHE_KEY/${notificationId}`,
    fetchEventLocationChangeDetails(api, { notificationId })
  );
  return withLoading(response);
};

const addEventLocationChange = (api) => async (body) => {
  return await api.addEventLocationChange(body);
};

export const useAddEventLocationChange = () => {
  const api = useApi();
  const mutate = useMatchMutate(LOCATION_CACHE_KEY);
  return useMutation(addEventLocationChange(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const editEventLocationChange = (api) => async (body) => {
  return await api.editEventLocationChange(body);
};

export const useEditEventLocationChange = () => {
  const api = useApi();
  const mutate = useMatchMutate(LOCATION_CACHE_KEY);
  return useMutation(editEventLocationChange(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteEventLocationChange =
  (api) =>
  async ({ eventLocationChangeId }) => {
    return await api.deleteEventLocationChange({ eventLocationChangeId });
  };

export const useDeleteEventLocationChange = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${LOCATION_CACHE_KEY}/${ALL}`);
  return useMutation(deleteEventLocationChange(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
