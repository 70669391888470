import * as React from 'react';
import { SENSOR_DIRECTIONS } from '../direction-input/constants';
import {
  BsArrowDown,
  BsArrowDownLeft,
  BsArrowDownRight,
  BsArrowLeft,
  BsArrowRight,
  BsArrowUp,
  BsArrowUpLeft,
  BsArrowUpRight,
} from 'react-icons/bs';
import { SENSOR_TYPE_KEYS } from '../../utilities/constants';

const directions = new Map();
directions.set(SENSOR_DIRECTIONS.RIGHT, 18);
directions.set(SENSOR_DIRECTIONS.DOWN_RIGHT, 63);
directions.set(SENSOR_DIRECTIONS.DOWN, 108);
directions.set(SENSOR_DIRECTIONS.DOWN_LEFT, 153);
directions.set(SENSOR_DIRECTIONS.LEFT, 198);
directions.set(SENSOR_DIRECTIONS.UP_LEFT, 243);
directions.set(SENSOR_DIRECTIONS.UP, 288);
directions.set(SENSOR_DIRECTIONS.UP_RIGHT, 333);

export const DetectionSensorSvg = (props) => {
  switch (props?.direction) {
    case SENSOR_DIRECTIONS.DOWN:
      return (
        <BsArrowDown
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
    case SENSOR_DIRECTIONS.DOWN_LEFT:
      return (
        <BsArrowDownLeft
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
    case SENSOR_DIRECTIONS.DOWN_RIGHT:
      return (
        <BsArrowDownRight
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
    case SENSOR_DIRECTIONS.UP:
      return (
        <BsArrowUp
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
    case SENSOR_DIRECTIONS.UP_RIGHT:
      return (
        <BsArrowUpRight
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
    case SENSOR_DIRECTIONS.UP_LEFT:
      return (
        <BsArrowUpLeft
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
    case SENSOR_DIRECTIONS.LEFT:
      return (
        <BsArrowLeft
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
    case SENSOR_DIRECTIONS.RIGHT:
      return (
        <BsArrowRight
          style={{
            width: props?.width,
            height: props?.height,
            fontWeight: 900,
            strokeWidth: 1,
            color: props?.color,
          }}
        />
      );
  }
  // return (
  //     <svg
  //         width={26}
  //         viewBox='0 0 25 43'
  //         fill='none'
  //         xmlns='http://www.w3.org/2000/svg'
  //         {...props}
  //     >
  //         <path
  //             fillRule='evenodd'
  //             clipRule='evenodd'
  //             d='M17.682 42.673A24.921 24.921 0 0 0 25 25C25 11.193 13.807 0 0 0v25h.009l17.673 17.673Z'
  //             stroke='#000'
  //         />
  //     </svg>
  // );
};

const MarkerSvg = (props) => (
  <svg
    // width={26}
    width={props?.width}
    height={props?.height}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25 43'
    xmlSpace='preserve'
    fill={props.color || '#2db34a'}
    {...props}
  >
    <path d='M25 16.7c0 13.4-11.3 25.8-11.3 25.8-.7.7-1.7.7-2.4 0 0 0-11.3-12.4-11.3-25.8C0 7.5 5.6 0 12.5 0S25 7.5 25 16.7z' />
    <path
      fill='#fff'
      d='M12.1 19.4c-3.3 0-6-2.5-6-5.7S8.8 8 12.1 8s6 2.5 6 5.7-2.7 5.7-6 5.7z'
    />
  </svg>
);

export const SensorFloorPlanIcon = ({
  type,
  width,
  height,
  direction,
  color,
}) => {
  if (type === SENSOR_TYPE_KEYS.DETECTION_SENSOR) {
    return (
      <DetectionSensorSvg
        color={color || '#2db34a'}
        direction={direction}
        width={width}
        height={height}
      />
    );
  }
  return <MarkerSvg width={width} height={height} color={color} />;
};

export default SensorFloorPlanIcon;
