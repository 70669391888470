import React from 'react';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

export const ExportCSV = ({ data, columns }) => {
  const { t } = useTranslation();
  return (
    <Button block>
      <CSVLink data={data} headers={columns}>
        {t('exportToCSV')}
      </CSVLink>
    </Button>
  );
};

export default ExportCSV;
