import React from 'react';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Bar, Breadcrumbs, PageError } from '../../../components';
import { useFetchUsers } from '../../../services/users';
import { useUserProvider } from '../../../context/session';
import classes from './index.module.css';
import Loader from '../../../components/loader';

const { Title, Text } = Typography;
const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('profile'),
            link: '/profile',
          },
        ]}
      />
      <Title level={3} style={{ marginBottom: 0 }}>
        {t('accountInformation')}
        &nbsp;
        <Tooltip title='move to edit'>
          <Link to='edit'>
            <Button icon={<EditOutlined />} />
          </Link>
        </Tooltip>
      </Title>
      <Bar />
    </>
  );
};
export const ViewProfile = () => {
  const { t } = useTranslation();
  const [user] = useUserProvider();

  const { user: profile } = user || {};
  const { id: userId } = profile || {};
  const {
    data: { users: data } = {},
    isLoading,
    error,
  } = useFetchUsers({ userId });
  if (error) {
    return (
      <div className={classes.content}>
        <Header />
        <PageError description='Error is found' />
      </div>
    );
  }
  if (isLoading) {
    return <Loader />;
  }
  const userDetails = data[0];
  return (
    <div className={classes.content}>
      <Header />
      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Text strong>{t('role')}</Text>
        </Col>
        <Text strong>{userDetails?.roleName}</Text>
      </Row>
      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Text strong>{t('name')}</Text>
        </Col>
        <Col>{userDetails?.name}</Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Text strong>{t('title')}</Text>
        </Col>
        <Col>{userDetails?.title}</Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Text strong>{t('email')}</Text>
        </Col>
        <Col>{userDetails?.email}</Col>
      </Row>
    </div>
  );
};

export default ViewProfile;
