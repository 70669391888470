import classes from './index.module.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, notification, Row, Select } from 'antd';
import { useFetchSites } from '../../services/sites';
import moment from 'moment';
import {
  useDoMaintenance,
  useFetchMaintenance,
  useGetMaintenanceDetails,
} from '../../services/sensors';
import { CustomTable, PageHeader } from '../../components';
import { LANGUAGE_CODES } from '../../utilities/constants';
import i18next from 'i18next';
import CustomModal from '../../components/custom-modal';

const { Option } = Select;
const buildColumns = (doMaintenance, toggleModal) => [
  {
    title: i18next.t('site'),
    key: 'siteName',
    dataIndex:
      i18next.language === LANGUAGE_CODES.ENGLISH ? 'siteName' : 'siteNameZh',
  },
  {
    title: i18next.t('user'),
    key: 'userName',
    dataIndex: 'userName',
  },
  {
    title: i18next.t('starttime'),
    key: 'startTime',
    dataIndex: 'startTime',
    render: (text, record, i) =>
      text === null ? 'NA' : moment(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: i18next.t('endtime'),
    key: 'endTime',
    dataIndex: 'endTime',
    render: (text, record, i) =>
      text === null ? (
        'NA'
      ) : (
        <>
          {moment(text).format('YYYY-MM-DD HH:mm')}
          <div style={{ position: 'absolute', right: 0, top: 5 }}>
            <Button type='primary' onClick={() => toggleModal(record?.id)}>
              {i18next.t('view')}
            </Button>
          </div>
        </>
      ),
  },
  {
    title: i18next.t('action'),
    key: 'endTime',
    dataIndex: 'endTime',
    render: (text, record, i) => {
      return (
        record.endTime === null && (
          <Button type='primary' onClick={() => doMaintenance(0)}>
            {i18next.t('stop')}
          </Button>
        )
      );
    },
  },
];
export const Maintenance = () => {
  const { mutate: doMaintenanceapi } = useDoMaintenance();
  const { mutate: getMaintenanceDetails } = useGetMaintenanceDetails();
  const { data: { sites, defaultLocation } = {} } = useFetchSites();
  const { t, i18n } = useTranslation();
  const [siteId, setSiteId] = useState(defaultLocation?.siteId);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [maintenanceDetails, setMaintenanceDetails] = useState({});

  const { data = {}, isLoading } = useFetchMaintenance();
  const { maintenanceList = [] } = data;
  const doMaintenance = async (type) => {
    const response = await doMaintenanceapi({ siteId: siteId, isStart: type });
    if (response.data.code === '1') {
      notification.success({
        message: type === 1 ? t('startedsuccess') : t('stoppedsuccess'),
      });
    }
  };
  const toggleModal = async (id) => {
    if (!isModalVisible) {
      const {
        data: { data: maintenance },
      } = await getMaintenanceDetails({ id });
      setMaintenanceDetails(maintenance?.maintenanceNotificationList);
    }
    setIsModalVisible((state) => !state);
  };
  const columns = buildColumns(doMaintenance, toggleModal);

  return (
    <div className={classes.content}>
      <PageHeader title={t('maintenance')} />
      <Row style={{ marginTop: 20 }}>
        <Col md={6} sm={14} xs={14} lg={6}>
          <Select
            value={siteId}
            onChange={(e) => setSiteId(e)}
            style={{ width: '100%' }}
          >
            {sites?.map((s, i) => (
              <Option key={i} value={s.id}>
                {i18n.language === LANGUAGE_CODES.ENGLISH ? s?.name : s?.nameZh}
              </Option>
            ))}
          </Select>
        </Col>
        <Col md={4} sm={6} xs={6} lg={4}>
          <Button
            type='primary'
            style={{ marginLeft: 10 }}
            onClick={() => doMaintenance(1)}
          >
            {t('start')}
          </Button>
        </Col>
      </Row>
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        dataSource={maintenanceList}
        pagination={{
          locale: { items_per_page: `/ ${t('page')}` },
          showLessItems: true,
          showSizeChanger: true,
          showTotal: (total) => (
            <div className='table-pagination'>
              {t('total')}: {total}
            </div>
          ),
        }}
      />
      <CustomModal
        title={'events'}
        isVisible={isModalVisible}
        onCancel={toggleModal}
        cancelText={'close'}
        isFooter={true}
      >
        <CustomTable
          isLoading={false}
          columns={[
            {
              title: i18next.t('eventtime'),
              key: 'createTime',
              dataIndex: 'createTime',
              render: (text, record, i) =>
                text === null
                  ? 'NA'
                  : moment(text).format('YYYY-MM-DD HH:mm:ss'),
            },
            {
              title: i18next.t('eventtype'),
              key: 'eventType',
              dataIndex: 'eventType',
              render: (text, record, i) => i18next.t(text),
            },
          ]}
          dataSource={maintenanceDetails}
          pagination={false}
        />
      </CustomModal>
    </div>
  );
};

export default Maintenance;
