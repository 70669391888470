import * as Yup from 'yup';

export const FilterFloorPlanSchema = Yup.object().shape({
  siteId: Yup.number().typeError('siteIsRequired').required('siteIsRequired'),
  floorId: Yup.number()
    .typeError('floorIsRequired')
    .required('floorIsRequired'),
  layerId: Yup.number()
    .typeError('layerIsRequired')
    .required('layerIsRequired'),
  zoneId: Yup.number().typeError('zoneIsRequired').required('zoneIsRequired'),
});
