import { useEffect, useRef, useState } from 'react';

export const useSensorInputData = (initialImage) => {
  const [imageData, setImageDate] = useState(initialImage);
  const dataRef = useRef();
  useEffect(() => {
    dataRef.current = imageData;
  }, [imageData]);

  const setImageDateNull = () => {
    setImageDate(null);
  };

  const forceSetImageDate = (e) => {
    setImageDate(e);
  };

  return {
    dataRef,
    imageData,
    forceSetImageDate,
    setImageDateNull,
  };
};
