import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { BatchForm, Breadcrumbs } from '../../../components';
import { useDeleteBait, useEditBait } from '../../../services/baits';

import { useFetchBatchDetails } from '../../../services/batch';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  generateFilePath,
  getBaitImageUrl,
} from '../../../utilities/transform';
import { getBase64String } from '../../../utilities/image-utils';
import { AddBatchSchema } from '../../../utilities/schemas';
import { BAIT_TYPES, EVENT_TYPES } from '../../../utilities/constants';

const buildInitialValues = (batch) => {
  const { name, expiryDate, path } = batch || {};
  return {
    ...batch,
    name,
    expiryDate: moment(expiryDate),
    imageData: null,
    path: getBaitImageUrl(path),
    eventStart: moment(),
    eventEnd: moment(),
  };
};

const { Title } = Typography;
export const AddBatch = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { isFromEvent = false } = state || { isFromEvent: false };
  const [baitTypes, setBaitTypes] = useState(
    state === null || state.eventType === null
      ? [BAIT_TYPES.NORMAL, BAIT_TYPES.POISON]
      : state.eventType === EVENT_TYPES.BAIT_REPLACE
      ? [BAIT_TYPES.NORMAL]
      : [BAIT_TYPES.POISON]
  );
  const { eventType } = state || {};
  const { data: { batch, batchItemList } = {}, isLoading } =
    useFetchBatchDetails({ batchId: 2 });
  const { mutate: editBait, status: editStatus } = useEditBait();
  const { mutate: deleteBait, status: deleteStatus } = useDeleteBait();

  const initialValues = buildInitialValues(batch);

  const handleDeleteBait = async () => {
    try {
    } catch (e) {}
  };

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          !isFromEvent
            ? {
                name: t('batchManagement'),
                link: '/batch-management',
              }
            : {
                name: t('event1'),
                link: '/events',
              },
          {
            name: t('add'),
            link: `/device-management/add`,
          },
        ]}
      />
      <Title level={3}>{t('createbatch')}</Title>

      <Formik
        enableReinitialize={false}
        validationSchema={AddBatchSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            const { name, path, imageData } = values || {};
            const params = { name: name.trim(), path, imageData };
            if (values.imageData) {
              params.imageData = getBase64String(imageData);
              params.path = generateFilePath();
            }
            params.expiryDate = moment(values.expiryDate).valueOf();
            actions.setSubmitting(true);
            const payload = { ...params };
            const { data } = await editBait(payload);
            notification.success({ message: t('editedSuccess') });
            const updatedSite = data?.data?.batch;
            const updatedData = buildInitialValues(updatedSite);
            await actions.resetForm({ values: updatedData });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin spinning={false}>
              <BatchForm
                dirty={dirty}
                values={initialValues}
                defaultLocation={initialValues}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                handleDeleteBait={handleDeleteBait}
                baitTypes={baitTypes}
                isFromEvent={isFromEvent}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddBatch;
