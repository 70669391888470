import moment from 'moment';

export const PICKET_TYPES = {
  YEAR: 'year',
  MONTH: 'month',
  DATE: 'date',
  CUSTOM: null,
};

export const buildChartDateFilters = (date, pickerType) => {
  if (pickerType === PICKET_TYPES.YEAR) {
    const startTime = moment(date).startOf('year').valueOf();
    const endTime = moment(date).endOf('year').valueOf();
    return { startTime, endTime };
  } else if (pickerType === PICKET_TYPES.MONTH) {
    const startTime = moment(date).startOf('month').valueOf();
    const endTime = moment(date).endOf('month').valueOf();
    return { startTime, endTime };
  } else if (pickerType === PICKET_TYPES.DATE) {
    const startTime = moment(date).startOf('day').valueOf();
    const endTime = moment(date).endOf('day').valueOf();
    return { startTime, endTime };
  } else if (pickerType === PICKET_TYPES.CUSTOM) {
    const startTime = moment(date)
      .subtract(140, 'days')
      .startOf('day')
      .valueOf();
    const endTime = moment(date).endOf('day').valueOf();
    return { startTime, endTime };
  }
};
