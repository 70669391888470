import { useMemo, useState } from 'react';

const getInitialWidth = (offset) => {
  // eslint-disable-next-line no-unused-expressions
  const availableWidth = window.screen.availWidth;
  if (availableWidth - offset < 1000) {
    return 1000;
  }
  return availableWidth - offset;
};

export const useZoomFloorPlan = (offset = 180) => {
  const [activeZoomScale, setActiveZoomScale] = useState(1);
  const optionsList = useMemo(() => {
    const initialWidth = getInitialWidth(offset);
    return [
      { index: 0, label: '1X', value: 1, imageWidth: initialWidth * 1 },
      { index: 1, label: '2X', value: 2, imageWidth: initialWidth * 2 },
      { index: 2, label: '3X', value: 3, imageWidth: initialWidth * 3 },
      { index: 3, label: '4X', value: 4, imageWidth: initialWidth * 4 },
      { index: 4, label: '5X', value: 5, imageWidth: initialWidth * 5 },
    ];
  }, []);
  const activeScaleInfo = useMemo(() => {
    return optionsList.find((item) => item.value === activeZoomScale);
  }, [activeZoomScale]);

  const onChangeZoomLevel = (value) => {
    setActiveZoomScale(value);
  };

  return {
    optionsList,
    activeScaleInfo,
    onChangeZoomLevel,
  };
};
