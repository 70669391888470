import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { Breadcrumbs, EventForm } from '../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { addEventSchema } from '../../../utilities/schemas';
import { useActiveSiteProvider } from '../../../context/site';
import moment from 'moment';

const { Title } = Typography;
export const AddEvent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeSite } = useActiveSiteProvider();
  const { state } = useLocation();
  const initialValues = {
    siteId: activeSite.value,
    type: state?.eventType,
    eventStart: moment(),
    eventEnd: moment(),
  };

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('event1'),
            link: '/events',
          },
          {
            name: t('add'),
            link: `/events/add`,
          },
        ]}
      />
      <Title level={3}>{t('addevent')}</Title>
      <Formik
        enableReinitialize={false}
        validationSchema={addEventSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {}}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin spinning={false}>
              <EventForm
                dirty={dirty}
                values={initialValues}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                eventType={state?.eventType}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddEvent;
