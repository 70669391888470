import React, { useMemo } from 'react';
import { Field, Form } from 'formik';
import { AntInput, AntSelect, Bar } from '../index';
import { Button, Col, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFetchRoles } from '../../services/roles';
import { getFormattedRoles } from '../../utilities/transform';

export const EditProfileForm = ({ handleSubmit, submitCount, resetForm }) => {
  const { t } = useTranslation();
  const { data: { roles = [] } = {} } = useFetchRoles();
  const roleOptions = useMemo(() => getFormattedRoles(roles), [roles]);

  return (
    <Spin spinning={false}>
      <Form onSubmit={handleSubmit}>
        <Typography.Title level={3}>{t('editAccount')}</Typography.Title>
        <Bar />
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntSelect}
              name='roleId'
              type='text'
              label={t('role')}
              placeholder={t('pleaseInputRole')}
              submitCount={submitCount}
              disabled
              required
              selectOptions={roleOptions}
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntInput}
              name='name'
              type='text'
              label={t('name')}
              placeholder={t('pleaseInputName')}
              submitCount={submitCount}
              hasFeedback
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntInput}
              name='title'
              type='text'
              label={t('title')}
              placeholder={t('pleaseInputTitle')}
              submitCount={submitCount}
              hasFeedback
              required
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntInput}
              name='email'
              type='email'
              label={t('email')}
              placeholder={t('pleaseInputEmail')}
              submitCount={submitCount}
              disabled
              required
            />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Row gutter={[8, 8]}>
              <Col>
                <Link to='/profile'>
                  <Button type='secondary'>{t('back')}</Button>
                </Link>
              </Col>
              <Col>
                <Button type='secondary' onClick={resetForm}>
                  {t('reset')}
                </Button>
              </Col>
              <Col>
                <Button type='primary' onClick={handleSubmit}>
                  {t('save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
