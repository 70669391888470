import * as React from 'react';

export const CaughtTrapSvg = (props) => (
  <svg
    width={30}
    height={30}
    viewBox='0 0 50 50'
    fill='#fff'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M5.86 41.21h2.93v2.93H5.86v-2.93ZM45.986 33.701a2.299 2.299 0 0 1 2.295 2.295 2.298 2.298 0 0 1-2.295 2.295h-10.81a2.932 2.932 0 0 0-2.93 2.93 2.926 2.926 0 0 0 2.93 2.93h1.318v-1.475h-1.318c-.801 0-1.455-.654-1.455-1.455s.654-1.455 1.455-1.455h10.81c2.07 0 3.76-1.69 3.76-3.76a3.77 3.77 0 0 0-3.564-3.76v-1.191c0-4.59-3.74-8.33-8.33-8.33h-.586a3.85 3.85 0 0 0-3.604-2.51 3.86 3.86 0 0 0-3.77 3.096 8.32 8.32 0 0 0-5.263 7.744V33.7h21.357Zm-1.27-1.465H26.114v-1.181a6.838 6.838 0 0 1 4.688-6.504l.469-.156.029-.489a2.37 2.37 0 0 1 2.363-2.207 2.376 2.376 0 0 1 2.373 2.373 2.376 2.376 0 0 1-2.373 2.373v1.475c2.07 0 3.77-1.65 3.838-3.701h.352a6.864 6.864 0 0 1 6.855 6.855l.01 1.162Z' />
    <path d='M28.584 27.949h1.475v1.474h-1.475V27.95Z' />
    <path d='m45.605 34.746-2.93 2.93-2.929-2.93-2.93 2.93-2.93-2.93-2.929 2.93-2.93-2.93-2.93 2.93-2.929-2.93-3.535 3.535h-5.45a6.807 6.807 0 0 0-.42-.508l4.942-6.523 4.961-.645-.527-4.111 4.111-.528-.527-4.11 4.111-.528-.537-4.112 4.101-.517-.527-4.112 4.111-.537-.8-6.162-6.28-4.843L2.207 37.44.908 39.15A7.233 7.233 0 0 0 0 42.656V42.822c.078 3.916 3.252 7.09 7.168 7.168H7.334a7.329 7.329 0 0 0 5.84-2.93H49.99v-7.93l-4.385-4.384ZM7.325 47.07a4.401 4.401 0 0 1-4.395-4.395 4.401 4.401 0 0 1 4.394-4.394 4.401 4.401 0 0 1 4.395 4.394 4.401 4.401 0 0 1-4.395 4.395Zm3.056-11.152c-.498-.225-1.035-.313-1.592-.42a7.705 7.705 0 0 0-1.357-.147L30.43 5.156l2.998 2.314.263 2.051-4.101.537.527 4.112-4.111.537.527 4.111-4.111.527.537 4.112-4.111.527.527 4.111-3.262.42-5.732 7.403Zm36.69 8.222H14.501a7.24 7.24 0 0 0 .146-1.465 7.24 7.24 0 0 0-.146-1.464h5.342l2.324-2.325 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 2.93 2.93 2.93-2.93 2.929 2.93 2.93-2.93 1.464 1.465v3.79Z' />
    <path d='m.908 39.15 1.299-1.709a7.323 7.323 0 0 0-1.299 1.71Z' />
  </svg>
);

export default CaughtTrapSvg;
