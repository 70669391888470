import React, { useMemo } from 'react';
import { Form } from 'formik';
import { Button, Col, Popconfirm, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { GUTTER, LANGUAGE_CODES } from '../../utilities/constants';
import { Link } from 'react-router-dom';
import { FirstSection } from './FirstSection';
import { SecondSection } from './SecondSection';
import { ThirdSection } from './ThirdSections';
import { useFetchSites } from '../../services/sites';
import Loader from '../loader';
import isEmpty from 'lodash/isEmpty';
import { PageError } from '../page-error';

export const RoleForm = ({
  isEditMode = true,
  values,
  handleSubmit,
  resetForm,
  submitCount,
  handleDeleteRole,
}) => {
  const { t, i18n } = useTranslation();
  const { data: { sites: data } = {}, isLoading } = useFetchSites();
  const formattedSites = useMemo(() => {
    if (isEmpty(data)) {
      return [];
    }
    const formattedSites = data.map((site) => ({
      key: site.id,
      localiseLabel:
        i18n.language === LANGUAGE_CODES.ENGLISH ? site?.name : site?.nameZh,
      value: site.id,
    }));
    return [{ key: 0, label: 'Global', value: 0 }, ...formattedSites];
  }, [data, i18n.language]);
  if (isLoading) {
    return <Loader />;
  }
  if (isEmpty(data) && !isLoading) {
    return <PageError description='Data not found' />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FirstSection submitCount={submitCount} sites={formattedSites} />
      <SecondSection submitCount={submitCount} />
      <ThirdSection submitCount={submitCount} />
      <Row gutter={[GUTTER, GUTTER]} style={{ marginTop: 10 }}>
        <Col>
          <Link to='/role-management'>
            <Button type='secondary'>{t('back')}</Button>
          </Link>
        </Col>
        <Col>
          <Button type='secondary' onClick={resetForm}>
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button type='primary' onClick={handleSubmit}>
            {t('save')}
          </Button>
        </Col>
        {isEditMode && (
          <Col>
            <Popconfirm
              title='Are you sure to delete this role?'
              onConfirm={handleDeleteRole}
              okText='Yes'
              cancelText='No'
            >
              <Button type='danger'>{t('delete')}</Button>
            </Popconfirm>
          </Col>
        )}
      </Row>
    </Form>
  );
};
