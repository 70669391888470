import { useEffect, useState } from 'react';
import {
  getImageDimensions,
  getScaledSensorFloorPlanInfo,
} from '../utilities/image-utils';
import { useZoomFloorPlan } from './use-zoom-floor-plan';
import { sensorScaleTransform } from '../utilities/transform';
import { useForceUpdate } from './use-force-update';

export const usePreviewSensorWithZoom = ({
  sensors: initialSensors,
  floorPlan: initialFloorPlan,
  offset = 180,
}) => {
  const { activeScaleInfo, onChangeZoomLevel, optionsList } =
    useZoomFloorPlan(offset);
  const { forceUpdate, forceUpdateKey } = useForceUpdate();
  const [sensors, setSensors] = useState([]);
  const [floorPlanMeta, setFloorPlanMeta] = useState({});

  useEffect(() => {
    const preprocessData = async () => {
      const { naturalWidth, naturalHeight } = await getImageDimensions(
        initialFloorPlan
      );
      const updatedFloorPlanMeta = getScaledSensorFloorPlanInfo(
        naturalWidth,
        naturalHeight,
        activeScaleInfo.imageWidth
      );

      const updatedSensors = sensorScaleTransform(
        initialSensors,
        updatedFloorPlanMeta.widthScale,
        updatedFloorPlanMeta.heightScale
      );
      setSensors(updatedSensors);
      setFloorPlanMeta(updatedFloorPlanMeta);
      setTimeout(forceUpdate, 1000);
    };

    // noinspection JSIgnoredPromiseFromCall
    preprocessData();
  }, [
    activeScaleInfo.imageWidth,
    forceUpdate,
    initialFloorPlan,
    initialSensors,
  ]);

  return {
    sensors,
    floorPlanMeta,
    floorPlan: initialFloorPlan,
    optionsList,
    activeScaleInfo,
    onChangeZoomLevel,
    forceUpdateKey,
  };
};
