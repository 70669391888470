import React from 'react';
import { Breadcrumbs, PageHeader, RoleForm } from '../../../components';
import { Formik } from 'formik';
import './index.css';
import { Col, notification, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoleSchema } from '../../../utilities/schemas';
import { useAddRole } from '../../../services/roles';
import { generatePermissionSchema } from '../../../utilities/transform';

const initialValues = {
  name: '',
  siteId: null,
  secondSection: [{ detectionSensor: false }, { trapSensor: false }],
  thirdSection: [
    { dashboardView: false },
    { sensorView: false, sensorEdit: false },
    { siteManagementView: false },
    { reportView: false },
    { roleManagementView: false, roleManagementEdit: false },
    { accountManagementView: false, accountManagementEdit: false },
    { baitManagementView: false, baitManagementEdit: false },
    { batchManagementView: false, batchManagementEdit: false },
  ],
};

export const AddRole = () => {
  const { t } = useTranslation();
  const { mutate } = useAddRole();

  return (
    <div className='content'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('roleManagement'),
            link: '/role-management',
          },
          {
            name: t('create'),
            link: '/role-management/add',
          },
        ]}
      />
      <PageHeader title={t('createRole')} />
      <Row>
        <Col span={24}>
          <Formik
            initialValues={initialValues}
            validationSchema={RoleSchema}
            onSubmit={async (values, actions) => {
              try {
                const castValues = RoleSchema.cast(values);
                const permissionSchema = generatePermissionSchema(
                  castValues.secondSection,
                  castValues.thirdSection
                );
                actions.setSubmitting(true);
                const payload = {
                  name: castValues.name,
                  siteId: castValues.siteId,
                  permissionSchema: JSON.stringify(permissionSchema),
                };
                await mutate(payload);
                actions.resetForm();
                notification.success({ message: t('createdSuccess') });
              } catch ({ message }) {
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({
              handleSubmit,
              values,
              submitCount,
              resetForm,
              isSubmitting,
            }) => (
              <Spin spinning={isSubmitting}>
                <RoleForm
                  isEditMode={false}
                  handleSubmit={handleSubmit}
                  values={values}
                  submitCount={submitCount}
                  resetForm={resetForm}
                />
              </Spin>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default AddRole;
