import { Col, DatePicker, Radio, Row } from 'antd';
import {
  DEFAULT_PAGE_SIZE,
  GUTTER,
  SENSOR_PAGE_KEYS,
} from '../../../../utilities/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  useFetchDetectionSensorData,
  useFetchTrapSensorData,
} from '../../../../services/sensors';
import { buildChartDateFilters, PICKET_TYPES } from './utils';
import { useParams } from 'react-router-dom';
import { SensorLineChart } from './sensor-line-chart';

const TrapSensorChart = ({ types, title, sensorId, filters }) => {
  const { data, isLoading } = useFetchTrapSensorData({
    sensorId,
    ...filters,
    count: DEFAULT_PAGE_SIZE,
    types,
    pageNumber: 1,
  });
  return (
    <SensorLineChart
      title={title}
      data={data}
      isLoading={isLoading}
      filters={filters}
    />
  );
};

const DetectionSensorChart = ({ types, title, sensorId, filters }) => {
  const { data, isLoading } = useFetchDetectionSensorData({
    sensorId,
    ...filters,
    count: DEFAULT_PAGE_SIZE,
    types,
    pageNumber: 1,
  });
  return (
    <SensorLineChart
      title={title}
      data={data}
      isLoading={isLoading}
      filters={filters}
    />
  );
};

const DEFAULT_DATE_FILTERS = {
  startTime: moment().startOf('year').valueOf(),
  endTime: moment().endOf('year').valueOf(),
};

export const SecondTab = ({ routeKey }) => {
  const { t } = useTranslation();
  const { sensorId } = useParams();
  const [dates, setDates] = useState(DEFAULT_DATE_FILTERS);
  const [pickerType, setPickerType] = useState(PICKET_TYPES.YEAR);

  const onChange = (event) => {
    setPickerType(event.target.value);
    setDates(buildChartDateFilters(new Date(), event.target.value));
  };

  const onChangeDate = (date) => {
    setDates(buildChartDateFilters(date, pickerType));
  };

  return (
    <div>
      <Row gutter={[GUTTER, GUTTER]} align='bottom'>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Radio.Group
            onChange={onChange}
            value={pickerType}
            buttonStyle='solid'
            size='large'
          >
            <Radio.Button value={PICKET_TYPES.YEAR}>{t('year')}</Radio.Button>
            <Radio.Button value={PICKET_TYPES.MONTH}>{t('month')}</Radio.Button>
            <Radio.Button value={PICKET_TYPES.DATE}>{t('day')}</Radio.Button>
            <Radio.Button value={PICKET_TYPES.CUSTOM}>
              {t('lastMessages')}
            </Radio.Button>
          </Radio.Group>
        </Col>
        {pickerType && (
          <Col xs={{ span: 24 }} md={{ span: 4 }}>
            <DatePicker
              size='large'
              picker={pickerType}
              onChange={onChangeDate}
              value={moment(dates.startTime)}
            />
          </Col>
        )}
      </Row>
      {routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR ? (
        <>
          <TrapSensorChart
            types={['active']}
            title='active'
            filters={dates}
            sensorId={sensorId}
          />
          <TrapSensorChart
            types={['batteryVoltage']}
            title='batteryVoltage'
            filters={dates}
            sensorId={sensorId}
          />
          <TrapSensorChart
            types={['missed']}
            title='missed'
            filters={dates}
            sensorId={sensorId}
          />
          <TrapSensorChart
            types={['caught']}
            title='caught'
            filters={dates}
            sensorId={sensorId}
          />
        </>
      ) : (
        <>
          <DetectionSensorChart
            types={['ratHit']}
            title='ratHit'
            filters={dates}
            sensorId={sensorId}
          />
          <DetectionSensorChart
            types={['battery']}
            title='battery'
            filters={dates}
            sensorId={sensorId}
          />
          <DetectionSensorChart
            types={['heartbeat']}
            title='heartbeat'
            filters={dates}
            sensorId={sensorId}
          />
        </>
      )}
    </div>
  );
};
