import React from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  Tooltip,
} from 'antd';
import i18next from 'i18next';
import { getIn } from 'formik';
import { CustomUpload } from './custom-upload';
import { QuestionCircleOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    radioOptions,
    tooltip,
    submitCount,
    type,
    hint,
    required = false,
    isArrayFormItem = false,
    ...props
  }) => {
    const touched =
      form?.touched[field?.name] || getIn(form?.touched, field?.name);
    const submitted = submitCount > 0;
    const hasError =
      form?.errors[field?.name] || getIn(form?.errors, field?.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onBlur = () => form.setFieldTouched(field.name, true);

    const onChange = (event) => {
      const { target } = event || {};
      const { value, checked } = target || {};
      if (type === 'checkbox') {
        form.setFieldValue(field.name, checked);
      } else if (type === 'text') {
        form.setFieldValue(field.name, value);
      } else if (type === 'password') {
        form.setFieldValue(field.name, value);
      } else if (type === 'email') {
        form.setFieldValue(field.name, value);
      } else {
        if (props.mode === 'multiple' && event.includes('all')) {
          const options = selectOptions
            .filter((item) => item.value !== 'all')
            .map((option) => option?.value);
          form.setFieldValue(
            field.name,
            field.value?.length === options?.length ? [] : options
          );
        } else {
          form.setFieldValue(field.name, event);
        }
      }
    };
    const renderField = (selectOptions) => {
      switch (type) {
        case 'radio':
          return (
            <>
              <AntComponent
                {...field}
                onBlur={onBlur}
                onChange={onChange}
                {...props}
              >
                <Row gutter={[8, 8]}>
                  {radioOptions &&
                    radioOptions.map((option, index) => (
                      <Col key={index}>
                        <Radio.Button
                          style={{ width: '100%', textAlign: 'center' }}
                          key={option?.key}
                          value={option?.value}
                        >
                          {option.localiseLabel || option?.label}
                        </Radio.Button>
                      </Col>
                    ))}
                </Row>
              </AntComponent>
            </>
          );
        case 'checkbox':
          return (
            <AntComponent
              {...field}
              onBlur={onBlur}
              onChange={onChange}
              {...props}
            >
              {i18next.t(label)}
            </AntComponent>
          );
        default:
          if (selectOptions?.length > 0) {
            return (
              <AntComponent
                {...field}
                onBlur={onBlur}
                onChange={onChange}
                style={{ width: '100%' }}
                maxTagCount={2}
                maxTagTextLength={6}
                {...props}
              >
                {selectOptions && props.mode === 'multiple' && (
                  <Option key={'all'} value={'all'}>
                    {i18next.t('all')}
                  </Option>
                )}
                {selectOptions &&
                  selectOptions.map((option) => (
                    <Option key={option?.key} value={option?.value}>
                      {option.localiseLabel || i18next.t(option?.label)}
                    </Option>
                  ))}
              </AntComponent>
            );
          } else {
            return (
              <AntComponent
                {...field}
                onBlur={onBlur}
                onChange={onChange}
                style={{ width: '100%' }}
                {...props}
              />
            );
          }
      }
    };

    return (
      <FormItem
        label={
          type !== 'checkbox' && (
            <span style={{ fontWeight: 'bold' }}>
              {i18next.t(label)}
              {tooltip && (
                <span style={{ marginLeft: '5px' }}>
                  <Tooltip title={tooltip}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              )}
            </span>
          )
        }
        labelCol={{ span: 24 }}
        hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
        help={submittedError || touchedError ? i18next.t(hasError) : false}
        validateStatus={submittedError || touchedError ? 'error' : 'success'}
        extra={hint}
        required={required}
      >
        {renderField(selectOptions)}
        {/*{selectOptions?.length > 0 ? (*/}
        {/*    <AntComponent*/}
        {/*        {...field}*/}
        {/*        onBlur={onBlur}*/}
        {/*        onChange={onChange}*/}
        {/*        {...props}*/}
        {/*        maxTagCount={2}*/}
        {/*    >*/}
        {/*        {selectOptions && props.mode === 'multiple' && (*/}
        {/*            <Option key={'all'} value={'all'}>*/}
        {/*                {i18next.t('all')}*/}
        {/*            </Option>*/}
        {/*        )}*/}
        {/*        {selectOptions &&*/}
        {/*            selectOptions.map((option) => (*/}
        {/*                <Option key={option?.key} value={option?.value}>*/}
        {/*                    {option.localiseLabel || i18next.t(option?.label)}*/}
        {/*                </Option>*/}
        {/*            ))}*/}
        {/*    </AntComponent>*/}
        {/*) : (*/}
        {/*    <AntComponent*/}
        {/*        {...field}*/}
        {/*        onBlur={onBlur}*/}
        {/*        onChange={onChange}*/}
        {/*        {...props}*/}
        {/*    ></AntComponent>*/}
      </FormItem>
    );
  };

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntPasswordInput = CreateAntField(Input.Password);
export const AntTextAreaInput = CreateAntField(Input.TextArea);
export const AntCheckbox = CreateAntField(Checkbox);
export const AntRadio = CreateAntField(Radio.Group);
export const AntUpload = CreateAntField(CustomUpload);
