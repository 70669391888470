import React, { useMemo, useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, notification, Row, Spin } from 'antd';
import {
  AntDatePicker,
  AntInput,
  AntSelect,
  AntTimePicker,
} from '../custom-fields';
import { useForceUpdate } from '../../hooks';
import { useSyncFormState } from '../../hooks/use-sync-form-state';
import { useFetchSites } from '../../services/sites';
import { useTranslation } from 'react-i18next';
import { getFormattedSites } from '../../utilities/transform';
import { Manual_EVENT_TYPES_OPTIONS } from '../../utilities/constants';

import {
  useAddNotification,
  useDeleteNotification,
  useEditNotification,
} from '../../services/notifications';
import isEmpty from 'lodash/isEmpty';

const { RangePicker } = DatePicker;

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 2 }}>
      {children}
    </Col>
  );
};

export const EventForm = ({}) => {
  const navigate = useNavigate();
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t, i18n } = useTranslation();
  const { values, handleReset, setFieldValue } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const { submitCount, validateForm, setTouched } = useFormikContext();
  const { data: { sites } = {} } = useFetchSites();
  const siteOptions = useMemo(
    () => getFormattedSites(sites),
    [sites, i18n.language]
  );
  const [dateRange, setDateRange] = useState([
    values.eventStart,
    values.eventEnd,
  ]);
  const { mutate: addNotification, status: editStatus } = useAddNotification();
  const { mutate: editNotification, status: addStatus } = useEditNotification();
  const { mutate: deleteNotification, status: deleteStatus } =
    useDeleteNotification();

  const isValidating = false;

  useSyncFormState({ callback: forceUpdate });

  const handleSubmit = async () => {
    try {
      const errors = await validateForm();
      if (!isEmpty(errors)) {
        setTouched({
          date: true,
          time: true,
          type: true,
          siteId: true,
        });

        return;
      }
      if (null != values.id) {
        const { remark, ...rest } = values;
        await editNotification({
          ...rest,
          remark: remark?.length > 0 ? remark : ' ',
          dateTime:
            values.date.format('YYYY-MM-DD') +
            ' ' +
            values.time.format('HH:mm:ss'),
        });
        notification.success({ message: t('editedSuccess') });
      } else {
        const { remark, ...rest } = values;
        await addNotification({
          ...rest,
          remark: remark?.length > 0 ? remark : ' ',
          dateTime:
            values.date.format('YYYY-MM-DD') +
            ' ' +
            values.time.format('HH:mm:ss'),
        });
        notification.success({ message: t('createdSuccess') });
      }

      handleReset1();
      navigate('/events');
    } catch ({ message }) {
      notification.error({ message });
    } finally {
      // actions.setSubmitting(false);
    }
    forceUpdate();
  };
  const handleDeleteEvents = async () => {
    try {
      await deleteNotification({ id: values.id });
      notification.success({ message: t('deletedSuccess') });
      navigate('/events');
    } catch (message) {
      notification.error({ message });
    }
  };

  const handleReset1 = async () => {
    await handleReset();
    forceUpdate();
  };
  const onDateRangeChange = async (e) => {
    setDateRange(e);
    await setFieldValue('startTime', e[0].startOf('day').valueOf());
    await setFieldValue('endTime', e[1].startOf('day').valueOf());
  };
  return (
    <Spin spinning={isValidating}>
      <Form>
        <div style={{ marginTop: 10 }} />
        <Row gutter={[10, 10]} align='middle'>
          <>
            <ItemWrapper>
              <Field
                component={AntSelect}
                name='siteId'
                label={t('site')}
                placeholder={t('pleaseSelectSite')}
                submitCount={submitCount}
                selectOptions={siteOptions}
                required
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntSelect}
                name='type'
                label={t('eventtype')}
                placeholder={t('pleaseselecteventtype')}
                submitCount={submitCount}
                selectOptions={Manual_EVENT_TYPES_OPTIONS}
                required
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntDatePicker}
                name='date'
                label={t('eventdate')}
                placeholder={t('pleaseselecteventtime')}
                submitCount={submitCount}
                required
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntTimePicker}
                name='time'
                label={t('eventtime')}
                placeholder={t('pleaseselecteventtime')}
                submitCount={submitCount}
                required
                format={'HH:mm'}
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntInput}
                name='remark'
                type='text'
                label={t('remark')}
                placeholder={t('pleaseenterremark')}
                submitCount={submitCount}
              />
            </ItemWrapper>
            <ItemWrapper></ItemWrapper>
            <ItemWrapper></ItemWrapper>
            <ItemWrapper></ItemWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} type='secondary'>
                <Link to='/events'>{t('back')}</Link>
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
                {t('reset')}
              </Button>
            </ActionButtonWrapper>
            {values.id ? (
              <ActionButtonWrapper>
                <Button
                  block
                  type='primary'
                  style={{ marginTop: 22 }}
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </Button>
              </ActionButtonWrapper>
            ) : (
              <ActionButtonWrapper>
                <Button
                  block
                  type='primary'
                  style={{ marginTop: 22 }}
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </Button>
              </ActionButtonWrapper>
            )}
            {values.id ? (
              <ActionButtonWrapper>
                <Button
                  block
                  type='primary'
                  style={{ marginTop: 22 }}
                  onClick={handleDeleteEvents}
                  danger
                >
                  {t('delete')}
                </Button>
              </ActionButtonWrapper>
            ) : (
              ''
            )}
          </>
          <Col
            xs={{ span: 24 }}
            md={{ span: 4 }}
            sm={{ span: 24 }}
            style={{ marginTop: 20 }}
          ></Col>
        </Row>
        <Row></Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }}></Col>
        </Row>
      </Form>
    </Spin>
  );
};
