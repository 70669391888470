import { useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const ROLES_CACHE_KEY = 'roles';
const ALL = 'all';
const fetchRoles = (api, filters) => async () => {
  const { data } = await api.fetchRoles(filters);
  return {
    roles: data?.data?.roles || [],
    totalCount: data?.data?.totalCount || 0,
  };
};

export const useFetchRoles = (filters = {}) => {
  const api = useApi();
  const response = useSWR(
    `${ROLES_CACHE_KEY}/${filters?.name || ALL}`,
    fetchRoles(api, filters)
  );
  return withLoading(response);
};

const addRole = (api) => async (body) => {
  return await api.addRole(body);
};

export const useAddRole = () => {
  const api = useApi();
  const mutate = useMatchMutate(ROLES_CACHE_KEY);
  return useMutation(addRole(api), {
    isShowErrorMessage: true,
    async onSuccess() {
      await mutate();
    },
  });
};

const editRole = (api) => async (body) => {
  return await api.editRole(body);
};

export const useEditRole = () => {
  const api = useApi();
  const mutate = useMatchMutate(ROLES_CACHE_KEY);
  return useMutation(editRole(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteRole =
  (api) =>
  async ({ roleId }) => {
    return await api.deleteRole({ roleId });
  };

export const useDeleteRole = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${ROLES_CACHE_KEY}/${ALL}`);
  return useMutation(deleteRole(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
