export const SENSOR_DIRECTIONS = {
  DOWN: 'down',
  DOWN_LEFT: 'downLeft',
  DOWN_RIGHT: 'downRight',
  UP: 'up',
  UP_RIGHT: 'upRight',
  UP_LEFT: 'upLeft',
  LEFT: 'left',
  RIGHT: 'right',
};
