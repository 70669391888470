import { useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';

const BATCH_CACHE_KEY = 'batch';
const ALL = 'all';

const fetchBatch = (api, filters) => async () => {
  const { data } = await api.fetchBatch(filters);
  return {
    batch: data?.data?.batch || [],
    totalCount: data?.data?.totalCount || [],
  };
};

export const useFetchBatch = (filters) => {
  const api = useApi();
  const response = useSWR(
    `${BATCH_CACHE_KEY}/${filters.batchId || ALL}`,
    fetchBatch(api, filters)
  );
  return withLoading(response);
};

const fetchBatchDetails = (api, filters) => async () => {
  const { data } = await api.fetchBatchDetails(filters);
  return data?.data || {};
};

export const useFetchBatchDetails = ({ batchId }) => {
  const api = useApi();
  const response = useSWR(
    `BATCH_CACHE_KEY/${batchId}`,
    fetchBatchDetails(api, { batchId })
  );
  return withLoading(response);
};

const addBatch = (api) => async (body) => {
  return await api.addBatch(body);
};

export const useAddBatch = () => {
  const api = useApi();
  const mutate = useMatchMutate(BATCH_CACHE_KEY);
  return useMutation(addBatch(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const editBatch = (api) => async (body) => {
  return await api.editBatch(body);
};

export const useEditBatch = () => {
  const api = useApi();
  const mutate = useMatchMutate(BATCH_CACHE_KEY);
  return useMutation(editBatch(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const disposeBatch = (api) => async (body) => {
  return await api.disposeBatch(body);
};

export const useDisposeBatch = () => {
  const api = useApi();
  const mutate = useMatchMutate(BATCH_CACHE_KEY);
  return useMutation(disposeBatch(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteBatch =
  (api) =>
  async ({ batchId }) => {
    return await api.deleteBatch({ batchId });
  };

export const useDeleteBatch = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${BATCH_CACHE_KEY}/${ALL}`);
  return useMutation(deleteBatch(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
