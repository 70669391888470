import React, { createContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import App from './App';
import './index.css';
import './language';
import { LANGUAGE, LANGUAGE_CODES, STYLE_SHEETS } from './utilities/constants';
import { ConfigProvider } from 'antd';
import { getStorageItem } from './utilities/storage';
import { changeLanguage } from './language/languageUtils';

const defaultValue = STYLE_SHEETS.MEDIUM;
export const FontContext = createContext({
  fontSize: '',
  setFontSize: () => {},
});

export const LanguageContext = createContext({
  language: 'zh',
  setLanguage: () => {},
});
const Application = () => {
  const [fontSize, setFontSize] = useState(defaultValue);
  const [language, setLanguage] = useState(LANGUAGE_CODES.CHINESE);

  const value = useMemo(() => ({ fontSize, setFontSize }), [fontSize]);
  const languageValue = useMemo(() => ({ language, setLanguage }), [language]);
  useEffect(() => {
    changeLanguage(language);
  }, []);
  return (
    <FontContext.Provider value={value}>
      <LanguageContext.Provider value={languageValue}>
        <ConfigProvider componentSize={fontSize}>
          <App />
        </ConfigProvider>
      </LanguageContext.Provider>
    </FontContext.Provider>
  );
};

ReactDOM.render(<Application />, document.getElementById('root'));
