import React from 'react';
import classes from './index.module.css';
import { Breadcrumbs, Loader, PageError } from '../../../../components';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useAddSiteFloorPlan, useFetchSites } from '../../../../services/sites';
import { notification, Spin } from 'antd';
import { AddFloorPlan } from '../../../../components/add-floor-plan';
import {
  generateFilePath,
  getFloorImageUrl,
} from '../../../../utilities/transform';
import { getBase64String } from '../../../../utilities/image-utils';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

const buildInitialValues = (site, floor) => {
  const { name: siteName } = site || {};
  const { name: floorName, floorPath } = floor || {};
  return {
    floorName,
    siteName,
    floorPath: getFloorImageUrl(floorPath),
    imageData: null,
  };
};

export const FloorManagement = () => {
  const { t } = useTranslation();
  const { id: siteId, floorId } = useParams();
  const { isLoading, data: { sites: data } = {} } = useFetchSites([siteId]);
  const { mutate } = useAddSiteFloorPlan();
  if (!data && isLoading) {
    return <Loader />;
  }

  const site = data[0] || {};
  const floors = site.floors || [];
  const matchedFloor = floors.find((item) => String(item.id) === floorId);
  const initialValues = buildInitialValues(site, matchedFloor);

  if (isEmpty(matchedFloor)) {
    return (
      <div className={classes.content}>
        <PageError description={'Error is found'} />
      </div>
    );
  }

  return (
    <div className={classes.content}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('siteManagement'),
            link: '/site-management',
          },
          {
            name: t('editSite'),
            link: `/site-management/${siteId}/edit`,
          },
          {
            name: t('edit'),
            link: `/site-management/${siteId}/floor-management/${floorId}`,
          },
        ]}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            const { imageData } = values || {};
            if (!imageData) {
              return notification.error({ message: 'Image is required' });
            }
            const params = {
              floorId,
              floorPath: generateFilePath(),
              imageData: getBase64String(imageData),
            };
            const response = await mutate(params);
            const updatedFloor = response.data?.data?.floor || {};
            const updatedValues = buildInitialValues(site, updatedFloor);
            actions.resetForm({ values: updatedValues });
            notification.success({ message: t('editedSuccess') });
          } catch ({ message }) {
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          values,
          submitCount,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Spin spinning={isSubmitting}>
              <AddFloorPlan
                siteId={siteId}
                handleSubmit={handleSubmit}
                values={values}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};

export default FloorManagement;
