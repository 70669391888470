import SelectField from '../../select';
import React, { useMemo } from 'react';
import { useFetchSites } from '../../../services/sites';
import { useActiveSiteProvider } from '../../../context/site';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODES } from '../../../utilities/constants';

export const SelectSite = ({ onSiteSelection }) => {
  const { data } = useFetchSites();
  const { sites = [] } = data || {};
  const navigate = useNavigate();
  const { activeSite, setActiveSite } = useActiveSiteProvider();
  const { i18n } = useTranslation();

  const formattedSites = useMemo(() => {
    return sites.map((site) => {
      const { id, name, nameZh } = site || {};
      return {
        value: id,
        label: i18n.language === LANGUAGE_CODES.ENGLISH ? name : nameZh,
      };
    });
  }, [sites, i18n.language]);

  const updateWebsite = (value) => {
    sessionStorage.clear();
    setActiveSite({ value });
    onSiteSelection(value);
    navigate('/');
  };

  return (
    <SelectField
      allowClear={false}
      options={formattedSites}
      value={activeSite?.value}
      onChange={updateWebsite}
      style={{ width: '100%' }}
    />
  );
};

export default SelectSite;
