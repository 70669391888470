import {
  fetchSensorHeatmapAsync,
  fetchSensorsAsync,
} from '../../services/sensors';
import { fetchSitesAsync } from '../../services/sites';
import { ROUTE_KEY_TO_SENSOR_TYPE_MAP } from '../../pages/sensor/constants';

export const fetchSensorAndSite = async ({
  siteId,
  floorId,
  layerId,
  zoneId,
  routeKey,
}) => {
  const { sensors } = await fetchSensorsAsync({
    siteId,
    floorId,
    layerId,
    zoneId,
    type: ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey],
    pageNumber: 1,
    count: 1000,
  });
  const { sites } = await fetchSitesAsync([siteId]);
  const floor = sites[0].floors.filter((floor) => floor.id === floorId).pop();

  return {
    sensors,
    floor,
  };
};

export const fetchSensorHeatmap = async ({
  siteId,
  floorId,
  layerId,
  zoneId,
  startTime,
  endTime,
  routeKey,
}) => {
  const { detectionData, trapCaughtData, trapMissedData } =
    await fetchSensorHeatmapAsync({
      siteId,
      floorId,
      layerId,
      zoneId,
      startTime,
      endTime,
      type: ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey],
    });

  return { detectionData, trapCaughtData, trapMissedData };
};
