import './floor-plan.css';
import { Spin, Typography } from 'antd';
import { Loader, ZoomFloorActionButtons } from '../../../components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getImageDimensions,
  getScaledSensorFloorPlanInfo,
} from '../../../utilities/image-utils';
import {
  getFloorImageUrl,
  sensorScaleTransform,
} from '../../../utilities/transform';
import { ROUTE_KEY_TO_SENSOR_TYPE_MAP } from '../constants';
import { SensorsList } from '../../../components/sensors-list';
import useDraggableScroll from 'use-draggable-scroll';
import { useForceUpdate, useZoomFloorPlan } from '../../../hooks';
import { fetchSensorAndSite } from './service';
import { sensorScaleDownTransform } from '../sensor-location-page/transform';
import orderBy from 'lodash/orderBy';

export const FloorPlan = ({ routeKey, filters, activeItems = [] }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [sensors, setSensors] = useState([]);
  const { onMouseDown } = useDraggableScroll(ref);
  const { forceUpdate, forceUpdateKey } = useForceUpdate();
  const sensorType = ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey];
  const [floorPlanMeta, setFloorPlanMeta] = useState({});
  const { optionsList, activeScaleInfo, onChangeZoomLevel } =
    useZoomFloorPlan();

  const {
    floorPlanImageUrl,
    scaledFloorPlanImageWidth,
    scaledFloorPlanImageHeight,
    sensorIconWidth,
    sensorIconHeight,
    naturalWidth,
    naturalHeight,
  } = floorPlanMeta || {};

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const { floor, sensors } = await fetchSensorAndSite(filters);
      const floorPlanImageUrl = getFloorImageUrl(floor.floorPath);
      const { naturalWidth, naturalHeight } = await getImageDimensions(
        floorPlanImageUrl
      );
      const {
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      } = getScaledSensorFloorPlanInfo(
        naturalWidth,
        naturalHeight,
        activeScaleInfo.imageWidth
      );
      setFloorPlanMeta({
        naturalWidth,
        naturalHeight,
        floorPlanImageUrl,
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      });
      const formattedSensors = sensorScaleDownTransform(
        sensors,
        widthScale,
        heightScale,
        naturalWidth,
        naturalHeight
      );
      setSensors(formattedSensors);
    } catch ({ message }) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleZoomLevelChange = async (imageWidth) => {
    const originalScaleInfo = getScaledSensorFloorPlanInfo(
      scaledFloorPlanImageWidth,
      scaledFloorPlanImageHeight,
      naturalWidth
    );
    const originalScaledSensors = sensorScaleTransform(
      sensors,
      originalScaleInfo.widthScale,
      originalScaleInfo.heightScale
    );

    const zoomedScaleInfo = getScaledSensorFloorPlanInfo(
      naturalWidth,
      naturalHeight,
      imageWidth
    );
    const zoomedSensors = sensorScaleTransform(
      originalScaledSensors,
      zoomedScaleInfo.widthScale,
      zoomedScaleInfo.heightScale
    );
    setFloorPlanMeta((old) => ({
      ...old,
      ...zoomedScaleInfo,
      naturalWidth,
      naturalHeight,
    }));
    setSensors(zoomedSensors);
    setTimeout(forceUpdate, 1500);
  };

  const coloredSensors = useMemo(() => {
    return orderBy(
      sensors.map((item) => {
        const isActiveItem = activeItems.includes(item.id);
        return isActiveItem ? { ...item, color: 'red' } : item;
      }),
      'color',
      'asc'
    );
  }, [sensors, activeItems]);

  useEffect(() => {
    onSubmit();
    // eslint-disable-next-line
  }, [filters]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Spin spinning={isLoading}>
      {floorPlanImageUrl && (
        <>
          <ZoomFloorActionButtons
            activeScaleInfo={activeScaleInfo}
            optionsList={optionsList}
            onChange={(item) => {
              onChangeZoomLevel(item.value);
              handleZoomLevelChange(item.imageWidth);
            }}
          />
          <div className='floor-plan-center'>
            <div
              className='floor-plan-wrapper'
              ref={ref}
              onMouseDown={onMouseDown}
            >
              <img
                draggable='false'
                alt='Sensor Area'
                className='floor-plan-image'
                src={floorPlanImageUrl}
                width={scaledFloorPlanImageWidth}
                height={scaledFloorPlanImageHeight}
                ref={ref}
                onMouseDown={onMouseDown}
              />
              <SensorsList
                isPopover
                isDraggableAllowed={false}
                sensors={coloredSensors}
                sensorType={sensorType}
                sensorIconWidth={sensorIconWidth}
                sensorIconHeight={sensorIconHeight}
                key={`${forceUpdateKey}-${activeItems[0]}`}
                scaledFloorPlanImageWidth={scaledFloorPlanImageWidth}
                scaledFloorPlanImageHeight={scaledFloorPlanImageHeight}
              />
            </div>
          </div>
        </>
      )}
      {!floorPlanImageUrl && coloredSensors.length > 0 && (
        <Typography.Text>{t('noFloorPlanFound')}</Typography.Text>
      )}
    </Spin>
  );
};
