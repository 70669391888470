import { sorter } from '../../../utilities/transform';
import { Button, Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import i18next from 'i18next';

export const buildTableColumns = () => {
  const stateText = (record) => {
    return (
      <div>{'A' == record.state ? i18next.t('active') : record.stateName}</div>
    );
  };
  return [
    {
      title: i18next.t('role'),
      dataIndex: 'roleName',
      key: 'roleName',
      sorter: (a, b) => sorter(a.roleName, b.roleName),
      isSearchable: true,
    },
    {
      title: i18next.t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sorter(a.name, b.name),
      isSearchable: true,
    },
    {
      title: i18next.t('title1'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sorter(a.title, b.title),
      isSearchable: true,
    },
    {
      title: i18next.t('email'),
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => sorter(a.email, b.email),
      isSearchable: true,
    },
    {
      title: i18next.t('status'),
      dataIndex: 'stateName',
      key: 'stateName',
      sorter: (a, b) => sorter(a.stateName, b.stateName),
      isSearchable: true,
      render: (text, record) => (
        <Space size='middle'>{stateText(record)}</Space>
      ),
    },
    {
      title: i18next.t('action'),
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title='move to edit'>
            <Link to={`${record.id}/edit`}>
              <Button icon={<EditOutlined />} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];
};
