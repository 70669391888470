import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../components';
import { FloorPlanForm } from './filter-form';
import { Formik } from 'formik';
import moment from 'moment';
import { SENSOR_PAGE_KEYS } from '../../utilities/constants';
import { FORM_STORAGE_KEYS } from '../../utilities/storage';
import { FilterHeatmapSchema } from '../../utilities/schemas';

export const FloorPlanHeatmap = () => {
  const { t } = useTranslation();
  return (
    <div className='p-10'>
      <PageHeader title={t('playback')} />
      <Formik
        initialValues={{
          startTime: moment().subtract(7, 'd'),
          endTime: moment(),
        }}
        validationSchema={FilterHeatmapSchema}
        onSubmit={() => {}}
      >
        <FloorPlanForm
          routeKey={SENSOR_PAGE_KEYS.DETECTION_SENSOR}
          formKey={FORM_STORAGE_KEYS.HEATMAP_FLOOR_MAP}
        />
      </Formik>
    </div>
  );
};
export default FloorPlanHeatmap;
