import React from 'react';
import { Link } from 'react-router-dom';
import classes from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { SENSOR_PAGE_KEYS } from '../../utilities/constants';
import { Button, Col, Input, Radio, Row, Tooltip, Typography } from 'antd';
import { EnvironmentFilled, SearchOutlined } from '@ant-design/icons';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const defaultOptionsList = [
  {
    labelKey: 'trapSensor',
    value: SENSOR_PAGE_KEYS.TRAP_SENSOR,
  },
  {
    labelKey: 'detectionSensor',
    value: SENSOR_PAGE_KEYS.DETECTION_SENSOR,
  },
  {
    labelKey: 'baitReportAndDevices',
    value: SENSOR_PAGE_KEYS.BAIT_SENSOR,
  },
];

export const PageHeader = ({
  title,
  to,
  isSearch,
  isCreate,
  onChange,
  search = '',
  sensorType = '',
  handleSensorType,
  sensorTypeOptionsList = defaultOptionsList,
  isFilterDropDownAllowed = false,
  isShowFilters,
  toggleFilters,
  setShowFloorPlan,
  isFloorPlanPin,
  isCreateEvent,
  showEventModel,
  isCreateBatch,
  showBatchModel,
}) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[10, 10]} className={classes.headerRow}>
      <div>
        <Row align='bottom'>
          <Col>
            <Typography.Title
              style={{ display: 'inline-block' }}
              level={3}
              className={classes.title}
            >
              {title}
            </Typography.Title>
          </Col>
          <Col>
            {isCreate && (
              <Tooltip title={t('moveToCreate')}>
                <Link to={to} style={{ marginLeft: 10 }}>
                  <Button>{t('create')}</Button>
                </Link>
              </Tooltip>
            )}
            {isCreateEvent && (
              <Tooltip title={t('moveToCreate')}>
                <Button onClick={showEventModel} style={{ marginLeft: 10 }}>
                  {t('create')}
                </Button>
              </Tooltip>
            )}
            {isCreateBatch && (
              <Tooltip title={t('moveToCreate')}>
                <Button onClick={showBatchModel} style={{ marginLeft: 10 }}>
                  {t('create')}
                </Button>
              </Tooltip>
            )}
          </Col>
        </Row>
        <div className={classes.headerBar} />
      </div>
      {sensorType && (
        <div>
          <Radio.Group
            onChange={(e) => handleSensorType(e.target.value)}
            defaultValue={sensorType}
            buttonStyle='solid'
          >
            {sensorTypeOptionsList.map(({ value, labelKey }) => {
              return <Radio.Button value={value}>{t(labelKey)}</Radio.Button>;
            })}
          </Radio.Group>
        </div>
      )}
      <div className={`${classes.rightHeader} offset-on-sm`}>
        {isFilterDropDownAllowed && (
          <Button
            type='primary'
            icon={isShowFilters ? <BsChevronDown /> : <BsChevronUp />}
            onClick={toggleFilters}
            style={{ height: '36px' }}
          />
        )}
        {isFloorPlanPin && (
          <EnvironmentFilled
            style={{ fontSize: '36px', color: '#EA232B' }}
            onClick={() => setShowFloorPlan(true)}
          />
        )}

        {isSearch && (
          <Input
            prefix={<SearchOutlined />}
            placeholder={t('typeIntoToSearch')}
            type='text'
            onChange={onChange}
            allowClear
            value={search}
          />
        )}
      </div>
    </Row>
  );
};
