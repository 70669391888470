import './index.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, notification, Row } from 'antd';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  ExchangeDeviceForm,
  Loader,
  PageHeader,
} from '../../../components';
import { ExchangeDeviceSchema } from '../../../utilities/schemas';
import { useFetchDeviceBySensorId } from '../../../services/device';
import { useExchangeSensor } from '../../../services/sensors';
import isEmpty from 'lodash/isEmpty';

const buildInitialValues = (device, sensor) => {
  return {
    id: device?.id,
    originalId: device?.code,
    location: sensor.siteName + ' ' + sensor.floorName,
    locationZh: sensor.siteNameZh + ' ' + sensor.floorNameZh,
  };
};

export const ExchangeDevice = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: { device, sensor } = {}, isLoading } = useFetchDeviceBySensorId(
    {
      sensorId: id,
    }
  );
  const { mutate: exchangeSensor } = useExchangeSensor();
  if (isEmpty(device) || isLoading) {
    return <Loader />;
  }

  const initialValues = buildInitialValues(device, sensor);
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('deviceList'),
            link: '/trap-sensor/list',
          },
          {
            name: t('exchange'),
            link: `/trap-sensor/exchange/${id}`,
          },
        ]}
      />
      <PageHeader title={t('exchange')} />
      <Row>
        <Col span={24}>
          <Formik
            initialValues={initialValues}
            validationSchema={ExchangeDeviceSchema}
            onSubmit={async (formValues, actions) => {
              /* useDataInputSensors(formValues); */
              try {
                await exchangeSensor(formValues);
                await actions.resetForm();
                notification.success({ message: t('editedSuccess') });
                navigate('/trap-sensor/list');
              } catch ({ message }) {
                notification.error({ message });
              } finally {
                actions.setSubmitting(false);
              }
            }}
            render={ExchangeDeviceForm}
          />
        </Col>
      </Row>
    </div>
  );
};
export default ExchangeDevice;
