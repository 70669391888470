import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { createContext, useContext, useEffect, useState } from 'react';
import { useFetchCurrentUser } from '../services/users';
import get from 'lodash/get';

const ActiveSiteContext = createContext({
  activeSite: {},
  setActiveSite: noop,
});

export const ActiveSiteProvider = ({ children }) => {
  const { data = {} } = useFetchCurrentUser();
  const { user = {} } = data || {};
  const siteId = get(user, 'overviewData.siteId');
  const [activeSite, setActiveSite] = useState();

  useEffect(() => {
    if (!siteId || !isEmpty(activeSite)) {
      return [];
    }
    const updatedSite = { value: siteId };
    setActiveSite(updatedSite);
    // eslint-disable-next-line
  }, [siteId]);

  return (
    <ActiveSiteContext.Provider value={{ activeSite, setActiveSite }}>
      {children}
    </ActiveSiteContext.Provider>
  );
};

export const useActiveSiteProvider = () => {
  const context = useContext(ActiveSiteContext);
  if (!context)
    throw new Error('useActiveSiteContext must be used inside UserProvider!');
  return context;
};
