import './index.css';
import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { EventFilterForm } from './event-filter-form';
import { PageHeader } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useUserProvider } from '../../../context/session';
import { useToggleFilters } from '../../../hooks';

export const EventsList = () => {
  const [user, setUser] = useUserProvider();

  const ROLEID = useMemo(() => {
    const { user: profile } = user || {};
    const { roleId } = profile || {};
    return roleId;
  }, [user]);
  const [showEventModel, setShowEventModel] = useState(false);
  const { isShowFilters, toggleFilters } = useToggleFilters();
  const { t } = useTranslation();
  return (
    <div className='p-10'>
      <PageHeader
        title={t('event1')}
        isCreateEvent={ROLEID === 3 ? false : true}
        showEventModel={() => setShowEventModel(true)}
        isFilterDropDownAllowed
        isShowFilters={isShowFilters}
        toggleFilters={toggleFilters}
      />
      <Formik initialValues={{}} onSubmit={() => {}}>
        <EventFilterForm
          isShowFilters={isShowFilters}
          showEventModel={showEventModel}
          setShowEventModel={(e) => setShowEventModel(e)}
          ROLEID={ROLEID}
        />
      </Formik>
    </div>
  );
};

export default EventsList;
