import React, { useEffect, useMemo, useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Col, Divider, Row, DatePicker } from 'antd';
import { useListFiltrate } from '../../services/sensors';
import { AntInput, AntSelect } from '../custom-fields';
import { useForceUpdate } from '../../hooks';
import {
  getFloorLayerAndZoneFromSite,
  getFormattedSites,
} from '../../utilities/transform';
import { useTranslation } from 'react-i18next';
import { useFetchSites } from '../../services/sites';
import {
  BATTERY_LEVELS,
  GUTTER,
  SENSOR_TYPES,
} from '../../utilities/constants';
import { useActiveSiteProvider } from '../../context/site';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return <Col>{children}</Col>;
};

export const BatteryReplaceForm = ({
  handleSubmit,
  handleDelete,
  editMode = false,
}) => {
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t, i18n } = useTranslation();
  const { activeSite: activeSiteId } = useActiveSiteProvider();
  const { values, errors, handleReset, setFieldValue, submitCount } =
    useFormikContext();
  const [dateRange, setDateRange] = useState([
    values.eventStart,
    values.eventEnd,
  ]);
  const {
    checkedList,
    siteId,
    layerId,
    floorId,
    zoneId,
    sensorType,
    batteryLevel,
  } = values || {};
  const { forceUpdateKey, forceUpdate } = useForceUpdate();

  const [activeSite, setActiveSite] = useState({});
  const [sensors, setSensors] = useState([]);

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (list) => {
    setFieldValue('checkedList', list);
    setIndeterminate(!!list.length && list.length < sensors.length);
    setCheckAll(list.length === sensors.length);
  };
  const onCheckAllChange = (e) => {
    const tempMap = sensors.map((item) => item.id);
    setFieldValue('checkedList', e.target.checked ? tempMap : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const { data: { sites } = {} } = useFetchSites();

  const { mutate: listFiltrate } = useListFiltrate();

  useEffect(async () => {
    await locationReset();
  }, [sites, i18n.language]);
  const locationReset = async () => {
    if (sites && sites.length > 0) {
      if (activeSiteId.value && !editMode) {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        const { floors, layers, zones } = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        setActiveSite({ id: selectedSite?.id, floors, layers, zones });
      } else if (values.siteId && editMode) {
        const selectedSite = sites.find((site) => site.id === values.siteId);
        const { floors, layers, zones } = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        setActiveSite({ id: selectedSite?.id, floors, layers, zones });
      }
    }
    forceUpdate();
  };

  useEffect(async () => {
    if (!editMode && activeSite) {
      if (activeSiteId.value) {
        await setFieldValue('siteId', activeSiteId.value);
      }
      if (activeSite.layers && activeSite.layers.length > 0) {
        await setFieldValue('layerId', [activeSite.layers[0].value]);
      }

      if (activeSite.floors && activeSite.floors.length > 0) {
        await setFieldValue('floorId', activeSite.floors[0].value);
      }

      if (activeSite.zones && activeSite.zones.length > 0) {
        await setFieldValue('zoneId', [activeSite.zones[0].value]);
      }
    }
  }, [activeSite]);

  useEffect(async () => {
    if (siteId && layerId && floorId && zoneId && sensorType && batteryLevel) {
      const { data: { data: { sensors: sensorsData } } = {} } =
        await listFiltrate({
          siteId,
          layerId,
          floorId,
          zoneId,
          sensorType,
          batteryLevel,
        });
      sensorsData &&
        sensorsData.forEach((n) => {
          n.label = n.name + '---' + n.id;
          n.value = n.id;
        });
      setSensors(sensorsData);
      if (editMode) {
        setFieldValue(
          'sensorList',
          sensorsData.map((sensor) => sensor.id)
        );
      }
    }
  }, [siteId, layerId, floorId, zoneId, sensorType, batteryLevel]);

  const siteOptions = useMemo(
    () => getFormattedSites(sites),
    [sites, i18n.language]
  );
  const handleReset1 = async () => {
    await handleReset();
    locationReset();
    forceUpdate();
  };
  const sensorsOption = () => {
    return sensors.map((sensor, index) => (
      <Row key={index}>
        <Col span={24}>
          <Checkbox value={sensor.id} key={sensor.id}>
            {sensor.name}
          </Checkbox>
        </Col>
      </Row>
    ));
  };
  const onDateRangeChange = async (e) => {
    setDateRange(e);
    await setFieldValue('startTime', e[0].startOf('day').valueOf());
    await setFieldValue('endTime', e[1].startOf('day').valueOf());
  };
  return (
    <Form>
      <div style={{ marginTop: 10 }} />
      <Row gutter={[10, 10]} align='middle'>
        <ItemWrapper>
          <Field
            component={AntSelect}
            name='siteId'
            label={t('site')}
            placeholder={t('pleaseSelectSite')}
            submitCount={submitCount}
            selectOptions={siteOptions}
            required
            disabled
          />
        </ItemWrapper>
        <ItemWrapper>
          <Field
            component={AntSelect}
            name='floorId'
            label={t('floor')}
            placeholder={t('pleaseSelectFloor')}
            submitCount={submitCount}
            selectOptions={activeSite.floors}
            required
          />
        </ItemWrapper>
        <ItemWrapper>
          <Field
            component={AntSelect}
            name='layerId'
            label={t('layer')}
            mode={'multiple'}
            placeholder={t('pleaseSelectLayer')}
            submitCount={submitCount}
            selectOptions={activeSite.layers}
            required
          />
        </ItemWrapper>

        <ItemWrapper>
          <Field
            component={AntSelect}
            name='zoneId'
            label={t('zone')}
            placeholder={t('pleaseSelectZone')}
            mode={'multiple'}
            submitCount={submitCount}
            selectOptions={activeSite.zones}
            required
          />
        </ItemWrapper>
        <ItemWrapper>
          <Field
            component={AntSelect}
            name='sensorType'
            label={t('sensorType')}
            placeholder={t('pleaseSelectSensorType')}
            submitCount={submitCount}
            selectOptions={SENSOR_TYPES}
            required
          />
        </ItemWrapper>
        <ItemWrapper>
          <Field
            component={AntSelect}
            name='batteryLevel'
            label={t('batteryLevel')}
            placeholder={t('pleaseSelectBatteryLevel')}
            submitCount={submitCount}
            selectOptions={BATTERY_LEVELS}
            required
          />
        </ItemWrapper>
        {editMode && (
          <ItemWrapper>
            <Field
              component={AntInput}
              name='remark'
              type='text'
              label={t('remark')}
              placeholder={t('pleaseenterremark')}
              submitCount={submitCount}
            />
          </ItemWrapper>
        )}
        <ItemWrapper>
          <span style={{ fontWeight: 'bold' }}>{t('date')}</span>
          <RangePicker
            style={{ width: '100%' }}
            value={dateRange}
            onChange={(val) => onDateRangeChange(val)}
          />
        </ItemWrapper>
      </Row>
      <Row>
        <ItemWrapper>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            style={{ width: '100%' }}
          >
            {t('checkall')}
          </Checkbox>
          <Divider />
          <CheckboxGroup
            style={{ width: '100%' }}
            value={checkedList}
            onChange={onChange}
          >
            {sensors && sensorsOption()}
          </CheckboxGroup>
          <div role='alert' className='ant-form-item-explain-error'>
            {submitCount > 0 && errors?.checkedList && t(errors?.checkedList)}
          </div>
        </ItemWrapper>
      </Row>
      <Row gutter={[GUTTER, GUTTER]}>
        <ActionButtonWrapper>
          <Button block style={{ marginTop: 22 }} type='secondary'>
            <Link to={'/events'}>{t('back')}</Link>
          </Button>
        </ActionButtonWrapper>
        <ActionButtonWrapper>
          <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
            {t('reset')}
          </Button>
        </ActionButtonWrapper>
        <ActionButtonWrapper>
          <Button
            block
            type='primary'
            style={{ marginTop: 22 }}
            onClick={handleSubmit}
          >
            {t('save')}
          </Button>
        </ActionButtonWrapper>
        <ActionButtonWrapper>
          <Button
            block
            type='primary'
            style={{ marginTop: 22 }}
            onClick={handleDelete}
            danger
          >
            {t('delete')}
          </Button>
        </ActionButtonWrapper>
      </Row>
    </Form>
  );
};
