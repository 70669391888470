import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { TrapSensorThirdTabForm } from './trap-sensor-third-tab-form';
import { DEFAULT_PAGE_SIZE } from '../../../../utilities/constants';

const initialValues = {
  startTime: moment().subtract(30, 'd'),
  endTime: moment(),
  pageNumber: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  isDisconnected: true,
  isActive: true,
  isMissed: true,
  isCaught: true,
  isBatteryVoltage: true,
  isRssi: true,
  isStatus: true,
};

export const TrapSensorThirdTab = () => {
  return (
    <Formik initialValues={initialValues}>
      <TrapSensorThirdTabForm />
    </Formik>
  );
};
