import './index.css';
import React from 'react';
import { Button, Col, Popconfirm, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray, Form } from 'formik';
import { AntInput, AntSelect, AntTextAreaInput, Bar } from '../index';
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { GUTTER, MEDIUMS } from '../../utilities/constants';
import { Link } from 'react-router-dom';

export const SiteForm = ({
  handleSubmit,
  values,
  submitCount,
  resetForm,
  isLoading,
  isEditMode = true,
  siteId,
  handleDelete,
  testSms,
}) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Row>
            <Col span={20}>
              <Title level={3}>{t('siteInformation')}</Title>
              <Bar />
            </Col>
          </Row>

          <Field
            component={AntInput}
            name='siteKey'
            type='text'
            label={t('key')}
            placeholder={t('pleaseInputKey')}
            submitCount={submitCount}
            hasFeedback
            required
          />
          <Field
            component={AntInput}
            name='name'
            type='text'
            label={t('siteNameEnglish')}
            placeholder={t('pleaseInputSiteNameEnglish')}
            submitCount={submitCount}
            hasFeedback
            required
          />
          <Field
            component={AntInput}
            name='nameZh'
            type='text'
            label={t('siteNameChinese')}
            placeholder={t('pleaseInputSiteNameChinese')}
            submitCount={submitCount}
            hasFeedback
            required
          />
          <Field
            component={AntTextAreaInput}
            name='address'
            label={t('address')}
            type='text'
            placeholder={t('pleaseInputAddress')}
            submitCount={submitCount}
            hasFeedback
          />
          <Field
            component={AntInput}
            name='contactEmail'
            type='email'
            label={t('contactEmail')}
            placeholder={t('pleaseInputContactEmail')}
            submitCount={submitCount}
            hasFeedback
            required
          />
          <FieldArray name='floors'>
            {({ insert, remove, push, replace }) => (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={20}>{t('floor')}</Col>
                  <Col span={4} className='plus-icon-container'>
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        if (isEditMode) {
                          return push({
                            local: true,
                            floorKey: '',
                            name: '',
                            nameZh: '',
                          });
                        }
                        return push({ floorKey: '', name: '', nameZh: '' });
                      }}
                    />
                  </Col>
                </Row>
                <div>
                  {values?.floors.length > 0 &&
                    values?.floors.map((floor, index) => {
                      if (floor?.isDelete === 1) {
                        return false;
                      } else {
                        return (
                          <Row
                            gutter={[10, 10]}
                            style={{
                              backgroundColor: 'aliceblue',
                              marginBottom: 10,
                            }}
                            key={index}
                            align='bottom'
                          >
                            <Col span={18}>
                              <Field
                                component={AntInput}
                                name={`floors.${index}.floorKey`}
                                type='text'
                                label={t('id')}
                                placeholder={t('pleaseInputId')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                            <Col>
                              <Button
                                shape='circle'
                                style={{ marginBottom: 6 }}
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  if (!isEditMode) {
                                    return remove(index);
                                  } else {
                                    if (floor?.local) {
                                      return remove(index);
                                    } else {
                                      return replace(index, {
                                        ...floor,
                                        isDelete: 1,
                                      });
                                    }
                                  }
                                }}
                              />
                            </Col>
                            <Col xs={{ span: 18 }} md={{ span: 9 }}>
                              <Field
                                component={AntInput}
                                name={`floors.${index}.name`}
                                type='text'
                                label={t('floorNameEnglish')}
                                placeholder={t('pleaseInputFloorNameEnglish')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                            <Col xs={{ span: 18 }} md={{ span: 9 }}>
                              <Field
                                component={AntInput}
                                name={`floors.${index}.nameZh`}
                                type='text'
                                label={t('floorNameChinese')}
                                placeholder={t('pleaseInputFloorNameChinese')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                            <Col>
                              {isEditMode && !floor.local && (
                                <Link
                                  to={`/site-management/${siteId}/floor-management/${floor.id}`}
                                >
                                  <Button
                                    shape='circle'
                                    style={{ marginBottom: 6 }}
                                    icon={<EditOutlined />}
                                  />
                                </Link>
                              )}
                            </Col>
                          </Row>
                        );
                      }
                    })}
                </div>
              </>
            )}
          </FieldArray>
          <FieldArray name='layers'>
            {({ insert, remove, push, replace }) => (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={20}>{t('layer')}</Col>
                  <Col span={4} className='plus-icon-container'>
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        if (isEditMode) {
                          return push({
                            local: true,
                            layerKey: '',
                            name: '',
                            nameZh: '',
                          });
                        }
                        return push({ layerKey: '', name: '', nameZh: '' });
                      }}
                    />
                  </Col>
                </Row>
                <div>
                  {values?.layers.length > 0 &&
                    values?.layers.map((layer, index) => {
                      if (layer?.isDelete) {
                        return false;
                      } else {
                        return (
                          <Row
                            gutter={[10, 10]}
                            style={{
                              backgroundColor: 'aliceblue',
                              marginBottom: 10,
                            }}
                            key={index}
                            align='bottom'
                          >
                            <Col span={18}>
                              <Field
                                component={AntInput}
                                name={`layers.${index}.layerKey`}
                                type='text'
                                label={t('id')}
                                placeholder={t('pleaseInputId')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                            <Col>
                              <Button
                                shape='circle'
                                style={{ marginBottom: 6 }}
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  if (!isEditMode) {
                                    return remove(index);
                                  } else {
                                    if (layer?.local) {
                                      return remove(index);
                                    } else {
                                      return replace(index, {
                                        ...layer,
                                        isDelete: 1,
                                      });
                                    }
                                  }
                                }}
                              />
                            </Col>
                            <Col xs={{ span: 18 }} md={{ span: 9 }}>
                              <Field
                                component={AntInput}
                                name={`layers.${index}.name`}
                                type='text'
                                label={t('layerNameEnglish')}
                                placeholder={t('pleaseInputLayerNameEnglish')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                            <Col xs={{ span: 18 }} md={{ span: 9 }}>
                              <Field
                                component={AntInput}
                                name={`layers.${index}.nameZh`}
                                type='text'
                                label={t('layerNameChinese')}
                                placeholder={t('pleaseInputLayerNameChinese')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                          </Row>
                        );
                      }
                    })}
                </div>
              </>
            )}
          </FieldArray>
          <FieldArray name='zones'>
            {({ insert, remove, push, replace }) => (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={20}>{t('zone')}</Col>
                  <Col span={4} className='plus-icon-container'>
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        if (isEditMode) {
                          return push({
                            local: true,
                            zoneKey: '',
                            name: '',
                            nameZh: '',
                          });
                        }
                        return push({ zoneKey: '', name: '', nameZh: '' });
                      }}
                    />
                  </Col>
                </Row>
                <div>
                  {values?.zones.length > 0 &&
                    values?.zones.map((zone, index) => {
                      if (zone?.isDelete) {
                        return false;
                      } else {
                        return (
                          <Row
                            gutter={[10, 10]}
                            style={{
                              backgroundColor: 'aliceblue',
                              marginBottom: 10,
                            }}
                            key={index}
                            align='bottom'
                          >
                            <Col span={18}>
                              <Field
                                component={AntInput}
                                name={`zones.${index}.zoneKey`}
                                type='text'
                                label={t('id')}
                                placeholder={t('pleaseInputId')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>

                            <Col>
                              <Button
                                shape='circle'
                                style={{ marginBottom: 6 }}
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  if (!isEditMode) {
                                    return remove(index);
                                  } else {
                                    if (zone?.local) {
                                      return remove(index);
                                    } else {
                                      return replace(index, {
                                        ...zone,
                                        isDelete: 1,
                                      });
                                    }
                                  }
                                }}
                              />
                            </Col>
                            <Col xs={{ span: 18 }} md={{ span: 9 }}>
                              <Field
                                component={AntInput}
                                name={`zones.${index}.name`}
                                type='text'
                                label={t('zoneNameEnglish')}
                                placeholder={t('pleaseInputZoneNameEnglish')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                            <Col xs={{ span: 18 }} md={{ span: 9 }}>
                              <Field
                                component={AntInput}
                                name={`zones.${index}.nameZh`}
                                type='text'
                                label={t('zoneNameChinese')}
                                placeholder={t('pleaseInputZoneNameChinese')}
                                submitCount={submitCount}
                                hasFeedback
                                required
                              />
                            </Col>
                          </Row>
                        );
                      }
                    })}
                </div>
              </>
            )}
          </FieldArray>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Row>
            <Col span={20}>
              <Title level={3}>{t('contactInformation')}</Title>
              <Bar />
            </Col>
          </Row>
          <Field
            component={AntInput}
            name='contactPerson'
            type='text'
            label={t('contactPerson')}
            placeholder={t('pleaseInputContactPerson')}
            submitCount={submitCount}
            hasFeedback
          />
          <Field
            component={AntInput}
            name='contactPhone'
            type='text'
            label={t('contactNumber')}
            placeholder={t('pleaseInputContactNumber')}
            submitCount={submitCount}
            hasFeedback
          />
          <Field
            component={AntTextAreaInput}
            name='remark'
            label={t('remarks')}
            type='text'
            placeholder={t('pleaseInputRemarks')}
            submitCount={submitCount}
            hasFeedback
          />
          <Row style={{ marginBottom: 10 }}>
            <Col span={20}>
              <Title level={3}>{t('triggerMedium')}</Title>
              <Bar />
            </Col>
          </Row>
          <Field
            component={AntSelect}
            name='triggerMedium'
            submitCount={submitCount}
            hasFeedback
            selectOptions={MEDIUMS}
            required
          />
          <Row style={{ marginBottom: 10 }}>
            <Col span={20}>
              <Title level={3}>{t('mailList')}</Title>
              <Bar />
            </Col>
          </Row>
          <Field
            component={AntTextAreaInput}
            name='emailList'
            type='text'
            submitCount={submitCount}
            hasFeedback
            selectOptions={['Option1', 'Option2', 'Option3']}
            hint={t('mailListHint')}
          />
          <Row style={{ marginBottom: 10 }}>
            <Col span={20}>
              <Title level={3}>
                {t('smsAlertList')}{' '}
                <Button onClick={testSms} type={'primary'}>
                  {t('test')}
                </Button>
              </Title>

              <Bar />
            </Col>
          </Row>
          <Field
            component={AntTextAreaInput}
            name='smsAlertList'
            type='text'
            submitCount={submitCount}
            hasFeedback
            selectOptions={['Option1', 'Option2', 'Option3']}
            hint={t('smsListHint')}
          />
          <Row style={{ marginBottom: 10 }}>
            <Col span={20}>
              <Title level={3}>{t('emailAlertList')}</Title>
              <Bar />
            </Col>
          </Row>
          <Field
            component={AntTextAreaInput}
            name='emailAlertList'
            type='text'
            submitCount={submitCount}
            hasFeedback
            selectOptions={['Option1', 'Option2', 'Option3']}
            hint={t('emailListHint')}
          />
          <Row style={{ marginBottom: 10 }}>
            <Col span={20}>
              <Title level={3}>{t('accountAlertList')}</Title>
              <Bar />
            </Col>
          </Row>
          <Field
            component={AntTextAreaInput}
            name='accountAlertList'
            type='text'
            placeholder={t('searchByNameTitleEmail')}
            submitCount={submitCount}
            hasFeedback
            selectOptions={['Option1', 'Option2', 'Option3']}
          />
        </Col>
      </Row>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col>
          <Link to='/site-management'>
            <Button type='secondary'>{t('back')}</Button>
          </Link>
        </Col>
        <Col>
          <Button type='secondary' onClick={resetForm}>
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button type='primary' onClick={handleSubmit}>
            {t('save')}
          </Button>
        </Col>
        {isEditMode && (
          <Col>
            <Popconfirm
              title='Are you sure to delete this site?'
              onConfirm={handleDelete}
              okText='Yes'
              cancelText='No'
            >
              <Button type='danger'>{t('delete')}</Button>
            </Popconfirm>
          </Col>
        )}
      </Row>
    </Form>
  );
};
