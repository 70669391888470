import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { Breadcrumbs, LocationChangeForm } from '../../../components';
import { useDeleteBait, useEditBait } from '../../../services/baits';
import { generateFilePath } from '../../../utilities/transform';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { getBase64String } from '../../../utilities/image-utils';
import { EventLocationChangeSchema } from '../../../utilities/schemas';

const buildInitialValues = (eventLocationChange) => {
  return {
    eventStart: moment(),
    eventEnd: moment(),
  };
};

const { Title } = Typography;
export const AddLocationChange = () => {
  const { state } = useLocation();
  const { isFromEvent } = state || { isFromEvent: false };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const eventLocationChange = {};
  const isLoading = false;

  const { mutate: editBait, status: editStatus } = useEditBait();
  const { mutate: deleteBait, status: deleteStatus } = useDeleteBait();

  const handleDeleteBait = async () => {
    try {
      await deleteBait({ eventLocationChangeId: id });
      notification.success({ message: t('deletedSuccess') });
      navigate('/device-management');
    } catch (e) {}
  };

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          !isFromEvent
            ? {
                name: t('batchManagement'),
                link: '/batch-management',
              }
            : {
                name: t('event1'),
                link: '/events',
              },
          {
            name: t('add'),
            link: `/device-management/add`,
          },
        ]}
      />
      <Title level={3}>{t('createbatch')}</Title>

      <Formik
        enableReinitialize={true}
        validationSchema={EventLocationChangeSchema}
        initialValues={buildInitialValues(eventLocationChange)}
        onSubmit={async (values, actions) => {
          try {
            const { name, path, imageData } = values || {};
            const params = { name: name.trim(), path, imageData };
            if (values.imageData) {
              params.imageData = getBase64String(imageData);
              params.path = generateFilePath();
            }
            params.expiryDate = moment(values.expiryDate).valueOf();
            actions.setSubmitting(true);
            const payload = { ...params, id };
            const { data } = await editBait(payload);
            notification.success({ message: t('editedSuccess') });
            const updatedSite = data?.data?.eventLocationChange;
            const updatedData = buildInitialValues(updatedSite);
            await actions.resetForm({ values: updatedData });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin
              spinning={deleteStatus === 'pending' || editStatus === 'pending'}
            >
              <LocationChangeForm
                dirty={dirty}
                values={buildInitialValues(eventLocationChange)}
                defaultLocation={buildInitialValues(eventLocationChange)}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                handleDeleteBait={handleDeleteBait}
                isFromEvent={isFromEvent}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddLocationChange;
