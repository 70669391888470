import { useApi } from './api';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';
import useSWR from 'swr';

const EVENT_BATTERY_REPLACE = 'event_battery_replace';
const ALL = 'all';

const disposeEventBatteryReplace = (api) => async (body) => {
  return await api.disposeEventBatteryReplace(body);
};

export const useDisposeEventBatteryReplace = () => {
  const api = useApi();
  const mutate = useMatchMutate(EVENT_BATTERY_REPLACE);
  return useMutation(disposeEventBatteryReplace(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const addEventBatteryReplace = (api) => async (body) => {
  return await api.addEventBatteryReplace(body);
};

export const useAddEventBatteryReplace = () => {
  const api = useApi();
  const mutate = useMatchMutate(EVENT_BATTERY_REPLACE);
  return useMutation(addEventBatteryReplace(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const fetchEventBatteryReplaceDetails = (api, filters) => async () => {
  const { data } = await api.fetchEventBatteryReplaceDetails(filters);
  return data?.data || {};
};

export const useFetchEventBatteryReplaceDetails = ({ notificationId }) => {
  const api = useApi();
  const response = useSWR(
    `${EVENT_BATTERY_REPLACE}/${notificationId}`,
    fetchEventBatteryReplaceDetails(api, { notificationId })
  );
  return withLoading(response);
};
const editEventBatteryReplace = (api) => async (body) => {
  return await api.editEventBatteryReplace(body);
};

export const useEditEventBatteryReplace = () => {
  const api = useApi();
  const mutate = useMatchMutate(EVENT_BATTERY_REPLACE);
  return useMutation(editEventBatteryReplace(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteEventBatteryReplace =
  (api) =>
  async ({ eventBatteryChangeId }) => {
    return await api.deleteEventBatteryReplace({ eventBatteryChangeId });
  };

export const useDeleteEventBatteryReplace = () => {
  const api = useApi();
  const mutate = useMatchMutate(`${EVENT_BATTERY_REPLACE}/${ALL}`);
  return useMutation(deleteEventBatteryReplace(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
