import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  ColorStrip,
  CustomTable,
  PageHeader,
  ZoomAbleFloorPlan,
} from '../../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchTrapSensorReport } from '../../../../services/sensors';
import {
  buildTableColumns,
  getLayers,
  getMatchedLayers,
  getSensorColor,
  getSensorData,
} from './transform';
import { fetchSitesAsync } from '../../../../services/sites';
import { getFloorImageUrl } from '../../../../utilities/transform';
import { Col, Popover, Row } from 'antd';
import './index.css';
import Text from 'antd/es/typography/Text';
import moment from 'moment';

const PopoverContent = ({ id, name, count }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>{t('id')}</Col>
        <Col>{id}</Col>
      </Row>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>{t('name')}</Col>
        <Col>{name}</Col>
      </Row>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>
          {t('caught')} &{t('missed')}
        </Col>
        <Col>{count}</Col>
      </Row>
    </>
  );
};

export const TrapSensorReportPdf = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState({
    site: '',
    floor: '',
    layers: [],
    from: '',
    to: '',
  });
  const [floorImage, setFloorImage] = useState(null);
  const [layerNames, setLayerNames] = useState([]);
  let { filters } = state || {};
  filters = JSON.parse(filters);
  const { siteId, floorId, layerId, startDate, endDate } = filters || {};
  let { data, isLoading } = useFetchTrapSensorReport({
    ...filters,
    count: 100000,
    isPdfPage: 1,
  });
  const sensorData = getSensorData(data?.sensorData);
  const columns = buildTableColumns();
  const layers = getLayers(data?.sensorData, layerNames);
  useEffect(() => {
    const initializeData = async () => {
      const { sites } = await fetchSitesAsync([siteId]);
      const floor = sites[0].floors
        .filter((floor) => floor.id === floorId)
        .pop();
      setLayerNames(getMatchedLayers(layerId, sites[0]?.layers));
      setFloorImage(getFloorImageUrl(floor?.floorPath));
      const layerNames = getMatchedLayers(layerId, sites[0]?.layers)?.map(
        (layer) => layer.name
      );
      setTitle({
        site: sites[0]?.name,
        floor: floor?.name,
        layers: layerNames,
        from: moment(startDate).format('MMMM Do YYYY'),
        to: moment(endDate).format('MMMM Do YYYY'),
      });
    };

    initializeData();
    // eslint-disable-next-line
  }, []);

  const renderSensorItem = ({ item, sensorIconHeight }) => {
    const { sensorId, name, missed = 0, caught = 0, layerId } = item || {};
    // noinspection JSSuspiciousNameCombination
    const style = {
      width: sensorIconHeight,
      height: sensorIconHeight,
      borderRadius: sensorIconHeight,
      fontSize: sensorIconHeight * 0.5,
      backgroundColor: getSensorColor(caught + missed, layerId, layers),
    };
    return (
      <Popover
        content={
          <PopoverContent
            id={sensorId}
            name={name}
            count={`${caught + missed}`}
          />
        }
      >
        <div style={style} className='sensor'>
          {`${caught + missed}`}
        </div>
      </Popover>
    );
  };

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('report'),
            link: '/trap-sensor/report',
          },
          {
            name: t('pdf'),
            link: null,
          },
        ]}
      />
      <PageHeader title={t('report')} />
      {floorImage && (
        <>
          <Row justify='center'>
            <Text strong className='text-center'>
              {title.site}-{title.floor}-{title.layers.join(',')}
              <br />({title.from} to {title.to})
            </Text>
          </Row>
          <ZoomAbleFloorPlan
            sensors={sensorData}
            floorPlan={floorImage}
            renderItem={renderSensorItem}
            isPdf={true}
          />
        </>
      )}
      <div className='layer-container'>
        {layers?.map((layer) => (
          <Row justify='center'>
            <Col md={{ span: 4 }}>
              <ColorStrip
                title={layer.layerName}
                start={0}
                end={21}
                colors={layer.strip}
              />
            </Col>
          </Row>
        ))}
      </div>
      <CustomTable
        rowKey='id'
        columns={columns}
        dataSource={sensorData}
        isPagination={false}
        isShowCount={false}
        isLoading={isLoading}
      />
    </div>
  );
};

export default TrapSensorReportPdf;
