import { useEffect, useState } from 'react';
import {
  getImageDimensions,
  getScaledSensorFloorPlanInfo,
} from '../utilities/image-utils';
import { useZoomFloorPlan } from './use-zoom-floor-plan';
import { heatmapScaleTransform } from '../utilities/transform';
import { useForceUpdate } from './use-force-update';

export const usePreviewHeatmapWithZoom = ({
  sensors: initialSensors,
  floorPlan: initialFloorPlan,
  offset = 180,
}) => {
  const { activeScaleInfo, onChangeZoomLevel, optionsList } =
    useZoomFloorPlan(offset);
  const { forceUpdate, forceUpdateKey } = useForceUpdate();
  const [sensors, setSensors] = useState({});
  const [floorPlanMeta, setFloorPlanMeta] = useState({});

  useEffect(() => {
    const preprocessData = async () => {
      const { naturalWidth, naturalHeight } = await getImageDimensions(
        initialFloorPlan
      );
      const updatedFloorPlanMeta = getScaledSensorFloorPlanInfo(
        naturalWidth,
        naturalHeight,
        activeScaleInfo.imageWidth
      );

      const updatedSensors = {
        detectionRatHit:
          initialSensors &&
          heatmapScaleTransform(
            initialSensors.detectionRatHit,
            updatedFloorPlanMeta.widthScale,
            updatedFloorPlanMeta.heightScale
          ),
        trapCaught:
          initialSensors &&
          heatmapScaleTransform(
            initialSensors.trapCaught,
            updatedFloorPlanMeta.widthScale,
            updatedFloorPlanMeta.heightScale
          ),
        trapMissed:
          initialSensors &&
          heatmapScaleTransform(
            initialSensors.trapMissed,
            updatedFloorPlanMeta.widthScale,
            updatedFloorPlanMeta.heightScale
          ),
        disconnectedTrapSensor:
          initialSensors &&
          heatmapScaleTransform(
            initialSensors.disconnectedTrapSensor,
            updatedFloorPlanMeta.widthScale,
            updatedFloorPlanMeta.heightScale
          ),
      };
      setSensors(updatedSensors);
      setFloorPlanMeta(updatedFloorPlanMeta);
      setTimeout(forceUpdate, 1000);
    };

    // noinspection JSIgnoredPromiseFromCall
    preprocessData();
  }, [
    activeScaleInfo.imageWidth,
    forceUpdate,
    initialFloorPlan,
    initialSensors,
  ]);

  return {
    sensors,
    floorPlanMeta,
    floorPlan: initialFloorPlan,
    optionsList,
    activeScaleInfo,
    onChangeZoomLevel,
    forceUpdateKey,
  };
};
