import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PageHeader } from '../../../components';
import {
  LANGUAGE_CODES,
  REPORT_TYPES_OPTIONS,
} from '../../../utilities/constants';
import { Button, Col, DatePicker, Row, Select, Table, Typography } from 'antd';
import { Option } from 'antd/lib/mentions';
import { useActiveSiteProvider } from '../../../context/site';
import { useFetchReportData } from '../../../services/sensors';
import { CSVLink } from 'react-csv';
import i18next from 'i18next';
import { M2Report } from './m2-report';

const { Text } = Typography;

export const ViewReport = () => {
  const { t } = useTranslation();
  const [reportType, setReportType] = useState('M2');
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedDate1, setSelectedDate1] = useState(moment());
  const [fetchSensorDataLoading, setSensorDataLoading] = useState(false);
  const [sensorData, setSensorData] = useState([]);
  const { activeSite: activeSiteId } = useActiveSiteProvider();
  const { mutate: getReportData } = useFetchReportData();

  const handleSubmit = async () => {
    try {
      setSensorDataLoading(true);
      const { data } = await getReportData({
        date: reportType.includes('D')
          ? selectedDate.format('YYYYMMDD')
          : selectedDate1.format('YYYYMM'),
        sd: selectedDate1.startOf('month').format('YYYYMMDD'),
        ed: selectedDate1.endOf('month').format('YYYYMMDD'),
        type: reportType,
        siteId: activeSiteId.value,
      });
      const sensorData1 = data.data.sensorData || [];
      setSensorData(sensorData1);
    } catch (e) {
    } finally {
      setSensorDataLoading(false);
    }
  };

  const onChangeReportType = (e) => {
    setReportType(e);
    setSensorData([]);
  };

  const exportReport = () => {};

  const d3colums = [
    {
      title: t('zone'),
      dataIndex: 'zoneEn',
      key: 'zoneEn',
      render: (text, r) =>
        i18next.language === LANGUAGE_CODES.ENGLISH ? r.zoneEn : r.zoneZh,
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record, i) => (text !== null ? t(text) : ''),
    },
    { title: t('deviceId'), dataIndex: 'sensorName', key: 'sensorName' },
    {
      title: t('batteryLvl'),
      dataIndex: 'batteryLvl',
      key: 'batteryLvl',
      render: (text, record, i) => (text !== null ? t(text) : ''),
    },
    {
      title: t('connectionstatus'),
      dataIndex: 'connectionStatus',
      key: 'connectionStatus',
      render: (text, record, i) => (text !== null ? t(text) : ''),
    },
  ];

  const D3_EXPORT_HEADERS = [
    {
      label: t('zone'),
      key: 'zoneEn',
    },
    {
      label: t('type'),
      key: 'type',
    },
    {
      label: t('deviceId'),
      key: 'sensorName',
    },
    {
      label: t('batteryLvl'),
      key: 'batteryLvl',
    },
    {
      label: t('connectionstatus'),
      key: 'connectionStatus',
    },
  ];

  const d2colums = [
    {
      title: t('zone'),
      dataIndex: 'zoneEn',
      key: 'zoneEn',
      render: (text, r) =>
        i18next.language === LANGUAGE_CODES.ENGLISH ? r.zoneEn : r.zoneZh,
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record, i) => (text !== null ? t(text) : ''),
    },
    { title: t('deviceId'), dataIndex: 'sensorName', key: 'sensorName' },
    { title: t('baitType'), dataIndex: 'baitName', key: 'baitName' },
    { title: t('filledDatetime'), dataIndex: 'fillDate', key: 'fillDate' },
    { title: t('expiryDate'), dataIndex: 'expiryDate', key: 'expiryDate' },
    {
      title: t('baitLvl'),
      dataIndex: 'baitLevel',
      key: 'baitLevel',
      render: (text, record, i) => (text !== null ? text + '%' : ''),
    },
  ];

  const D2_EXPORT_HEADERS = [
    {
      label: t('zone'),
      key: 'zoneEn',
    },
    {
      label: t('type'),
      key: 'type',
    },
    {
      label: t('deviceId'),
      key: 'sensorName',
    },
    {
      label: t('baitType'),
      key: 'baitName',
    },
    {
      label: t('filledDatetime'),
      key: 'fillDate',
    },
    {
      label: t('expiryDate'),
      key: 'expiryDate',
    },
    {
      label: t('baitLvl'),
      key: 'baitLevel',
    },
  ];

  const d1colums = [
    { title: t('trappedDatetime'), dataIndex: 'trapedDate', key: 'trapedDate' },
    { title: t('cleandatetime'), dataIndex: 'cleanDate', key: 'cleanDate' },
    { title: t('kpi'), dataIndex: 'kpi', key: 'kpi' },
    {
      title: t('zone'),
      dataIndex: 'zoneEn',
      key: 'zoneEn',
      render: (text, r) =>
        i18next.language === LANGUAGE_CODES.ENGLISH ? r.zoneEn : r.zoneZh,
    },
    { title: t('deviceId'), dataIndex: 'deviceId', key: 'deviceId' },
    {
      title: t('ratSize'),
      dataIndex: 'ratSize',
      key: 'ratSize',
      render: (text, record, i) => (text !== null ? t(text) : ''),
    },
    { title: t('baitTypea'), dataIndex: 'baitTypeA', key: 'baitTypeA' },
    { title: t('baitTypeb'), dataIndex: 'baitTypeB', key: 'baitTypeB' },
    {
      title: t('trapstatus'),
      dataIndex: 'trapStatus',
      key: 'trapStatus',
      render: (text, record, i) => (text !== null ? t(text) : ''),
    },
  ];

  const D1_EXPORT_HEADERS = [
    {
      label: t('trappedDatetime'),
      key: 'trapedDate',
    },
    {
      label: t('cleandatetime'),
      key: 'cleanDate',
    },
    {
      label: t('kpi'),
      key: 'kpi',
    },
    {
      label: t('zone'),
      key: 'zoneEn',
    },
    {
      label: t('deviceId'),
      key: 'deviceId',
    },
    {
      label: t('ratSize'),
      key: 'ratSize',
    },
    {
      label: t('baitTypea'),
      key: 'baitTypeA',
    },
    {
      label: t('baitTypeb'),
      key: 'baitTypeB',
    },
    {
      label: t('trapstatus'),
      key: 'trapStatus',
    },
  ];

  const m3colums = [
    { title: t('baitType'), dataIndex: 'baitType', key: 'baitType' },
    { title: t('trappedDatetime'), dataIndex: 'trapedDate', key: 'trapedDate' },
    { title: t('deviceId'), dataIndex: 'deviceId', key: 'deviceId' },
    { title: t('ratSize'), dataIndex: 'ratSize', key: 'ratSize' },
    {
      title: t('zone'),
      dataIndex: 'zoneEn',
      key: 'zoneEn',
      render: (text, r) =>
        i18next.language === LANGUAGE_CODES.ENGLISH ? r.zoneEn : r.zoneZh,
    },
    {
      title: t('datesAfterRefill'),
      dataIndex: 'datesAfterRefill',
      key: 'datesAfterRefill',
    },
  ];

  const M3_EXPORT_HEADERS = [
    {
      label: t('baitType'),
      key: 'baitType',
    },
    {
      label: t('trappedDatetime'),
      key: 'trapedDate',
    },
    {
      label: t('deviceId'),
      key: 'deviceId',
    },
    {
      label: t('ratSize'),
      key: 'ratSize',
    },
    {
      label: t('zone'),
      key: 'zoneEn',
    },
    {
      label: t('datesAfterRefill'),
      key: 'datesAfterRefill',
    },
  ];

  return (
    <div className='p-10'>
      <div>
        <PageHeader title={t('report')} />
        <Row gutter={[10, 10]} align='middle' style={{ marginTop: 22 }}>
          <Col md={{ span: 5 }} xs={{ span: 24 }}>
            <Select
              style={{ width: '100%' }}
              placeholder={t('pleaseselectreporttype')}
              value={reportType}
              onChange={onChangeReportType}
            >
              {REPORT_TYPES_OPTIONS.map((r, i) => (
                <Option key={i} value={r.value}>
                  {t(r.label)}
                </Option>
              ))}
            </Select>
          </Col>
          {reportType.includes('D') && (
            <Col md={{ span: 4 }} xs={{ span: 24 }}>
              <DatePicker
                style={{ width: '100%' }}
                value={selectedDate}
                onChange={(e) => setSelectedDate(e)}
                allowClear={false}
                disabledDate={(c) => c > moment().endOf('day')}
              />
            </Col>
          )}
          {reportType.includes('M') && (
            <Col md={{ span: 4 }} xs={{ span: 24 }}>
              <DatePicker
                style={{ width: '100%' }}
                picker='month'
                format={'MMM YYYY'}
                allowClear={false}
                value={selectedDate1}
                disabledDate={(c) => c > moment().endOf('month')}
                onChange={(e) => {
                  setSelectedDate1(e);
                  setSensorData([]);
                }}
              />
            </Col>
          )}
          <Col md={{ span: 2 }} xs={{ span: 24 }}>
            <Button block type='primary' onClick={handleSubmit}>
              {t('submit')}
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          {reportType === 'D3' && (
            <Col xs={{ span: 24 }}>
              <Button
                block
                type='primary'
                style={{ float: 'right', width: 137 }}
              >
                <CSVLink
                  data={sensorData}
                  headers={D3_EXPORT_HEADERS}
                  filename={
                    reportType + '_' + selectedDate.format('YYYYMMDD') + '.csv'
                  }
                >
                  {t('exportToCSV')}
                </CSVLink>
              </Button>
            </Col>
          )}
          {reportType === 'D2' && (
            <Col xs={{ span: 24 }}>
              <Button
                block
                type='primary'
                style={{ float: 'right', width: 137 }}
              >
                <CSVLink
                  data={sensorData}
                  headers={D2_EXPORT_HEADERS}
                  filename={
                    reportType + '_' + selectedDate.format('YYYYMMDD') + '.csv'
                  }
                >
                  {t('exportToCSV')}
                </CSVLink>
              </Button>
            </Col>
          )}
          {reportType === 'D1' && (
            <Col xs={{ span: 24 }}>
              <Button
                block
                type='primary'
                style={{ float: 'right', width: 137 }}
              >
                <CSVLink
                  data={sensorData}
                  headers={D1_EXPORT_HEADERS}
                  filename={
                    reportType + '_' + selectedDate.format('YYYYMMDD') + '.csv'
                  }
                >
                  {t('exportToCSV')}
                </CSVLink>
              </Button>
            </Col>
          )}
          {reportType === 'M3' && (
            <Col xs={{ span: 24 }}>
              <Button
                block
                type='primary'
                style={{ float: 'right', width: 137 }}
              >
                <CSVLink
                  data={sensorData}
                  headers={M3_EXPORT_HEADERS}
                  filename={
                    reportType + '_' + selectedDate.format('YYYYMM') + '.csv'
                  }
                >
                  {t('exportToCSV')}
                </CSVLink>
              </Button>
            </Col>
          )}
        </Row>
        <Row style={{ marginTop: 10 }}></Row>
        {reportType === 'D3' && (
          <Table
            columns={d3colums}
            dataSource={sensorData}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
              size: 'default',
            }}
          />
        )}
        {reportType === 'D2' && (
          <Table
            columns={d2colums}
            dataSource={sensorData}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
              size: 'default',
            }}
          />
        )}
        {reportType === 'D1' && (
          <Table
            columns={d1colums}
            dataSource={sensorData}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
              size: 'default',
            }}
          />
        )}
        {reportType === 'M2' && (
          <M2Report
            selectedDate={selectedDate1}
            sensorData={sensorData}
            fetchSensorDataLoading={fetchSensorDataLoading}
          />
        )}
        {reportType === 'M3' && (
          <Table
            columns={m3colums}
            dataSource={sensorData}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
              size: 'default',
            }}
          />
        )}
        {reportType !== 'M2' && (
          <div className='total-count' style={{ marginTop: -40 }}>
            <Text strong>
              {t('total')}: {sensorData.length || 0}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewReport;
