import React from 'react';
import { Tabs } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { SecondTab } from './second-tab';
import { SENSOR_PAGE_KEYS } from '../../../utilities/constants';
import { TrapSensorFirstTab } from './first-tab/trap-sensor-first-tab';
import { DetectionSensorFirstTab } from './first-tab/detection-sensor-first-tab';
import { TrapSensorThirdTab } from './third-tab/trap-sensor-third-tab';
import { DetectionSensorThirdTab } from './third-tab/detection-sensor-third-tab';
import { Breadcrumbs } from '../../../components';
import { BaitSensorFirstTab } from './first-tab/bait-sensor-first-tab';
import { BaitSensorThirdTab } from './third-tab/bait-sensor-third-tab';

const getFirstTab = (routeKey, sensorId) => {
  switch (routeKey) {
    case SENSOR_PAGE_KEYS.TRAP_SENSOR:
      return <TrapSensorFirstTab routeKey={routeKey} sensorId={sensorId} />;
    case SENSOR_PAGE_KEYS.DETECTION_SENSOR:
      return (
        <DetectionSensorFirstTab routeKey={routeKey} sensorId={sensorId} />
      );
    case SENSOR_PAGE_KEYS.BAIT_SENSOR:
      return <BaitSensorFirstTab routeKey={routeKey} sensorId={sensorId} />;
  }
};
const getThirdTab = (routeKey, sensorId) => {
  switch (routeKey) {
    case SENSOR_PAGE_KEYS.TRAP_SENSOR:
      return <TrapSensorThirdTab routeKey={routeKey} sensorId={sensorId} />;
    case SENSOR_PAGE_KEYS.DETECTION_SENSOR:
      return (
        <DetectionSensorThirdTab routeKey={routeKey} sensorId={sensorId} />
      );
    case SENSOR_PAGE_KEYS.BAIT_SENSOR:
      return <BaitSensorThirdTab routeKey={routeKey} sensorId={sensorId} />;
  }
};
export const SensorHistoryPage = ({ routeKey }) => {
  const { t } = useTranslation();
  const { sensorId } = useParams();
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('deviceList'),
            link: `/${routeKey}/list`,
          },
          {
            name: t('history'),
            link: `/${routeKey}/${sensorId}/history`,
          },
        ]}
      />
      <Link to={`/${routeKey}/list`}>
        <Title level={3}>
          {t('deviceList')}&nbsp;/&nbsp;{t('history')}
        </Title>
      </Link>
      <Tabs defaultActiveKey='1'>
        <Tabs.TabPane tab={t('log')} key='1'>
          {getFirstTab(routeKey, sensorId)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('charts')} key='2'>
          <SecondTab routeKey={routeKey} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('data')} key='3'>
          {getThirdTab(routeKey, sensorId)}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
