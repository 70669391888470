import React from 'react';
import { Field, Form } from 'formik';
import { AntInput, AntSelect } from '../index';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GUTTER } from '../../utilities/constants';
import { useDeviceEfficient } from '../../services/sensors';
import { LANGUAGE_CODES } from '../../utilities/constants';

export const ExchangeDeviceForm = ({
  handleSubmit,
  submitCount,
  resetForm,
}) => {
  const { t, i18n } = useTranslation();
  let { data: { deviceList } = {} } = useDeviceEfficient({});
  deviceList &&
    deviceList.forEach((n) => {
      n.key = n.code;
      n.value = n.code;
      n.label = n.code;
    });
  if (deviceList) {
    deviceList = deviceList.sort((a, b) => b.code - a.code);
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name='originalId'
            type='text'
            label={t('originalId')}
            submitCount={submitCount}
            disabled
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name={
              i18n.language === LANGUAGE_CODES.ENGLISH
                ? 'location'
                : 'locationZh'
            }
            type='text'
            label={t('location')}
            submitCount={submitCount}
            disabled
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntSelect}
            name='newId'
            label={t('newId')}
            placeholder={t('pleaseInputNewId')}
            submitCount={submitCount}
            selectOptions={deviceList}
            hasFeedback
            required
          />
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Row gutter={[8, 8]}>
            <Col>
              <Link to='/trap-sensor/list'>
                <Button type='secondary'>{t('back')}</Button>
              </Link>
            </Col>
            <Col>
              <Button type='secondary' onClick={resetForm}>
                {t('reset')}
              </Button>
            </Col>
            <Col>
              <Button type='primary' onClick={handleSubmit}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
