import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { LANGUAGE_CODES } from '../../../utilities/constants';
import isEmpty from 'lodash/isEmpty';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { checkEmptiness } from '../../../utilities/functions';

const COLORS = [
  '#bf4790',
  '#1435d0',
  '#8f4b1e',
  '#d97035',
  '#dcc104',
  '#62b9a0',
  '#5e6942',
  '#aecbd1',
  '#c59439',
  '#b9243d',
  '#53e106',
  '#5389f8',
];

const TEST_DATA = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
];

export const CustomPieChart = ({
  data = TEST_DATA,
  width = 200,
  height = 200,
  outerRadius = 60,
  innerRadius = 0,
  dataKey = 'value',
  nameKey = '',
  fill = '#8884d8',
  labelLine = false,
  label = null,
}) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === LANGUAGE_CODES.ENGLISH;

  return (
    <ResponsiveContainer width={width} height={height}>
      {!checkEmptiness(data) && !isEmpty(data) ? (
        <PieChart width={'100%'} height={'100%'}>
          <Pie
            data={data}
            cx='50%'
            cy='50%'
            fill={fill}
            dataKey={dataKey}
            nameKey={nameKey}
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            labelLine={labelLine}
            label={label}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index]} />
            ))}
          </Pie>
          <Legend
            payload={data.map((item, index) => {
              return {
                id: item.name,
                type: 'square',
                value: isEnglish ? item?.name : item?.nameZh,
                color: COLORS[index % COLORS.length],
              };
            })}
          />
          <Tooltip />
        </PieChart>
      ) : (
        <div style={{ width: '100%', height }}>
          <Typography.Text>{t('noData')}</Typography.Text>
        </div>
      )}
    </ResponsiveContainer>
  );
};
