import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import { Button, Col, DatePicker, Radio, Row, Spin } from 'antd';
import { useFetchDashboardData } from '../../services/sensors';
import { AntSelect, ShowHideWrapper } from '../../components';
import { useForceUpdate } from '../../hooks';
import moment from 'moment';
import { sensorSiteFloorLayerFilterValidation } from '../../utilities/validation-utils';
import { useSyncFormState } from '../../hooks/use-sync-form-state';
import { useActiveSiteProvider } from '../../context/site';

import { useTranslation } from 'react-i18next';
import { useFetchSites } from '../../services/sites';
import { getFloorLayerAndZoneFromSite } from '../../utilities/transform';
import './dashboard-body.css';
import { LANGUAGE_CODES, SENSOR_TYPE_KEYS } from '../../utilities/constants';
import { useNavigate } from 'react-router-dom';
import { TrapDashboardBody } from './trap';
import { BaitDashboardBody } from './bait';
import { DetectionDashboardBody } from './detection';
import { DeviceStatusBody } from './devicestatus';

const { RangePicker } = DatePicker;
const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 2 }}>
      {children}
    </Col>
  );
};

const defaultOptionsList = [
  {
    label: 'trapSensor',
    value: SENSOR_TYPE_KEYS.TRAP_SENSOR,
  },
  {
    label: 'detectionSensor',
    value: SENSOR_TYPE_KEYS.DETECTION_SENSOR,
  },
  {
    label: 'baitSensor',
    value: SENSOR_TYPE_KEYS.BAIT_SENSOR,
  },
];

export const DashboardBody = ({ isShowFilters }) => {
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { values, setFieldValue, resetForm } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const [sensorType, setSensorType] = useState(SENSOR_TYPE_KEYS.TRAP_SENSOR);
  const { submitCount } = useFormikContext();
  const [activeSite, setActiveSite] = useState({});
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, 'd'),
    moment(),
  ]);
  const { activeSite: activeSiteId } = useActiveSiteProvider();

  const { data: { sites, defaultLocation } = {} } = useFetchSites();

  const TIME_RANGE_OPTION = [
    { label: t('day'), value: '1' },
    { label: t('night'), value: '2' },
  ];

  useEffect(() => {
    if (sites && sites.length > 0) {
      const selectedSite = sites.find((site) => site.id === activeSiteId.value);
      var activeSite1 = getFloorLayerAndZoneFromSite(
        selectedSite,
        selectedSite?.id
      );
      setActiveSite(activeSite1);
    }
  }, [sites, i18n.language]);

  const isEnglish = i18n.language === LANGUAGE_CODES.ENGLISH;

  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value === defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('floorId', activeSite1.floors[0].value);
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }
      forceUpdate();
    }
  };

  useEffect(async () => {
    if (defaultLocation) {
      locationReset();
    }
  }, [defaultLocation]);

  useEffect(async () => {
    forceUpdate();
  }, [sensorType]);

  const filters = useMemo(() => {
    const { siteId, floorId, layerId, zoneId, timeRange } = values;
    const filters = {
      startTime: dateRange[0].startOf('day').valueOf(),
      endTime: dateRange[1].endOf('day').valueOf(),
    };
    if (siteId) filters.siteId = siteId;
    if (floorId) filters.floorId = floorId;
    if (layerId) filters.layerId = layerId;
    if (zoneId) filters.zoneId = zoneId;
    filters.types = [sensorType];
    filters.timeRange = [timeRange];
    return filters;
  }, [forceUpdateKey]);

  const { data: dashboardData, isValidating } = useFetchDashboardData(filters);

  useSyncFormState({ callback: forceUpdate });

  const handleSubmit = async () => {
    await setFieldValue('pageNumber', 1);
    if (sensorSiteFloorLayerFilterValidation(values)) {
      forceUpdate();
    }
  };
  const handleReset1 = async () => {
    resetForm();
    setDateRange([moment().subtract(30, 'd'), moment()]);
    setSensorType(SENSOR_TYPE_KEYS.TRAP_SENSOR);
    locationReset();
  };

  const handleSensorType = (st) => {
    setSensorType(st);
  };
  return (
    <Spin spinning={isValidating}>
      <Form>
        <div style={{ marginTop: 10 }} />
        <ShowHideWrapper isShow={isShowFilters}>
          <Row gutter={[10, 10]} align='middle'>
            <>
              <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 2 }}>
                <Field
                  component={AntSelect}
                  name='floorId'
                  label={t('floor')}
                  placeholder={t('pleaseSelectFloor')}
                  submitCount={submitCount}
                  selectOptions={activeSite.floors}
                />
              </Col>
              <ItemWrapper>
                <Field
                  mode={'multiple'}
                  component={AntSelect}
                  name='layerId'
                  label={t('layer')}
                  placeholder={t('pleaseSelectLayer')}
                  submitCount={submitCount}
                  selectOptions={activeSite.layers}
                />
              </ItemWrapper>

              <ItemWrapper>
                <Field
                  mode={'multiple'}
                  component={AntSelect}
                  name='zoneId'
                  label={t('zone')}
                  placeholder={t('pleaseSelectZone')}
                  submitCount={submitCount}
                  selectOptions={activeSite.zones}
                />
              </ItemWrapper>
              <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 2 }}>
                <Field
                  component={AntSelect}
                  name='timeRange'
                  label={t('timeRange')}
                  placeholder={t('pleaseSelecttimeRange')}
                  submitCount={submitCount}
                  selectOptions={TIME_RANGE_OPTION}
                  tooltip={
                    <span>
                      {t('dayTimeRangeTooltip')} <br />
                      {t('nightTimeRangeTooltip')}
                    </span>
                  }
                />
              </Col>
              <ActionButtonWrapper>
                <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
                  {t('reset')}
                </Button>
              </ActionButtonWrapper>
              <ActionButtonWrapper>
                <Button
                  block
                  type='primary'
                  style={{ marginTop: 22 }}
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </Button>
              </ActionButtonWrapper>
            </>
          </Row>
          <Row gutter={[10, 10]} style={{ marginBottom: '5px' }}>
            <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }}>
              <RangePicker
                style={{ width: '100%' }}
                value={dateRange}
                allowClear={false}
                onChange={(val) => {
                  setDateRange(val);
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 6 }}>
              <Radio.Group
                onChange={(e) => handleSensorType(e.target.value)}
                value={sensorType}
                buttonStyle='solid'
              >
                {defaultOptionsList.map(({ value, label }) => {
                  return <Radio.Button value={value}>{t(label)}</Radio.Button>;
                })}
              </Radio.Group>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 1 }} md={{ span: 1 }}>
              <Button
                style={{
                  width: 100,
                  border: 0,
                  background: '#efefef',
                  cursor: 'pointer',
                }}
                onClick={(e) => handleSensorType('device_status')}
              >
                {' '}
              </Button>
            </Col>
          </Row>
        </ShowHideWrapper>
        {sensorType === SENSOR_TYPE_KEYS.TRAP_SENSOR && (
          <TrapDashboardBody dashboardData={dashboardData} />
        )}
        {sensorType === SENSOR_TYPE_KEYS.DETECTION_SENSOR && (
          <DetectionDashboardBody dashboardData={dashboardData} />
        )}
        {sensorType === SENSOR_TYPE_KEYS.BAIT_SENSOR && (
          <BaitDashboardBody dashboardData={dashboardData} />
        )}
        {sensorType === 'device_status' && (
          <DeviceStatusBody dashboardData={dashboardData} />
        )}
      </Form>
    </Spin>
  );
};
