import React from 'react';
import { Formik } from 'formik';
import { notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, ChangePasswordForm, PageHeader } from '../../components';
import { ChangePasswordSchema } from '../../utilities/schemas';
import { useEditPassword } from '../../services/users';
import { useUserProvider } from '../../context/session';
import { useParams } from 'react-router-dom';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const ChangePassword = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [user] = useUserProvider();
  const { user: profile } = user || {};
  const { id: userId } = profile || {};
  const { mutate: EditPassword, status: editStatus } = useEditPassword();
  const crumbs = id
    ? [
        {
          name: t('settings'),
          link: '/settings',
        },
        {
          name: t('accountManagement'),
          link: '/account-management',
        },
        {
          name: t('changePassword'),
          link: '/change-password',
        },
      ]
    : [
        {
          name: t('settings'),
          link: '/settings',
        },
        {
          name: t('changePassword'),
          link: '/change-password',
        },
      ];
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs crumbs={crumbs} />
      <PageHeader title={t('changePassword')} />
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        enableReinitialize={false}
        onSubmit={async (values, actions) => {
          try {
            const castValues = ChangePasswordSchema.cast(values);
            const { oldPassword, newPassword } = castValues;
            const payload = {
              userId: id ? id : userId,
              oldPassword,
              newPassword,
            };
            actions.setSubmitting(true);
            await EditPassword(payload);

            await actions.resetForm();
            notification.success({ message: t('editedSuccess') });
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, submitCount, handleSubmit, resetForm }) => (
          <Spin spinning={editStatus === 'pending'}>
            <ChangePasswordForm
              handleSubmit={handleSubmit}
              values={values}
              submitCount={submitCount}
              resetForm={resetForm}
            />
          </Spin>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
