import React from 'react';
import { Field, Form } from 'formik';
import { AntPasswordInput } from '../index';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ChangePasswordForm = ({
  handleSubmit,
  values,
  submitCount,
  resetForm,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[8, 8]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntPasswordInput}
            name='oldPassword'
            type='password'
            label={t('oldPassword')}
            placeholder={t('pleaseInputOldPassword')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntPasswordInput}
            name='newPassword'
            type='password'
            label={t('newPassword')}
            placeholder={t('pleaseInputNewPassword')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntPasswordInput}
            name='confirmPassword'
            type='password'
            label={t('confirmPassword')}
            placeholder={t('pleaseInputConfirmPassword')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} />
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Row gutter={[8, 8]}>
            <Col>
              <Button type='secondary' onClick={() => navigate(-1)}>
                {t('back')}
              </Button>
            </Col>
            <Col>
              <Button type='secondary' onClick={resetForm}>
                {t('reset')}
              </Button>
            </Col>
            <Col>
              <Button type='primary' onClick={handleSubmit}>
                {t('submit')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
