import { useTranslation } from 'react-i18next';
import { Image, message, Upload } from 'antd';
import { MdUploadFile } from 'react-icons/md';
import React from 'react';
import classes from './custom-upload.module.scss';
import { useFormikContext } from 'formik';
import { useSensorInputData } from '../../hooks';
import { BASE_URL } from '../../services/api';

export const CustomUpload = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const {
    placeholder,
    extraData,
    imageWidth,
    imageHeight,
    name,
    photoRequired,
  } = props;
  let img = null;
  if ('photo' == name) {
    // console.log('photo',values.photo)
    img = values.photo;
  } else if ('imageAfter' == name) {
    // console.log('imageAfter',values.imageAfter)
    img = values.imageAfter;
  } else if ('imageBefore' == name) {
    // console.log('imageBefore',values.imageBefore)
    img = values.imageBefore;
  }
  const { imageData, forceSetImageDate, setImageDateNull } =
    useSensorInputData(img);
  const isImage = img !== null ? !!imageData : false;

  const handleChange = ({ file } = {}) => {
    const { status, response } = file;
    if (status === 'done') {
      if (response?.code === 200) {
        forceSetImageDate(response.filename);
        setFieldValue(name, response.filename);
      } else {
        message.error(response.message || '上傳失敗');
      }
    }
  };
  return (
    <Upload.Dragger
      action={BASE_URL + 'system/fileUpload?type=' + extraData.uploadType}
      accept='image/*'
      showUploadList={false}
      onChange={handleChange}
      disabled={props?.disabled}
    >
      <p>
        <MdUploadFile style={{ fontSize: 40 }} />
      </p>
      <p>
        {photoRequired && <span className='required' />}
        {t(placeholder)}
      </p>

      {isImage && (
        <div className={classes.imageBodyWrapper}>
          <div onClick={(event) => event.stopPropagation()}>
            {!!imageData && (
              <Image
                src={imageData}
                width={imageWidth || 200}
                height={imageHeight || 200}
                style={{ objectFit: 'contain' }}
              />
            )}
          </div>
        </div>
      )}
    </Upload.Dragger>
  );
};
