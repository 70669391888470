import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { BatchForm, Breadcrumbs, Loader, PageError } from '../../../components';
import { useDeleteBait, useEditBait } from '../../../services/baits';

import { useFetchBatchDetails } from '../../../services/batch';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  generateFilePath,
  getBaitImageUrl,
} from '../../../utilities/transform';
import { getBase64String } from '../../../utilities/image-utils';
import { AddBaitSchema } from '../../../utilities/schemas';
import { BAIT_TYPES, EVENT_TYPES } from '../../../utilities/constants';

const buildInitialValues = (batch, notificationData) => {
  const { name, expiryDate, path } = batch || {};
  return {
    ...batch,
    zoneId: JSON.parse(batch.zoneId),
    layerId: JSON.parse(batch.layerId),
    name,
    expiryDate: moment(expiryDate),
    imageData: null,
    path: getBaitImageUrl(path),
    eventStart: moment(notificationData?.eventStart),
    eventEnd: moment(notificationData?.eventEnd),
  };
};

const { Title } = Typography;
export const EditBatch = () => {
  const { state } = useLocation();
  const { isFromEvent } = state || { isFromEvent: false };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [baitTypes, setBaitTypes] = useState(
    state === null || state.eventType === null
      ? [BAIT_TYPES.NORMAL, BAIT_TYPES.POISON]
      : state.eventType === EVENT_TYPES.BAIT_REPLACE
      ? [BAIT_TYPES.NORMAL]
      : [BAIT_TYPES.POISON]
  );
  const {
    data: { batch, batchItemList, notification: notificationData } = {},
    isLoading,
  } = useFetchBatchDetails({ batchId: id });

  const { mutate: editBait, status: editStatus } = useEditBait();
  const { mutate: deleteBait, status: deleteStatus } = useDeleteBait();
  React.useEffect(() => {
    if (!isEmpty(batch)) {
      setBaitTypes([batch.baitType]);
    }
  }, [batch]);
  if (isEmpty(batch) || isLoading) {
    return <Loader />;
  }
  // const bait = baits[0];
  if (!isLoading && isEmpty(batch)) {
    return (
      <div style={{ padding: 10 }}>
        <PageError description='Data not found' />;
      </div>
    );
  }

  const handleDeleteBait = async () => {
    try {
      await deleteBait({ batchId: id });
      notification.success({ message: t('deletedSuccess') });
      navigate('/device-management');
    } catch (e) {}
  };

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          !isFromEvent
            ? {
                name: t('batchManagement'),
                link: '/batch-management',
              }
            : {
                name: t('event1'),
                link: '/events',
              },
          {
            name: t('edit'),
            link: `/device-management/${id}/edit`,
          },
        ]}
      />
      <Title level={3}>{t('editBatch')}</Title>

      <Formik
        enableReinitialize={true}
        validationSchema={AddBaitSchema}
        initialValues={buildInitialValues(batch, notificationData)}
        onSubmit={async (values, actions) => {
          try {
            const { name, path, imageData } = values || {};
            const params = { name: name.trim(), path, imageData };
            if (values.imageData) {
              params.imageData = getBase64String(imageData);
              params.path = generateFilePath();
            }
            params.expiryDate = moment(values.expiryDate).valueOf();
            actions.setSubmitting(true);
            const payload = { ...params, id, baitTypes };
            const { data } = await editBait(payload);
            notification.success({ message: t('editedSuccess') });
            const updatedSite = data?.data?.batch;
            const updatedData = buildInitialValues(updatedSite);
            await actions.resetForm({ values: updatedData });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          submitCount,
          resetForm,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => {
          return (
            <Spin
              spinning={deleteStatus === 'pending' || editStatus === 'pending'}
            >
              <BatchForm
                dirty={dirty}
                values={buildInitialValues(batch)}
                defaultLocation={buildInitialValues(batch)}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                handleDeleteBait={handleDeleteBait}
                baitTypes={baitTypes}
                isFromEvent={isFromEvent}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default EditBatch;
