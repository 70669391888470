import { notification } from 'antd';
import i18next from 'i18next';

export const sensorSiteFloorLayerFilterValidation = (values) => {
  const { siteId, floorId, layerId } = values || {};
  if (siteId && !floorId) {
    notification.error({
      message: i18next.t('pleaseSelectFloor'),
    });
    return false;
  }
  if (siteId && !layerId) {
    notification.error({
      message: i18next.t('pleaseSelectLayer'),
    });
    return false;
  }
  return true;
};
