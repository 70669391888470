import React from 'react';
import { Formik } from 'formik';
import { CreateSensorSchema } from './schema';
import { useTranslation } from 'react-i18next';
import { notification, Spin } from 'antd';
import { useAddSensor } from '../../../services/sensors';
import {
  AddEditSensorForm,
  Breadcrumbs,
  PageHeader,
} from '../../../components';
import { ROUTE_KEY_TO_SENSOR_TYPE_MAP } from '../constants';
import { SENSOR_PAGE_KEYS } from '../../../utilities/constants';
import { useSyncFormState } from '../../../hooks/use-sync-form-state';
import { useActiveSiteProvider } from '../../../context/site';

const SyncFormState = () => {
  useSyncFormState();
  return null;
};

export const AddSensorPage = ({ routeKey }) => {
  const { t } = useTranslation();
  const { activeSite } = useActiveSiteProvider();
  const { mutate, status: addDeviceStatus } = useAddSensor();
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('deviceList'),
            link:
              routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
                ? '/trap-sensor/list'
                : routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR
                ? '/detection-sensor/list'
                : routeKey === SENSOR_PAGE_KEYS.BAIT_SENSOR
                ? '/bait-sensor/list'
                : '',
          },
          {
            name: `${t('add')}`,
            link:
              routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
                ? '/trap-sensor/add'
                : routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR
                ? '/detection-sensor/add'
                : routeKey === SENSOR_PAGE_KEYS.BAIT_SENSOR
                ? '/bait-sensor/add'
                : '',
          },
        ]}
      />
      <PageHeader title={t('addDevice')} />
      <Formik
        initialValues={{
          isProduction: 1,
          state: 'A',
          direction: 'down',
          type: ROUTE_KEY_TO_SENSOR_TYPE_MAP[routeKey],
          siteId: activeSite.value,
        }}
        validationSchema={CreateSensorSchema}
        onSubmit={async (formValues, actions) => {
          try {
            const { updatedItem, ...values } = formValues || {};
            const { latitude, longitude } = updatedItem || {};
            const payload = { ...values, latitude, longitude };
            actions.setSubmitting(true);
            if (routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR) {
              delete payload.direction;
            }
            await mutate(payload);
            await actions.resetForm();
            notification.success({ message: t('createdSuccess') });
          } catch ({ message }) {
            notification.error({ message });
          } finally {
            await actions.setSubmitting(false);
          }
        }}
      >
        {({ values, submitCount, resetForm, handleSubmit, setFieldValue }) => {
          return (
            <Spin spinning={addDeviceStatus === 'pending'}>
              <AddEditSensorForm
                isEditMode={false}
                FORM_MODE={'add'}
                routeKey={routeKey}
                values={values}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
              />
              <SyncFormState />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default AddSensorPage;
