import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  CustomTable,
  PageHeader,
  ZoomAbleFloorPlan,
} from '../../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchDetectionSensorReport } from '../../../../services/sensors';
import {
  buildTableColumns,
  getColorGroups,
  getMatchedLayers,
  getSensorColor,
  getSensorData,
} from './transform';
import { fetchSitesAsync } from '../../../../services/sites';
import { getFloorImageUrl } from '../../../../utilities/transform';
import { Avatar, Col, Popover, Row } from 'antd';
import './index.css';
import Text from 'antd/es/typography/Text';
import moment from 'moment';

const PopoverContent = ({ id, name, count }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>{t('id')}</Col>
        <Col>{id}</Col>
      </Row>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>{t('name')}</Col>
        <Col>{name}</Col>
      </Row>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>{t('hit')}</Col>
        <Col>{count}</Col>
      </Row>
    </>
  );
};

export const DetectionSensorReportPdf = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  useNavigate();
  const [title, setTitle] = useState({
    site: '',
    floor: '',
    layers: [],
    from: '',
    to: '',
  });
  const [floorImage, setFloorImage] = useState(null);

  let { filters } = state || {};
  filters = JSON.parse(filters);
  const { siteId, floorId, layerId, startDate, endDate } = filters || {};
  let { data, isLoading } = useFetchDetectionSensorReport({
    ...filters,
    count: 100000,
    isPdfPage: 1,
  });
  const sensorData = getSensorData(data?.sensorData);
  const columns = buildTableColumns();
  const colorGroups = getColorGroups();
  useEffect(() => {
    const initializeData = async () => {
      const { sites } = await fetchSitesAsync([siteId]);
      const floor = sites[0].floors
        .filter((floor) => floor.id === floorId)
        .pop();
      setFloorImage(getFloorImageUrl(floor?.floorPath));
      const layerNames = getMatchedLayers(layerId, sites[0]?.layers)?.map(
        (layer) => layer.name
      );
      setTitle({
        site: sites[0]?.name,
        floor: floor?.name,
        layers: layerNames,
        from: moment(startDate).format('MMMM Do YYYY'),
        to: moment(endDate).format('MMMM Do YYYY'),
      });
    };

    initializeData();
    // eslint-disable-next-line
  }, []);

  const renderSensorItem = ({ item, sensorIconHeight }) => {
    const { sensorId, name, layerId, ratHit } = item || {};
    // noinspection JSSuspiciousNameCombination
    const style = {
      width: sensorIconHeight,
      height: sensorIconHeight,
      borderRadius: sensorIconHeight,
      fontSize: sensorIconHeight * 0.5,
      backgroundColor: getSensorColor(ratHit, layerId, colorGroups),
    };
    return (
      <Popover
        content={<PopoverContent id={sensorId} name={name} count={ratHit} />}
      >
        <div style={style} className='sensor' />
      </Popover>
    );
  };

  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('report'),
            link: '/detection-sensor/report',
          },
          {
            name: t('pdf'),
            link: null,
          },
        ]}
      />
      <PageHeader title={t('report')} />

      {floorImage && (
        <>
          <Row justify='center'>
            <Text strong className='text-center'>
              {title.site}-{title.floor}-{title.layers.join(',')}
              <br />({title.from} to {title.to})
            </Text>
          </Row>
          <ZoomAbleFloorPlan
            sensors={sensorData}
            floorPlan={floorImage}
            renderItem={renderSensorItem}
            isPdf={true}
          />
        </>
      )}
      <div className='layer-container'>
        <Row justify='center'>
          {colorGroups.map((group, index) => (
            <Col md={{ span: 4 }} key={index}>
              <Avatar
                size='large'
                className='sensor'
                style={{ backgroundColor: group?.color }}
              />
              <Text strong>
                {t('hits')}{' '}
                {group?.minHits === 15 ? (
                  <>>=15</>
                ) : (
                  <>
                    ({group?.minHits} to {group?.maxHits})
                  </>
                )}
              </Text>
            </Col>
          ))}
        </Row>
      </div>
      <CustomTable
        rowKey='id'
        columns={columns}
        dataSource={sensorData}
        isLoading={isLoading}
        pagination={{
          locale: { items_per_page: `/ ${t('page')}` },
          showLessItems: true,
          showSizeChanger: true,
          showTotal: (total) => (
            <div className='table-pagination'>
              {t('total')}: {total}
            </div>
          ),
        }}
      />
    </div>
  );
};

export default DetectionSensorReportPdf;
