import { useEffect, useRef } from 'react';
import { useLogout } from '../services/auth';

const TEN_MINUTES_MILLI_SECONDS = 60 * 60 * 1000;

export const useAutoLogout = () => {
  const logout = useLogout();
  const timerRef = useRef();

  const setTimerInterval = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      logout();
    }, TEN_MINUTES_MILLI_SECONDS);
  };

  useEffect(() => {
    setTimerInterval();
    window.addEventListener('click', setTimerInterval);
    window.addEventListener('load', setTimerInterval);
    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('click', setTimerInterval);
      window.removeEventListener('load', setTimerInterval);
    };
  }, []);

  return {};
};
