import './App.css';
import React from 'react';
import { SWRConfig } from 'swr';
import { AppRouter } from './router';
import { HashRouter } from 'react-router-dom';

const App = () => {
  return (
    <SWRConfig value={{ revalidateOnFocus: false }}>
      <HashRouter>
        <AppRouter />
      </HashRouter>
    </SWRConfig>
  );
};

export default App;
