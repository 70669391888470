import React, { useMemo } from 'react';
import { Field, Form } from 'formik';
import { AntInput, AntPasswordInput, AntSelect, PageError } from '../index';
import { Button, Col, Popconfirm, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { GUTTER, STATUSES } from '../../utilities/constants';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { getFormattedRoles } from '../../utilities/transform';
import { useFetchSites } from '../../services/sites';
import { useFetchRoles } from '../../services/roles';
import { EditAccountSiteSection } from '../edit-account-site-section';

export const AccountForm = ({
  handleSubmit,
  submitCount,
  resetForm,
  title = '',
  id = 0,
  isEditMode = true,
  handleDeleteUser,
}) => {
  const { t } = useTranslation();
  const { data: { roles } = {}, isLoading: fetchRolesLoading } =
    useFetchRoles();
  const { data: { sites } = {}, isLoading: fetchSiteLoading } = useFetchSites();
  const roleOptions = useMemo(() => getFormattedRoles(roles), [roles]);
  if (fetchSiteLoading || fetchRolesLoading) {
    return <Spin spinning />;
  }
  if (isEmpty(sites) || isEmpty(roles)) {
    return <PageError description='Unable to fetch the data' />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      {/*<Title level={3}>{t(title)}</Title>*/}
      <Row gutter={[8, 8]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntSelect}
            name='roleId'
            label={t('role')}
            placeholder={t('searchByKeyTitleEState')}
            submitCount={submitCount}
            hasFeedback
            required
            selectOptions={roleOptions}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name='name'
            type='text'
            label={t('name')}
            placeholder={t('pleaseInputName')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name='title'
            type='text'
            label={t('title')}
            placeholder={t('pleaseInputTitle')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntInput}
            name='email'
            type='email'
            label={t('email')}
            placeholder={t('pleaseInputEmail')}
            submitCount={submitCount}
            hasFeedback
            required
          />
        </Col>
        {isEditMode ? (
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              name='state'
              component={AntSelect}
              selectOptions={STATUSES}
              label={t('status')}
              placeholder={t('pleaseSelectStatus')}
              submitCount={submitCount}
              hasFeedback
              required
            />
          </Col>
        ) : (
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Field
              component={AntPasswordInput}
              name='userPassword'
              type='password'
              label={t('password')}
              placeholder={t('pleaseInputPassword')}
              submitCount={submitCount}
              hasFeedback
              required
            />
          </Col>
        )}
      </Row>
      {isEditMode && (
        <Link to={`/account-management/${id}/change-password`}>
          <Button type='primary'>{t('changePassword')}</Button>
        </Link>
      )}
      <EditAccountSiteSection />
      <Row gutter={[GUTTER, GUTTER]}>
        <Col>
          <Link to='/account-management'>
            <Button type='secondary'>{t('back')}</Button>
          </Link>
        </Col>
        <Col>
          <Button type='secondary' onClick={resetForm}>
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button type='primary' onClick={handleSubmit}>
            {t('save')}
          </Button>
        </Col>
        {isEditMode && (
          <Col>
            <Popconfirm
              title='Are you sure to delete this user?'
              onConfirm={handleDeleteUser}
              okText='Yes'
              cancelText='No'
            >
              <Button type='danger'>{t('delete')}</Button>
            </Popconfirm>
          </Col>
        )}
      </Row>
    </Form>
  );
};
