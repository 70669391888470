import React, { useEffect, useState } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useActiveSiteFloorLayerZone } from '../../../../hooks';
import {
  AntSelect,
  AntUpload,
  ZoomAbleFloorPlan,
} from '../../../../components';
import {
  BAITUSAGE,
  BATTERY_LEVEL_KEYS,
  GUTTER,
  RATSIZE,
  SENSOR_TYPE_KEYS,
} from '../../../../utilities/constants';
import {
  useDeviceEfficient,
  useListFiltrate,
} from '../../../../services/sensors';
import { useFetchBaits } from '../../../../services/baits';
import { Link } from 'react-router-dom';
import { getFormattedSensors, getSelectedSensor } from '../transform';
import { getFloorImageUrl } from '../../../../utilities/transform';

const DefaultActionButtonWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} md={{ span: 8 }}>
      {children}
    </Col>
  );
};
export const AddNotificationForm = ({
  values,
  handleSubmit,
  submitCount,
  resetForm,
}) => {
  const { t } = useTranslation();
  const [sensors, setSensors] = useState([]);

  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const { activeSite } = useActiveSiteFloorLayerZone();
  const { mutate: listFiltrate } = useListFiltrate();
  const { setFieldValue } = useFormikContext();

  let { data: { deviceList } = {} } = useDeviceEfficient({});
  const { data: { baits } = {} } = useFetchBaits({});
  deviceList &&
    deviceList.forEach((n) => {
      n.key = n.code;
      n.value = n.code;
      n.label = n.code;
    });
  if (deviceList) {
    deviceList = deviceList.sort((a, b) => b.code - a.code);
  }
  baits &&
    baits.forEach((n) => {
      n.key = n.id;
      n.value = n.id;
      n.label = n.name;
    });
  useEffect(async () => {
    const { siteId, layerId, floorId, zoneId } = values;
    if (siteId && layerId && floorId && zoneId) {
      const { data: { data: { sensors: sensorsData } } = {} } =
        await listFiltrate({
          siteId,
          layerId: [layerId],
          floorId,
          zoneId: [zoneId],
          sensorType: SENSOR_TYPE_KEYS.TRAP_SENSOR,
          batteryLevel: BATTERY_LEVEL_KEYS.ANY,
        });
      setSensors(sensorsData);
    }
  }, [values]);

  return (
    <Form>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
          <Row gutter={[GUTTER, GUTTER]}>
            <Col xs={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='floorId'
                label={t('floor')}
                placeholder={t('pleaseSelectFloor')}
                submitCount={submitCount}
                selectOptions={activeSite.floors}
                required
              />
            </Col>
            <Col xs={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='layerId'
                label={t('layer')}
                placeholder={t('pleaseSelectLayer')}
                submitCount={submitCount}
                selectOptions={activeSite.layers}
                required
              />
            </Col>

            <Col xs={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='zoneId'
                label={t('zone')}
                placeholder={t('pleaseSelectZone')}
                submitCount={submitCount}
                selectOptions={activeSite.zones}
                required
              />
            </Col>
            <Col xs={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='originalId'
                type='text'
                label={t('originalId')}
                placeholder={t('pleaseSelectOriginalId')}
                submitCount={submitCount}
                selectOptions={getFormattedSensors(sensors)}
                onChange={(value) => {
                  setFieldValue('originalId', value);
                  setFieldValue(
                    'sensorId',
                    sensors.find((sensor) => sensor.sensorId === value)?.id ||
                      null
                  );

                  setFieldValue(
                    'location',
                    `${
                      sensors.find((sensor) => sensor.sensorId === value)
                        ?.siteName
                    } ${
                      sensors.find((sensor) => sensor.sensorId === value)
                        ?.floorName
                    }` || ''
                  );
                }}
                required
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 22, offset: 1 }}>
              <Field
                component={AntUpload}
                name='photo'
                listType='picture'
                label={''}
                placeholder={t('uploadPhoto')}
                submitCount={submitCount}
                hasFeedback
                photoRequired
                extraData={{
                  uploadType: 'input',
                }}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='ratSize'
                label={t('ratSize')}
                placeholder={t('pleaseInputRatSize')}
                submitCount={submitCount}
                selectOptions={RATSIZE}
                hasFeedback
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='bait'
                label={t('bait')}
                placeholder={t('pleaseSelectBait')}
                submitCount={submitCount}
                selectOptions={baits}
                hasFeedback
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='baitUsage'
                label={t('baitUsage')}
                placeholder={t('pleaseSelectBaitUsage')}
                submitCount={submitCount}
                selectOptions={BAITUSAGE}
                hasFeedback
                required
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Field
                component={AntSelect}
                name='newId'
                label={t('newId')}
                placeholder={t('pleaseInputNewId')}
                submitCount={submitCount}
                selectOptions={deviceList}
                hasFeedback
              />
            </Col>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} type='secondary'>
                <Link to='/notifications'>{t('back')}</Link>
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                block
                style={{ marginTop: 22 }}
                onClick={() => {
                  resetForm();
                  setSensors([]);
                }}
              >
                {t('reset')}
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                block
                type='primary'
                style={{ marginTop: 22 }}
                onClick={handleSubmit}
              >
                {t('submit')}
              </Button>
            </ActionButtonWrapper>
          </Row>
        </Col>
        <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 18 }}>
          {sensors?.length > 0 && (
            <ZoomAbleFloorPlan
              sensors={
                values?.originalId
                  ? [getSelectedSensor(sensors, values?.originalId)]
                  : sensors
              }
              floorPlan={getFloorImageUrl(
                sensors && sensors.length > 0 ? sensors[0].floorPath : ''
              )}
              pincolor={'#EA232B'}
              offset={1000}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};
