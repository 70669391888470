export const Images = {
  emsdLogo: require('./emsdLogo.png'),
  emsdLogo2: require('./emsdLogo2.png'),
  emsdLogoSmall: require('./EMSD_square.jpg'),
  emsdLogo2Small: require('./FEHD_square.jpg'),
  notificationDetail: require('./notification-detail.jpeg'),
  projectName: require('./project-name.png'),
  greenLocationPin: require('./green-location-pin.png'),
  loading: require('./loading.gif'),
  t1: require('./t1.png'),
  activeimg: require('./active.png'),
  batteryimg: require('./battery.png'),
  caughtimg: require('./caught.png'),
  hitimg: require('./hit.png'),
  missedimg: require('./missed.png'),
  baitReplacement: require('./new_bait_replacement.png'),
  batteryReplacement: require('./new_battery.png'),
  battery: require('./new_battery.png'),
  catchTrapReplacement: require('./new_catch_trap_replacement.png'),
  missedTrapReset: require('./new_missed_trap_reset.png'),
  totalAlert: require('./new_total_alert.png'),
  totalAlertAcknowledged: require('./new_total_alert_acknowledged.png'),
  bait0: require('./new_tshapebait_0.png'),
  bait25: require('./new_tshapebait_25.png'),
  bait50: require('./new_tshapebait_50.png'),
  bait100: require('./new_tshapebait_100.png'),
};
