import React, { useEffect, useMemo, useState } from 'react';
import './index.css';
import { Field, Form, useFormikContext } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';
import { useListByLocation } from '../../services/sensors';
import { AntSelect, AntTextAreaInput, AntUpload } from '../custom-fields';
import { useForceUpdate, useZoomFloorPlan } from '../../hooks';
import moment from 'moment';
import { useSyncFormState } from '../../hooks/use-sync-form-state';
import {
  getFloorLayerAndZoneFromSite,
  getFormattedSites,
} from '../../utilities/transform';
import { useAddBait, useFetchBaits } from '../../services/baits';
import { useTranslation } from 'react-i18next';
import { useFetchSites } from '../../services/sites';
import { useActiveSiteProvider } from '../../context/site';
import {
  useAddEventLocationChange,
  useDeleteEventLocationChange,
  useEditEventLocationChange,
} from '../../services/eventLocationChange';
import { BAIT_TYPES, SENSOR_TYPE_KEYS } from '../../utilities/constants';

import isEmpty from 'lodash/isEmpty';

const { RangePicker } = DatePicker;

const { Option } = Select;

const CheckboxGroup = Checkbox.Group;

const DefaultItemWrapper = ({ children }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
      {children}
    </Col>
  );
};

const DefaultActionButtonWrapper = ({ children }) => {
  return <Col>{children}</Col>;
};

const COLORS = ['#8884d8', '#ff0000', '#FFFF00', '#FF00FF'];

export const LocationChangeForm = ({
  handleSubmit2,
  values2,
  defaultLocation,
  submitCount2,
  baitTypes,
  isFromEvent,
}) => {
  const navigate = useNavigate();
  const ActionButtonWrapper = DefaultActionButtonWrapper;
  const ItemWrapper = DefaultItemWrapper;
  const { t, i18n } = useTranslation();
  const { values, handleReset, resetForm, setFieldValue } = useFormikContext();
  const { forceUpdateKey, forceUpdate } = useForceUpdate();
  const [types, setTypes] = useState([
    SENSOR_TYPE_KEYS.TRAP_SENSOR,
    SENSOR_TYPE_KEYS.BAIT_SENSOR,
  ]);
  const { data: { baits, totalCount } = {} } = useFetchBaits({
    types: baitTypes,
  });
  // if (isEmpty(baits) ) {
  //   return <Loader />;
  // }
  baits &&
    baits.forEach((n) => {
      n.key = n.id;
      n.value = n.id;
      n.label = n.name;
      n.type = n.type;
    });
  const { submitCount, validateForm, setFieldTouched } = useFormikContext();
  const [activeSite, setActiveSite] = useState({});
  const [activeLocation, setActiveLocation] = useState({});
  const [sensors, setSensors] = useState([]);
  const [dateRange, setDateRange] = useState([
    values.eventStart,
    values.eventEnd,
  ]);
  const { activeScaleInfo } = useZoomFloorPlan();

  const { data: { sites } = {}, isLoading: fetchSiteLoading } = useFetchSites();

  const { mutate } = useAddBait();
  const { mutate: editEventLocationChange, status: editStatus } =
    useEditEventLocationChange();
  const { mutate: addEventLocationChange, status: addStatus } =
    useAddEventLocationChange();
  const { mutate: deleteEventLocationChange, status: deleteStatus } =
    useDeleteEventLocationChange();
  const { mutate: listByLocation, status: listStatus } = useListByLocation();
  const { activeSite: activeSiteId } = useActiveSiteProvider();

  // console.log('values',values);
  useEffect(() => {
    locationReset();
  }, [sites, i18n.language]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const selectedSite = sites.find(
        (site) => site?.id === activeSiteId.value
      );
      var activeSite1 = getFloorLayerAndZoneFromSite(
        selectedSite,
        selectedSite?.id
      );
      setActiveSite(activeSite1);
    }
  }, [sites, i18n.language, activeSiteId]);

  const locationReset = async () => {
    if (sites && sites.length > 0 && defaultLocation) {
      if (activeSiteId.value == defaultLocation.siteId) {
        const selectedSite = sites.find(
          (site) => site.id === defaultLocation.siteId
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        // setActiveSite(activeSite1);
        await setFieldValue('test', '');
        await setFieldValue('siteId', defaultLocation.siteId);
        await setFieldValue('layerId', defaultLocation.layerId);
        await setFieldValue('floorId', defaultLocation.floorId);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        values.siteId = defaultLocation.siteId;
        values.floorId = defaultLocation.floorId;
        values.layerId = defaultLocation.layerId;
        values.zoneId = activeSite1.zones.map((item) => item.value);
      } else {
        const selectedSite = sites.find(
          (site) => site.id === activeSiteId.value
        );
        var activeSite1 = getFloorLayerAndZoneFromSite(
          selectedSite,
          selectedSite?.id
        );
        await setFieldValue('test', '');
        await setFieldValue('siteId', activeSiteId.value);
        await setFieldValue(
          'zoneId',
          activeSite1.zones.map((item) => item.value)
        );
        await setFieldValue(
          'layerId',
          activeSite1.layers.map((item) => item.value)
        );
        await setFieldValue('floorId', activeSite1.floors[0].value);
        values.siteId = selectedSite?.id;
        values.floorId = activeSite1.floors[0].value;
        values.layerId = activeSite1.layers.map((item) => item.value);
        values.zoneId = activeSite1.zones.map((item) => item.value);
      }
      forceUpdate();
    }
  };

  const siteOptions = useMemo(() => getFormattedSites(sites), [sites]);

  const handleSiteChange = async (siteId) => {
    const selectedSite = sites.find((site) => site.id === siteId);
    var activeSite1 = getFloorLayerAndZoneFromSite(selectedSite, siteId);
    activeSite1.id = siteId;
    const locationDate = activeLocation;
    locationDate.siteId = siteId;
    if (activeSite1.layers.length > 0) {
      await setFieldValue('layerId', activeSite1.layers[0].value);
      locationDate.layerId = activeSite1.layers[0].value;
    } else {
      await setFieldValue('layerId', []);
      locationDate.layerId = null;
    }

    if (activeSite1.floors.length > 0) {
      await setFieldValue('floorId', activeSite1.floors[0].value);
      locationDate.floorId = activeSite1.floors[0].value;
    } else {
      await setFieldValue('floorId', '');
      locationDate.floorId = null;
    }

    if (activeSite1.zones.length > 0) {
      await setFieldValue('zoneId', activeSite1.zones[0].value);
      locationDate.zoneId = activeSite1.zones[0].value;
    } else {
      await setFieldValue('zoneId', []);
      locationDate.zoneId = null;
    }
    setSensors([]);
    await setFieldValue('siteId', siteId);
    // setActiveLocation({...activeLocation,siteId:siteId})
    setActiveLocation({ ...locationDate });
    setActiveSite(activeSite1);
  };

  const isValidating = false;

  const onChangeBaitId = async (e) => {
    values.baitId = e;
    if (activeLocation.siteId) {
      const bait = baits.filter((b) => b.id === e);
      if (bait.length > 0) {
        var btypes =
          bait[0].type === BAIT_TYPES.NORMAL
            ? [SENSOR_TYPE_KEYS.TRAP_SENSOR]
            : [SENSOR_TYPE_KEYS.BAIT_SENSOR];
        setTypes(btypes);
        const { data: { data: { sensors: sensorsData } } = {} } =
          await listByLocation({ ...activeLocation, types: btypes });
        sensorsData &&
          sensorsData.forEach((n) => {
            n.label = n.name + '---' + n.id;
            n.value = n.id;
          });
        setSensors(sensorsData);
      }
    }
  };
  useSyncFormState({ callback: forceUpdate });

  const handleSubmit = async () => {
    try {
      setFieldTouched('siteIds');
      setFieldTouched('baitId');
      setFieldTouched('imageAfter');
      setFieldTouched('imageBefore');
      const errors = await validateForm();
      if (!isEmpty(errors)) return;

      if (null != values.id) {
        const editData = {
          ...values,
          startTime: dateRange[0].startOf('day').valueOf(),
          endTime: dateRange[1].endOf('day').valueOf(),
          id: values.id,
        };
        await editEventLocationChange(editData);
        notification.success({ message: t('editedSuccess') });
      } else {
        const addData = {
          ...values,
          startTime: dateRange[0].startOf('day').valueOf(),
          endTime: dateRange[1].endOf('day').valueOf(),
        };
        await addEventLocationChange(addData);
        notification.success({ message: t('createdSuccess') });
      }
      navigate(isFromEvent ? '/events' : '/batch-management');
    } catch ({ message }) {
      notification.error({ message });
    } finally {
      // actions.setSubmitting(false);
    }
    forceUpdate();
  };

  const handleDeleteLocationChange = async () => {
    try {
      await deleteEventLocationChange({ eventLocationChangeId: values.id });
      notification.success({ message: t('deletedSuccess') });
      navigate(isFromEvent ? '/events' : '/batch-management');
    } catch (message) {
      notification.error({ message });
    }
  };

  const handleReset1 = async () => {
    await handleReset();
    await resetForm({ values: { imageAfter: null, imageBefore: null } });
    locationReset();
    forceUpdate();
  };
  const onChangeCheckedBaitId = (e, id) => {
    let sensorsTemp = sensors;
    sensorsTemp.map((item) => {
      if (id == item.id) {
        item.baitId = e;
      }
      return item;
    });
    let temp = JSON.parse(JSON.stringify(sensorsTemp));
    setSensors(temp);
  };

  return (
    <Spin spinning={isValidating}>
      <Form>
        <div style={{ marginTop: 10 }} />
        <Row gutter={[10, 10]} align='middle'>
          <>
            <ItemWrapper>
              <Field
                component={AntSelect}
                name='floorId'
                label={t('floor')}
                placeholder={t('pleaseSelectFloor')}
                submitCount={submitCount}
                selectOptions={activeSite.floors}
                onSelect={(label, option) =>
                  setActiveLocation({
                    ...activeLocation,
                    floorId: option.value,
                  })
                }
              />
            </ItemWrapper>
            <ItemWrapper>
              <span style={{ fontWeight: 'bold' }}>{t('date')}</span>
              <RangePicker
                style={{ width: '100%' }}
                value={dateRange}
                onChange={(val) => setDateRange(val)}
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntUpload}
                name='imageAfter'
                listType='picture'
                label={t('imageAfter')}
                placeholder={t('uploadPhoto')}
                submitCount={submitCount}
                hasFeedback
                photoRequired
                extraData={{
                  uploadType: 'location',
                }}
              />
            </ItemWrapper>
            <ItemWrapper>
              <Field
                component={AntUpload}
                name='imageBefore'
                listType='picture'
                label={t('imageBefore')}
                placeholder={t('uploadPhoto')}
                submitCount={submitCount}
                hasFeedback
                photoRequired
                extraData={{
                  uploadType: 'location',
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <Field
                component={AntTextAreaInput}
                name='remark'
                label={t('remarks')}
                type='text'
                placeholder={t('pleaseInputRemarks')}
                submitCount={submitCount}
                hasFeedback={false}
              />
            </ItemWrapper>
            <ItemWrapper></ItemWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} type='secondary'>
                <Link to={isFromEvent ? '/events' : '/batch-management'}>
                  {t('back')}
                </Link>
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button block style={{ marginTop: 22 }} onClick={handleReset1}>
                {t('reset')}
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                block
                type='primary'
                style={{ marginTop: 22 }}
                onClick={handleSubmit}
              >
                {t('save')}
              </Button>
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                block
                type='primary'
                style={{ marginTop: 22 }}
                onClick={handleDeleteLocationChange}
                danger
              >
                {t('delete')}
              </Button>
            </ActionButtonWrapper>
          </>
          <Col
            xs={{ span: 24 }}
            md={{ span: 4 }}
            sm={{ span: 24 }}
            style={{ marginTop: 20 }}
          ></Col>
        </Row>
        <Row></Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }}></Col>
        </Row>
      </Form>
    </Spin>
  );
};
