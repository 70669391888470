import moment from 'moment';

const isValidDate = (date) => {
  try {
    return moment(date).isValid();
  } catch (e) {
    return false;
  }
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  if (!isValidDate(date)) {
    return false;
  }
  return moment(date).format(format);
};

export const formatTime = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!isValidDate(date)) {
    return false;
  }
  return moment(date).format(format);
};

export const disableStartDate = (_, endTime) => (current) => {
  if (current.isAfter(endTime)) {
    return true;
  }
  return false;
};

export const disableEndDate = (startTime, _) => (current) => {
  const tomorrow = moment().add(1, 'day').startOf('day');
  if (current.isBefore(startTime)) {
    return true;
  }
  if (current.isBefore(tomorrow)) {
    return false;
  }
  return true;
};

export const startOfDay = (time) => {
  return moment(time).startOf('day').valueOf();
};

export const endOfDay = (time) => {
  return moment(time).endOf('day').valueOf();
};
