import apisauce from 'apisauce';
import {useRef} from 'react';
import {storageGet} from '../storage';
import {useLogout} from './auth';
import {notification} from 'antd';
import {isEmpty} from 'lodash';

// export const BASE_URL = 'http://localhost:8091/rcp/';
//export const BASE_URL = 'https://test.popared.com/rcp/';
//export const IMAGE_URL = 'https://test.popared.com';
// export const BASE_URL = 'https://www.influencermarketinghk.com/rcp/';
// export const IMAGE_URL = 'https://www.influencermarketinghk.com';

export const BASE_URL = 'https://www.influencermarketinghk.com/rcp/';
export const IMAGE_URL = 'https://www.influencermarketinghk.com';
//
// export const IMAGE_URL = '';
//  export const BASE_URL = '/rcp/';

// export const BASE_URL = 'http://123.176.103.187:8091/rcp/';
// export const IMAGE_URL = 'https://rcs.bim.emsd.gov.hk';

const isTokenExpired = (response) => {
    const code = response?.data?.code;
    const errorCode = response?.data?.error_code;
    return code === '0' && errorCode === 'U003';
};

export const configureRequestInterceptors = (api, {onLogOut}) => {
    api.axiosInstance.interceptors.request.use((config) => {
        const {token, user} = storageGet('user') || {};
        if (token && user) {
            config.headers.token = token;
            config.headers.userId = user.id;
        }
        return config;
    });

    api.axiosInstance.interceptors.response.use(
        (response) => {
            if (isTokenExpired(response)) {
                const {user} = storageGet('user') || {};
                if (!isEmpty(user)) {
                    notification.error({message: 'Session is expired'});
                }
                onLogOut();
            }
            return response;
        },
        async (error) => {
            return Promise.reject(error);
        }
    );
};

const create = (baseURL = BASE_URL) => {
    const api = apisauce.create({
        baseURL,
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
        },
        // timeout: 60000,
        timeout: 99600000,
    });

    return {
        apinInstance: api,
        getBaseURL: () => api.getBaseURL(),
        setBaseURL: (url) => api.setBaseURL(url),
        setHeaders: (data = {}) => api.setHeaders(data),
        deleteHeader: (data = {}) => api.deleteHeader(data),
        signIn: (data = {}) => api.post('user/login', data),

        fetchSites: (data) => api.post('site/list', data),
        editSite: (data) => api.post('site/edit', data),
        addSite: (data) => api.post('site/add', data),
        addFloorPlan: (data) => api.post('site/addFloorPlan', data),
        deleteSite: (data) => api.post('site/delete', data),
        testSms: (data) => api.post('site/testsms', data),

        fetchMaintenance: (data) => api.post('sensor/listmaintenance', data),
        doMaintenance: (data) => api.post('sensor/domaintenance', data),
        getMaintenanceDetails: (data) => api.post('maintenance/details', data),

        fetchSensors: (data) => api.post('sensor/list', data),
        fetchSensorDetails: (data) => api.post('sensor/details', data),
        listByLocation: (data) => api.post('sensor/listByLocation', data),
        listFiltrate: (data) => api.post('sensor/listFiltrate', data),
        addSensor: (data) => api.post('sensor/add', data),
        editSensor: (data) => api.post('sensor/edit', data),
        deleteSensor: (data) => api.post('sensor/delete', data),
        saveSensorLocation: (data) => api.post('sensor/saveLocation', data),
        datainputAndExchange: (data) =>
            api.post('sensor/datainputAndExchange', data),
        exchangeSensor: (data) => api.post('sensor/exchange', data),

        fetchBaits: (data) => api.post('bait/list', data),
        addBait: (data) => api.post('bait/add', data),
        editBait: (data) => api.post('bait/edit', data),
        deleteBait: (data) => api.post('bait/delete', data),

        fetchBatch: (data) => api.post('batch/list', data),
        addBatch: (data) => api.post('batch/add', data),
        editBatch: (data) => api.post('batch/edit', data),
        disposeBatch: (data) => api.post('batch/dispose', data),
        deleteBatch: (data) => api.post('batch/delete', data),
        fetchBatchDetails: (data) => api.post('batch/details', data),

        fetchEventLocationChange: (data) =>
            api.post('eventLocationChange/list', data),
        addEventLocationChange: (data) => api.post('eventLocationChange/add', data),
        editEventLocationChange: (data) =>
            api.post('eventLocationChange/edit', data),
        deleteEventLocationChange: (data) =>
            api.post('eventLocationChange/delete', data),
        fetchEventLocationChangeDetails: (data) =>
            api.post('eventLocationChange/details', data),

        fetchEventTrapClean: (data) => api.post('eventTrapClean/list', data),
        addEventTrapClean: (data) => api.post('eventTrapClean/add', data),
        editEventTrapClean: (data) => api.post('eventTrapClean/edit', data),
        deleteEventTrapClean: (data) => api.post('eventTrapClean/delete', data),
        fetchEventTrapCleanDetails: (data) =>
            api.post('eventTrapClean/details', data),

        addEventBatteryReplace: (data) => api.post('eventBatteryChange/add', data),
        fetchEventBatteryReplaceDetails: (data) =>
            api.post('eventBatteryChange/details', data),
        editEventBatteryReplace: (data) =>
            api.post('eventBatteryChange/edit', data),
        deleteEventBatteryReplace: (data) =>
            api.post('eventBatteryChange/delete', data),
        disposeEventBatteryReplace: (data) =>
            api.post('eventBatteryChange/dispose', data),

        fetchRoles: (data) => api.post('role/list', data),
        addRole: (data) => api.post('role/add', data),
        editRole: (data) => api.post('role/edit', data),
        deleteRole: (data) => api.post('role/delete', data),

        fetchUsers: (data) => api.post('user/list', data),
        addUser: (data) => api.post('user/add', data),
        editUser: (data) => api.post('user/edit', data),
        deleteUser: (data) => api.post('user/delete', data),
        editPassword: (data) => api.post('user/changePassword', data),

        fetchDetectionSensorDashboard: (data) =>
            api.post('sensor/getDetectionSensorDashboard', data),

        fetchDashboardData: (data) => api.post('system/dashboard', data),

        fetchHomeData: (data) => api.post('system/homedata', data),

        fetchTrapSensorDashboard: (data) =>
            api.post('sensor/getTrapSensorDashboard', data),
        fetchTrapSensorData: (data) => api.post('sensor/getTrapSensorData', data),
        fetchTrapSensorReport: (data) =>
            api.post('sensor/getTrapSensorReportData', data),

        getDetectionSensorData: (data) =>
            api.post('sensor/getDetectionSensorData', data),
        fetchDetectionSensorReport: (data) =>
            api.post('sensor/getDetectionSensorReportData', data),
        fetchSensorHeatmap: (data) => api.post('sensor/getHeatMapData', data),
        addCaughtInfo: (data) => api.post('sensor/addCaughtInfo', data),

        fetchBaitSensorData: (data) => api.post('sensor/getBaitSensorData', data),

        fetchNotifications: (data) => api.post('notification/list', data),
        fetchEvents: (data) => api.post('notification/eventlist', data),
        fetchNotificationDetails: (data) => api.post('notification/details', data),
        addNotification: (data) => api.post('notification/add', data),
        editNotification: (data) => api.post('notification/edit', data),
        removeNotification: (data) => api.post('notification/delete', data),
        acknowledgeNotification: (data) => api.post('notification/ack', data),
        fetchReportData: (data) => api.post('report/getReportData', data),

        fetchDevice: (data) => api.post('device/list', data),
        addDevice: (data) => api.post('device/add', data),
        editDevice: (data) => api.post('device/edit', data),
        deleteDevice: (data) => api.post('device/delete', data),
        fetchDeviceDetails: (data) => api.post('device/details', data),
        fetchDeviceBySensorId: (data) => api.post('device/detailsBySensorId', data),

	  deviceEfficient: (data) => api.post('sensor/deviceEfficient', data),
  };
};
export const api = create();
export const useApi = () => {
    const onLogOut = useLogout();
    const apiHook = useRef(api);
    configureRequestInterceptors(apiHook.current.apinInstance, {
        onLogOut,
    });
    return apiHook.current;
};
