import { fetchSensorsAsync } from '../../../services/sensors';
import { fetchSitesAsync } from '../../../services/sites';

export const fetchSensorAndSite = async (filters) => {
  const { sensors } = await fetchSensorsAsync({
    ...filters,
    count: 1000,
  });
  const { sites } = await fetchSitesAsync([filters.siteId]);
  const floor = sites[0].floors
    .filter((floor) => floor.id === filters.floorId)
    .pop();

  return {
    sensors,
    floor,
  };
};
