export const getAnimationData = (initData, animate = true, floorPlanMeta) => {
  let animationData = [];
  const detectionData = initData;
  const counter = animate ? detectionData?.length : 1;
  if (detectionData) {
    for (let i = 0; i < counter; i++) {
      let momentData = detectionData[i];
      let sensors = momentData?.s;
      let points = [];
      for (let j = 0; j < sensors?.length; j++) {
        let sensor = sensors[j];
        points.push({
          x: floorPlanMeta.widthScale(parseInt(sensor?.a || 0)) + (sensor?.t * 20)/2,
          y: floorPlanMeta.heightScale(parseInt(sensor?.o || 0)) + (sensor?.t * 20)/2,
          radius: sensor?.t * 20,
        });
      }
      /* let min = Number.POSITIVE_INFINITY;
      let max = Number.NEGATIVE_INFINITY;
      let tmp;
      for (let i = points.length - 1; i >= 0; i--) {
        tmp = points[i].value;
        if (tmp < min) min = tmp;
        if (tmp > max) max = tmp;
      } */

      let dataPoints = {
        date: momentData?.d,
        data: points,
      };
      animationData.push(dataPoints);
    }    
  }
  return animationData;
};
