import React from 'react';
import './index.css';
import { Col, Row } from 'antd';

export const ColorStrip = ({ title, colors, start, end }) => (
  <>
    <Row>
      <Col>{title}</Col>
    </Row>
    <Row justify='space-between'>
      <Col>{start}</Col>
      <Col>{end}</Col>
    </Row>
    <div>
      {colors.map((color) => (
        <div
          style={{
            display: 'inline-block',
            backgroundColor: color,
            width: '25%',
          }}
        >
          &nbsp;
        </div>
      ))}
    </div>
  </>
);

export default ColorStrip;
