import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export const Breadcrumbs = ({ crumbs }) => {
  return (
    <Breadcrumb>
      {crumbs?.map(({ name, link, state }, index) => (
        <Breadcrumb.Item key={index}>
          {link !== null ? (
            <Link to={link} state={state}>
              {name}
            </Link>
          ) : (
            <span>{name}</span>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
