import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  useDeleteSensor,
  useEditSensor,
  useFetchSensorDetails,
} from '../../../services/sensors';
import {
  AddEditSensorForm,
  Breadcrumbs,
  Loader,
  PageError,
  PageHeader,
} from '../../../components';
import { EditSensorSchema } from './schema';
import { SENSOR_PAGE_KEYS } from '../../../utilities/constants';

const { Title } = Typography;
const buildInitialValues = (device) => {
  return {
    sensorId: device?.sensorId,
    name: device?.name,
    siteId: device?.siteId,
    floorId: device?.floorId,
    layerId: device?.layerId,
    zoneId: device?.zoneId,
    isProduction: device?.isProduction,
    state: device?.state,
    remark: device?.remark,
    manufacturer: device?.manufacturer,
    direction: device?.direction,
    type: device?.type,
    latitude: device?.latitude,
    longitude: device?.longitude,
  };
};

export const EditSensorPage = ({ routeKey }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, data: { sensor } = {} } = useFetchSensorDetails({
    id: id,
  });
  const { mutate: editSensor } = useEditSensor();
  const { mutate: deleteSensor, status: deleteStatus } = useDeleteSensor();
  if (!sensor && isLoading) {
    return <Loader />;
  }

  const device = sensor;
  if (isEmpty(device)) {
    return (
      <div style={{ padding: 10 }}>
        <Title level={3}>{t('editDevice')}</Title>
        <PageError description='Error is found' />
      </div>
    );
  }
  const initialValues = buildInitialValues(device);
  const handleDeleteSensor = async () => {
    try {
      await deleteSensor({ sensorId: device.id });
      notification.success({ message: t('deletedSuccess') });
      navigate(`/${routeKey}/list`);
    } catch (e) {}
  };

  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('deviceList'),
            link:
              routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
                ? '/trap-sensor/list'
                : routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR
                ? '/detection-sensor/list'
                : routeKey === SENSOR_PAGE_KEYS.BAIT_SENSOR
                ? '/bait-sensor/list'
                : '',
          },
          {
            name: t('editDevice'),
            link:
              routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR
                ? `/trap-sensor/${id}/edit`
                : routeKey === SENSOR_PAGE_KEYS.DETECTION_SENSOR
                ? `/detection-sensor/${id}/edit`
                : routeKey === SENSOR_PAGE_KEYS.BAIT_SENSOR
                ? `/bait-sensor/${id}/edit`
                : '',
          },
        ]}
      />
      <PageHeader title={t('editDevice')} />

      <Formik
        initialValues={initialValues}
        validationSchema={EditSensorSchema}
        onSubmit={async (formValues, actions) => {
          try {
            const { updatedItem, ...values } = formValues || {};
            const { latitude, longitude } = updatedItem || {};
            actions.setSubmitting(true);
            const payload = { ...values, latitude, longitude, id: device?.id };
            if (routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR) {
              delete payload.direction;
            }
            const { data } = await editSensor(payload);
            const updatedSensor = data?.data?.sensor;
            const updatedData = buildInitialValues({
              ...updatedSensor,
              sensorId: payload.sensorId,
            });
            await actions.resetForm({ values: updatedData });
            notification.success({ message: t('editedSuccess') });
          } catch ({ message }) {
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          submitCount,
          resetForm,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Spin spinning={isSubmitting || deleteStatus === 'pending'}>
              <AddEditSensorForm
                isEditMode={true}
                sensorId={device?.id}
                routeKey={routeKey}
                values={values}
                submitCount={submitCount}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                handleDeleteSensor={handleDeleteSensor}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};
export default EditSensorPage;
