import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import Title from 'antd/es/typography/Title';
import { Field, useFormikContext } from 'formik';
import { AntSelect } from '../custom-fields';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getFloorLayerAndZoneFromSite,
  getFormattedSites,
} from '../../utilities/transform';
import { useFetchSites } from '../../services/sites';
import Bar from '../bar';

export const EditAccountSiteSection = () => {
  const { t, i18n } = useTranslation();
  const { submitCount, values, setFieldValue } = useFormikContext();
  const [activeSite, setActiveSite] = useState({});
  const { siteId } = values || {};
  const { data: { sites = [] } = {} } = useFetchSites();
  useEffect(() => {
    if (!siteId) {
      return;
    }
    const selectedSite = sites.find((site) => site.id === siteId);
    if (isEmpty(selectedSite)) {
      return;
    }
    setActiveSite(getFloorLayerAndZoneFromSite(selectedSite, siteId));
  }, [sites, siteId, i18n.language]);

  const sitesOptions = useMemo(
    () => getFormattedSites(sites),
    [sites, i18n.language]
  );

  const handleSiteChange = async () => {
    await setFieldValue('layerId', null);
    await setFieldValue('floorId', null);
    await setFieldValue('zoneId', null);
  };

  if (isEmpty(sites)) {
    return null;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Title level={3} style={{ marginBottom: 12 }}>
        {t('defaultDataShowingAtOverview')}
      </Title>
      <Bar />
      <Row gutter={[8, 8]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntSelect}
            name='siteId'
            label={t('site')}
            placeholder={t('site')}
            submitCount={submitCount}
            hasFeedback
            selectOptions={sitesOptions}
            onSelect={(label, option) => handleSiteChange(option.value)}
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntSelect}
            name='floorId'
            label={t('floor')}
            placeholder={t('floor')}
            submitCount={submitCount}
            hasFeedback
            selectOptions={activeSite.floors}
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Field
            component={AntSelect}
            name='layerId'
            label={t('layer')}
            mode={'multiple'}
            placeholder={t('layer')}
            submitCount={submitCount}
            hasFeedback
            selectOptions={activeSite.layers}
            required
          />
        </Col>
      </Row>
    </div>
  );
};
