import { sorter } from '../../../../utilities/transform';
import { Typography } from 'antd';
import {
  SENSOR_ATTRIBUTE_TO_SYMBOL_MAP,
  SENSOR_PAGE_KEYS,
} from '../../../../utilities/constants';
import { formatTime } from '../../../../utilities/time-utils';
import React from 'react';
import i18next from 'i18next';

export const buildTableColumns = (routeKey) => {
  return [
    {
      title: i18next.t('action'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => sorter(a.type, b.type),
      render: (type) => {
        return (
          <Typography.Text className='d-flex'>
            {
              //<SensorAttributeIcon type={type} />&nbsp;&nbsp;
            }
            {i18next.t(type)}
          </Typography.Text>
        );
      },
    },
    {
      title: i18next.t('value'),
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => sorter(a.value, b.value),
      render: (text, { type }) => {
        if (
          type === 'caught' ||
          type === 'missed' ||
          (type === 'active' && routeKey === SENSOR_PAGE_KEYS.TRAP_SENSOR)
        ) {
          return null;
        }
        return (
          <p>
            {text || 0}
            {SENSOR_ATTRIBUTE_TO_SYMBOL_MAP[type]}
          </p>
        );
      },
    },
    {
      title: i18next.t('date'),
      dataIndex: 'eventTime',
      key: 'type',
      sorter: (a, b) => sorter(a.eventTime, b.eventTime),
      render: (text, record) => {
        return <p>{formatTime(record.eventTime)}</p>;
      },
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => sorter(a.id, b.id),
    },
  ];
};
