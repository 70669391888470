import { api, useApi } from './api';
import useSWR from 'swr';
import { useMatchMutate, withLoading } from './utils';
import { useMutation } from '../hooks';
import orderBy from 'lodash/orderBy';

const SENSORS_CACHE_KEY = 'sensors';
const DETECTION_SENSOR_KEY = 'detection_sensor_key';
const TRAP_SENSOR_KEY = 'trap_sensor_key';
const TRAP_SENSOR_DATA_KEY = 'trap_sensor_data';
const DETECTION_SENSOR_DATA_KEY = 'trap_sensor_data';
const TRAP_SENSOR_REPORT_KEY = 'trap_sensor_data';
const DETECTION_SENSOR_REPORT_KEY = 'detection_sensor_data';
const DASHBOARD_KEY = 'dashboard_key';
const BAIT_SENSOR_DATA_KEY = 'bait_sensor_data';
const MAINTENANCE_DATA_KEY = 'bait_sensor_data';

const fetchSensors =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchSensors(filters);
    return {
      sensors: data?.data?.sensors || [],
      totalCount: data?.data?.totalCount || 0,
    };
  };

export const useFetchSensors = (filters = {}) => {
  const api = useApi();
  const response = useSWR(
    { url: SENSORS_CACHE_KEY, filters },
    fetchSensors(api)
  );
  return withLoading(response);
};

const fetchSensorDetails =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchSensorDetails(filters);
    return {
      sensor: data?.data?.sensor || {},
    };
  };

export const useFetchSensorDetails = (filters = {}) => {
  const api = useApi();
  const response = useSWR(
    { url: SENSORS_CACHE_KEY, filters },
    fetchSensorDetails(api)
  );
  return withLoading(response);
};

const fetchMaintenance = (api) => async () => {
  const { data } = await api.fetchMaintenance();
  return {
    maintenanceList: data?.data?.maintenanceList || [],
    totalCount: data?.data?.totalCount || 0,
  };
};

export const useFetchMaintenance = () => {
  const api = useApi();
  const response = useSWR({ url: SENSORS_CACHE_KEY }, fetchMaintenance(api));
  return withLoading(response);
};

const doMaintenance = (api) => async (body) => {
  return await api.doMaintenance(body);
};

export const useDoMaintenance = () => {
  const api = useApi();
  const mutate = useMatchMutate(SENSORS_CACHE_KEY);
  return useMutation(doMaintenance(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
const getMaintenanceDetails = (api) => async (body) => {
  return await api.getMaintenanceDetails(body);
};

export const useGetMaintenanceDetails = (filters = {}) => {
  const api = useApi();
  const { id } = filters || {};
  const mutate = useMatchMutate(`${MAINTENANCE_DATA_KEY}/${id}`);
  return useMutation(getMaintenanceDetails(api), {
    async onSuccess() {
      await mutate();
    },
  });
};
const deviceEfficient =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.deviceEfficient(filters);
    return {
      deviceList: data?.data?.deviceList || [],
      totalCount: data?.data?.totalCount || [],
    };
  };

export const useDeviceEfficient = (filters = {}) => {
  const api = useApi();
  const response = useSWR(
    { url: SENSORS_CACHE_KEY, filters },
    deviceEfficient(api)
  );
  return withLoading(response);
};
const datainputAndExchange = (api) => async (body) => {
  return await api.datainputAndExchange(body);
};

export const useDatainputAndExchange = () => {
  const api = useApi();
  const mutate = useMatchMutate(`SENSORS_CACHE_KEY`);
  return useMutation(datainputAndExchange(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const exchangeSensor = (api) => async (body) => {
  return await api.exchangeSensor(body);
};

export const useExchangeSensor = () => {
  const api = useApi();
  const mutate = useMatchMutate(`SENSORS_CACHE_KEY`);
  return useMutation(exchangeSensor(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const listByLocation = (api) => async (body) => {
  return await api.listByLocation(body);
};

export const useListByLocation = () => {
  const api = useApi();
  const mutate = useMatchMutate(SENSORS_CACHE_KEY);
  return useMutation(listByLocation(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const addSensor = (api) => async (body) => {
  return await api.addSensor(body);
};

export const useAddSensor = () => {
  const api = useApi();
  const mutate = useMatchMutate(SENSORS_CACHE_KEY);
  return useMutation(addSensor(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const editSensor = (api) => async (body) => {
  return await api.editSensor(body);
};

export const useEditSensor = () => {
  const api = useApi();
  const mutate = useMatchMutate(SENSORS_CACHE_KEY);
  return useMutation(editSensor(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const deleteSensor = (api) => async (body) => {
  return await api.deleteSensor(body);
};

export const useDeleteSensor = () => {
  const api = useApi();
  const mutate = useMatchMutate(SENSORS_CACHE_KEY);
  return useMutation(deleteSensor(api), {
    async onSuccess() {
      await mutate();
    },
  });
};

const fetchDetectionSensorDashboard =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchDetectionSensorDashboard(filters);
    return data?.data;
  };

export const useFetchDetectionSensor = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: DETECTION_SENSOR_KEY,
      filters,
    },
    fetchDetectionSensorDashboard(api)
  );
  return withLoading(response);
};

const fetchDashboardData =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchDashboardData(filters);
    return data?.data;
  };

export const useFetchDashboardData = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: DASHBOARD_KEY,
      filters,
    },
    fetchDashboardData(api)
  );
  return withLoading(response);
};
const fetchHomeData =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchHomeData(filters);
    return data?.data;
  };

export const useFetchHomeData = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: DASHBOARD_KEY,
      filters,
    },
    fetchHomeData(api)
  );
  return withLoading(response);
};

const fetchTrapSensorDashboard =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchTrapSensorDashboard(filters);
    return data?.data;
  };

export const useFetchTrapSensor = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: TRAP_SENSOR_KEY,
      filters,
    },
    fetchTrapSensorDashboard(api)
  );
  return withLoading(response);
};

const fetchTrapSensorData =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchTrapSensorData(filters);
    return {
      sensorData: data?.data.sensorData || [],
      totalCount: data?.data.totalCount || 0,
    };
  };

export const useFetchTrapSensorData = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: TRAP_SENSOR_DATA_KEY,
      filters,
    },
    fetchTrapSensorData(api)
  );
  return withLoading(response);
};

const fetchReportData = (api) => async (body) => {
  return await api.fetchReportData(body);
};

export const useFetchReportData = () => {
  const api = useApi();
  const mutate = useMatchMutate(TRAP_SENSOR_DATA_KEY);
  return useMutation(fetchReportData(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const fetchDetectionSensorData =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.getDetectionSensorData(filters);
    return {
      sensorData: data?.data.sensorData || [],
      totalCount: data?.data.totalCount || 0,
    };
  };

export const useFetchDetectionSensorData = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: DETECTION_SENSOR_DATA_KEY,
      filters,
    },
    fetchDetectionSensorData(api)
  );
  return withLoading(response);
};

export const fetchSensorsAsync = async (filters) => {
  const { data } = await api.fetchSensors(filters);
  return {
    sensors: data?.data?.sensors || [],
    totalCount: data?.data?.totalCount || 0,
  };
};

export const saveSensorLocationAsync = async (body) => {
  return await api.saveSensorLocation(body);
};
const fetchTrapSensorReport =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchTrapSensorReport(filters);
    const { sensorData, sensors, totalCount } = data?.data || {};
    return {
      sensorData: orderBy(sensorData || sensors, 'eventTime', 'asc'),
      totalCount,
    };
  };
export const useFetchTrapSensorReport = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: TRAP_SENSOR_REPORT_KEY,
      filters,
    },
    fetchTrapSensorReport(api)
  );
  return withLoading(response);
};

const fetchDetectionSensorReport =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchDetectionSensorReport(filters);
    const { sensorData, sensors, totalCount } = data?.data || {};
    return {
      sensorData: orderBy(sensorData || sensors, 'eventTime', 'asc'),
      totalCount,
    };
  };

export const useFetchDetectionSensorReport = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: DETECTION_SENSOR_REPORT_KEY,
      filters,
    },
    fetchDetectionSensorReport(api)
  );
  return withLoading(response);
};

export const fetchSensorHeatmapAsync = async (filters) => {
  const { data } = await api.fetchSensorHeatmap(filters);
  return {
    detectionData: data?.data?.detectionData || {},
    trapCaughtData: data?.data?.trapCaughtData || {},
    trapMissedData: data?.data?.trapMissedData || {},
  };
};

const listFiltrate = (api) => async (body) => {
  return await api.listFiltrate(body);
};

export const useListFiltrate = () => {
  const api = useApi();
  const mutate = useMatchMutate(SENSORS_CACHE_KEY);
  return useMutation(listFiltrate(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};
const addCaughtInfo = (api) => async (body) => {
  return await api.addCaughtInfo(body);
};

export const useAddCaughtInfo = () => {
  const api = useApi();
  const mutate = useMatchMutate(SENSORS_CACHE_KEY);
  return useMutation(addCaughtInfo(api), {
    isShowErrorMessage: false,
    async onSuccess() {
      await mutate();
    },
  });
};

const fetchBaitSensorData =
  (api) =>
  async ({ filters }) => {
    const { data } = await api.fetchBaitSensorData(filters);
    return {
      sensorData: data?.data.sensorData || [],
      totalCount: data?.data.totalCount || 0,
    };
  };

export const useFetchBaitSensorData = (filters = []) => {
  const api = useApi();
  const response = useSWR(
    {
      url: BAIT_SENSOR_DATA_KEY,
      filters,
    },
    fetchBaitSensorData(api)
  );
  return withLoading(response);
};
