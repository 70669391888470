import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, CustomTable, PageHeader } from '../../components';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { useFetchSites } from '../../services/sites';
import { searchTableFields, sorter } from '../../utilities/transform';
import i18next from 'i18next';
import {
  FORM_STORAGE_KEYS,
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../utilities/storage';
import { LANGUAGE_CODES } from '../../utilities/constants';

const buildTableColumns = (lang) => {
  return [
    {
      key: 'id',
      title: i18next.t('site'),
      dataIndex: lang === LANGUAGE_CODES.ENGLISH ? 'name' : 'nameZh',
      sorter: (a, b) => sorter(a.name, b.name),
      isSearchable: true,
    },
    {
      title: i18next.t('action'),
      key: 'action',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <Tooltip title='move to edit'>
              <Link to={`${record?.id}/edit`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

export const SiteManagement = () => {
  const { t, i18n } = useTranslation();
  const { search: savedSearch } = getSessionStorageItem(
    FORM_STORAGE_KEYS.SITE_LIST
  );
  const [search, setSearch] = useState(savedSearch || '');
  const { data: { sites, totalCount } = {}, isLoading } = useFetchSites();

  const columns = useMemo(() => {
    return buildTableColumns(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, i18n]);

  const onChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const filteredData = useMemo(() => {
    return searchTableFields(search, columns, sites);
  }, [search, sites, columns]);
  useEffect(() => {
    return () => {
      setSessionStorageItem(FORM_STORAGE_KEYS.SITE_LIST, { search });
    };
  }, [search]);
  return (
    <div className='p-10'>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('siteManagement'),
            link: '/site-management',
          },
        ]}
      />
      <PageHeader
        to='add'
        isSearch
        isCreate
        title={t('siteManagement')}
        onChange={onChange}
        search={search}
      />
      <CustomTable
        rowKey='id'
        columns={columns}
        dataSource={filteredData}
        isShowCount
        isPagination={false}
        totalCount={totalCount}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SiteManagement;
