import './index.css';
import React from 'react';
import { Col, Empty, Row, Spin, Table, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import Pagination from '../pagination';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export const CustomTable = ({
  header,
  columns = [],
  dataSource = [],
  pagination = true,
  isLoading = false,
  isPagination = false,
  isShowCount,
  rowKey = (item, index) => index,
  onPageChange,
  currentPage,
  totalCount,
  pageSize,
  onRow,
}) => {
  const { t } = useTranslation();
  const getHeader = () => {
    if (header) {
      return { title: header };
    }
  };

  const isDataExist = !isEmpty(dataSource);
  return (
    <Spin spinning={!isDataExist && isLoading}>
      <Table
        {...getHeader()}
        rowKey={rowKey}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        locale={{
          triggerAsc: t('sortAsc'),
          triggerDesc: t('sortDesc'),
          cancelSort: t('cancelSort'),
          emptyText: <Empty description={t('noData')} />,
        }}
        onRow={onRow}
      />
      <Row justify='space-between' align='bottom'>
        <Col>
          {isShowCount && isDataExist && (
            <div className='total-count'>
              <Text strong>
                {t('total')}: {totalCount || 0}
              </Text>
            </div>
          )}
        </Col>
        <Col>
          {isPagination && isDataExist && (
            <Pagination
              onChange={onPageChange}
              currentPage={currentPage}
              pageSize={pageSize}
              totalCount={totalCount}
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};
