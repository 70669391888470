import moment from 'moment';

export const FORM_STORAGE_KEYS = {
  SENSOR_REPORT: 'sensor_report',
  NOTIFICATION_LIST: 'notification_list',
  EVENT_LIST: 'event_list',
  DEVICE_LIST: 'device_list',
  SITE_LIST: 'site_list',
  BAIT_LIST: 'bait_list',
  ROLE_LIST: 'role_list',
  ACCOUNT_LIST: 'account_list',
  ASSIGN_SENSOR: 'assign_sensor',
  ASSIGN_TRAP_SENSOR: 'assign_trap_sensor',
  ASSIGN_DETECTION_SENSOR: 'assign_detection_sensor',
  HEATMAP_FLOOR_MAP: 'heatmap_floor_plan',
  LOGIN: 'login',
};

const VALID_TIME_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

export const setStorageItem = (key, value) => {
  localStorage.setItem(key, value);
};
export const getStorageItem = (key) => localStorage.getItem(key);

export const setSessionStorageItem = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};
export const getSessionStorageItem = (key) => {
  const parseData = JSON.parse(sessionStorage.getItem(key)) || {};
  const updatedData = {};
  Object.keys(parseData).forEach((key) => {
    const itemValue = parseData[key];
    if (itemValue?.toString().match(VALID_TIME_REGEX)) {
      updatedData[key] = moment(itemValue);
    } else {
      updatedData[key] = itemValue;
    }
  });
  return updatedData;
};
