import './index.css';
import { Divider } from 'antd';
import { SideBar } from './side-bar';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { LayoutHeader } from './layout-header/layout-header';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserProvider } from '../../context/session';
import { ActiveSiteProvider, useActiveSiteProvider } from '../../context/site';
import Loader from '../loader';
import { useAutoLogout } from '../../hooks';

const Body = () => {
  useAutoLogout();
  const { activeSite } = useActiveSiteProvider();
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => {
    setCollapsed((old) => !old);
  };

  return (
    <div className='layout-container'>
      <LayoutHeader collapsed={collapsed} setCollapsed={onCollapse} />
      <Divider className='m-0' />
      <div className={collapsed ? 'custom-layout-close' : 'custom-layout-open'}>
        {!collapsed && <SideBar />}
        <div className='page-content'>
          {!isEmpty(activeSite) ? <Outlet /> : <Loader />}
        </div>
      </div>
    </div>
  );
};

export const ProtectedLayout = () => {
  const [user] = useUserProvider();
  if (isEmpty(user)) {
    return <Navigate to='/login' replace />;
  }
  return (
    <ActiveSiteProvider>
      <Body />
    </ActiveSiteProvider>
  );
};

export default ProtectedLayout;
