import React from 'react';
import { Field, Form } from 'formik';
import { AntDatePicker, AntInput, AntSelect, AntUpload } from '../index';
import { Button, Col, Popconfirm, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BAIT_TYPES_OPTIONS, GUTTER } from '../../utilities/constants';
import { Link } from 'react-router-dom';

export const AddBaitForm = ({
  isEdit = true,
  handleSubmit,
  values,
  submitCount,
  resetForm,
  setFieldValue,
  handleDeleteBait,
}) => {
  const { t } = useTranslation();
  const { path, imageData } = values || {};

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[GUTTER, GUTTER]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            component={AntInput}
            name='name'
            type='text'
            required
            label={t('name')}
            placeholder={t('pleaseInputName')}
            submitCount={submitCount}
            hasFeedback
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            component={AntSelect}
            name='type'
            label={t('baitType')}
            placeholder={t('pleaseSelectBaitType')}
            submitCount={submitCount}
            hasFeedback
            style={{ width: '100%' }}
            selectOptions={BAIT_TYPES_OPTIONS}
            required
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            component={AntDatePicker}
            name='expiryDate'
            label={t('date')}
            placeholder={t('date')}
            defaultValue={values.fromDate}
            format='YYYY-MM-DD'
            submitCount={submitCount}
            hasFeedback
            style={{ width: '100%' }}
            allowClear={false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }}>
          <Field
            component={AntUpload}
            name='imageData'
            label={t('photo')}
            placeholder={t('uploadPhoto')}
            submitCount={submitCount}
            hasFeedback={false}
            maxCount={1}
            extraData={{
              path,
              uploadType: 'bait',
              imageData,
            }}
          />
        </Col>
      </Row>

      <Row gutter={[GUTTER, GUTTER]}>
        <Col>
          <Link to='/device-management'>
            <Button type='secondary'>{t('back')}</Button>
          </Link>
        </Col>
        <Col>
          <Button type='secondary' onClick={resetForm}>
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button type='primary' onClick={handleSubmit}>
            {t('save')}
          </Button>
        </Col>
        {isEdit && (
          <Col>
            <Popconfirm
              title='Are you sure to delete this bait?'
              onConfirm={handleDeleteBait}
              okText='Yes'
              cancelText='No'
            >
              <Button type='danger'>{t('delete')}</Button>
            </Popconfirm>
          </Col>
        )}
      </Row>
    </Form>
  );
};
