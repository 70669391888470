import { useState } from 'react';

export const useToggleFilters = () => {
  const [isShowFilters, setIsShowFilters] = useState(true);

  const showFilters = () => {
    setIsShowFilters(true);
  };

  const hideFilters = () => {
    setIsShowFilters(false);
  };

  const toggleFilters = () => {
    setIsShowFilters((old) => !old);
  };

  return {
    isShowFilters,
    toggleFilters,
    showFilters,
    hideFilters,
  };
};
