import './sensor-floor-plans.css';
import { useFormikContext } from 'formik';
import { ZoomFloorActionButtons } from '../index';
import { Spin } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { SensorsList } from '../sensors-list';
import { useForceUpdate, useZoomFloorPlan } from '../../hooks';
import {
  getImageDimensions,
  getScaledSensorFloorPlanInfo,
} from '../../utilities/image-utils';
import {
  getFloorImageUrl,
  sensorScaleTransform,
} from '../../utilities/transform';
import useDraggableScroll from 'use-draggable-scroll';
import { fetchSensorAndSite } from '../../pages/sensor/sensor-location-page/service';
import { sensorScaleDownTransform } from '../../pages/sensor/sensor-location-page/transform';
import { DEFAULT_SENSOR_ID } from './constant';
import { isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';

const SensorFloorPlanComponent = (
  { routeKey, draggableSensors = [], sensorType, isEditMode, editSensorId },
  reference
) => {
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();
  const { onMouseDown } = useDraggableScroll(ref);
  const { forceUpdate, forceUpdateKey } = useForceUpdate();
  const [floorPlanMeta, setFloorPlanMeta] = useState({});
  const { optionsList, activeScaleInfo, onChangeZoomLevel } =
    useZoomFloorPlan();
  const { values } = useFormikContext();

  const [sensors, setSensors] = useState([]);
  const { siteId, floorId, layerId, zoneId, direction } = values || {};
  const {
    floorPlanImageUrl,
    scaledFloorPlanImageWidth,
    scaledFloorPlanImageHeight,
    sensorIconWidth,
    sensorIconHeight,
    naturalWidth,
    naturalHeight,
  } = floorPlanMeta || {};

  useEffect(() => {}, [values.siteId]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const { siteId, floorId, layerId, zoneId } = values;
      const params = {
        siteId,
        floorId,
        layerId,
        zoneId,
        routeKey,
      };
      const { floor, sensors } = await fetchSensorAndSite(params);
      const floorPlanImageUrl = getFloorImageUrl(floor.floorPath);
      const { naturalWidth, naturalHeight } = await getImageDimensions(
        floorPlanImageUrl
      );
      const {
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      } = getScaledSensorFloorPlanInfo(
        naturalWidth,
        naturalHeight,
        activeScaleInfo.imageWidth
      );
      setFloorPlanMeta({
        naturalWidth,
        naturalHeight,
        floorPlanImageUrl,
        widthScale,
        heightScale,
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        sensorIconWidth,
        sensorIconHeight,
      });
      const formattedSensors = sensorScaleDownTransform(
        sensors,
        widthScale,
        heightScale,
        naturalWidth,
        naturalHeight
      );

      const coloredSensors = orderBy(
        formattedSensors.map((item) => {
          if (draggableSensors.includes(item.id)) {
            return {
              ...item,
              color: 'red',
            };
          }
          return item;
        }),
        'color',
        'asc'
      );

      if (!isEditMode) {
        coloredSensors.push({
          type: coloredSensors[0].type,
          color: 'red',
          latitude: 0,
          longitude: 0,
          id: DEFAULT_SENSOR_ID,
          direction: direction,
        });
      }
      setSensors(coloredSensors);
      setTimeout(forceUpdate, 1500);
    } catch ({ message }) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleZoomLevelChange = async (imageWidth) => {
    const originalScaleInfo = getScaledSensorFloorPlanInfo(
      scaledFloorPlanImageWidth,
      scaledFloorPlanImageHeight,
      naturalWidth
    );
    const originalScaledSensors = sensorScaleTransform(
      sensors,
      originalScaleInfo.widthScale,
      originalScaleInfo.heightScale
    );

    const zoomedScaleInfo = getScaledSensorFloorPlanInfo(
      naturalWidth,
      naturalHeight,
      imageWidth
    );
    const zoomedSensors = sensorScaleTransform(
      originalScaledSensors,
      zoomedScaleInfo.widthScale,
      zoomedScaleInfo.heightScale
    );
    setFloorPlanMeta((old) => ({
      ...old,
      ...zoomedScaleInfo,
      naturalWidth,
      naturalHeight,
    }));
    setSensors(zoomedSensors);
    setTimeout(forceUpdate, 1500);
  };

  useEffect(() => {
    onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, floorId, layerId]);
  // }, [siteId, floorId, layerId, zoneId]);

  useImperativeHandle(reference, () => ({
    getUpdatedSensorInfo() {
      const { widthScale, heightScale } = getScaledSensorFloorPlanInfo(
        scaledFloorPlanImageWidth,
        scaledFloorPlanImageHeight,
        naturalWidth
      );
      const updatedSensors = sensorScaleTransform(
        sensors,
        widthScale,
        heightScale
      );
      if (isEditMode) {
        return updatedSensors.find((item) => item.id === editSensorId);
      } else {
        return updatedSensors.find((item) => item.id === DEFAULT_SENSOR_ID);
      }
    },
    refetchData: onSubmit,
  }));

  useEffect(() => {
    if (isEmpty(sensors)) {
      return;
    }
    setSensors((old) => {
      return old.map((item) => {
        if (editSensorId === item.id || item.id === DEFAULT_SENSOR_ID) {
          return { ...item, direction };
        }
        return item;
      });
    });
    // eslint-disable-next-line
  }, [direction]);

  return (
    <Spin spinning={isLoading}>
      <div>
        {floorPlanImageUrl && (
          <>
            <ZoomFloorActionButtons
              activeScaleInfo={activeScaleInfo}
              optionsList={optionsList}
              onChange={(item) => {
                onChangeZoomLevel(item.value);
                handleZoomLevelChange(item.imageWidth);
              }}
            />
            <div className='floor-plan-center'>
              <div
                className='floor-plan-wrapper'
                ref={ref}
                onMouseDown={onMouseDown}
              >
                <img
                  draggable='false'
                  alt='Sensor Area'
                  className='floor-plan-image'
                  src={floorPlanImageUrl}
                  width={scaledFloorPlanImageWidth}
                  height={scaledFloorPlanImageHeight}
                  ref={ref}
                  onMouseDown={onMouseDown}
                />
                <SensorsList
                  isPopover
                  draggableSensors={draggableSensors}
                  key={forceUpdateKey}
                  sensors={sensors}
                  sensorType={sensorType}
                  sensorIconWidth={sensorIconWidth}
                  sensorIconHeight={sensorIconHeight}
                  scaledFloorPlanImageWidth={scaledFloorPlanImageWidth}
                  scaledFloorPlanImageHeight={scaledFloorPlanImageHeight}
                  onUpdate={async (item, index, updatedLatLng) => {
                    setSensors((old) => {
                      const updated = [...old];
                      updated[index] = {
                        ...updated[index],
                        ...updatedLatLng,
                        color: 'red',
                      };
                      return updated;
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export const SensorFloorPlans = forwardRef(SensorFloorPlanComponent);
