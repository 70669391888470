import { Col, Popover, Row } from 'antd';
import './sensor-list.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import SensorFloorPlanIcon from '../sensor-floor-icon';
import { DEFAULT_SENSOR_ID } from '../add-edit-sensor-form/constant';
import { SENSOR_TYPE_KEYS } from '../../utilities/constants';

const PopoverContent = ({ item }) => {
  const { sensorId, name } = item || {};
  const { t } = useTranslation();
  return (
    <>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>{t('id')}</Col>
        <Col>{sensorId}</Col>
      </Row>
      <Row justify='space-between' gutter={10}>
        <Col className='popover-content'>{t('name')}</Col>
        <Col>{name}</Col>
      </Row>
    </>
  );
};

const DraggableWrapper = ({
  item,
  latitude,
  longitude,
  children,
  isPopover,
  scaledFloorPlanImageWidth,
  scaledFloorPlanImageHeight,
  sensorIconWidth,
  sensorIconHeight,
  onUpdate,
  disabled,
}) => {
  return (
    <Draggable
      disabled={disabled}
      defaultPosition={{
        x: latitude,
        y: longitude - scaledFloorPlanImageHeight,
      }}
      scale={1}
      onStop={(event, data) => {
        const updatedLatLng = {
          latitude: data.x,
          longitude: data.y + scaledFloorPlanImageHeight,
        };
        onUpdate(updatedLatLng);
      }}
      bounds={{
        bottom: -sensorIconHeight,
        left: 0,
        top: -scaledFloorPlanImageHeight,
        right: scaledFloorPlanImageWidth - sensorIconWidth,
      }}
    >
      {isPopover ? (
        <div>
          <Popover content={<PopoverContent item={item} />}>{children}</Popover>
        </div>
      ) : (
        children
      )}
    </Draggable>
  );
};

export const SensorsList = ({
  isPopover = true,
  sensors,
  sensorIconWidth,
  sensorIconHeight,
  scaledFloorPlanImageWidth,
  scaledFloorPlanImageHeight,
  onUpdate,
  disabled = false,
  renderItem,
  draggableSensors = [],
  pincolor,
  isDraggableAllowed = true,
}) => {
  return sensors.map((item, index) => {
    const { id, latitude, name, longitude, direction, type } = item || {};
    const style = { x: latitude, y: longitude };
    let isDisabled = false;
    if (draggableSensors.length > 0) {
      isDisabled = !(
        draggableSensors.filter(
          (sensorId) => sensorId === id || id === DEFAULT_SENSOR_ID
        ).length > 0
      );
      if (!isDisabled) {
        item.color = 'red';
      }
    } else {
      isDisabled = disabled;
    }
    return (
      <DraggableWrapper
        key={index}
        item={item}
        id={id}
        name={name}
        isPopover={isPopover}
        disabled={isDisabled || !isDraggableAllowed}
        latitude={latitude}
        longitude={longitude}
        draggableStyle={style}
        scaledFloorPlanImageWidth={scaledFloorPlanImageWidth}
        scaledFloorPlanImageHeight={scaledFloorPlanImageHeight}
        sensorIconWidth={sensorIconWidth}
        sensorIconHeight={sensorIconHeight}
        onUpdate={(updatedLatLng) => {
          onUpdate(item, index, updatedLatLng);
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: '5px',
          }}
        >
          <div style={{ width: sensorIconWidth, height: sensorIconHeight }}>
            {typeof renderItem === 'function' ? (
              renderItem({ item, sensorIconWidth, sensorIconHeight })
            ) : (
              <SensorFloorPlanIcon
                key={id}
                type={type}
                direction={direction}
                width={sensorIconWidth}
                height={sensorIconHeight}
                color={pincolor ? pincolor : item?.color}
              />
            )}
          </div>
          {(type === SENSOR_TYPE_KEYS.TRAP_SENSOR ||
            type === SENSOR_TYPE_KEYS.BAIT_SENSOR ||
            type === SENSOR_TYPE_KEYS.DETECTION_SENSOR) && (
            <span
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                display: 'inline-block',
                width: 100,
                color: pincolor ? pincolor : item?.color,
              }}
            >
              {name}
            </span>
          )}
        </div>
      </DraggableWrapper>
    );
  });
};
