import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '../utilities/storage';
import { isEmpty } from 'lodash';
import { useActiveSiteProvider } from '../context/site';

export const useFormStateBackup = ({
  formKey,
  callback = () => {},
  isMultiLayer = false,
  isMultiZone = false,
  initialValues = {},
}) => {
  const { setValues, values, resetForm } = useFormikContext();
  const { activeSite } = useActiveSiteProvider();
  const { value: activeSiteId } = activeSite || {};
  useEffect(() => {
    const initializeForm = async () => {
      const oldState = getSessionStorageItem(formKey);
      if (isEmpty(oldState)) {
        const initialState = { ...values };
        initialState.siteId = activeSiteId;
        await setValues(initialState);
        callback();
      } else {
        if (oldState.siteId !== activeSiteId) {
          oldState.siteId = activeSiteId;
          oldState.floorId = null;
          if (isMultiLayer) {
            oldState.layerId = [];
          } else {
            oldState.layerId = null;
          }
          if (isMultiZone) {
            oldState.zoneId = [];
          } else {
            oldState.zoneId = null;
          }
        }
        await setValues(oldState);
        callback();
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    initializeForm();
    // eslint-disable-next-line
  }, [activeSiteId]);

  useEffect(() => {
    setSessionStorageItem(formKey, values);
  }, [formKey, values]);

  const handleReset = async () => {
    const updatedState = { ...initialValues, siteId: activeSiteId };
    await resetForm({ values: updatedState });
    callback();
  };

  return {
    handleReset,
  };
};
