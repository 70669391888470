import React from 'react';
import { Field, Form } from 'formik';
import { AntInput, AntPasswordInput } from '../index';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { GUTTER } from '../../utilities/constants';

export const LoginForm = ({
  handleSubmit,
  submitCount,
  resetForm,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={AntInput}
        name='username'
        type='text'
        label={t('loginName')}
        placeholder={t('loginName')}
        submitCount={submitCount}
        hasFeedback
        required
      />
      <Field
        component={AntPasswordInput}
        name='password'
        type='password'
        label={t('password')}
        placeholder={t('password')}
        submitCount={submitCount}
        hasFeedback
        required
      />
      <Row gutter={[GUTTER, GUTTER]} style={{ marginTop: 10 }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 11, offset: 0 }}
          lg={{ span: 11, offset: 0 }}
        >
          <Button
            type='secondary'
            block
            onClick={resetForm}
            disabled={isLoading}
          >
            {t('reset')}
          </Button>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 11, offset: 2 }}
          lg={{ span: 11, offset: 2 }}
        >
          <Button
            block
            type='primary'
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            {t('submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
