import { ASSIGN_SENSOR_FORM_INITIAL_VALUES } from './constant';

export const buildInitialValues = (
  sensors,
  siteId,
  floorId,
  layerId,
  zoneId
) => {
  if (sensors?.length > 0) {
    const availableSensors = sensors.map((sensor) => {
      const { id, latitude, longitude } = sensor || {};
      return {
        id: parseInt(id),
        latitude: parseInt(latitude),
        longitude: parseInt(longitude),
      };
    });
    return { siteId, floorId, layerId, zoneId, sensors: availableSensors };
  } else {
    return ASSIGN_SENSOR_FORM_INITIAL_VALUES;
  }
};

export const sensorScaleDownTransform = (
  sensors = [],
  widthScale,
  heightScale,
  naturalWidth,
  naturalHeight
) => {
  return sensors.map((sensor) => {
    const { id, latitude, longitude, direction, name, type, sensorId } =
      sensor || {};
    const lat = parseInt(latitude || 0);
    const lng = parseInt(longitude || 0);

    const sanitizedLat = lat > naturalWidth || lat < 0 ? 0 : lat;
    const sanitizedLng = lng > naturalHeight || lng < 0 ? 0 : lng;
    return {
      id: parseInt(id),
      sensorId,
      latitude: widthScale(sanitizedLat),
      longitude: heightScale(sanitizedLng),
      direction,
      name,
      type,
    };
  });
};
