import { sorter } from '../../../utilities/transform';
import { Button, Space, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { IoMdSwitch } from 'react-icons/io';
import {
  ICON_SIZE,
  LANGUAGE_CODES,
  SENSOR_PAGE_KEYS,
  SENSOR_TYPE_KEYS,
  STATUS_KEYS,
} from '../../../utilities/constants';
import { FiEdit } from 'react-icons/fi';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import React from 'react';
import i18next from 'i18next';
import moment from 'moment';

export const buildTableColumns = (routeKey) => {
  const columns = [
    {
      title: i18next.t('id'),
      dataIndex: 'sensorId',
      key: 'sensorId',
      sorter: (a, b) => sorter(a.sensorId, b.sensorId),
    },
    {
      title: i18next.t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sorter(a.name, b.name),
    },
    {
      title: i18next.t('type'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => sorter(a.type, b.type),
      render: (text) => {
        switch (text) {
          case SENSOR_TYPE_KEYS.DETECTION_SENSOR:
            return (
              <Typography.Text size='middle'>
                {i18next.t('rodentDetectionSensor')}
              </Typography.Text>
            );
          case SENSOR_TYPE_KEYS.TRAP_SENSOR:
            return (
              <Typography.Text size='middle'>
                {i18next.t('rodentTrapSensor')}
              </Typography.Text>
            );
          case SENSOR_TYPE_KEYS.BAIT_SENSOR:
            return (
              <Typography.Text size='middle'>
                {i18next.t('baitSensor')}
              </Typography.Text>
            );
          case SENSOR_TYPE_KEYS.OTHER_SENSOR:
            return (
              <Typography.Text size='middle'>
                {i18next.t('otherSensor')}
              </Typography.Text>
            );
        }
      },
    },
    {
      title: i18next.t('site'),
      dataIndex:
        i18next.language === LANGUAGE_CODES.ENGLISH ? 'siteName' : 'siteNameZh',
      key: 'site',
      sorter: (a, b) => {
        return sorter(a.siteName, b.siteName);
      },
    },
    {
      title: i18next.t('floor'),
      dataIndex:
        i18next.language === LANGUAGE_CODES.ENGLISH
          ? 'floorName'
          : 'floorNameZh',
      key: 'floor',
      sorter: (a, b) => {
        return sorter(a.floorName, b.floorName);
      },
    },
    {
      title: i18next.t('layer'),
      dataIndex:
        i18next.language === LANGUAGE_CODES.ENGLISH
          ? 'layerName'
          : 'layerNameZh',
      key: 'layer',
      sorter: (a, b) => sorter(a.layerName, b.layerName),
    },
    {
      title: i18next.t('zone'),
      dataIndex:
        i18next.language === LANGUAGE_CODES.ENGLISH ? 'zoneName' : 'zoneNameZh',
      key: 'zone',
      sorter: (a, b) => sorter(a.zoneName, b.zoneName),
    },
    {
      title: i18next.t('status'),
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => sorter(a.state, b.state),
      render: (text) => {
        switch (text) {
          case STATUS_KEYS.ACTIVE:
            return (
              <Typography.Text size='middle'>
                {i18next.t('active')}
              </Typography.Text>
            );
          case STATUS_KEYS.IN_ACTIVE:
            return (
              <Typography.Text size='middle'>
                {i18next.t('inActive')}
              </Typography.Text>
            );
        }
      },
    },
    {
      title: i18next.t('lastSeen'),
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      sorter: (a, b) => sorter(a.lastSeen, b.lastSeen),
      render: (item) =>
        item !== null ? moment(item).format('YYYY-MM-DD HH:mm') : 'NA',
    },
    {
      title: i18next.t('exchange'),
      key: 'exchange',
      render: (item) => (
        <Space size='middle'>
          <Tooltip title={i18next.t('moveToExchange')}>
            <Link to={`/trap-sensor/exchange/${item.id}`}>
              <Button
                icon={
                  <IoMdSwitch
                    style={{
                      fontSize: ICON_SIZE,
                    }}
                  />
                }
              />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: i18next.t('dataInput'),
      key: 'dataInput',
      render: () => (
        <Space size='middle'>
          <Tooltip title={i18next.t('moveToDataInput')}>
            <Link to='/trap-sensor/input-data'>
              <Button icon={<FiEdit style={{ fontSize: ICON_SIZE }} />} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: i18next.t('history'),
      key: 'history',
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={i18next.t('moveToHistory')}>
            <Link to={`/${routeKey}/${record?.sensorId}/history`}>
              <Button
                icon={
                  <HistoryOutlined
                    style={{
                      fontSize: ICON_SIZE,
                    }}
                  />
                }
              />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: i18next.t('action'),
      key: 'action',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <Tooltip title={i18next.t('moveToEdit')}>
              <Link to={`/${routeKey}/${record?.id}/edit`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  if (routeKey !== SENSOR_PAGE_KEYS.TRAP_SENSOR) {
    return columns.filter(({ key }) => {
      return !(key === 'dataInput' || key === 'exchange');
    });
  }
  return columns;
};

export const buildCSVColumns = () => [
  {
    label: i18next.t('id'),
    key: 'sensorId',
  },
  {
    label: i18next.t('name'),
    key: 'name',
  },
  {
    label: i18next.t('type'),
    key: 'type',
  },
  {
    label: i18next.t('site'),
    key:
      i18next.language === LANGUAGE_CODES.ENGLISH ? 'siteName' : 'siteNameZh',
  },

  {
    label: i18next.t('floor'),
    key:
      i18next.language === LANGUAGE_CODES.ENGLISH ? 'floorName' : 'floorNameZh',
  },
  {
    label: i18next.t('layer'),
    key:
      i18next.language === LANGUAGE_CODES.ENGLISH ? 'layerName' : 'layerNameZh',
  },
  {
    label: i18next.t('zone'),
    key:
      i18next.language === LANGUAGE_CODES.ENGLISH ? 'zoneName' : 'zoneNameZh',
  },
  {
    label: i18next.t('status'),
    key: 'state',
  },
  {
    label: i18next.t('lastSeen'),
    key: 'lastSeen',
  },
];
export const getLocaleCSVData = (data) => {
  return data?.map((item) => {
    let type, state;
    switch (item?.type) {
      case SENSOR_TYPE_KEYS.DETECTION_SENSOR:
        type = i18next.t('rodentDetectionSensor');
        break;
      case SENSOR_TYPE_KEYS.TRAP_SENSOR:
        type = i18next.t('rodentTrapSensor');
        break;
      case SENSOR_TYPE_KEYS.BAIT_SENSOR:
        type = i18next.t('baitSensor');
        break;
      case SENSOR_TYPE_KEYS.OTHER_SENSOR:
        type = i18next.t('otherSensor');
        break;
    }
    switch (item?.state) {
      case STATUS_KEYS.ACTIVE:
        state = i18next.t('active');
        break;
      case STATUS_KEYS.IN_ACTIVE:
        state = i18next.t('inActive');
        break;
    }
    return {
      ...item,
      type,
      state,
      lastSeen:
        item.lastSeen !== null
          ? moment(item.lastSeen).format('YYYY-MM-DD HH:mm')
          : 'NA',
    };
  });
};
