import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { DetectionSensorThirdTabForm } from './detection-sensor-third-tab-form';
import { DEFAULT_PAGE_SIZE } from '../../../../utilities/constants';

const initialValues = {
  startTime: moment().subtract(30, 'd'),
  endTime: moment(),
  pageNumber: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  isRatHit: true,
  isBattery: true,
  isHeartBeat: true,
  isRssi: true,
  isStatus: true,
};

export const DetectionSensorThirdTab = () => {
  return (
    <Formik initialValues={initialValues}>
      <DetectionSensorThirdTabForm />
    </Formik>
  );
};
