import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, notification, Row, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { AddBaitForm, Breadcrumbs } from '../../../../components';
import { useAddBait } from '../../../../services/baits';
import { useNavigate } from 'react-router-dom';
import { generateFilePath } from '../../../../utilities/transform';
import { AddBaitSchema } from '../../../../utilities/schemas';
import { getBase64String } from '../../../../utilities/image-utils';

const { Title } = Typography;
export const AddBait = () => {
  const { t } = useTranslation();
  const { mutate } = useAddBait();
  const navigate = useNavigate();
  return (
    <div style={{ padding: 10 }}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('baitManagement'),
            link: '/device-management',
          },
          {
            name: t('create'),
            link: '/device-management/add',
          },
        ]}
      />
      <Row gutter={[2, 3]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Title level={3}>{t('createBait')}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Formik
            initialValues={{}}
            validationSchema={AddBaitSchema}
            onSubmit={async (values, actions) => {
              try {
                const { name, expiryDate, imageData, type } = values || {};
                if (!imageData) {
                  return notification.error({ message: 'Image is required' });
                }
                const params = {
                  name: name.trim(),
                  expiryDate,
                  path: generateFilePath(),
                  imageData: getBase64String(imageData),
                  type: type,
                };
                actions.setSubmitting(true);
                await mutate(params);
                actions.resetForm();
                notification.success({ message: t('createdSuccess') });
                navigate('/device-management');
              } catch ({ message }) {
                notification.error({ message });
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({
              values,
              submitCount,
              resetForm,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <Spin spinning={isSubmitting}>
                  <AddBaitForm
                    isEdit={false}
                    values={values}
                    submitCount={submitCount}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                  />
                </Spin>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};
export default AddBait;
