import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLORS, LANGUAGE_CODES } from '../../../utilities/constants';
import { useTranslation } from 'react-i18next';
import '../dashboard-body.css';
import {
  checkEmptiness,
  renderCustomizedLabel,
} from '../../../utilities/functions';
import { CustomPieChart } from '../../../components/charts/pie-chart';
import { isEmpty } from 'lodash';
import { Typography } from 'antd';

const GRAPH_HEIGHT = 580;

export const DetectionDashboardBody = ({ dashboardData }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === LANGUAGE_CODES.ENGLISH;
  const {
    totalCountByLayers = [],
    countbyDate = [],
    totalCountByTimerange = [],
    countbyDateandLayer = [],
    layers = [],
    countbyDay = [],
    countbyDayandLayer = [],
    totalCountByZones = [],
    top10Sensors = [],
    totalCountByZonesSensor = [],
    countDetectionZoneBylayer = [],
    top10DetectionBylayer = [],
  } = dashboardData || {};

  const getRandomColorCode = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return '#' + randomColor;
  };
  return (
    <div>
      <div className='graphs-container'>
        <div style={{ border: '1px solid #ccc' }}>
          <p className='graph-title'>{t('totalcountbyzone')}</p>
          <CustomPieChart
            width='100%'
            height={400}
            data={totalCountByZones}
            dataKey='count'
            nameKey={isEnglish ? 'name' : 'nameZh'}
            outerRadius={100}
            labelLine={true}
            label={renderCustomizedLabel}
          />
        </div>
        <div style={{ border: '1px solid #ccc' }}>
          <p className='graph-title'>{t('totalCountbyZoneSensor')}</p>
          <ResponsiveContainer width='100%' height={GRAPH_HEIGHT}>
            {!checkEmptiness(totalCountByZonesSensor) &&
            !isEmpty(totalCountByZonesSensor) ? (
              <BarChart
                data={totalCountByZonesSensor}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={isEnglish ? 'name' : 'nameZh'}>
                  <Label
                    value={t('zone')}
                    offset={-5}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    offset={0}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip wrapperStyle={{ zIndex: 100 }} />
                {totalCountByZonesSensor.map((d, j) =>
                  d.sensors.map((s, i) => (
                    <Bar
                      dataKey={s}
                      stackId={d.name}
                      fill={getRandomColorCode()}
                    />
                  ))
                )}
              </BarChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
        <div style={{ border: '1px solid #ccc' }}>
          <p className='graph-title'>{t('top10sensor')}</p>
          <ResponsiveContainer width='100%' height={GRAPH_HEIGHT}>
            {!checkEmptiness(top10Sensors) && !isEmpty(top10Sensors) ? (
              <BarChart
                layout={'vertical'}
                data={top10Sensors.sort((a, b) => b.count - a.count)}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis type='number'>
                  <Label
                    value={t('totalCount')}
                    offset={-5}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis
                  dataKey='name'
                  type='category'
                  tick={{ padding: '10px', margin: '10px' }}
                >
                  <Label
                    angle={-90}
                    value={t('sensorName')}
                    offset={-12}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
                <Legend verticalAlign='top' />
                <Bar
                  dataKey={isEnglish ? 'count' : '數量'}
                  barSize={20}
                  fill='#8884d8'
                />
              </BarChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
      </div>
      <div className='graphs-container'>
        <div style={{ border: '1px solid #ccc', padding: '5px' }}>
          <p className='graph-title'>{t('totalCount')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {!checkEmptiness(totalCountByLayers) &&
            !isEmpty(totalCountByLayers) ? (
              <BarChart
                data={totalCountByLayers}
                margin={{
                  top: 5,
                  right: 25,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey={isEnglish ? 'name' : 'nameZh'}
                  tick={{
                    width: 'fit-content',
                    height: 'auto',
                    wordWrap: 'break-word',
                  }}
                  interval={0}
                >
                  <Label
                    value={t('layer')}
                    offset={-5}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
                <Bar dataKey={isEnglish ? 'count' : '數量'} fill='#8884d8' />
              </BarChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
        <div style={{ border: '1px solid #ccc' }}>
          <p className='graph-title'>{t('totalCountbyDate')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {!checkEmptiness(countbyDate) ? (
              <LineChart
                data={countbyDate}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 5,
                  right: 25,
                }}
              >
                <Line
                  type='monotone'
                  dataKey={isEnglish ? 'count' : '數量'}
                  stroke='#8884d8'
                />
                <CartesianGrid stroke='#ccc' />
                <XAxis dataKey='date'>
                  <Label
                    value={t('date')}
                    offset={-5}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis allowDecimals={false}>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
              </LineChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
        <div style={{ border: '1px solid #ccc' }}>
          <p className='graph-title'>{t('dayoftheWeek')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {!checkEmptiness(countbyDay) && !isEmpty(countbyDay) ? (
              <BarChart
                data={countbyDay}
                margin={{
                  top: 5,
                  right: 25,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={isEnglish ? 'day' : 'dayZh'}>
                  <Label value={t('day')} offset={-3} position='insideBottom' />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
                <Bar dataKey={isEnglish ? 'count' : '數量'} fill='#8884d8' />
              </BarChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
      </div>
      <div className='graphs-container'>
        <div style={{ border: '1px solid #ccc', padding: '5px' }}>
          <p className='graph-title'>{t('totalCount')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {!checkEmptiness(totalCountByTimerange) &&
            !isEmpty(totalCountByTimerange) ? (
              <BarChart
                data={totalCountByTimerange}
                margin={{
                  top: 5,
                  right: 25,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey={isEnglish ? 'name' : 'nameZh'}
                  tick={{
                    width: '230px',
                    height: '200px',
                    wordWrap: 'normal',
                  }}
                  interval={0}
                  minTickGap={-200}
                >
                  <Label
                    value={t('layer')}
                    offset={0}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
                <Bar dataKey='Day' fill='#8884d8' name={t('allDay')} />
                <Bar dataKey='Night' fill='#F00' name={t('night')} />
                <Legend verticalAlign='top' />
              </BarChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
        <div style={{ border: '1px solid #ccc' }}>
          <p className='graph-title'>{t('layerCountbyDate')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {!checkEmptiness(countbyDateandLayer) ? (
              <LineChart
                data={countbyDateandLayer}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 5,
                  right: 25,
                }}
              >
                {layers.map((l, i) => (
                  <Line
                    type='monotone'
                    dataKey={isEnglish ? l.name : l.nameZh}
                    stroke={COLORS[i]}
                  />
                ))}
                <CartesianGrid stroke='#ccc' />
                <XAxis dataKey='date'>
                  <Label
                    value={t('date')}
                    offset={-5}
                    position='insideBottom'
                  />
                </XAxis>
                <YAxis allowDecimals={false}>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
                <Legend verticalAlign='top' />
              </LineChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
        <div style={{ border: '1px solid #ccc' }}>
          <p className='graph-title'>{t('layerCountbyDateoftheweek')}</p>
          <ResponsiveContainer width='100%' height={250}>
            {!checkEmptiness(countbyDayandLayer) &&
            !isEmpty(countbyDayandLayer) ? (
              <BarChart
                data={countbyDayandLayer}
                margin={{
                  top: 5,
                  right: 25,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={isEnglish ? 'day' : 'dayZh'}>
                  <Label value={t('day')} offset={-3} position='insideBottom' />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value={t('totalCount')}
                    position='insideLeft'
                  />
                </YAxis>
                <Tooltip />
                <Legend verticalAlign='top' />
                {layers.map((l, i) => (
                  <Bar
                    dataKey={isEnglish ? l.name : l.nameZh}
                    fill={COLORS[i]}
                  />
                ))}
              </BarChart>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography.Text>{t('noData')}</Typography.Text>
              </div>
            )}
          </ResponsiveContainer>
        </div>
      </div>
      <div className='graphs-container1'>
        {countDetectionZoneBylayer.map((gd, i) => (
          <div key={i} style={{ border: '1px solid #ccc', padding: '5px' }}>
            <p className='graph-title'>{isEnglish ? gd.name : gd.nameZh}</p>
            <CustomPieChart
              width='100%'
              height={400}
              data={gd.data}
              dataKey='count'
              nameKey={isEnglish ? 'name' : 'nameZh'}
              outerRadius={100}
              labelLine={true}
              label={renderCustomizedLabel}
            />
          </div>
        ))}
        {top10DetectionBylayer.map((gd, i) => (
          <div key={i} style={{ border: '1px solid #ccc' }}>
            <p className='graph-title'>{isEnglish ? gd.name : gd.nameZh}</p>
            <ResponsiveContainer width='100%' height={700}>
              {!checkEmptiness(gd.data) && !isEmpty(gd.data) ? (
                <BarChart
                  layout={'vertical'}
                  data={gd.data.sort((a, b) => b.count - a.count)}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis type='number'>
                    <Label
                      value={t('totalCount')}
                      offset={-5}
                      position='insideBottom'
                    />
                  </XAxis>
                  <YAxis
                    dataKey='name'
                    type='category'
                    tick={{ padding: '10px', margin: '10px' }}
                  >
                    <Label
                      angle={-90}
                      value={t('sensorName')}
                      offset={-12}
                      position='insideLeft'
                    />
                  </YAxis>
                  <Tooltip />
                  <Legend verticalAlign='top' />
                  <Bar
                    dataKey={isEnglish ? 'count' : '數量'}
                    barSize={20}
                    fill='#8884d8'
                  />
                </BarChart>
              ) : (
                <div style={{ width: '100%' }}>
                  <Typography.Text>{t('noData')}</Typography.Text>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    </div>
  );
};
