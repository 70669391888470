import React from 'react';
import { Field } from 'formik';
import { AntInput, AntSelect } from '../index';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const FirstSection = ({ submitCount, sites }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={16}>
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <Field
          component={AntInput}
          type='text'
          name='name'
          label={t('role')}
          placeholder={t('pleaseInputRole')}
          hasFeedback
          required
          submitCount={submitCount}
        />
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <Field
          component={AntSelect}
          name='siteId'
          label={t('site')}
          placeholder={t('pleaseSelectSite')}
          hasFeedback
          selectOptions={sites}
          required
          submitCount={submitCount}
        />
      </Col>
    </Row>
  );
};
