import classes from './index.module.css';
import React from 'react';
import { Breadcrumbs, SiteForm } from '../../../components';
import { Formik } from 'formik';
import { EditSiteInformationSchema } from '../../../utilities/schemas';
import { notification, Spin } from 'antd';
import { useAddSite } from '../../../services/sites';
import { useTranslation } from 'react-i18next';

const initialValues =
  process.env.NODE_ENV === 'development'
    ? {
        englishName: '',
        chineseName: '',
        siteKey: '',
        address: '',
        contactEmail: '',
        contactPhone: '',
        contactPerson: '',
        remark: '',
        triggerMedium: 'SMS',
        emailAlertList: '',
        smsAlertList: '',
        emailList: '',
        accountAlertList: '',
        floors: [],
        layers: [],
        zones: [],
      }
    : {
        englishName: '',
        chineseName: '',
        siteKey: '',
        address: '',
        contactEmail: '',
        contactPhone: '',
        contactPerson: '',
        remark: '',
        triggerMedium: 'SMS',
        emailAlertList: '',
        smsAlertList: '',
        emailList: '',
        accountAlertList: '',
        floors: [],
        layers: [],
        zones: [],
      };

export const AddSite = () => {
  const { t } = useTranslation();
  const { mutate } = useAddSite();
  return (
    <div className={classes.content}>
      <Breadcrumbs
        crumbs={[
          {
            name: t('settings'),
            link: '/settings',
          },
          {
            name: t('siteManagement'),
            link: '/site-management',
          },
          {
            name: t('add'),
            link: '/site-management/add',
          },
        ]}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={EditSiteInformationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true);
            const castValues = EditSiteInformationSchema.cast(values);
            // const { englishName, chineseName, ...rest } = castValues;
            // await mutate({ name: englishName, name_zh: chineseName, ...rest });
            await mutate(castValues);
            actions.resetForm();
            notification.success({ message: t('createdSuccess') });
          } catch ({ message }) {
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, values, submitCount, resetForm, isSubmitting }) => {
          return (
            <Spin spinning={isSubmitting}>
              <SiteForm
                isEditMode={false}
                handleSubmit={handleSubmit}
                values={values}
                submitCount={submitCount}
                resetForm={resetForm}
              />
            </Spin>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddSite;
