import React from 'react';
import { Col, Image, Layout, notification, Row } from 'antd';
import classes from './index.module.scss';
import { Images } from '../../images';
import { Formik } from 'formik';
import { LoginSchema } from '../../utilities/schemas';
import { useLogin } from '../../services/auth';
import { LoginForm } from '../../components';
import { useUserProvider } from '../../context/session';
import isEmpty from 'lodash/isEmpty';
import {
  FORM_STORAGE_KEYS,
  getStorageItem,
  setStorageItem,
} from '../../utilities/storage';

const initialValues = JSON.parse(getStorageItem(FORM_STORAGE_KEYS.LOGIN)) || {
  // username: 'test@gmail.com',
  // password: 'test123',

  // username: 'test1@gmail.com',
  // password: '00000000',

  // username: 'test7@gmail.com',
  // password: 'test123',
  //
  username: 'test5@gmail.com',
  password: 'test123',
  // username: '',
  // password: '',
};

export const Login = () => {
  const { t } = useLogin();
  const { mutate, status } = useLogin();
  const [user] = useUserProvider();

  if (!isEmpty(user)) {
    //return <Navigate to='/' replace />;
  }

  const onLogin = async (data) => {
    try {
      setStorageItem(FORM_STORAGE_KEYS.LOGIN, JSON.stringify(data));
      await mutate(data);
    } catch (e) {
      notification.error({ message: t('serverError') });
    }
  };
  return (
    <Layout className={classes.container}>
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          type='flex'
          align='middle'
          style={{ marginBottom: 10 }}
        >
          <Image width={220} preview={false} src={Images.projectName} />
        </Col>
        <Col
          xs={{ span: 20, offset: 2 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          className={classes.loginForm}
        >
          <Row justify='space-around' align='middle'>
            <Col xs={12} type='flex' align='end'>
              <Image width={120} preview={false} src={Images.emsdLogo2} />
            </Col>
            <Col xs={12} type='flex' align='middle'>
              <Image width={120} preview={false} src={Images.emsdLogo} />
            </Col>
          </Row>
          <Row>
            <br />
            <br />
            <br />
            <Col xs={24}>
              <Formik
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={onLogin}
              >
                {({ handleSubmit, submitCount, resetForm }) => {
                  return (
                    <LoginForm
                      handleSubmit={handleSubmit}
                      submitCount={submitCount}
                      resetForm={resetForm}
                      isLoading={status === 'pending'}
                    />
                  );
                }}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
